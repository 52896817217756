import styles from './table-widget.module.css';
import { Link } from 'react-router-dom';
//components
import ScanStatus from './TableWidget/components/ScanStatus/ScanStatus';
// import ScanStatus from './components/ScanStatus';
import ActionsPanelMiMo from './TableTypes/MiMoTable/components/ActionPanel/ActionPanel';
import ActionsPanelReports from './TableTypes/ReportsTable/components/ActionPanel/ActionPanel';
import ActionsPanelScans from './TableTypes/ScansSubmissionTable/components/ActionPanel/ActionPanel';
import ActionsPanelUnits from './TableTypes/UnitsTable/components/ActionPanel';
import ActionsPanelScansDone from './TableTypes/ScansDoneTable/components/ActionPanel/ActionPanel';
//constants
import { CategoryFilter, DATE_FORMAT } from '../../constants';
//utils
import { dateTimeFormater } from '../../utils/date_utils';
import { Button, Chip } from '@mui/material';
import ActionsPanelJourneys from './TableTypes/JourneysTable/components/ActionPanel/ActionPanel';
import SwitchStatus from './TableTypes/JourneysTable/components/StatusSwitch/SwitchStatus';
import AddIcon from '@mui/icons-material/Add';
import ActionsPanelSchedules from './TableTypes/InspectionSchedulesTable/components/ActionPanel/ActionPanel';


export const CommonHeader = {
    REFERENCE: 'Reference',
    UNITS: 'Units',
    TENANT_NAME: 'Tenant Name',
    SCAN_STATUS: 'Scan Status',
    ACTION: 'Actions',
};

export const colorKeysConfig = {
    'done': 'text-green-500',
    'missing': 'text-red-500',
    'init': 'text-blue-500',
    'failed': styles.textOrange
}

const buildColumnDictionary = ({
    key, value, isSortable=false, isVisibleOnDesktop=true, isVisibleOnMobile=true, 
    headersExtraClasses='', cellsExtraClasses='', customRenderer=null, showTitle=false
}) => (
    {
        key,
        value,
        isSortable,
        isVisibleOnMobile,
        isVisibleOnDesktop,
        headersExtraClasses,
        cellsExtraClasses,
        ...(customRenderer ? {customRenderer: customRenderer} : {}),
        showTitle
    }
)

export const tableConfigurations = {
    [CategoryFilter.MOVE_IN]: [
        buildColumnDictionary({key: 'ext_ref', value: 'Reference', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'subject', value: 'Units', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'tenant_name', value: 'Tenant Name', cellsExtraClasses: ' max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary(
            {
                key: 'check_in', value: 'Move-In', isSortable: true,
                customRenderer: (rowData) => dateTimeFormater(rowData.check_in, DATE_FORMAT)
            }
        ),
        buildColumnDictionary(
            {
                key: 'scanStatus', value: 'Scan Status', isSortable: true,
                customRenderer: (rowData) => (
                    <ScanStatus 
                        scan_date={rowData.scan_date}
                        latest_scan_date={rowData.latest_scan_date}
                        scan_datetime={rowData.scan_datetime}
                        baseline_date={rowData.baseline_date}
                    />
                )
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelMiMo
                            pid={rowData.pid}
                            subject={rowData.subject}
                            latest_report_id={rowData.latest_report_id}
                            ext_ref={rowData.ext_ref}
                            changeSetNotifState={setNotifState}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.MOVE_OUT]: [
        buildColumnDictionary({key: 'ext_ref', value: 'Reference', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'subject', value: 'Units', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'tenant_name', value: 'Tenant Name', cellsExtraClasses: ' max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary(
            {
                key: 'check_out', value: 'Move-Out', isSortable: true,
                customRenderer: (rowData) => dateTimeFormater(rowData.check_out, DATE_FORMAT)
            }
        ),
        buildColumnDictionary(
            {
                key: 'scanStatus', value: 'Scan Status', isSortable: true,
                customRenderer: (rowData) => (
                    <ScanStatus 
                        scan_date={rowData.scan_date}
                        latest_scan_date={rowData.latest_scan_date}
                        scan_datetime={rowData.scan_datetime}
                        baseline_date={rowData.baseline_date}
                    />
                )
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelMiMo
                            pid={rowData.pid}
                            subject={rowData.subject}
                            latest_report_id={rowData.latest_report_id}
                            ext_ref={rowData.ext_ref}
                            changeSetNotifState={setNotifState}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.REPORTS]: [
        buildColumnDictionary(
            {
                key: 'ext_ref', value: 'Reference', headersExtraClasses: ' width-in1000 max-w-in200 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in200 min-w-in0 in-lg:max-w-in150 truncate', 
                isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'subject', value: 'Unit', cellsExtraClasses: ' in-lg:max-w-in150 truncate', isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'tenant_name', value: 'Tenant Name', headersExtraClasses: ' width-in1000 max-w-in170 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in170 min-w-in0 truncate', 
                isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'inspection_type', value: 'Report Type', headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0', isSortable: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'report_date', value: 'Report Date', isSortable: true, 
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0', cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => dateTimeFormater(rowData.report_date, DATE_FORMAT)
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                cellsExtraClasses: ' width-in1000 max-w-in120 min-w-in0 text-right',
                headersExtraClasses: ' width-in1000 max-w-in120 min-w-in0',
                customRenderer: (rowData) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelReports
                            pid={rowData.pid}
                            subject={rowData.subject}
                            report_id={rowData.report_id}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.SCANS]: [
        buildColumnDictionary({key: 'ext_ref', value: 'Reference', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'subject', value: 'Unit', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'tenant_name', value: 'Tenant Name', cellsExtraClasses: ' max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'inspection_type', value: 'Inspection Type', isSortable: true}),
        buildColumnDictionary(
            {
                key: 'date_submitted', value: 'Date Submitted', isSortable: true,
                customRenderer: (rowData) => dateTimeFormater(rowData.date_submitted, DATE_FORMAT)
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                customRenderer: (rowData) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelScans
                            pid={rowData.pid}
                            subject={rowData.subject}
                            report_id={rowData.report_id}
                            videoLink={rowData.ts}
                            submission_status={rowData.submission_status}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.SCANS_DONE]: [
        buildColumnDictionary(
            {
                key: 'performer', value: 'Performer', cellsExtraClasses: ' max-w-in150 truncate capitalize', 
                isSortable: true, showTitle: true,
                customRenderer: (rowData) => rowData.performer ? rowData.performer : rowData.performer_id ? rowData.performer_id : "N/A"
            }),
        buildColumnDictionary({key: 'ext_ref', value: 'Reference', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'subject', value: 'Unit', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'scan_type', value: 'Inspection Type', cellsExtraClasses: ' capitalize', isSortable: true}),
        buildColumnDictionary(
            {
                key: 'date_submitted', value: 'Started At', isSortable: true,
                customRenderer: (rowData) => dateTimeFormater(rowData.scan_datetime, DATE_FORMAT)
            }
        ),
        buildColumnDictionary(
            {
                key: 'scan_status', value: 'Status',
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => {
                    let scan_status = rowData.scan_status.toLowerCase();
                    const dateDistance = Math.abs(new Date() - new Date(rowData.scan_datetime));
                    if (
                        (dateDistance > 24 * 60 * 60 * 1000) && 
                        (scan_status === "initialized" || scan_status === "ongoing")
                    ) {
                        scan_status = "stale session";
                    }
                    return (
                        <ScanStatus 
                            scan_status={"scan - " + scan_status}
                            baseline_date={"s"}
                            scan_datetime={rowData.scan_datetime}
                        />
                    );
                }
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                cellsExtraClasses: ' width-in1000 max-w-in120 min-w-in0 text-right',
                headersExtraClasses: ' width-in1000 max-w-in120 min-w-in0',
                customRenderer: (rowData) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelScansDone
                            pid={rowData.pid}
                            subject={rowData.subject}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.UNITS]: [
        buildColumnDictionary(
            {
                key: 'ext_ref', value: 'Reference', headersExtraClasses: ' width-in1000 max-w-in250 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in250 min-w-in0 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'subject', value: 'Units', cellsExtraClasses: ' in-lg:max-w-in150 truncate', isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'lastReport', value: 'Last Report', isSortable: true,
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => (
                    rowData.latest_scan_date ?
                        <ScanStatus 
                            scan_status="No Status"
                            latest_scan_date={rowData.latest_scan_date}
                            scan_datetime={rowData.latest_scan_date}
                            baseline_date={rowData.baseline_date}
                        />
                        :
                        "N/A"
                )
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                cellsExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4 ml-4">
                        <ActionsPanelUnits
                            pid={rowData.pid}
                            subject={rowData.subject}
                            latest_report_id={rowData.latest_report_id}
                            ext_ref={rowData.ext_ref}
                            scan_id={rowData.scan_id}
                            baseline_date={rowData.baseline_date}
                            changeSetNotifState={setNotifState}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.INSPECTION_JOURNEYS]: [
        buildColumnDictionary(
            {
                key: 'name', value: 'Name', cellsExtraClasses: ' in-lg:max-w-in150 truncate', 
                isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'assignedSchedules', value: 'Assigned Schedules', isSortable: false,
                cellsExtraClasses: ' width-in1000 max-w-in250 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in250 min-w-in0',
                customRenderer: (rowData) => {
                    const assigned_schedules = JSON.parse(rowData?.assigned_schedules || '[]');
                    return (
                        assigned_schedules && assigned_schedules.length > 0 ?
                            <>
                                <div className='flex flex-wrap gap-1'>
                                    {assigned_schedules.map((schedule, index) => (
                                        <Chip
                                            label={schedule.split("_").join(" ").split(" ").map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(" ")}
                                            color="primary"
                                        />
                                    ))}
                                </div>
                            </>
                            :
                            "N/A"
                    );
                }
            }
        ),
        buildColumnDictionary(
            {
                key: 'targets', value: 'Targets', isSortable: false,
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => (
                    <span title={rowData.targets_num + " Targets"}>
                        {rowData.targets_num} Targets
                    </span>
                )
            }
        ),
        buildColumnDictionary(
            {
                key: 'status', value: 'Status', isSortable: true,
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData, setNotifState) => (
                    <SwitchStatus 
                        jid={rowData.id}
                        ogStatus={rowData.status === "active"}
                        setNotifState={setNotifState}
                    />
                )
            }
        ),
        buildColumnDictionary(
            {
                key: 'created_at', value: 'Created At', isSortable: true,
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => {
                    const m = rowData?.metadata ? JSON.parse(rowData.metadata)?.general : {};
                    return (
                        <span title={m?.created?.at}>
                            {dateTimeFormater(m?.created?.at, DATE_FORMAT)}
                            {m?.modified?.at ?
                                <>
                                    <br />
                                    <span className="text-xs text-gray-500">
                                        Last Modified: {dateTimeFormater(m?.modified?.at, DATE_FORMAT + " HH:MM")}
                                    </span>
                                </> : ""
                            }
                        </span>
                    );
                }
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                cellsExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4 ml-4">
                        <ActionsPanelJourneys
                            jid={rowData.id}
                            journeyName={rowData.name}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.INSPECTION_SCHEDULES]: [
        buildColumnDictionary(
            {
                key: 'inspection_type', value: 'Inspection Type', 
                cellsExtraClasses: ' in-lg:max-w-in150 truncate', 
                isSortable: false, showTitle: true,
                customRenderer: (rowData) => {
                    const inspectionType = rowData.inspection_type.split("_").join(" ").split(" ").map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(" ");
                    return (
                        <span title={inspectionType}>
                            {inspectionType}
                        </span>
                    )
                }
            }
        ),
        buildColumnDictionary(
            {
                key: 'assigned_to', value: 'Assigned To', isSortable: false,
                cellsExtraClasses: ' width-in1000 max-w-in250 min-w-in0 truncate',
                headersExtraClasses: ' width-in1000 max-w-in250 min-w-in0',
                customRenderer: (rowData) => {
                    const assignedTo = JSON.parse(rowData.assigned_to);
                    const membersData = rowData.assigned_to_members_data ? JSON.parse(rowData.assigned_to_members_data) : [];
                    // const members = rowData.assigned_to?.members ? JSON.parse(rowData.assigned_to.members) : [];
                    const title = assignedTo.type === "tenant" ? "Resident" : (membersData || []).map(x => `${x.first_name} ${x.last_name}`).join(", ");
                    return (
                        <span title={title}>
                            {title}
                        </span>
                    );
                }
            }
        ),
        buildColumnDictionary(
            {
                key: 'dueDateRange', value: 'Due Date Range', isSortable: false,
                cellsExtraClasses: ' width-in1000 max-w-in210 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in210 min-w-in0',
                customRenderer: (rowData) => {
                    const dueRange = JSON.parse(rowData.due_range);
                    const title = `${dueRange[0]} Days Before - ${dueRange[1]} Days After`;
                    return (
                        <span title={title}>
                            {dueRange[0]} Days Before - {dueRange[1]} Days After
                        </span>
                    );
                }
            }
        ),
        buildColumnDictionary(
            {
                key: 'notices', value: 'Notices', isSortable: false,
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData, setNotifState) => {
                    const notices = JSON.parse(rowData.notices);
                    const noticesNum = Object.keys(notices).length;
                    return (
                        noticesNum === 0 ?
                            <Link 
                                className="cursor-pointer" 
                                title="Set Up Notices" 
                                to={`/schedule/edit/${rowData.id}`}
                            >
                                <Button
                                    variant="contained"
                                    // startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}  
                                    sx={{
                                        backgroundColor: '#4F46E5', 
                                        color: 'white',
                                        fontSize: '11px',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        padding: '5px 10px',
                                        '&:hover': { backgroundColor: '#4338CA' } 
                                    }}
                                >
                                    Set Up Notices
                                </Button>
                            </Link>
                            :
                            <span title={noticesNum + " Notices"}>
                                {noticesNum} Notices
                            </span>
                    )
                }
            }
        ),
        buildColumnDictionary(
            {
                key: 'created_at', value: 'Created At', isSortable: false,
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => {
                    const m = rowData?.metadata ? JSON.parse(rowData.metadata)?.general : {};
                    return (
                        <span title={m?.created?.at}>
                            {dateTimeFormater(m?.created?.at, DATE_FORMAT)}
                            {m?.modified?.at ?
                                <>
                                    <br />
                                    <span className="text-xs text-gray-500">
                                        Last Modified: {dateTimeFormater(m?.modified?.at, DATE_FORMAT + " HH:MM")}
                                    </span>
                                </> : ""
                            }
                        </span>
                    );
                }
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                cellsExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4 ml-4">
                        <ActionsPanelSchedules
                            sid={rowData.id}
                            due_range={rowData.due_range}
                            assigned_to={rowData.assigned_to}
                            notices={rowData.notices}
                        />
                    </div>
                )
            }
        ),
    ]
};
