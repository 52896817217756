import React from 'react'

const cancelIcon = ({ fill = 'none', width = '18px', height = '18px', styleClass = "" }) => {
  return (
    <svg
        className={`transition duration-75 ${styleClass}`}
        width={width}
        height={height}
        fill={fill}
        viewBox='0 0 18 18'
      >
        <path d="M8.66602 16.5C12.8082 16.5 16.166 13.1421 16.166 9C16.166 4.85786 12.8082 1.5 8.66602 1.5C4.52388 1.5 1.16602 4.85786 1.16602 9C1.16602 13.1421 4.52388 16.5 8.66602 16.5Z" stroke="#FC5555" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.916 6.75L6.41602 11.25M6.41602 6.75L10.916 11.25" stroke="#FC5555" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default cancelIcon