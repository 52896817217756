import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios/axios';
import { para_be } from '../../config';

const startLoading = state => {
    state.loading = true;
    state.error = null;
};

const loadingFailed = (state, action) => {
    state.loading = false;
    state.error = action.payload;
};

const loadingSuccess = payloadSetter => (state, action) => {
    state.loading = false;
    payloadSetter(state, action.payload);
};

// Async thunk for fetching billing details
export const fetchBillingDetails = createAsyncThunk('billing/fetchBillingDetails', async (_, { rejectWithValue }) => {
    try {
        const { data } = await axios.get(`${para_be}/payments/get_billing_details`, { withCredentials: true, });
        return data.billing_details;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

// Async thunk for setting billing details
export const setPaymentMethod = createAsyncThunk('billing/setPaymentMethod', async ({ paymentMethodId }, { rejectWithValue }) => {
    try {
        const requestBody = {
            payment_method_id: paymentMethodId,
        };

        const { data } = await axios.post(`${para_be}/payments/set_payment_method`, requestBody, { withCredentials: true, });
        if (data.status >= 400) {
            const cleanedMessage = data.msg.replace(/^Request\s+\w+:\s*/, '');
            return rejectWithValue(cleanedMessage);
        }

        return { paymentMethodId };
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

// Async thunk for removing a billing method
export const removeBillingMethod = createAsyncThunk('billing/removeBillingMethod', async (paymentMethodId, { rejectWithValue }) => {
    try {
        const { data } = await axios.delete(`${para_be}/payments/remove_payment_method`, {
            withCredentials: true,
            data: { payment_method_id: paymentMethodId },
        });

        if (data.status !== 204) {
            const cleanedMessage = data.msg?.replace(/^Request\s+\w+:\s*/, '') || 'Failed to remove billing method';
            return rejectWithValue(cleanedMessage);
        }
        return paymentMethodId;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

export const setDefaultPaymentMethod = createAsyncThunk('billing/setDefaultPaymentMethod', async (paymentMethodId, { rejectWithValue }) => {
    try {
        const { data } = await axios.post(
            `${para_be}/payments/set_default_payment_method`,
            { payment_method_id: paymentMethodId },
            {
                withCredentials: true,
            }
        );

        if (data.status !== 200) {
            const cleanedMessage = data.msg.replace(/^Request\s+\w+:\s*/, '') || 'Failed to set default payment method.';
            return rejectWithValue(cleanedMessage);
        }
        return paymentMethodId;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

const initialState = {
    loading: false,
    error: null,
    paymentMethodId: null,
    billingDetails: null,
};

const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {
        clearPaymentState: (state) => {
            state.loading = false;
            state.error = null;
            state.paymentMethodId = null;
            state.billingDetails = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchBillingDetails.pending, startLoading)
        .addCase(
            fetchBillingDetails.fulfilled,
            loadingSuccess((state, details) => (state.billingDetails = details))
        )
        .addCase(fetchBillingDetails.rejected, loadingFailed)
        .addCase(setPaymentMethod.pending, startLoading)
        .addCase(
            setPaymentMethod.fulfilled,
            loadingSuccess((state, paymentMethodId) => (state.paymentMethodId = paymentMethodId))
        )
        .addCase(setPaymentMethod.rejected, loadingFailed)
        .addCase(removeBillingMethod.pending, startLoading)
        .addCase(
            removeBillingMethod.fulfilled,
            loadingSuccess((state, id) => {
                state.billingDetails.payment_methods = state.billingDetails?.payment_methods?.filter(method => method.id !== id);
            })
        )
        .addCase(removeBillingMethod.rejected, loadingFailed)
        .addCase(setDefaultPaymentMethod.pending, startLoading)
        .addCase(
            setDefaultPaymentMethod.fulfilled,
            loadingSuccess((state, id) => {
                if (state.billingDetails && state.billingDetails.payment_methods) {
                    state.billingDetails.payment_methods.forEach(method => {
                    method.is_default = method.id === id;
                    });
                }
            })
        )
        .addCase(setDefaultPaymentMethod.rejected, loadingFailed);
    },
});

export const { clearPaymentState, clearError, setError } = billingSlice.actions;
export default billingSlice.reducer;
