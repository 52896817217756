import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { Button } from '@mui/material';
//components
import SnackBar from '../../../../../../components/SnackBar';
//assets
import { BanknotesIcon, PencilIcon } from '@heroicons/react/20/solid';
//constants
import { SeverityLevels } from '../../../../../../constants';
import { fetchCustomerInvoices } from '../../../../../../store/slices/customerSlice';
import CircularLoader from '../../../../../../components/loaders/CircularLoader';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { classNames } from '../../../../../../utils/main_utils';


const statusStyles = {
    paid: 'bg-green-100 text-green-800',
    open: 'bg-blue-100 text-blue-800',
    void: 'bg-red-100 text-red-800',
    draft: 'bg-gray-100 text-gray-800',
};

const CustomerInvoices = () => {
    const dispatch = useDispatch();
    const { customerInvoices, fetchCustomerInvoices: { loading, error } } = useSelector(state => state.customer);
    const [notification, setNotification] = useState({
        open: false,
        severity: SeverityLevels.SUCCESS,
        message: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchCustomerInvoices()).unwrap();
            } catch (err) {
                console.error('Failed to fetch subscription data:', err);
            }
        };
        fetchData();
    }, [dispatch]);

    const handleNotificationClose = () => {
        setNotification({ ...notification, open: false });
    };


    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <CircularLoader size={36} color="secondary" />
            </div>
        );
    } else if (error) {
        return <div>Error: {error}</div>;
    }
    else if (!customerInvoices) {
        return <div>No customer data available</div>;
    }
    
    return (
        <>
            <div className="min-h-full">
                <div className="flex flex-1 flex-col">
                    <main className="flex-1 pb-8">
                        <div className="flex justify-between items-center mb-6 mt-6"> 
                            <div>
                                <h2 className="mx-auto max-w-6xl text-lg font-medium leading-6 text-gray-900 mb-4">Invoices</h2>
                            </div>
                            <div className="flex">
                            </div>
                        </div>

                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl">
                                <div className="mt-2 flex flex-col">
                                    {/* <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"> */}
                                    <div className="min-w-full align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 min-w-32 text-left text-sm font-semibold text-gray-900">
                                                        Charge
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-left text-sm font-semibold text-gray-900">
                                                        Customer
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Date
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {
                                                    customerInvoices.map((customerInvoice, index) => (
                                                        <tr className="bg-white">
                                                            <td className="w-full max-w-0 whitespace-nowrap sm:px-2 lg:px-6 py-4 text-sm text-gray-900">
                                                                <div className='flex'>
                                                                    <BanknotesIcon 
                                                                        aria-hidden="true"
                                                                        className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                                                                    <span className='ml-2 truncate text-gray-500 group-hover:text-gray-900'>
                                                                        {(customerInvoice.total/100).toLocaleString("en-US", {style: 'currency', currency: customerInvoice.currency})}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                                <span className='truncate text-gray-500 group-hover:text-gray-900'>
                                                                    {customerInvoice.customer_name}
                                                                </span>
                                                            </td>
                                                            <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                                <time dateTime={new Date(customerInvoice.created * 1000).toISOString()}>{new Date(customerInvoice.created * 1000).toLocaleDateString()}</time>
                                                            </td>
                                                            <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                                <span
                                                                    className={classNames(
                                                                        statusStyles[customerInvoice.status] || 'bg-gray-100 text-gray-800',
                                                                        'inline-flex items-center rounded-full px-4 py-1 text-xs font-medium capitalize'
                                                                    )}
                                                                >
                                                                    {customerInvoice.status}
                                                                </span>
                                                            </td>
                                                            <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-right text-sm text-gray-500">
                                                                <div className="flex justify-end gap-x-2">
                                                                    <Button
                                                                        variant="contained"
                                                                        startIcon={<ArrowDownTrayIcon sx={{ width: 20, height: 20 }} />}  
                                                                        onClick={()=>{}}
                                                                        sx={{
                                                                            backgroundColor: '#4F46E5', 
                                                                            color: 'white',
                                                                            fontSize: '12px',
                                                                            textTransform: 'none',
                                                                            borderRadius: '8px',
                                                                            padding: '6px 12px',
                                                                            '&:hover': { backgroundColor: '#4338CA' } 
                                                                        }}
                                                                        href={customerInvoice.invoice_pdf}
                                                                        download={"Paraspot_Invoice_" + customerInvoice.created + ".pdf"}
                                                                    >
                                                                        Download Invoice
                                                                    </Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shadow sm:hidden">
                            <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {
                                    customerInvoices.map((customerInvoice, index) => (
                                        <li>
                                            <div className="block bg-white px-4 py-4 hover:bg-gray-50">
                                                <span className="flex items-center space-x-4">
                                                    <span className="flex flex-1 space-x-2 truncate">
                                                        <BanknotesIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                                        <span className="ml-2 flex flex-col truncate text-sm text-gray-500">
                                                            <span className='truncate'>
                                                                {(customerInvoice.total/100).toLocaleString("en-US", {style: 'currency', currency: customerInvoice.currency})}
                                                            </span>
                                                            <span className='truncate'>
                                                                Customer: &nbsp;
                                                                {customerInvoice.customer_name}
                                                            </span>
                                                            <span className='truncate'>
                                                                Date: &nbsp;
                                                                <time dateTime={new Date(customerInvoice.created * 1000).toISOString()}>{new Date(customerInvoice.created * 1000).toLocaleDateString()}</time>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>

                                                <div className="flex justify-between mt-4">
                                                    <div className="flex">
                                                        <span
                                                            className={classNames(
                                                                statusStyles[customerInvoice.status] || 'bg-gray-100 text-gray-800',
                                                                'inline-flex items-center rounded-full px-4 py-1 text-xs font-medium capitalize'
                                                            )}
                                                        >
                                                            {customerInvoice.status}
                                                        </span>
                                                    </div>
                                                    <div className="flex gap-x-2">
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<ArrowDownTrayIcon sx={{ width: 20, height: 20 }} />}  
                                                            onClick={()=>{}}
                                                            sx={{
                                                                backgroundColor: '#4F46E5', 
                                                                color: 'white',
                                                                fontSize: '12px',
                                                                textTransform: 'none',
                                                                borderRadius: '8px',
                                                                padding: '6px 12px',
                                                                '&:hover': { backgroundColor: '#4338CA' } 
                                                            }}
                                                            href={customerInvoice.invoice_pdf}
                                                            download={"Paraspot_Invoice_" + customerInvoice.created + ".pdf"}
                                                        >
                                                            Download Invoice
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </main>
                </div>
            </div>

            {/* Notification */}
            {notification.open &&
                <SnackBar 
                    open={notification.open}
                    handleClose={handleNotificationClose}
                    severity={notification.severity}
                    message={notification.message}
                    duration={6000} />
            }
        </>
    );
};

export default CustomerInvoices;
