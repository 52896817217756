import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//components
import Notification from '../../../../../side_notification/side_notification';
import EmptyState from '../../../../../emptyState/empty_state';
import CircularProgress from '../../../../../loaders/CircularLoader';
import BaseModal from '../../../../../Modal';
//utils
import { dateTimeFormater } from '../../../../../../utils/date_utils';
//constants
import { para_be } from '../../../../../../config';


const AllTenancies = ({ open, handleClose, pid, subject }) => {
    const [newData, setNewData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEmptyState, setEmptyState] = useState(false);
    const [notifState, setNotifState] = useState(null);
    
    useEffect(() => {
        if (pid) {
            let searchParams = { pid: pid };
            fetch(para_be + '/tenancies/search?filters=' + JSON.stringify(searchParams), { credentials: 'include', })
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    if (response.result.data.length > 0) {
                        setNewData(response.result.data.sort((a, b) => (new Date(a[4]) > new Date(b[4]) ? -1 : 1)));
                        setEmptyState(false);
                    } else setEmptyState(true);
                } else setNotifState(response.msg);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setNotifState('An error has occurred, please try again later');
                setIsLoading(false);
                setEmptyState(true);
            });
        }
    }, [pid]);
    
    const handleCloseNotif = () => {
        setNotifState(null);
    };
    
    return (
        <BaseModal open={open} onClose={handleClose} minWidth={400}>
            <div className="ar-container">
                {isLoading ? (
                    <div className="flex justify-center items-center h-72">
                        <CircularProgress size={24} />
                    </div>
                ) : (
                    <div>
                        <div className="h2 blue-headline">All Tenancies</div>
                        <div className="text-1 ar-address">{subject}</div>
                        <div className="ar-body custom-scrollbar-1" style={{ height: 'calc(100% - 92.5px)' }}>
                            {isEmptyState ? (
                                <EmptyState text="No Tenancies available." size="md" />
                            ) : (
                                newData.map(item => {
                                    const [tenancy_id, _, __, ___, checkin, checkout, deposit, rent, contact, metadata, client_name] = item;
                                    const { email, mobile: phone } = contact;
                                    const dateTimeToPrintCheckIn = dateTimeFormater(checkin, 'dd/mm/yyyy');
                                    const dateTimeToPrintCheckOut = dateTimeFormater(checkout, 'dd/mm/yyyy');
                                    const outRent = rent && typeof rent === 'string' ? JSON.parse(rent).price : rent ? rent.price : 'NaN';
                                    const outDeposit = deposit && typeof deposit === 'string' ? JSON.parse(deposit).price : deposit ? deposit.price : 'NaN';
                                    
                                    return (
                                        <div key={item.id}>
                                            <Link className="ar-link" to={'#'}>
                                                <div className="ar-item-header">
                                                    <div className="text-1-2 ar-item-date">{client_name}</div>
                                                    <div className="text-1-2 ar-item-date">
                                                        {dateTimeToPrintCheckIn} - {dateTimeToPrintCheckOut}
                                                    </div>
                                                </div>
                                                <div className="info-container">
                                                    <div className="info-label">
                                                        Email:
                                                        <span className="info-value">{email}</span>
                                                    </div>
                                                    <div className="info-label">
                                                        Mobile:
                                                        <span className="info-value">{phone}</span>
                                                    </div>
                                                    <div className="info-label">
                                                        Rent:
                                                        <span className="info-value">{outRent}</span>
                                                    </div>
                                                    <div className="info-label">
                                                        Deposit:
                                                        <span className="info-value">{outDeposit}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                )}
                {notifState && 
                    <Notification closeFunc={handleCloseNotif} text={notifState} type="error" />
                }
            </div>
        </BaseModal>
    );
};

export default AllTenancies;