import React from "react";
import { postReqOptBuilder } from "../../../utils/main_utils";
//components
import Btn from "../../buttons/standard/btn";
//styles
import "./report_card.css";

export default function Card(props) {

    const onAddToChecklist = () => {
        fetch(props.para_be + "/checklists/new", postReqOptBuilder({report_id: props.report_id, subject: props.subject, description: props.description ? props.description : "", media: props.media}))
        .then(response => response.json())
        .then(response => {
            if (props.onAddToChecklist) props.onAddToChecklist();
        });
    };

    const onIgnoreItem = () => {
        fetch(props.para_be + "/reports/ignore_report_item", postReqOptBuilder({report_id: props.report_id, item: props.itemID}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                if (!props.noRemoveOnIgnore) document.getElementById("det-" + props.itemID).remove();
                if (props.onIgnoreItem) props.onIgnoreItem();
            } else {
                if (props.onError) props.onError("Failed to ignore report item. Please try again later");
            }
        })
    }

    return (
        <section className="card-body flexColumn" id={"det-" + props.itemID}>
            <div className="bd-data-container flexRow">
                <img src={props.media} alt="" onClick={props.onImageClick}/>
                <div className="flexColumn">
                    <div className="text-1-3">{props.subject}</div>
                    {props?.category &&
                        <div>
                            <span class="para-label para-l-info para-label-breakable">{props.category}</span>
                        </div>
                    }
                    <div className="text-1">{props.description}</div>
                    {/* <div className="text-1"><u>Where:</u>{props.where}</div>
                    <div className="text-1"><u>position:</u>{props.position}</div> */}
                </div>
            </div>
            <div className="flexRow buttons-wrapper">
                <Btn
                    text="Ignore"
                    type="secondary"
                    onclick={onIgnoreItem}
                    />
                <Btn
                    text="Add to Checklist"
                    type="primary"
                    onclick={onAddToChecklist}
                    />
                </div>
        </section>
    )
}