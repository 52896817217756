import React from 'react';
import { ThreeDots } from 'react-loader-spinner'


const ThreeDotsLoder = ({height,width,color,radius,wrapperStyle,wrapperClass}) => {
    return (
        <>
            <ThreeDots
                visible={true}
                height={height}
                width={width}
                color={color}
                radius={radius}
                ariaLabel="three-dots-loading"
                wrapperStyle={wrapperStyle}
                wrapperClass={wrapperClass}
            />
        </>
    )
}

export default ThreeDotsLoder
