import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
//components
import Btn from "../../components/buttons/standard/btn";
import HideTextInput from "../../components/inputs/hide_text_input/input"
import SimpleInput from "../../components/inputs/simple_input/input";
//utils
import {getClientData, postReqOptBuilder} from "../../utils/main_utils";
//hooks
import useAuthenticate from '../../hooks/useAuthenticate';
//styles
import "./login.css";
//assets
import logo from "../../assets/icons/logo.svg";
import ChangePasswordPopup from "../../components/popups/change_password/change_password";
import BackBtn from "../../components/buttons/back_btn/back";

export default function Login(props) {
    const cred = useSelector((state) => state.auth.cred);

    const [inputError, setInputError] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [showForgotPassModal, setShowForgotPassModal] = useState(false);

    const {authentication} = useAuthenticate();
    
    let history = useHistory();
    useEffect(()=> {
        getClientData().then(data => {
            setClientData(data);
        });
    // eslint-disable-next-line no-sequences
    }, []);

    const handleLogin = async (event) => {
        event.preventDefault();
        const userName = document.getElementById("email_pn").value;
        const password = document.getElementById("password").value;
        if (userName.length <= 0 || password.length <= 0) {
            setInputError("Please enter both email and password");
            return;
        }
        const ip = clientData ? clientData[0] : null;
    
        try {
            const response = await fetch(props.para_be + '/auth/login', postReqOptBuilder({
                "username": userName,
                "pass": password,
                "ip": ip
            }));
            const data = await response.json();
    
            if (data.status === 200) {
                console.log(data.result)
                localStorage.setItem("AuthToken", data.result);
                await authentication();
                history.push({ pathname: "/management", state: { from: "/login", auth: data.result } });
            } else {
                setInputError(data.msg);
            }
        } catch (error) {
            console.log(error);
            setInputError("Something went wrong, please try again");
        }
    };

    const userHasLogin = async () => {
        console.log(cred)
        if (cred !== null) {
            await authentication();
            history.push('/management');
        }
    };

    useEffect( () => {
        userHasLogin();
    }, [cred]);


    return (
        <div>
            <Helmet>
                <title>Paraspot - Sign In</title>
                <meta name="description" content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office."/>
                <meta property="og:title" content="Paraspot - Sign In"/>
                <meta property="og:description" content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office."/>
            </Helmet>
            <section className="page-content">
                <div className="para-logo image-container">
                    <a href="/">
                        <img id="para-logo" src={logo} alt="Paraspot Logo"/>
                    </a>
                </div>
                <div className="login-page user_sign_page">
                    <div className="access-form">
                        {showForgotPassModal ?
                            <div className="forgot-pass-modal">
                                <BackBtn clickFunction={() => { setShowForgotPassModal(false); }}/>
                                <ChangePasswordPopup para_be={props.para_be}/>
                            </div> :
                            <form className="login-form" method="post">
                                <div className="user-sign-header">
                                    <h3>Sign-In</h3>
                                    <div className="switch-user-sign d-none">
                                        <span>
                                        Or<a href="/sign-up">Sign Up</a>
                                        </span>
                                    </div>
                                </div>
                                <div className="sign-in-options row-view">
                                    <div className="sio sio-selected">
                                        <span>Username or Email Address</span>
                                    </div>
                                    <div className="sio">
                                        <span>SMS SOON</span>
                                    </div>
                                </div>
                                <input type="hidden" name="client_ip"/>
                                <div className="login-container login-container-active simple-login-container">
                                    <SimpleInput
                                        id="email_pn"
                                        name="email"
                                        type="text"
                                        si_input_text="Username or Email Address"
                                        extraClasses="input-2"
                                        error_state={inputError ? "input-err" : ""}
                                    />
                                    <HideTextInput
                                        id="password"
                                        name="password"
                                        si_input_text="Password"
                                        classes="input-2"
                                        error_state={inputError ? "input-err" : ""}
                                    />
                                    <div className="forgot-pass-btn">
                                        <div className="text-1-2" onClick={() => setShowForgotPassModal(true)}>Forgot Password?</div>
                                    </div>
                                    <div className={"sign-form-err " + (inputError ? "" : "d-none")}>
                                        <span>{inputError}</span>
                                    </div>
                                    <Btn
                                        text="Sign in"
                                        type="sign"
                                        onclick={handleLogin}
                                    />
                                </div>
                                <div className="login-container sms-login-container">
                                    <div className="sms-step sms-step-active" id="sms-so">
                                        <div className="simple-input-container">
                                            <input id="phone-number" name="phone-number" placeholder="Phone Number"/>
                                            <div className="si-input-title">Phone Number</div>
                                        </div>
                                        <div className="sign-form-err">
                                            <span>Incorrect username or password</span>
                                        </div>

                                        <button className="primary-btn sign-form-btn norm-size" type="button">Send verification code</button>
                                    </div>
                                    <div className="sms-step" id="sms-st">
                                        <p className="description">
                                            We just sent an SMS with a confirmation code to XXX-XXXXXXX (Phone Number)
                                        </p>
                                        <div className="simple-input-container">
                                            <input id="sms-code" name="sms-code" placeholder="Enter Code"/>
                                            <div className="si-input-title">Enter Code</div>
                                        </div>
                                        <div className={"sign-form-err " + (inputError ? "" : "d-none")}>
                                            <span>Incorrect Username or Password</span>
                                        </div>
                                        <div id="resend-code">
                                            <span>
                                                Didn't work? <button type="button" id="resend-code-btn">Send me another code</button>
                                            </span>
                                        </div>
                                        <button className="primary-btn sign-form-btn" type="submit">Sign In</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}