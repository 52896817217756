import React, {useState, useEffect, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

//styles
import "../management/management.css";
//components
import Pagination from "../../components/pagination/pagination";
import Btn from "../../components/buttons/standard/btn";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_state";
import Notification from "../../components/side_notification/side_notification";
import MasterPopup from "../../components/popups/main_popup";
import CreateNewClient from "../../components/popups/create_client/create_client.js";
import AddNewUnit from "../../components/popups/add_new_unit/add_new_unit";
import { SimpleDropdownContainer, SimpleInputDropdown } from "../../components/dropdowns/simple_dropdown/simple_dropdown";
//utils
import { dateTimeFormater, isToday } from "../../utils/date_utils.js";
//assets
import smallSearch from"../../assets/icons/search-small.svg"
import searchUp from "../../assets/icons/search-up.svg";
import searchDown from "../../assets/icons/search-down.svg";
import searchDownSelect from "../../assets/icons/search-down-select.svg";
import searchUpSelect from "../../assets/icons/search-up-select.svg";
import dots from "../../assets/icons/dots.svg";
//constants
const itemsPerPage = 20;

export default function Admin_Management (props) {
    const token = props.token;
    // state based variables
    const [isLoader, setLoader] = useState(true);
    const [sortedClients, setSortedClients] = useState();
    const [currentSort, setCurrentSort] = useState({"column": null, "direction": null}); //sort direction(true ASC, false DESC)
    const [currentSearch, setCurrentSearch] = useState(null);
    const [activeDD, setActiveDD] = useState(null);
    const [maxItems, setMaxItems] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [extendedTableItem, setExtendedTableItem] = useState(null);
    const [unitExtendedInfo, setUnitExtendedInfo] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    
    //load of initial data
    useEffect(() => {
        let body = {
            "access_key": token,
            "upperLimit": itemsPerPage
        };
        //if prev location is last report, set body according to previous state
        const historyState = window.history.state;
        console.log(historyState);
        if (historyState != null && historyState.state != null && historyState.state['from'].startsWith('/report/')) {
            const state = historyState.state;
            //unpack saved state
            if (state.sortColumn) {
                body["sortColumn"] = state.sortColumn;
                body["sortDirection"] = state.sortDirection;
                setCurrentSort({"column": state.sortColumn, "direction": state.sortDirection});
            }
            if (state.search) {
                body['searchValue'] = state.search;
                setCurrentSearch(state.search);
                document.getElementById("search").value = state.search;
            }
            if (state.page) {
                body['page'] = state.page;
                body["lowerLimit"] = (state.page - 1) * itemsPerPage;
                body["upperLimit"] = state.page * itemsPerPage;
                setCurrentPage(state.page);
            }
            window.history.replaceState(null,null,null);
        }
        fetchClients(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (extendedTableItem !== null) {
            fetch(props.para_be + "/units/get_extended_info?pid=" + extendedTableItem, {credentials: "include"})
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    setUnitExtendedInfo(response.result);
                } else {
                    setUnitExtendedInfo({});
                }
            })
        } else {
            setUnitExtendedInfo(null);
        }
    }, [extendedTableItem])

    const handleSortClients = (index) => {
        let sortColumn = [1, 2, 3, 4].includes(index) ? index : null;
        let sortDirection = currentSort.column !== index ? false : !currentSort.direction;

        fetchClients({"access_key": token, "sortColumn": sortColumn, "sortDirection": sortDirection, "searchValue": currentSearch, "upperLimit": itemsPerPage});
        setCurrentPage(1);
        setCurrentSort({"column": index, "direction": sortDirection});
    };

    const handleSearch = () => {
        const searchValue = document.querySelector("#search").value;
        handleChangePage(1, searchValue === null ? "" : searchValue);
        setCurrentSearch(searchValue === null ? "" : searchValue);
    };

    const handleChangePage = (page, searchValue=null) => {
        if(searchValue === null) {
            searchValue = currentSearch;
        }
        fetchClients({"access_key": token, "lowerLimit": (page-1)*itemsPerPage, 'upperLimit': page * itemsPerPage, "searchValue": searchValue, "sortColumn": currentSort.column, "sortDirection": currentSort.direction});
        setCurrentPage(page);
    };

    const fetchClients = (body) => {
        setLoader(true);
        let q = "?";
        for (let i of Object.keys(body)) {
            if (body[i] != null) q += (i + "=" + (i === "filters" ? JSON.stringify(body[i]) : body[i]) + "&");
        }
        q = q.slice(0, -1);
        fetch(props.para_be + '/paraspot/admin/clients/list' + q, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                if (Array.isArray(response.result.data) && response.result.data.length > 0) {
                    setSortedClients(response.result.data.slice(0));
                    if (isMainEmptyState) setMainEmptyState(false);
                } else {
                    setMainEmptyState(true);
                }
                if (response.result.maxRows !== null && response.result.maxRows !== maxItems) {
                    setMaxItems(response.result.maxRows);
                }
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
            setLoader(false);
        })
        .catch (error => {
            setMainEmptyState(true);
            setNotifState({text: "Can't fetch data", type: "error"});
            setLoader(false);
        });
    };

    useEffect(() => {
        if (activeDD) {
            document.body.addEventListener('click' , closeDD);
            return () => {document.body.removeEventListener('click' , closeDD)};
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDD]);

    const handleDropDown = (event, t) => {
        event.stopPropagation();
        if (activeDD === t) closeDD();
        else {
            if (activeDD) closeDD();
            setActiveDD(t);
        }
    };

    useEffect(()=> {
        closeDD();
    }, [popupState])

    const closeDD = () => {
        if (activeDD) setActiveDD(null);
    };

    const handleOpenNewClientPopup =() => {
        setPopupState([<CreateNewClient closeFunc={handleClosePopup} para_be={props.para_be}/>, {closeFunc: handleClosePopup}])
    };

    const handleClosePopup = (refresh=false) => {
        setPopupState(null);
        if (refresh) handleChangePage(currentPage);
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    }
    

    return (
        <section className="mgmt-main">
            <Helmet>
                <title>Paraspot | Admin Side</title>
                <meta name="description" content="."/>
                <meta property="og:title" content="Paraspot | Admin Side"/>
                <meta property="og:description" content="."/>
            </Helmet>
            <div className="mgmt-header flexRow">
                <div className="mgmt-search flexRow">
                    <input className="text-1" id="search" placeholder="Search" onKeyUp={() => handleSearch()}/>
                    <img className="unselectable" src={smallSearch} alt="small-search"/>
                </div>
                <div className ="mgmt-sort mobile-only">
                    <SimpleInputDropdown
                        placeholder="Sort by"
                        keepPlaceholder={true}
                        selectorClasses="pad-5-ud"
                        borderedItems={true}
                        onclick={(item) => handleSortClients(item === 'name' ? 1 : item === 'units' ? 2 : item === 'users' ? 3 : 4)}
                        items={[['name', 'Client Name'], ['units', 'No. of Units'], ['users', 'No. of Users'], ['last_scan', 'Last Scan']].reduce((dict, [k, v], idx) => {
                            let isCurrentSort = currentSort.column === idx+1;
                            return {[k]: {'present': v, 'disabled': false, 'preItemText': 
                                            <div className="sort-icons flexColumn">
                                                <img src={(isCurrentSort && currentSort.direction === true) ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                <img src={(isCurrentSort && currentSort.direction === false) ? searchDownSelect : searchDown} alt="arrow-down"/>
                                            </div>}, ...dict}
                        }, {})}/>
                </div>
                <div className="mgmt-header-btns flexRow">
                    <Btn 
                        text="Create New Client"
                        type="primary"
                        extraClasses="mgmt-add"
                        onclick={() => handleOpenNewClientPopup()}/>
                </div>
            </div>

            <div className="mgmt-table">
                <div className="top-pg-container">
                    <div>{currentPage < 2 ? 1 : (((currentPage-1)*itemsPerPage) + 1)}-{itemsPerPage*currentPage >= maxItems ? maxItems : itemsPerPage*currentPage} of {maxItems} Clients</div>
                </div>
                <table cellSpacing="0" cellPadding="0">
                    <thead className="hide-mobile">
                        <tr>
                            {Object.entries(
                                {
                                    'Client Name': {'sortable': true, 'className': 'th-cn'}, 
                                    'Units': {'sortable': true, 'className': 'th-units'}, 
                                    'Users': {'sortable': true, 'className': 'th-users'}, 
                                    'Last Scan': {'sortable': true, 'className': 'th-last-scan'}, 
                                    'Status': {'sortable': false, 'className': 'th-status'}, 
                                    'Contact': {'sortable': false, 'className': 'th-contact'}, 
                                    'Action': {'sortable': false, 'className': 'th-action'}
                                }).map(([k, v], index) => {
                                    let isCurrentSort = currentSort.column === index+1 && v['sortable'];
                                    return (
                                        <th className={v['className'] + (isCurrentSort ? " sort-active" : "")}>
                                            <div {...(v['sortable'] ? {onClick: () => handleSortClients(index+1)} : {})}>
                                                <span>{k}</span>
                                                {v['sortable'] &&
                                                    <div className="sort-icons flexColumn">
                                                        <img src={isCurrentSort && currentSort.direction === true ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                        <img src={isCurrentSort && currentSort.direction === false ? searchDownSelect : searchDown} alt="arrow-down"/>
                                                    </div>
                                                }
                                            </div>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader ? <MainLoader/> :
                            isMainEmptyState ? <EmptyState text={"No data, add new item"}  size="lg"/> :
                            sortedClients?.map(function (item, index) {
                                //data: 0-PID, 1-ext_ref, 2-subject, 3-report status, 4-report date, 5-report id, 6-baseline-status, 7-scan url, 8-is external reference
                                let rowStatus, format, dateTimeToPrint;
                                if(item[4]) {
                                    rowStatus = ((item[3] !== 1 && item[3] !== 0) ? null : (item[3] === 1 ? "Pass" : "Fail"));
                                    format = isToday(item[4]) ? "HH:MM" : "dd/mm/yyyy";
                                    dateTimeToPrint = item[4] ? dateTimeFormater(item[4], format) : "";
                                }

                                const contactData = item[4] ? JSON.parse(item[4]) : {};
                                const lastScanData = item[6] ? JSON.parse(item[6]) : {};

                                const item_id = "mgmt_item_" + index;
                                const extendedSection = "";

                                return (
                                    <>
                                        <tr id={item_id} key={item_id} onClick={() => { 
                                                // console.log("[i] Changing table item extension view to:", item[0] !== extendedTableItem ? item[0] : null); 
                                                // setExtendedTableItem(item[0] !== extendedTableItem ? item[0] : null); 
                                            }}>
                                            <td className={currentSort.column === 1 ? "sort-active" : ""} data-th="Client Name">{item[1]}</td>
                                            <td className={currentSort.column === 2 ? "sort-active" : ""} data-th="No of Units">{item[2]}</td>
                                            <td className={currentSort.column === 2 ? "sort-active" : ""} data-th="No of Users">{item[3]}</td>
                                            <td className={currentSort.column === 3 ? "sort-active" : ""} data-th="Last Scan">
                                                {item[6] ? dateTimeFormater(lastScanData[0].split(".")[0], "dd/mm/yyyy HH:MM") : "N/A"}
                                            </td>
                                            <td className={currentSort.column === 3 ? "sort-active" : ""} data-th="Status">{item[5]}</td>
                                            <td data-th="Contact Dets">
                                                {Object.keys(contactData).map(k => {
                                                    return (
                                                        <>
                                                            {k}: {contactData[k]}<br/>
                                                        </>
                                                    )
                                                })}
                                            </td>
                                            <td>
                                                <div className="report-btns">
                                                    <SimpleDropdownContainer
                                                        extraClasses="more-items"
                                                        showDropdown={activeDD === item[0]}
                                                        borderedItems={true}
                                                        items={{
                                                            'Sample 1': {'present': 'Sample 1', 'disabled': true, 'onclick': (k, e) => {}},
                                                            'Sample 2': {'present': 'Sample 2', 'disabled': true, 'onclick': (k, e) => {}},
                                                            'Sample 3': {'present': 'Sample 3', 'disabled': true, 'onclick': (k, e) => {}},
                                                        }}>
                                                        <img src={dots} alt="more-btn" onClick={(e) => handleDropDown(e, item[0])}/>
                                                    </SimpleDropdownContainer>
                                                </div>
                                            </td>
                                        </tr>
                                        {extendedSection}
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
                {isMainEmptyState ? "" :
                    <Pagination
                        currentPage = {currentPage}
                        maxPages = {Math.ceil(maxItems / itemsPerPage)}
                        handleChangePage = {(page) => handleChangePage(page)}
                        setNotifState = {setNotifState}
                        itemsPerPage = {itemsPerPage}
                    />
                }
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}
        </section>
    )
}
