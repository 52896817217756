import React from "react";
//styles
import "./inputError.css";

export default function InputError (props) {

    return (
        <div className={"i-error text-3" + (props.type ? ` ${props.type}` : "")}>
            {props.text}
        </div>
    )
}