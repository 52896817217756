import React from 'react'

const NoData = ({ fill = 'none', width = '25px', height = '24px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 18 18'
    >
      
      <path d="M16.5846 6.16406H8.41797C7.4263 6.16406 6.66797 6.9224 6.66797 7.91406V16.0807C6.66797 17.0724 7.4263 17.8307 8.41797 17.8307H16.5846C17.5763 17.8307 18.3346 17.0724 18.3346 16.0807V7.91406C18.3346 6.9224 17.5763 6.16406 16.5846 6.16406ZM10.168 14.9141C10.168 15.2641 9.93464 15.4974 9.58464 15.4974C9.23464 15.4974 9.0013 15.2641 9.0013 14.9141V12.5807C9.0013 12.2307 9.23464 11.9974 9.58464 11.9974C9.93464 11.9974 10.168 12.2307 10.168 12.5807V14.9141ZM13.0846 14.9141C13.0846 15.2641 12.8513 15.4974 12.5013 15.4974C12.1513 15.4974 11.918 15.2641 11.918 14.9141V9.08073C11.918 8.73073 12.1513 8.4974 12.5013 8.4974C12.8513 8.4974 13.0846 8.73073 13.0846 9.08073V14.9141ZM16.0013 14.9141C16.0013 15.2641 15.768 15.4974 15.418 15.4974C15.068 15.4974 14.8346 15.2641 14.8346 14.9141V11.4141C14.8346 11.0641 15.068 10.8307 15.418 10.8307C15.768 10.8307 16.0013 11.0641 16.0013 11.4141V14.9141Z" fill="#918BEF"/>
    </svg>
  );
};

export default NoData
