import { useState, useRef} from "react";
//styles
import "../input.css"
import "./input.css";
import InputError from "../../errorMessages/inputError/inputError";

export default function SimpleInput (props) {
    const [value, setValue] = useState(props.value);
    
    return (
        <div className={"simple-input-container" + (props.containerClasses ? ` ${props.containerClasses}` : "")}>
            {props.label ? <div className="simple-input-label text-1-3">{props.label}</div> : ""}
            <div className="simple-input-wrapper">
                <input 
                    id={props.id} 
                    name={props.name} 
                    placeholder={props.si_input_text ? " " : props.placeholder}
                    type={props.type}
                    className={"text-0 text-no-resp" + (props.extraClasses ? ` ${props.extraClasses}` : "") + (props.inputError ? " input-err" : "")}
                    style={props.inputStyle}
                    readOnly={props.readOnly}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        if (props.on_change) props.on_change(e);
                    }}
                    {...(props.inputProps ? props.inputProps : {})}
                />
                {props.si_input_text ? <div className="si-input-title">{props.si_input_text}</div> : ""}
            </div>
            {props.inputError ? (typeof props.inputError === "string" ?
                    <InputError text={props.inputError} type={"error"}/> : 
                    <InputError text={props.inputError.text} type={props.inputError.type}/>
                ) : ""
            }
        </div>
    )
}