function getClientData() {
    return fetch('https://www.cloudflare.com/cdn-cgi/trace')
    .then(response => response.text())
    .then(data => {
        let client_ip;
        let client_locality;
        for (let line of data.split(/\r?\n/)) {
            if (line.startsWith("ip=")) {
                window.client_ip = line.split("=")[1];
                client_ip = line.split("=")[1];
            } else if (line.startsWith("loc=")) {
                window.client_locality = line.split("=")[1];
                client_locality = line.split("=")[1];
            }
        }
        return [(client_ip === undefined) ? null : client_ip, (client_locality === undefined) ? null : client_locality]
    })
    .catch(error => {
        console.log(error);
        return [null, null];
    });
}

function disableBodyScroll (status) {
    if (status) {
        document.body.classList.add("scroll-y-disable");
    } else {
        document.body.classList.remove("scroll-y-disable");
    }
}

function isEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

function postReqOptBuilder(reqBody, withCreds=true, extraHeaders={}, jsonStringify=true) {
    return {
        method: 'POST',
        headers: {...(jsonStringify ? {'Content-Type': 'application/json'} : {}), ...extraHeaders},
        ...(reqBody ? {body: jsonStringify ? JSON.stringify(reqBody) : reqBody} : {}),
        ...(withCreds ? {credentials: "include"} : {})
    };
}

function capitalizeString(s, eachWord=false) {
    if (s.length === 0) return s;
    let out = [];
    let words = eachWord ? s.split(" ") : [s];
    for (let w of words) out.push(w.charAt(0).toUpperCase() + w.slice(1).toLowerCase());
    return out.join(" ");
}

function urlSearchToObject(search) {
    if (!search) return {};
    else if (search.charAt(0) === "?") search = search.slice(1);
    let searchValues = search.split("&");
    let out = {};
    for (let item of searchValues) {
        let [k, v] = item.split("=");
        if (k && k.length > 0) out[decodeURI(k)] = v ? decodeURI(v) : v;
    }
    return out;
}

function isMobile() {
    return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test((navigator.userAgent||navigator.vendor||window.opera))
        ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test((navigator.userAgent||navigator.vendor||window.opera).substr(0,4))
    );
}

function isIOS() {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
}

function doesHaveCamProblem() {
    return (/CPH2195|OPG02|VOG-L29|VOG-AL10|VOG-L04/.test(navigator.userAgent))
}

function createDictFromHashedUrl(hashString) {
    return hashString.length > 0 && hashString.includes("=") ? hashString.replace("#","")?.replaceAll("%20", " ").split("&")
    .reduce((acc, o) => {
        let [k, v] = o.split("=");
        return {...acc, [k]: v}
    }, {}) : {};
}

function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

function axiosReqOptBuilder(reqBody, withCreds = true, extraHeaders = {}, jsonStringify = true) {
    return {
        method: 'POST',
        headers: {
            ...(jsonStringify ? {'Content-Type': 'application/json'} : {}),
            ...extraHeaders
        },
        withCredentials: withCreds,
        data: jsonStringify ? JSON.stringify(reqBody) : reqBody
    };
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function addTextToClipboard(text) {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        // Modern method (Clipboard API)
        return navigator.clipboard.writeText(text).then(() => {
            return true;
        }).catch((error) => {
            console.error("Clipboard error:", error);
            return false;
        });
    } else {
        // Fallback for older browsers (execCommand method)
        const textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";  // Avoid scrolling to bottom of the page
        textArea.style.opacity = "0";       // Make it invisible
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        try {
            const successful = document.execCommand('copy');
            if (!successful) {
                return false;
            }
        } catch (err) {
            console.error("Fallback: Unable to copy", err);
            return false;
        }
        
        document.body.removeChild(textArea);
        return true;
    }
}

const urlListContains = (arr, r) => arr.some(i => new RegExp(i).test(r));

const getCurrencyCode = () => {
    const locale = new Intl.Locale(navigator.language);
    const region = locale.region;
    const euLocales = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'];
    return euLocales.includes(region) ? 'eur' : 'usd';
};


export {
    getClientData,
    disableBodyScroll,
    isEmail,
    postReqOptBuilder,
    isMobile,
    isIOS,
    doesHaveCamProblem,
    capitalizeString,
    urlSearchToObject,
    createDictFromHashedUrl,
    randomInt,
    axiosReqOptBuilder,
    classNames,
    urlListContains,
    addTextToClipboard,
    getCurrencyCode
}