import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import SnackBar from '../../../SnackBar';
import TableWidget from '../../TableWidget';
//modals
import AllReports from '../../TableWidget/components/Modals/AllReports';
import VideoModal from '../../TableWidget/components/Modals/VideoModal';
import AllTenancies from '../../TableWidget/components/Modals/AllTenancies';
//slices
import { closeModal } from '../../../../store/slices/searchSlice';
//constants
import { MODAL_TYPES } from '../../../../constants'


const ReportsTable = () => {
    const dispatch = useDispatch();
    const { rid } = useSelector(state => state.table.selectedRowInTable);
    const { selectedRowInTable, modals } = useSelector(state => state.table);
    const [notifState, setNotifState] = useState(null);
    
    const handleCloseModal = (modalName) => {
        dispatch(closeModal(modalName));
    };


    return (
        <>
            {/* Modals */}
            {modals.videoModal && (
                <VideoModal
                    open={modals.videoModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.VIDEO_MODAL)}
                    videoLink={selectedRowInTable.videoLink}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.allReportsModal && (
                <AllReports
                    open={modals.allReportsModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.ALL_REPORTS_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.allTenanciesModal && (
                <AllTenancies
                    open={modals.allTenanciesModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.ALL_TENANCIES_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                />
            )}
            {/* Main Table Layout */}
            <TableWidget showExpandedRows={true} />
            {/* Snackbar Notification */}
            {Boolean(notifState) && (
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={notifState.type === 'error' ? 'error' : 'success'}
                    message={notifState.text}
                    duration={6000}
                />
            )}
        </>
    );
};

export default ReportsTable;