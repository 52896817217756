import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
//components
import Notification from "../../../../../components/side_notification/side_notification";
import Btn from "../../../../../components/buttons/standard/btn";
import BaseModal from '../../../../../components/Modal'
import SimpleInput from "../../../../../components/inputs/simple_input/input";
import { SingleRadioInput } from "../../../../../components/radioInput/radioInput";
import ActionableInput from "../../../../../components/inputs/actionable_input/actionable_input";
//utils
import { isMobile, postReqOptBuilder } from "../../../../../utils/main_utils";
//constants
import { para_be } from '../../../../../config';


const AddNewUnitModal = ({ open, handleClose, performFetch }) => {
    const endUserData = useSelector((state) => state.config.endUserData);

    const [baseScanSelection, setBaseScanSelection] = useState(1);
    const [notifState, setNotifState] = useState(false);
    const [inputError, setInputError] = useState(null);
    const [loading, setLoading] = useState(false);
    const isMobileDevice = isMobile();
    // Create refs for input elements
    const unitInputRef = useRef(null);
    const referenceInputRef = useRef(null);
    const emailOrMobileInputRef = useRef(null);
    
    useEffect(() => {
        setBaseScanSelection(2); 
    }, []);
    
    const handleApprove = () => {
        setLoading(true);
        const unitInput = unitInputRef.current.value;
        const referenceInput = referenceInputRef.current.value;
        console.log("unitInput:", unitInput);
        console.log("referenceInput:", referenceInput);
        
        if (unitInput.length > 0) {
            setNotifState(false);
            let reqBody = {};
            if (baseScanSelection === 2) {
                const emailOrMobile = emailOrMobileInputRef.current?.value || "";
                if (emailOrMobile.length === 0) {
                    setInputError("emailOrMobile");
                    setLoading(false);
                    return;
                } else {
                    reqBody["sendLinkTo"] = emailOrMobile;
                }
            } else if (baseScanSelection === 3) {
                reqBody["addLater"] = 1;
            }
            
            console.log("Adding new unit");
            fetch(`${para_be}/units/new`, postReqOptBuilder({
                    unit: unitInput,
                    extRef: referenceInput,
                    ip: endUserData?.ip,
                    ...reqBody,
                })
            )
            .then((response) => response.json())
            .then((response) => {
                if (response.status === 200) {
                    performFetch();
                    handleClose();
                    if (baseScanSelection === 1) {
                        window.location.href = `${window.location.origin}/${response.result.pid}/baseline-scan`;
                    }
                } else {
                    setNotifState(response.msg);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setNotifState("An error has occurred, please try again later");
                setLoading(false);
            });
        } else {
            setInputError("unit");
            setLoading(false);
        }
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    };
    
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            maxWidth={800}
        >
            <div className="p-6 max-w-md mx-auto bg-white">
                <div className="flex flex-col space-y-6">
                    <h2 className="text-2xl font-bold text-blue-600">Add New Unit</h2>
                    {/* Unit Input */}
                    <SimpleInput
                        id="unit"
                        name="unit"
                        label="Unit"
                        placeholder="Add unit/room number"
                        type="text"
                        inputError={inputError === "unit" ? "Please enter Unit Name" : undefined}
                        inputProps={{ ref: unitInputRef }}
                    />
                    {/* Reference Input */}
                    <SimpleInput
                        id="reference"
                        name="reference"
                        label="Reference"
                        placeholder="Add your reference ID"
                        type="text"
                        inputProps={{ ref: referenceInputRef }}
                    />
                    
                    <div className="space-y-4">
                        <p className="text-lg font-semibold">Add Baseline Scan</p>
                        <SingleRadioInput
                            name="scan"
                            label="Create Baseline Scan"
                            checked={baseScanSelection === 1}
                            disabled={!isMobileDevice}
                            onclick={() => (isMobileDevice ? setBaseScanSelection(1) : false)}
                        />
                        {/* <SingleRadioInput
                            name="scan"
                            label="Send Link"
                            checked={baseScanSelection === 2}
                            onclick={() => setBaseScanSelection(2)}
                        >
                            {baseScanSelection === 2 &&
                                <ActionableInput
                                    id="EM-input"
                                    placeholder="Add your email or mobile number"
                                    inputError={
                                        inputError === "emailOrMobile"
                                            ? "Please enter email or mobile number"
                                            : undefined
                                    }
                                    inputProps={{ ref: emailOrMobileInputRef }}
                                />
                            }
                        </SingleRadioInput> */}
                        <SingleRadioInput
                            name="scan"
                            label="Add Later"
                            checked={baseScanSelection === 3}
                            onclick={() => setBaseScanSelection(3)}
                        />
                    </div>

                    <Btn text={loading ? "Loading..." : "Approve"} type="primary" onclick={handleApprove} />
                </div>
                
                {notifState && 
                    <Notification closeFunc={handleCloseNotif} text={notifState} type="error" />
                }
            </div>
        </BaseModal>
    )
}

export default AddNewUnitModal;