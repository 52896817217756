import {useState, useCallback, useEffect, useRef} from 'react';
//components
import Btn from "../../buttons/standard/btn";
//styles
import "./editable_input.css";
//assets
import { ReactComponent as EditIcon } from "../../../assets/icons/edit_pen.svg";
import { postReqOptBuilder } from '../../../utils/main_utils';

export default function EditableInput (props) {
    const [editState, setEditState] = useState(false);
    const inputValue = useRef(null);
    const [originalValue, setOriginalValue] = useState(props.value);

    const handleUserKeyPress = useCallback(event => {
        console.log(event.key);
        if (event.key === 'Escape') {
            inputValue.current.value = originalValue;
            setEditState(false);
        } else {
            if (props.updateOnEnter && event.key === 'Enter') handleUpdateClick();
        }
    }, []);

    useEffect(()=> {
        inputValue.current.value = props.value;
    }, []);

    useEffect(()=> {
        if (editState) {
            window.addEventListener('keydown', handleUserKeyPress);
        } else {
            window.removeEventListener('keydown', handleUserKeyPress);
        }
        return () => {window.removeEventListener('keydown', handleUserKeyPress)};
    }, [editState]);

    const handleUpdateClick = () => {
        const body = {...props.body, 'text_to_update': inputValue.current.value};
        fetch(props.updateUrl, postReqOptBuilder(body))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setOriginalValue(inputValue.current.value);
                setEditState(false);
                if (props.updateChange) props.updateChange();
                if (props.onTextUpdate) props.onTextUpdate(inputValue.current.value);
            } else {
                props.updateNotifState({text: response.msg, type: "error"});
            }
        })
        .catch (error => {
            props.updateNotifState({text: "Failed to update", type: "error"});
        });
    };

    return (
        <div className="editable-input-box">
            <div className={editState ? "edit-box" : "read-box"} onClick={() => setEditState(true)}>
                <input ref={inputValue} readOnly={!editState}/>
                {editState ?
                    <Btn
                        text="Update"
                        type="primary"
                        onclick={handleUpdateClick}
                    /> : <EditIcon stroke="#1890ff" fill="#1890ff"/>
                }
            </div>
    </div>
    )
}