import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

//components
import Btn from "../../components/buttons/standard/btn";
import SimpleInput from "../../components/inputs/simple_input/input";
import MasterPopup from "../../components/popups/main_popup";
//styles
import "./move_out.css";
//assets
import logoPlaceholder from "../../assets/placeholders/no-image.jpg"
import { postReqOptBuilder } from "../../utils/main_utils";


export default function ClientLanding(props) {
    const base_url = props.base_url;
    const clientBeName = props.match.params.cbe_name;

    const [inputError, setInputError] = useState(null);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [companyBanner, setCompanyBanner] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [checkoutResult, setCheckoutResult] = useState(null);
    const history = useHistory();


    useEffect(() => {
        fetch(props.para_be + "/media/client_media?client_be_name=" + clientBeName)
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                const graphics = JSON.parse(response.result[0]);
                try {
                    setCompanyLogo(graphics.logo);
                } catch (e) {
                    console.log(e);
                }
                try {
                    setCompanyBanner(graphics.banner);
                } catch (e) {
                    console.log(e);
                }
            }
        })
    }, [])
    
    
    const validation = () => {
        let isValid = true;
        setInputError("");
        const reqInputs = {'room_no': "Room Number", 'order_ln': "Last Name"}
        for (const [k, v] of Object.entries(reqInputs)) {
            const reqIn = document.getElementById(k).value;
            if (!reqIn.length) {
                setInputError("Please Enter " + v);
                isValid = false;
                break;
            }
        }
        return isValid;
    };
    
    const handleCheck = () => {
        if (validation()) {
            console.log("checkThe");
            fetch(props.para_be + "/scan/authenticateGuest", 
                postReqOptBuilder(
                    {
                        'clientBeName': clientBeName,
                        'unit-code': document.getElementById("room_no").value,
                        'guestLastName': document.getElementById("order_ln").value,
                    }
                ))
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    if (response.result.code === "scan_done") {
                        setCheckoutResult(
                            <div className="checkout-result-container">
                                <h3 className="h3">Thank you for staying with us!</h3>
                                <p className="text-1">
                                    We hope you enjoyed your stay. Your checkout scan is in processing and a member of our team will reach out for the release of your security deposit.
                                </p>
                            </div>
                        );
                    } else if (response.result.code === "pending_scan") {
                        setCheckoutResult(
                            <div className="checkout-result-container">
                                <h3 className="h3">Thank you for staying with us!</h3>
                                <p className="text-1">
                                Before we can finalize your checkout, you need to perform a scan in your property.
                                <br/>
                                Click on the button below to open the checkout scan page.
                                </p>
                                <Btn
                                    text="Open Checkout Scanner"
                                    type="sign"
                                    onclick={() => {
                                        window.open(response.result.url, "_blank");
                                    }}
                                />
                            </div>
                        );
                    } else if (response.result.code === "no_tenancy") {
                        setCheckoutResult(
                            <div className="checkout-result-container">
                                <h3 className="h3">Thank you for staying with us!</h3>
                                <p className="text-1">
                                    We hope you enjoyed your stay.
                                </p>
                            </div>
                        );
                    }
                } else {
                    setCheckoutResult(
                        <div className="checkout-result-container">
                            <h3 className="h3">Thank you for staying with us!</h3>
                            <p class="text-1">
                                We apologize for the inconvenience, but something went wrong. Please refresh your screen and try again.
                                <br/><br/>
                                If the problem repeats itself or you have any questions don't hesitate to contact our team on <a href="tel:+493062931420">+49 (0)3062931420</a>.
                            </p>
                        </div>
                    );
                }
            })
        }
    };
    return (
        <div>
            <Helmet>
                <title>Paraspot - Client Landing</title>
                <meta
                    name="description"
                    content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office."
                />
                <meta property="og:title" content="Paraspot - Sign In" />
                <meta
                    property="og:description"
                    content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office."
                />
            </Helmet>
            <section className="page-content">
                {/* Background */}
                <div className="banner-pp">
                    <div className="top-bannerX">
                        <img src={companyBanner} alt="banner" />
                    </div>
                </div>
                {/* Foreground */}
                <div className="login-page user_sign_page">
                    <div className="access-formX">
                        <div className="login-form form">
                            {/* Client Rounded Logo Section */}
                            <div className="logo-img-container">
                                <img src={companyLogo ? companyLogo : logoPlaceholder} alt="logo"/>
                            </div>
                            
                            <div className="user-sign-header">
                                <h3>Checkout</h3>
                            </div>
                            {checkoutResult ?
                                checkoutResult
                                :
                                <div className="login-container login-container-active simple-login-container">
                                    <SimpleInput
                                        id="room_no"
                                        name="room_no"
                                        type="text"
                                        si_input_text="Room Number (e.g. 302)"
                                        extraClasses="input-2"
                                        error_state={inputError ? "input-err" : ""}
                                    />
                                    <SimpleInput
                                        id="order_ln"
                                        name="order_ln"
                                        type="text"
                                        si_input_text="Last Name (e.g. Johnson)"
                                        extraClasses="input-2"
                                        error_state={inputError ? "input-err" : ""}
                                    />
                                    <div
                                        className={
                                            "sign-form-err " +
                                            (inputError ? "" : "d-none")
                                        }
                                    >
                                        <span>{inputError}</span>
                                    </div>
                                    <Btn
                                        text="Checkout"
                                        type="sign"
                                        onclick={handleCheck}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {popupState ? (
                    <MasterPopup
                        extraClasses="custom-scrollbar"
                        {...popupState[1]}
                    >
                        {popupState[0]}
                    </MasterPopup>
                ) : (
                    ""
                )}
            </section>
        </div>
    );
}