import React from 'react';
//components
import Btn from "../../buttons/standard/btn";
//styles
import "../popups.css";
import "./confirmation_popup.css";

export default function ConfirmPopup (props) {

    return (
        <div className='popup-body' id="confirm-popup">
            <div className="h2 blue-headline">{props.headlineText}</div>
            <div className="text-0">{props.mainText}</div>
            {props.extraContent}
            <div className="btn-container">
                <Btn
                    text={props.declineText}
                    type="secondary"
                    onclick={props.declineChoice}
                />
                <Btn
                    text={props.confirmText}
                    type="primary"
                    onclick={props.confirmChoice}
                />
            </div>
        </div>
    )
}