import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import SnackBar from '../../../SnackBar';
import TableWidget from '../../TableWidget';
//modals
import VideoModal from '../../TableWidget/components/Modals/VideoModal';
import ApproveModal from '../../TableWidget/components/Modals/ApproveModal';
import RejectModal from '../../TableWidget/components/Modals/RejectModal';
//utils
import axios from '../../../../axios/axios';
//slices
import { setPage , closeModal } from '../../../../store/slices/searchSlice';
//constants
import { para_be } from '../../../../config';
import { MODAL_TYPES } from '../../../../constants'
import { postLogEntry } from '../../../../store/slices/activitySlice';


const ScansSubmissionsTable = () => {
    const dispatch = useDispatch();
    const endUserData = useSelector((state) => state.config.endUserData);
    const { rid } = useSelector(state => state.table.selectedRowInTable);
    const { page, selectedRowInTable, modals } = useSelector(state => state.table);
    const [notifState, setNotifState] = useState(null);
    
    const handleCloseModal = (modalName) => {
        dispatch(closeModal(modalName));
    };
    
    const handleSubmissionScan = async (selected_action = "approve", msg = null, should_send_msg = false) => {
        try {
            const response = await axios.post(
                `${para_be}/scan/${selected_action}_pending`,
                {
                    rid: rid,
                    ...(selected_action === 'approve' && should_send_msg ? { send_msg: "1" } : {}),
                    ...(msg ? { msg: msg } : {})
                }, { withCredentials: true }
            );
        
            if (response.data.status === 200) {
                setNotifState({
                    text: selected_action === "approve" ? "Submission approved successfully" : "Submissions rejected successfully",
                    type: "success"
                });
                dispatch(postLogEntry( 
                    { 
                        activityID: rid, activityType: 'scanSubmission', ip: endUserData?.ip, 
                        action: {action: selected_action, target: "scanSubmission"} 
                    }
                ));
            } else {
                setNotifState({
                    text: selected_action === "approve" ? "Something went wrong while approving submission" : "Something went wrong while rejecting submission",
                    type: "error"
                });
            }
        } catch (error) {
            setNotifState({
                text: selected_action === "approve" ? "Something went wrong while approving submission" : "Something went wrong while rejecting submission",
                type: "error"
            });
        } finally {
          if (selected_action === "approve") handleCloseModal(MODAL_TYPES.APPROVE_MODAL)
          else handleCloseModal(MODAL_TYPES.REJECT_MODAL);
        }
        dispatch(setPage(page));
    };


    return (
        <>
            {/* Modals */}
            {modals.videoModal && (
                <VideoModal
                    open={modals.videoModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.VIDEO_MODAL)}
                    videoLink={selectedRowInTable.videoLink}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.approveModal && (
                <ApproveModal
                    open={modals.approveModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.APPROVE_MODAL)}
                    onApprove={handleSubmissionScan}
                />
            )}
            {modals.rejectModal && (
                <RejectModal
                    open={modals.rejectModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.REJECT_MODAL)}
                    onRejection={handleSubmissionScan}
                />
            )}
            {/* Main Table Layout */}
            <TableWidget showExpandedRows={true} />
            {/* Snackbar Notification */}
            {Boolean(notifState) && (
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={notifState.type === 'error' ? 'error' : 'success'}
                    message={notifState.text}
                    duration={6000}
                />
            )}
        </>
    );
};

export default ScansSubmissionsTable;