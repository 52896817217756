import React, { useState } from 'react';
//components
import Btn from "../../../../../buttons/standard/btn";
import BaseModal from '../../../../../Modal'


const RejectModal = ({ open, handleClose, onRejection }) => {  
    const [rejectionMsg, setRejectionMsg] = useState("");
    
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            minWidth={400}
            maxWidth={400}
        >
            <div className='popup-body' id="confirm-popup">
                <div className="h2 blue-headline">Reject Submission</div>
                <div className="text-1">
                    Are you sure you want to reject the scan submission?
                    <br/>
                    The rejection will be sent to the tenant only if a rejection message is provided.
                    <br/>
                    Please note, this action cannot be undone.
                </div>
                <div className="simple-input-label wrapper-text">
                    <div className="simple-input-label text-1-3">
                        Rejection Message (Optional)
                    </div>
                    <textarea
                        rows={5}
                        className="email-body"
                        name="body"
                        placeholder="Enter rejection message here (Optional)"
                        defaultValue={rejectionMsg}
                        onChange={(e) => { setRejectionMsg(e.target.value); }} />
                </div>
                <div className="flex w-48 justify-between">
                    <Btn
                        text="Cancel"
                        type="secondary"
                        onclick={handleClose} />
                    <Btn
                        text="Reject"
                        type="primary"
                        onclick={() => {onRejection('reject',rejectionMsg)}} />
                </div>
            </div>
        </BaseModal>
    )
}

export default RejectModal;