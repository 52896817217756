import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
//components
import BaseModal from '../../../../../Modal';
import CustomButton from '../../../../../buttons/CustomButton';
//utils
import axios from '../../../../../../axios/axios';
//constants
import { para_be } from '../../../../../../config';
//slices
import { fetchData, setBtnLoading } from '../../../../../../store/slices/searchSlice';
import { postLogEntry } from '../../../../../../store/slices/activitySlice';


const UpdateUnit = ({ open, handleClose, updatedItem, itemKey, ext_ref, pid, subject, setNotifState }) => {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);
    const { page, pageSize, sortColumn, sortDirection, searchValue, filters, btnLoading } = useSelector(state => state.table);
    const [inputError, setInputError] = useState(null);
    const inputRef = useRef('');
    
    const fetchDataFunc = async () => {
        const body = {
            lowerLimit: (page - 1) * pageSize,
            upperLimit: page * pageSize,
            filters,
            searchValue,
            sortDirection,
            sortColumn,
        };
        dispatch(fetchData(body));
    };
    
    // Handle Assign Reference Number
    const handleAssignReferenceNumber = async event => {
        event.preventDefault();
        setInputError(null);
        
        const body = { pid: pid };
        const newValue = inputRef.current?.value;
        if (!newValue || newValue.length === 0) {
            setInputError(`Please enter ${updatedItem.toLowerCase()}`);
            return;
        }
        body[itemKey] = newValue;
        
        try {
            dispatch(setBtnLoading(true));
            const response = await axios.post(`${para_be}/units/update_unit`, body, {
                withCredentials: true,
            });
            if (response.status === 200) {
                dispatch(postLogEntry( 
                    { 
                        activityID: pid, activityType: 'unit', ip: endUserData?.ip, 
                        action: {action: "update", target: "unit"} 
                    }
                ));
                await fetchDataFunc();
                handleClose();
                setNotifState({ type: 'success', text: `Update ${updatedItem}` });
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
            }
        } catch (error) {
            setNotifState({ text: 'Update failed', type: 'error' });
            console.error('Error updating unit:', error);
        } finally {
            dispatch(setBtnLoading(false));
        }
    };
    
    return (
        <BaseModal open={open} onClose={handleClose} minWidth={400}>
            <div className="update-ref">
                <div className="update-ref-header h2 blue-headline">Edit {updatedItem}</div>
                <div className="text-lg mb-2">
                    <span className="font-bold">Unit: </span> {subject}
                </div>
                <div className="text-lg mb-4">
                    <span className="font-bold">Reference: </span> {ext_ref}
                </div>
                <div className="mb-10">
                    <TextField
                        label={`New ${updatedItem}`}
                        placeholder={`Enter your new ${updatedItem.toLowerCase()}`}
                        error={Boolean(inputError)}
                        helperText={inputError}
                        inputRef={inputRef}
                        fullWidth
                        variant="outlined"
                    />
                </div>
                
                <CustomButton 
                  onClick={e => handleAssignReferenceNumber(e)}
                  disabled={btnLoading} 
                  loading={btnLoading}
                  variant="contained"
                >
                    {`Update ${updatedItem}`}
                </CustomButton>
            </div>
        </BaseModal>
    );
};

export default UpdateUnit;