import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
//components
import BaseModal from '../../../../../Modal';
import CustomButton from '../../../../../buttons/CustomButton';
//utils
import axios from '../../../../../../axios/axios';
//constants
import { para_be } from '../../../../../../config';
//slices
import { fetchData } from '../../../../../../store/slices/searchSlice';
import { postLogEntry } from '../../../../../../store/slices/activitySlice';
import DropdownWithCheckboxes from '../../../../../dropdowns/muiSelect/muiMultiSelect';
import MainLoader from '../../../../../loaders/main_loader/main_loader';


const UpdateJourney = ({ open, handleClose, setNotifState, jid, journeyName: prevJourneyName }) => {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);
    const { page, pageSize, sortColumn, sortDirection, searchValue, filters } = useSelector(state => state.table);
    // loading states
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    // states
    const [journeyName, setJourneyName] = useState(prevJourneyName);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [availableUnits, setAvailableUnits] = useState({});
    
    
    const fetchDataFunc = async () => {
        const body = {
            lowerLimit: (page - 1) * pageSize,
            upperLimit: page * pageSize,
            filters,
            searchValue,
            sortDirection,
            sortColumn,
        };
        dispatch(fetchData(body));
    };

    const onCloseModal = (force=false) => {
        if (loadingBtn && !force) return;
        handleClose();
    };

    const onUpdate = async (e) => {
        e.preventDefault();
        setLoadingBtn(true);

        const body = {
            journey_id: jid,
            name: journeyName,
            targets: selectedUnits
        }
        try {
            const response = await axios.post(`${para_be}/inspections/journey/update`, body, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                dispatch(postLogEntry( 
                    { 
                        activityID: jid, activityType: 'journey', ip: endUserData?.ip, 
                        action: {action: "update", target: "journey"} 
                    }
                ));
                await fetchDataFunc();
                onCloseModal(true);
                setNotifState({ type: 'success', text: `Journey updated successfully` });
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
            }
        } catch (error) {
            setNotifState({ text: 'Failed to update journey', type: 'error' });
            console.error('Error updating journey:', error);
        } finally {
            setLoadingBtn(false);
        }
    };

    const fetchAvailableUnits = async () => {
        try {
            const response = await axios.get(`${para_be}/units/list`, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                const units = response.data.result.reduce((acc, unit) => {
                    return {
                        ...acc,
                        [unit.pid]: {
                            primary: `(${unit.ext_ref}) ${unit.subject}`,
                            secondary: unit?.group
                        }
                    }
                }, {});
                setAvailableUnits(units);
                return true;
            }
        } catch (error) {
            console.error('Error fetching units:', error);
        }
        return false;
    };

    const fetchJourneyData = async () => {
        try {
            const response = await axios.get(`${para_be}/inspections/journey/get?journey_id=${jid}`, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                setJourneyName(response.data.result.name);
                setSelectedUnits(response.data.result.targets);
                return true;
            }
        } catch (error) {
            console.error('Error fetching journey data:', error);
        }
        return false;
    };

    useEffect(() => {
        setLoading(true);
        setErrorLoading(false);
        
        Promise.all([fetchAvailableUnits(), fetchJourneyData()])
        .then((results) => {
            if (results.includes(false)) {
                setErrorLoading(true);
            }
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error loading modal:', error);
            setErrorLoading(true);
            setLoading(false);
        });
    }, []);
    
    return (
        <BaseModal open={open} onClose={onCloseModal} minWidth={400} maxWidth={600}>
            {loading ?
                <div className='h-64'>
                    <MainLoader/>
                </div> 
            : errorLoading ?
                <div className='h-64 flex flex-col justify-center center'>
                    <span className='text-gray-500 text-md text-center'>
                        Something went wrong while loading the modal. Please try again.
                        <br/>
                        If the problem persists, please contact support.
                    </span>
                </div> 
            :
                <div className="flex flex-col gap-y-4">
                    <div>
                        <span className="text-gray-700 text-lg font-medium">Edit Journey</span> 
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-md font-medium text-gray-700">
                            Journey Name
                        </label>
                        <input 
                            className="border border-gray-300 rounded-md py-3 px-4" 
                            type="text" 
                            value={journeyName}
                            placeholder="Enter Journey Name"
                            onChange={(e) => setJourneyName(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-md font-medium text-gray-700">
                            Select Target Units
                        </label>
                        <DropdownWithCheckboxes 
                            options={availableUnits}
                            selected={selectedUnits}
                            onChange={(t)=>{ setSelectedUnits(t); }}
                            label="Select Target Units"
                            scrollable={true}
                            searchable={true}
                        />
                    </div>
                    
                    <CustomButton 
                        variant="contained"
                        sx={{
                            backgroundColor: '#4F46E5', 
                            color: 'white',
                            fontSize: '12px',
                            textTransform: 'none',
                            borderRadius: '8px',
                            padding: '9px 15px',
                            '&:hover': { backgroundColor: '#4338CA' } 
                        }}
                        loading={loadingBtn}
                        onClick={onUpdate}
                    >
                        Update Journey
                    </CustomButton>
                </div>

            }
        </BaseModal>
    );
};

export default UpdateJourney;