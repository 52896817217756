import { ReactComponent as BuildingsIcon } from './svg/buildings.svg';
import { ReactComponent as PlayIcon } from './svg/play.svg';
import { ReactComponent as ReportsIcon } from './svg/reports.svg';
import { ReactComponent as SpeedometersIcon } from './svg/speedometer.svg';
import { ReactComponent as SettingsIcon } from './svg/settings.svg';
import { ReactComponent as LifeCycleIcon } from './svg/lifeCycle.svg';

const Icon = ({ type, isSelected, className }) => {
  const getIconComponent = () => {
    const iconProps = {
      style: { fill: isSelected ? 'white' : '' }, 
      className: className 
    };
    const iconProps2 = {
      style: { stroke: isSelected ? 'white' : '#7F8594' }, 
      className: className 
    };

    switch (type) {
      case 'Buildings':
        return <BuildingsIcon {...iconProps} />;
      case 'Play':
        return <PlayIcon {...iconProps} />;
      case 'Reports':
        return <ReportsIcon {...iconProps} />;
      case 'Speedometer':
        return <SpeedometersIcon {...iconProps} />;
      case 'Settings':
        return <SettingsIcon {...iconProps2} />;
      case 'LifeCycle':
        return <LifeCycleIcon {...iconProps2} />;
      default:
        return <span className={className}>Icon not found</span>; // Also apply className here for consistency
    }
  };

  return (
    <div>
      {getIconComponent()}
    </div>
  );
};

export default Icon;