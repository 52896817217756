import React, { useState, useRef } from 'react';
//styles
import "./pagination.css";

export default function Pagination (props) {
    const pageInput = useRef(null);
    const staticPosition = useRef(props.currentPage);

    const maxPages = props.maxPages;
    const [currentPage, setCurrentPage] = useState(props.currentPage);

    const handlePagination = (newPage) => {
        newPage = isNaN(newPage) && typeof newPage !== "number" ? parseInt(newPage) : newPage
        if (newPage > maxPages || newPage <= 0) {
            setCurrentPage(staticPosition.current);
            props.setNotifState({text: 'Invalid page number', type: 'error'});
            return;
        }
        staticPosition.current = newPage;
        props.handleChangePage(newPage);
    }

    const handleClick = (type = null) => {
        let newPage = type !== "previous" && type !== "next" ? pageInput.current.value : (parseInt(staticPosition.current)+(type === "next" ? 1 : -1));
        if (type === "previous" || type === "next") {
            if (newPage > maxPages || newPage <= 0) return;
            setCurrentPage(newPage);
            handlePagination(newPage);
        } else {
            setCurrentPage(isNaN(newPage) ? parseInt(newPage) : newPage);
            if (pageInput.current.value.length > 0) {
                let currentInputValue = pageInput.current.value;
                if (currentInputValue !== maxPages) setTimeout(() => (currentInputValue === pageInput.current.value) ? handlePagination(pageInput.current.value) : null, 1500);
            }
        }
    };

    return (
        <div className="in-pg-container">
            <div>
                <div className={"in-controller" + (currentPage === 1 ? " inactive" : "")} onClick={() => handleClick("previous")}>{"<"}</div>
                <input ref={pageInput} className="in-page-input" type="number" value={currentPage} onChange={() => handleClick()}/>
                <div className={"in-controller" + (currentPage === maxPages ? " inactive" : "")} onClick={() => handleClick("next")}>{">"}</div>
                <div className="n-Of-Pages">of {maxPages}</div>
            </div>
        </div>
    )
}