import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CircularLoader from '../../../../../../components/loaders/CircularLoader';
import './scan-completion-pie-chart.css';
import { scanCompletionChartColorsConfig , scanCompletionChartPieOptions } from './config/scan-completion-pie-chart.config';




ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);






const ScanCompletionPieChart = () => {
  const { stats, statsLoading } = useSelector(state => state.dashboard);
  

  const filteredStats = stats.filter(item => item.value !== 0);

  const valuesArray = filteredStats.map(item => item.value);
  const labelsArray = filteredStats.map(item => item.label);



  const ref = useRef(null);
  useEffect(() => {
    if (ref) {
      console.log(ref);
    }
  }, []);
  const data = {
    labels: labelsArray,
    datasets: [
      {
        label: 'Scan Completion',
        data: valuesArray, // TODO - change to real data here
        backgroundColor: scanCompletionChartColorsConfig,
        borderWidth: 0,
        hoverOffset: 4, 
      },
    ],
  };

  const options = scanCompletionChartPieOptions;

  return (
    <div className="w-full mx-auto">
      <div className="h-52 flex justify-center items-center">
        {!statsLoading ?  <Pie ref={ref} data={data} options={options} /> : <CircularLoader size={36} color="secondary" />}
      </div>
    </div>
  );
};

export default ScanCompletionPieChart;
