import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import BaseModal from "../../../../../../../components/Modal";
import CustomButton from "../../../../../../../components/buttons/CustomButton";
//slices
import { setCustomer } from "../../../../../../../store/slices/customerSlice";
//constants
import { SeverityLevels } from "../../../../../../../constants";
import { isEmail } from "../../../../../../../utils/main_utils";


const UpdateCustomerInfo = ({ open, handleClose, setNotification }) => {
    const { customerData } = useSelector(state => state.customer);
    const [customerInfo, setCustomerInfo] = useState({
        customer_email: customerData?.email || "",
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    

    const handleSubmit = async () => {
        setLoading(true);
        if (customerInfo.customer_email === "") {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Please fill out all the fields.'});
            setLoading(false);
            return;
        } else if (isEmail(customerInfo.customer_email) === false) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Invalid email in the input field.'});
            setLoading(false);
            return;
        }
        
        try {
            await dispatch(setCustomer({ email: customerInfo.customer_email, })).unwrap();
            setNotification({open: true, severity: SeverityLevels.SUCCESS, message: 'Updated customer info successfully!'});
            setLoading(false);
            handleClose();
        } catch (err) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Failed to update customer info.'});
            setLoading(false);
        }
    }

    const handleInputChange = e => {
        const { name, value } = e.target;
        setCustomerInfo(prev => ({...prev, [name]: value}));
    };
    
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            maxWidth={800}
        >
            <div className="p-6 max-w-md mx-auto bg-white">
                <div className="flex flex-col space-y-6">
                    <h2 className="text-xl font-bold mb-6">Update Customer Info</h2>
                    {/* Update Email Input */}
                    <div className="mt-4 mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customer_email">Email</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="customer_email"
                            type="text"
                            name="customer_email"
                            value={customerInfo.customer_email}
                            onChange={handleInputChange}
                            required />
                    </div>
                    
                    <CustomButton variant="contained" color="primary" onClick={handleSubmit} disabled={loading} loading={loading}>
                        Update
                    </CustomButton>
                </div>
            </div>
        </BaseModal>
    )
}

export default UpdateCustomerInfo;