import React from 'react';
import NoDataIcon from './assets/NoData';


const EmptyState = ({ headerText, decriptionText }) => {
    return (
        <div className="flex flex-col w-80 h-24">
            <div className="flex justify-center">
                <NoDataIcon />
                <span className="text-sm font-medium text-gray-400 ml-2 mt-2">{headerText}</span>
            </div>
            <span className="text-md font-medium text-black ml-2 mt-2 text-center">{decriptionText}</span>
        </div>
    );
};

export default EmptyState;