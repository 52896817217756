import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
//assets
import DocsIcon from '../../../../icons/docsIcon';
import FileStack from '../../../../icons/fileStack';
import EyeIcon from '../../../../icons/eyeIcon';
//constants
import { MODAL_TYPES } from '../../../../../../constants';


const OptionsMenu = React.memo(({ pid, subject }) => {
    const dispatch = useDispatch();
    const menuItems = [
        {
            label: 'All Reports',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_REPORTS_MODAL, subject, pid })),
        },
        {
            label: 'View Baseline',
            icon: EyeIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.VIDEO_MODAL, subject, pid })) 
        },
    ];

    return (
        <TableOptionsMenu items={menuItems} />
    );
});

const ActionsPanelReports = ({ pid, subject, report_id }) => {
    return (
        <>
            {report_id ? (
                    <Link to={`/report/${report_id}`} className="cursor-pointer" title={"View Report"}>
                        <DocsIcon />
                    </Link>
                ) : (
                    <span className='cursor-default'>
                        <DocsIcon disabled />
                    </span>
                )
            }
            <OptionsMenu pid={pid} subject={subject} />
        </>
    )
}

export default ActionsPanelReports;