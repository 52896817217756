import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox } from '@mui/material';
import DropdownWithCheckboxes from '../../../../components/dropdowns/muiSelect/muiMultiSelect';
import SimpleInput from '../../../../components/inputs/simple_input/input';
//components
//modals
//slices
//utils
//constants
import { para_be } from '../../../../config';
import SnackBar from '../../../../components/SnackBar';
import CustomButton from '../../../../components/buttons/CustomButton';
import { postReqOptBuilder } from '../../../../utils/main_utils';

const INSPECTIONS = {
    "move_in": {
        name: "Move-In",
        description: "A comprehensive inspection that records the condition of a unit upon move-in. It ensures a clear reference point for the unit's condition at the beginning of the lease.",
    },
    "move_out": {
        name: "Move-Out",
        description: "A detailed inspection that documents the condition of a unit at the end of a lease. It helps compare the current state of the unit to its condition at move-in, ensuring accurate assessments for repairs, cleaning, or deposit deductions.",
    },
    "pre_move_out": {
        name: "Pre-Move-Out",
        description: "An inspection conducted before a resident vacates the unit. It identifies potential damages or issues that need addressing, giving the resident an opportunity to resolve them before the final move-out inspection.",
    },
    "quarterly": {
        name: "Quarterly",
        description: "A routine inspection performed every three months to ensure the unit is being properly maintained. It helps identify and address minor issues before they escalate into costly repairs.",
    },
    "semi_annual": {
        name: "Semi-Annual",
        description: "A twice-yearly inspection to check the condition of the unit, ensuring it meets safety standards and identifying any maintenance needs to protect the property’s value.",
    },
    "annual": {
        name: "Annual",
        description: "A yearly inspection that provides a comprehensive overview of the unit’s condition. It ensures compliance with lease agreements and helps maintain the property in excellent condition.",
    },
    "turn": {
        name: "Turn",
        description: "A thorough inspection conducted between tenants to prepare the unit for the next resident. It identifies required repairs, cleaning, and upgrades, ensuring the unit is move-in ready.",
    }
}

const CreateJourney = () => {
    const dispatch = useDispatch();
    // global states
    const { data: clientLimits } = useSelector(state => state.clientLimits);
    const { sidebarOpen } = useSelector(state => state.config);
    // local states
    const [notifState, setNotifState] = useState(null);
    const [selectedInspections, setSelectedInspections] = useState([]);
    const [availableUnits, setAvailableUnits] = useState({});
    const [availableTeamMembers, setAvailableTeamMembers] = useState({});
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [journeyName, setJourneyName] = useState(null);
    const [inspectionSchedules, setInspectionSchedules] = useState(
        Object.keys(INSPECTIONS).reduce((acc, key) => {
            return {
                ...acc,
                [key]: {
                    daysBefore: null,
                    daysAfter: null,
                    assigneeType: null,
                    assignees: [],
                }
            }
        }, {})
    );
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const onCreate = () => {
        setLoading(true);

        if (!journeyName) {
            setNotifState({ text: 'Please enter a journey name', type: 'error' });
            setLoading(false);
            return;
        }
        if (selectedInspections.length > 0) {
            for (let key of selectedInspections) {
                if (
                    !inspectionSchedules[key].daysBefore || !inspectionSchedules[key].daysAfter || 
                    !inspectionSchedules[key].assigneeType || (
                        inspectionSchedules[key].assigneeType === "team" && 
                        inspectionSchedules[key].assignees.length === 0
                    )
                ) {
                    setNotifState({ text: 'Please fill all inspection schedules', type: 'error' });
                    setLoading(false);
                    return;
                }
            }
        }

        fetch(
            `${para_be}/inspections/journey/create`, 
            postReqOptBuilder(
                {
                    journey_name: journeyName,
                    targets: selectedUnits,
                }, true
            )
        )
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                const journeyId = response.result.journey_id;
                Promise.all(
                    selectedInspections.map(async (selected) => {
                        try {
                            const resp = await fetch(
                                `${para_be}/inspections/schedule/create`, 
                                postReqOptBuilder(
                                    {
                                        journey_id: journeyId,
                                        inspection_type: selected,
                                        due_range: [
                                            inspectionSchedules[selected].daysBefore, 
                                            inspectionSchedules[selected].daysAfter
                                        ],
                                        assigned_to: {
                                            type: inspectionSchedules[selected].assigneeType,
                                            ...(inspectionSchedules[selected].assigneeType === "team" ? 
                                                {members: inspectionSchedules[selected].assignees} : {}
                                            )
                                        },
                                        notices: []
                                    }, true
                                )
                            );
                            if (resp.status !== 200) {
                                console.error(`Error creating inspection (${selected}) schedule:`, resp);
                                return false;
                            }
                        } catch (error) {
                            console.error(`Error creating inspection (${selected}) schedule:`, error);
                            return false;
                        }
                        return true;
                    })
                )
                .then((results) => {
                    if (results.includes(false)) {
                        setNotifState({ 
                            text: 'Failed creating inspection schedules. Please try again later or contact support.', 
                            type: 'error' 
                        });
                    } else {
                        setNotifState({ text: 'Journey created successfully', type: 'success' });
                    }
                    setLoading(false);
                    history.push("/journey/" + journeyId);
                });
            } else {
                setNotifState({ text: response.msg, type: 'error' });
                setLoading(false);
            }
        })
        .catch(error => {
            console.error('Error creating journey:', error);
            setNotifState({ text: 'Failed creating journey. Please try again later or contact support.', type: 'error' });
            setLoading(false);
        });
    };
    
    useEffect(() => {
        fetch(`${para_be}/units/list`, { method: 'GET', credentials: 'include' })
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                const units = response.result.reduce((acc, unit) => {
                    return {
                        ...acc,
                        [unit.pid]: {
                            primary: `(${unit.ext_ref}) ${unit.subject}`,
                            secondary: unit?.group
                        }
                    }
                }, {});
                setAvailableUnits(units);
            }
        })
        .catch(error => {
            console.error('Error fetching units:', error);
            setNotifState({ text: 'Failed fetching target units', type: 'error' });
        });

        fetch(`${para_be}/client/get_all_users`, { method: 'GET', credentials: 'include' })
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                const users = response.result.reduce((acc, user) => {
                    return {
                        ...acc,
                        [user.uid]: {
                            primary: `${user.fullName} - ${user.role}`,
                            secondary: user.email
                        }
                    }
                }, {});
                setAvailableTeamMembers(users);
            }
        })
        .catch(error => {
            console.error('Error fetching team members:', error);
            setNotifState({ text: 'Failed fetching team members', type: 'error' });
        });
    }, []);

    
    return (
        <>
            <main className="flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-8">
                    <div className="mt-2 pb-16 max-w-2xl mx-auto flex flex-col">
                        <div className="flex flex-col">
                            <span className="text-gray-700 text-lg font-medium">Create Inspection Journey</span> 
                            <span className="text-sm text-gray-500">Fill the form below to create a new inspections journey</span>
                        </div>
                        <div className="mt-8">
                            <div className="flex flex-col gap-6">
                                <div className="flex flex-col gap-2">
                                    <label className="text-md font-medium text-gray-700">
                                        Journey Name
                                    </label>
                                    <input 
                                        className="border border-gray-300 rounded-md py-3 px-4" 
                                        type="text" 
                                        placeholder="Enter Journey Name"
                                        onChange={(e) => setJourneyName(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="text-md font-medium text-gray-700">
                                        Select Target Units
                                    </label>
                                    <DropdownWithCheckboxes 
                                        options={availableUnits}
                                        onChange={(t)=>{ setSelectedUnits(t); }}
                                        label="Select Target Units"
                                        scrollable={true}
                                        searchable={true}
                                    />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label className="text-md font-medium text-gray-700">
                                        Select Inspections
                                    </label>
                                    <div className='flex flex-wrap gap-2'>
                                        {Object.keys(INSPECTIONS).map((key) => {
                                            return (
                                                <div 
                                                    key={key} 
                                                    className={[
                                                        'flex flex-col gap-1 relative flex-grow',
                                                        'rounded-md py-4 px-4',
                                                        (selectedInspections.includes(key) ? 'bg-white border-indigo-700 border-2' : 'border bg-white border-gray-300')
                                                    ].join(" ")}
                                                >
                                                    <div 
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            setSelectedInspections(prev => {
                                                                if (prev.includes(key)) {
                                                                    return prev.filter(item => item !== key);
                                                                } else {
                                                                    return [...prev, key];
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <input 
                                                            type="checkbox" 
                                                            checked={selectedInspections.includes(key)} 
                                                            className='absolute top-2 left-2 w-3 h-3 d-none'
                                                        />
                                                        <label className="text-sm font-medium text-gray-900">
                                                            {INSPECTIONS[key].name}
                                                        </label>
                                                        <p className="text-xs text-gray-700 text-justify">
                                                            {INSPECTIONS[key].description}
                                                        </p>
                                                    </div>
                                                    {selectedInspections.includes(key) &&
                                                        <div className='mt-4 border-t pt-4 gap-3'>
                                                            <div className="flex flex-col max-w-sm gap-3">
                                                                <div className="relative flex flex-col gap-2">
                                                                    <label className="text-sm font-medium text-gray-700">
                                                                        Due By - Days Range
                                                                    </label>
                                                                    <div className="flex flexRow">
                                                                        <SimpleInput 
                                                                            type="number"
                                                                            si_input_text="Enter Days Before"
                                                                            containerClasses="no-margin flex-grow w-1/2"
                                                                            inputStyle={{paddingTop: '1rem'}}
                                                                            on_change={(e) => {
                                                                                setInspectionSchedules(
                                                                                    (prev) => {
                                                                                        return {
                                                                                            ...prev,
                                                                                            [key]: {
                                                                                                ...prev[key],
                                                                                                daysBefore: e.target.value
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                        <div className="text-md font-medium text-gray-700 mx-3 my-auto">—</div>
                                                                        <SimpleInput 
                                                                            type="number"
                                                                            si_input_text="Enter Days After"
                                                                            containerClasses="no-margin flex-grow w-1/2"
                                                                            inputStyle={{paddingTop: '1rem'}}
                                                                            on_change={(e) => {
                                                                                setInspectionSchedules(
                                                                                    (prev) => {
                                                                                        return {
                                                                                            ...prev,
                                                                                            [key]: {
                                                                                                ...prev[key],
                                                                                                daysAfter: e.target.value
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-col gap-2">
                                                                    <label className="text-sm font-medium text-gray-700">
                                                                        Assign To
                                                                    </label>
                                                                    <DropdownWithCheckboxes 
                                                                        options={{
                                                                            tenant: {
                                                                                primary: "Resident",
                                                                                secondary: "The resident will be responsible for the inspection"
                                                                            },
                                                                            team: {
                                                                                primary: "Team",
                                                                                secondary: "A member of your team will be responsible for the inspection"
                                                                            }
                                                                        }}
                                                                        multipleSelection={false}
                                                                        onChange={(selected) => {
                                                                            setInspectionSchedules(
                                                                                (prev) => {
                                                                                    return {
                                                                                        ...prev,
                                                                                        [key]: {
                                                                                            ...prev[key],
                                                                                            assigneeType: selected
                                                                                        }
                                                                                    }
                                                                                }
                                                                            );
                                                                        }}
                                                                        label="Select Assignee"
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col gap-2">
                                                                    <label className="text-sm font-medium text-gray-700">
                                                                        Select Team Members
                                                                    </label>
                                                                    <DropdownWithCheckboxes 
                                                                        options={availableTeamMembers}
                                                                        onChange={(selected) => { 
                                                                            setInspectionSchedules(
                                                                                (prev) => {
                                                                                    return {
                                                                                        ...prev,
                                                                                        [key]: {
                                                                                            ...prev[key],
                                                                                            assignees: selected
                                                                                        }
                                                                                    }
                                                                                }
                                                                            );
                                                                        }}
                                                                        label="Select Team Members"
                                                                        scrollable={true}
                                                                        searchable={true}
                                                                        disabled={inspectionSchedules[key].assigneeType !== "team"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"fixed border-t border-slate-500 bottom-0 right-0 py-4 px-6 bg-white " + (sidebarOpen ? "left-64" : "left-8")}>
                        <div className='flex justify-between'>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#fff', 
                                    strokeWidth: '1px',
                                    stroke: '#F2F2F3',
                                    fontSize: '12px',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    padding: '9px 15px',
                                    color: '#0C1222',
                                    minWidth: 0,
                                    '&:hover': {
                                        backgroundColor: '#F7F7F7', 
                                    },
                                }}
                                disabled={loading}
                                onClick={() => {
                                    history.push('/journeys');
                                }}
                            >
                                Cancel
                            </Button>
                            <CustomButton 
                                variant="contained"
                                sx={{
                                    backgroundColor: '#4F46E5', 
                                    color: 'white',
                                    fontSize: '12px',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    padding: '9px 15px',
                                    '&:hover': { backgroundColor: '#4338CA' } 
                                }}
                                loading={loading}
                                onClick={onCreate}
                            >
                                Create New Journey
                            </CustomButton>
                        </div>
                    </div>
                </div>
                {/* Snackbar Notification */}
                {Boolean(notifState) && (
                    <SnackBar
                        open={Boolean(notifState)}
                        handleClose={() => setNotifState(null)}
                        severity={notifState.type}
                        message={notifState.text}
                        duration={6000}
                    />
                )}
            </main>
        </>
    )
};

export default CreateJourney;