import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
//assets
import { CreditCardIcon, CubeIcon, DocumentIcon, FingerPrintIcon, UserCircleIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline';
//components
import { Dialog } from '@headlessui/react';
//utils
import { paymentAccountExists } from '../../utils';
import { classNames } from '../../../../../../utils/main_utils';


const subscriptionNavigation = [
    {
        name: 'Show Subscription',
        id: 'showSubscription',
        icon: CreditCardIcon,
        path: '/payments/showSubscription',
    },
];

const customerNavigation = [
    {
        name: 'Payment Method',
        id: 'paymentMethod',
        icon: CubeIcon,
        path: '/payments/paymentMethod',
    },
    {
        name: 'Invoices',
        id: 'invoices',
        icon: DocumentIcon,
        path: '/payments/invoices',
    },
    {
        name: 'Customer Info',
        id: 'customerInfo',
        icon: UsersIcon,
        path: '/payments/customerInfo',
    },
];


const SubNavigation = () => {
    const { customerData } = useSelector(state => state.customer);
    const { data: { subscription } } = useSelector(state => state.subscription);
    const history = useHistory();

    let isHaveCustomer = false;

    if (customerData) {
        isHaveCustomer = paymentAccountExists(customerData);
    }

    return (
        <>
            <div className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:flex-none lg:border-0">
                <div className="flex-none">
                    <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap">
                        {subscriptionNavigation.map(item => (
                            <li key={item.name}>
                                <button
                                    onClick={() => history.push(`/settings${item.path}`)}
                                    className={classNames(
                                        window.location.pathname.endsWith(`${item.path}`) ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                        !subscription && item.id !== 'createSubscription' ? 'cursor-not-allowed opacity-50' : '',
                                        subscription && item.id === 'createSubscription' ? 'cursor-not-allowed opacity-50' : '',
                                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6'
                                    )}
                                    disabled={(!subscription && item.id !== 'createSubscription') || (subscription && item.id === 'createSubscription')}
                                >
                                    <item.icon
                                        aria-hidden="true"
                                        className={classNames(window.location.pathname.endsWith(`${item.path}`) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0')}
                                    />
                                    {item.name}
                                </button>
                            </li>
                        ))}
                        {customerNavigation.map(item => (
                            <li key={item.name}>
                                <button
                                    onClick={() => history.push(`/settings${item.path}`)}
                                    className={classNames(
                                        window.location.pathname.endsWith(`${item.path}`) ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                        !isHaveCustomer ? 'cursor-not-allowed opacity-50' : '',
                                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6'
                                    )}
                                    disabled={!isHaveCustomer}
                                >
                                    <item.icon
                                        aria-hidden="true"
                                        className={
                                            classNames(
                                                window.location.pathname.endsWith(`${item.path}`) ? 
                                                    'text-indigo-600' : 
                                                    'text-gray-400 group-hover:text-indigo-600', 
                                                'h-6 w-6 shrink-0'
                                            )
                                        } />
                                    {item.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default SubNavigation;
