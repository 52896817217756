import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//mui
import { Card, CardContent, Divider, Typography } from "@mui/material";
//components
import BaseModal from "../../../../../../../components/Modal";
import { SimpleInputDropdown } from "../../../../../../../components/dropdowns/simple_dropdown/simple_dropdown";
import MainLoader from "../../../../../../../components/loaders/main_loader/main_loader";
import CustomButton from "../../../../../../../components/buttons/CustomButton";
//hooks
import useDebounce from "../../../../../../../hooks/useDebounce";
//slices
import { fetchBillingDetails } from "../../../../../../../store/slices/billingSlice";
import { setSubscription } from "../../../../../../../store/slices/subscriptionsSlice";
//constants
import { para_be } from "../../../../../../../config";
import { SeverityLevels } from "../../../../../../../constants";


const UpdateSubscription = ({ open, handleClose, setNotification }) => {
    const { loading, billingDetails } = useSelector(state => state.billing);
    const { SubscriptionMetadata } = useSelector(state => state.customer);
    
    const [customerData, setCustomerData] = useState({
        portfolioType: SubscriptionMetadata.subscription?.plan,
        portfolioSize: 0,
    });
    const [loadingQuote, setLoadingQuote] = useState(false);
    const [quote, setQuote] = useState(null);
    const [loadedBillingDetails, setLoadedBillingDetails] = useState(false);

    const debouncedCustomerData = useDebounce(customerData, 500);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!billingDetails && !loadedBillingDetails) {
            dispatch(fetchBillingDetails());
            setLoadedBillingDetails(true);
        }
    }, [dispatch]);

    useEffect(() => {
        if ((!billingDetails || !billingDetails.default_payment_method) && loadedBillingDetails) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Failed to load data to update subscription'});
            handleClose();
        }
    }, [billingDetails]);

    useEffect(() => {
        if (debouncedCustomerData.portfolioType && debouncedCustomerData.portfolioSize > 0) {
            setLoadingQuote(true);
            fetch(
                para_be + '/payments/get_subscription_quote?' + 
                new URLSearchParams(
                    {
                        portfolio_type: (debouncedCustomerData.portfolioType || SubscriptionMetadata.subscription.plan).toUpperCase(),
                        quantity: Number(SubscriptionMetadata.subscription.units || 0) + Number(debouncedCustomerData.portfolioSize || 0),
                        currency: SubscriptionMetadata.subscription.rate.currency || "$"
                    }
                ), { credentials: 'include' }
            )
            .then(response => response.json())
            .then(response => {
                setQuote(response.result);
            })
            .finally(() => {
                setLoadingQuote(false);
            })
        }
    }, [debouncedCustomerData]);

    
    const handleSubmit = async () => {
        if (!customerData.portfolioType || !customerData.portfolioSize) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Please fill out all the fields.'});
            return;
        }

        try {
            await dispatch(
                setSubscription({ 
                    paymentMethodId: billingDetails.default_payment_method, 
                    planType: 'premium', 
                    plan: customerData.portfolioType.toUpperCase(), 
                    quantity: Number(SubscriptionMetadata.subscription.units || 0) + Number(debouncedCustomerData.portfolioSize || 0), 
                    price: quote.price_id 
                })
            ).unwrap();
            setNotification({open: true, severity: SeverityLevels.SUCCESS, message: 'Updated subscription successfully!'});
            handleClose();
        } catch (err) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Failed to update subscription.'});
            throw new Error('Failed to update subscription.\nError: ' + err.message);
        }
    }

    const handleInputChange = e => {
        const { name, value } = e.target;
        setCustomerData(prev => ({...prev, [name]: value}));
    };
    
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            maxWidth={800}
        >
            <div className="p-6 max-w-md mx-auto bg-white">
                <div className="flex flex-col space-y-6">
                    <h2 className="text-xl font-bold mb-6">Update Subscription</h2>
                    {/* Select Inspections Limit */}
                    <div>
                        <SimpleInputDropdown
                            label={"Inspections Limit (per unit)"}
                            placeholder={"Choose Type"}
                            extraClasses={"dd-full-width"}
                            value={
                                SubscriptionMetadata.subscription?.plan === "STR" ? 
                                "Unlimited" : 
                                SubscriptionMetadata.subscription?.plan === "MTR" ? 
                                "8 Inspections / Year" :
                                SubscriptionMetadata.subscription?.plan === "LTR" ?
                                "2 Inspections / Year" : null
                            }
                            items={{
                                str: { present: "Unlimited" },
                                mtr: { present: "8 Inspections / Year", disabled: ["STR"].includes(SubscriptionMetadata.subscription.plan) },
                                ltr: { present: "2 Inspections / Year", disabled: ["MTR", "STR"].includes(SubscriptionMetadata.subscription.plan) },
                            }}
                            closeOnSelection={true}
                            onclick={(selectedValue, e) => {
                                setCustomerData(prev => ({...prev, portfolioType: selectedValue}));
                            }}
                        />
                        {SubscriptionMetadata.subscription.plan &&
                            <span className='block text-gray-500 text-xs mt-1 pt-0'>
                                {
                                    "STR" === SubscriptionMetadata.subscription.plan ?
                                    'Current Plan: Unlimited number of inspections per property per year' :
                                    <>
                                        Current Plan: Limited to 
                                        {
                                            "LTR" === SubscriptionMetadata.subscription.plan ? ' 2 ' : ' 8 '
                                        }
                                        inspections per property per year
                                    </>
                                }
                            </span>
                        }
                    </div>
                    {/* Add to Portfolio Input */}
                    <div className="mt-4 mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="portfolioSize">Add to Portfolio</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="portfolioSize"
                            type="number"
                            name="portfolioSize"
                            value={customerData.portfolioSize}
                            onChange={handleInputChange}
                            required />
                    </div>
                    
                    {(quote || loadingQuote) &&
                        <div className="mt-6 mb-6">
                            <Divider className="my-8 mt-4" />
                        </div>
                    }
                    {loadingQuote ? 
                        <MainLoader /> :
                        quote ?
                            <>
                                <Card className='shadow-sm'>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom sx={{
                                            textDecoration: 'line-through',
                                            color: '#A0aec0' // E2e8f0
                                        }}>
                                            {SubscriptionMetadata.subscription.rate.currency === "eur" ? "€" : "$"}
                                            {(SubscriptionMetadata.subscription.rate.value*SubscriptionMetadata.subscription.units).toLocaleString("en-US")} / Month
                                        </Typography>
                                        <Typography variant="h6" gutterBottom>
                                            {SubscriptionMetadata.subscription.rate.currency === "eur" ? "€" : "$"}
                                            {Number(quote.price).toLocaleString('en-US')} / Month
                                        </Typography>
                                        <Typography color="textSecondary" sx={{
                                            textDecoration: 'line-through',
                                            color: '#A0aec0' // E2e8f0
                                        }}>
                                            Base Price: {SubscriptionMetadata.subscription.rate.currency === "eur" ? "€" : "$"}{Number(SubscriptionMetadata.subscription.rate.value).toLocaleString('en-US')} / Month
                                        </Typography>
                                        <Typography color="textSecondary">
                                            Base Price: {SubscriptionMetadata.subscription.rate.currency === "eur" ? "€" : "$"}{Number(quote.base_price).toLocaleString('en-US')} / Month
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </> : ""
                    }
                    
                    <CustomButton variant="contained" color="primary" onClick={handleSubmit} disabled={loading || loadingQuote} loading={loading || loadingQuote}>
                        Update Subscription
                    </CustomButton>
                </div>
            </div>
        </BaseModal>
    )
}

export default UpdateSubscription;