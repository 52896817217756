import React from 'react'

const ReportsIcon = ({ fill = 'none', width = '20px', height = '20px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 18 18'
    >
      <path d="M5.54167 1.16602C5.05842 1.16602 4.66667 1.55777 4.66667 2.04102V2.62435C4.66667 3.1076 5.05842 3.49935 5.54167 3.49935H8.45833C8.94158 3.49935 9.33333 3.1076 9.33333 2.62435V2.04102C9.33333 1.55777 8.94158 1.16602 8.45833 1.16602H5.54167Z" fill="#918BEF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.79167 2.35404C3.05789 2.39521 2.59466 2.51214 2.26256 2.84423C1.75 3.35679 1.75 4.18175 1.75 5.83167V9.33167C1.75 10.9816 1.75 11.8065 2.26256 12.3191C2.77513 12.8317 3.60008 12.8317 5.25 12.8317H8.75C10.3999 12.8317 11.2249 12.8317 11.7374 12.3191C12.25 11.8065 12.25 10.9816 12.25 9.33167V5.83167C12.25 4.18175 12.25 3.35679 11.7374 2.84423C11.4053 2.51214 10.9421 2.39521 10.2083 2.35404V2.62435C10.2083 3.59085 9.42483 4.37435 8.45833 4.37435H5.54167C4.57517 4.37435 3.79167 3.59085 3.79167 2.62435V2.35404ZM4.08333 5.68685C3.84171 5.68685 3.64583 5.88272 3.64583 6.12435C3.64583 6.36597 3.84171 6.56185 4.08333 6.56185H4.375C4.61662 6.56185 4.8125 6.36597 4.8125 6.12435C4.8125 5.88272 4.61662 5.68685 4.375 5.68685H4.08333ZM6.125 5.68685C5.88338 5.68685 5.6875 5.88272 5.6875 6.12435C5.6875 6.36597 5.88338 6.56185 6.125 6.56185H9.91667C10.1583 6.56185 10.3542 6.36597 10.3542 6.12435C10.3542 5.88272 10.1583 5.68685 9.91667 5.68685H6.125ZM4.08333 7.72852C3.84171 7.72852 3.64583 7.92439 3.64583 8.16602C3.64583 8.40764 3.84171 8.60352 4.08333 8.60352H4.375C4.61662 8.60352 4.8125 8.40764 4.8125 8.16602C4.8125 7.92439 4.61662 7.72852 4.375 7.72852H4.08333ZM6.125 7.72852C5.88338 7.72852 5.6875 7.92439 5.6875 8.16602C5.6875 8.40764 5.88338 8.60352 6.125 8.60352H9.91667C10.1583 8.60352 10.3542 8.40764 10.3542 8.16602C10.3542 7.92439 10.1583 7.72852 9.91667 7.72852H6.125ZM4.08333 9.77018C3.84171 9.77018 3.64583 9.96606 3.64583 10.2077C3.64583 10.4493 3.84171 10.6452 4.08333 10.6452H4.375C4.61662 10.6452 4.8125 10.4493 4.8125 10.2077C4.8125 9.96606 4.61662 9.77018 4.375 9.77018H4.08333ZM6.125 9.77018C5.88338 9.77018 5.6875 9.96606 5.6875 10.2077C5.6875 10.4493 5.88338 10.6452 6.125 10.6452H9.91667C10.1583 10.6452 10.3542 10.4493 10.3542 10.2077C10.3542 9.96606 10.1583 9.77018 9.91667 9.77018H6.125Z" fill="#918BEF"/>
    </svg>
  );
};

export default ReportsIcon