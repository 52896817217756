import React, {useState, useEffect, useRef} from "react";
//components
import Btn from "../../../components/buttons/standard/btn";
import BackBtn from "../../../components/buttons/back_btn/back";
import EmptyState from "../../../components/emptyState/empty_state";
import MainLoader from "../../../components/loaders/main_loader/main_loader";
import Notification from "../../../components/side_notification/side_notification";
import EditableInput from "../../../components/inputs/editable_input/editable_input";
//utils
import { daysAgo } from "../../../utils/date_utils";
//styles
import "./checklist_item.css";
//assets
import checkboxMarked from "../../../assets/icons/checkbox-marked.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit_pen.svg";
import deleteIcon from "../../../assets/icons/DeleteOutlined.svg";
import editIcon_2 from "../../../assets/icons/Edit.svg";
import { postReqOptBuilder } from "../../../utils/main_utils";

export default function ChecklistItem (props) {
    const scrollToLastMessage = useRef();
    const targetComment = useRef();
    const textAreaRef = useRef()
    const [isEmptyState, setEmptyState] = useState(false);
    const [isLoading, setLoader] = useState(true);
    const [messages, setMessages] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(true);
    const [notifState, setNotifState] = useState(null);
    const [isChangedSubject, setSubjectChanged] = useState(false);
    const [descriptionEdit, setDescriptionEdit] = useState(false);
    const [descriptionValue, setDescriptionValue] = useState(props.description);
    const [commentInEditMode, setCommentInEditMode] = useState(null);
    

    useEffect( () => {
        fetch(props.para_be + '/checklists/get_comments?report_id=' + props.reportId + "&ci_id=" + props.ci_id)
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                if (Array.isArray(response.result) && response.result.length > 0) {
                    setMessages(response.result);
                    if (isEmptyState) setEmptyState(false);
                } else {
                    setEmptyState(true);
                }
            } else {
                setEmptyState(true);
                setNotifState({text:"Failed to fetch comments", type:"error"});
            }
            setLoader(false);
        })
        .catch (error => {
            console.log(error);
            setNotifState({text:"Failed to fetch comments", type:"error"});
            setLoader(false);
            setEmptyState(true);
        });
    }, [forceRefresh]);

    const addNewComment = () => {
        const commentValue = document.getElementById("comment-text-area").value;
        fetch(props.para_be + '/checklists/add_new_comment', postReqOptBuilder({'report_id': props.reportId, 'ci_id': props.ci_id, 'msg': commentValue}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                document.getElementById("comment-text-area").value = "";
                setForceRefresh(!forceRefresh);
            } else {
                setNotifState({text: "Failed to add comment", type: "error"});
            }
        })
        .catch (error => {
            setNotifState({text: "Failed to add comment", type: "error"});
        });
    };

    useEffect(() => {
        setTimeout(() => {
            while (true) {
                if (props.hashParams && document.querySelector(`#comment-${props.hashParams.comment_id}`)) {
                    targetComment.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                } else if (messages && document.querySelectorAll(".comment-box").length === messages.length) {
                    scrollToLastMessage.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                } else if (!messages) {
                    break;
                }
            }
        }, 100);
    }, [messages]);

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    const updateNotifState = (state) => {
        setNotifState(state)
    };

    const handleSubjectChanged = () => {
        setSubjectChanged(true);
    };

    const onSubjectUpdate = (new_subject) => {
        props.handleSubjectChange(props.ci_id, new_subject);
    };

    const handleBack = () => {
        props.handleBack(isChangedSubject)
    };

    useEffect(() => {
        textAreaRef.current.style.height = "0px";
        const scrollHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = scrollHeight + "px";
    }, [descriptionValue, descriptionEdit]);

    const updateDescription = () => {
        fetch(props.para_be + '/checklists/update_item', postReqOptBuilder({'type': 'description', 'report_id': props.reportId, 'ci_id': props.ci_id, 'text_to_update': descriptionValue}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setNotifState({text: "Saved successfully", type: "success"});
            } else {
                setNotifState({text: "Failed to update description", type: "error"});
            }
            setDescriptionEdit(false);
        })
        .catch (error => {
            setNotifState({text:"Failed to update description", type:"error"});
            setDescriptionEdit(false);
        });
    };

    const handleKeyPressComment = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) addNewComment();
    };

    const closeDescription = (event) => {
        if (event.key === 'Escape') {
            setDescriptionValue(props.description);
            setDescriptionEdit(false);
        }
    };

    const getCommentIdx = (comment_id) => {
        let commentIdx = -1;
        for (let i=0; i<messages.length; i++) {
            if (messages[i][1] === comment_id) {
                commentIdx = i;
                break;
            }
        }
        return commentIdx;
    }

    const switchCommentMode = (commenter, comment_id, current_value) => {
        let commentIdx = getCommentIdx(comment_id);
        if (commentIdx !== -1 && messages[commentIdx][6] === 1) {
            setCommentInEditMode([commentIdx, current_value]);
        }
    };

    const handleCommentKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) updateComment();
        else if (event.key === 'Escape') setCommentInEditMode(null);
    }

    const updateComment = () => {
        if (commentInEditMode) {
            let newMessages = [...messages];
            newMessages[commentInEditMode[0]][2] = commentInEditMode[2];
            fetch(props.para_be + "/checklists/edit_comment", postReqOptBuilder({'comment_id': newMessages[commentInEditMode[0]][1], 'msg': commentInEditMode[1]}))
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    setMessages(newMessages);
                    setNotifState({text: 'Comment Updated Successfully', type: 'success'});
                } else {
                    setNotifState({text: "Failed to update the comment", type: "error"});
                }
                setCommentInEditMode(null);
            })
            .catch(error => {
                setNotifState({text: "Failed to update the comment", type: "error"});
                setCommentInEditMode(null);
            });
        }
    };

    const removeComment = (comment_id) => {
        let commentIdx = getCommentIdx(comment_id);
        if (commentIdx !== -1 && messages[commentIdx][6] === 1) {
            let newMessages = [...messages];
            newMessages.splice(commentIdx, 1);
            fetch(props.para_be + "/checklists/remove_comment", postReqOptBuilder({'comment_id': comment_id}))
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    setMessages(newMessages);
                    setNotifState({text: 'Comment was removed Successfully', type: 'success'});
                } else {
                    setNotifState({text: 'Failed to remove comment', type: 'error'});
                }
            })
            .catch(error => {
                setNotifState({text: "Failed to remove comment", type: "error"});
            });
        }
    }

    return (
        <div className={"comment-page " + (isEmptyState ? "pos-relative" : "")}>
            <BackBtn clickFunction={handleBack}/>
            <div className="item-header"> 
                <div className="pos-relative">
                    <div className="subject-text-box">
                        <EditableInput
                            value={props.subject}
                            updateUrl={props.para_be + "/checklists/update_item"}
                            body={{"type":"subject", "report_id":props.reportId, "ci_id":props.ci_id}}
                            onError={updateNotifState}
                            updateChange={handleSubjectChanged}
                            onTextUpdate={onSubjectUpdate}
                            updateOnEnter={true}/>
                            
                    </div>
                    {props.status === 1 ?
                    <div>
                        <span className="checked-resolved">
                            <img src={checkboxMarked} alt="resolved"/>Resolved
                            </span>
                    </div> : ""}
                </div>
                <div className="unit-subject">{props.reportSubject}</div>
                <div>{props.reportDate}</div>
                <div className="item-description">
                        <img src={props.mediaUrl} alt="item-image"/>
                        <div>
                             <textarea
                                className="custom-scrollbar"
                                placeholder="No description yet"
                                ref={textAreaRef}
                                readOnly={!descriptionEdit}
                                value={descriptionValue}
                                onChange={(e) => setDescriptionValue(e.target.value)}
                                onKeyDown={(e) => closeDescription(e)}
                            />
                            {descriptionEdit ?
                                <Btn
                                    text="Update"
                                    type="primary"
                                    onclick={updateDescription}
                                /> : <EditIcon stroke="#1890ff" fill="#1890ff" onClick={()=>setDescriptionEdit(true)}/>
                            }
                        </div>
                </div>
            </div>
            <div className="comments-title">Comments</div>
            {isEmptyState ?
                <div className="empty-wrapper"><EmptyState text="No comments yet." size="md"/></div> :
                <div className={"comments-container custom-scrollbar" + (isLoading ? " pos-relative" : "")}>
                    {isLoading ? <MainLoader/> :
                        (messages ? messages.map((item, mIdx) => {
                            //item 0-uid, 1-ci_id, 2-msg, 3-datetime, 4-first name, 4-last name
                            //TODO - add 5 - img src 
                            return (
                                <div className="comment-box">
                                    {/* <img src="#"/> */}
                                    <div className="comment-body" id={props.hashParams.comment_id === item[1] ? "comment-background" : ""} ref={props.hashParams.comment_id === item[1] ? targetComment : null}>
                                        <div className="user-name">{item[4]} {item[5]} <span>{daysAgo(item[3])}</span></div>
                                        <textarea 
                                            className="text-1"
                                            type="text"
                                            value={commentInEditMode && commentInEditMode[0] === mIdx ? commentInEditMode[1] : item[2]}
                                            rows={(commentInEditMode && commentInEditMode[1] && commentInEditMode[0] === mIdx ? commentInEditMode[1] : item[2] ? item[2] : "\n").split("\n").length}
                                            readOnly={!commentInEditMode || commentInEditMode[0] !== mIdx}
                                            onChange={(e) => setCommentInEditMode([commentInEditMode[0], e.target.value])}
                                            onKeyDown={(commentInEditMode && commentInEditMode[0] === mIdx) ? ((e) => handleCommentKeyDown(e)) : null}
                                        />
                                        {(commentInEditMode && commentInEditMode[0] === mIdx) && 
                                            <Btn
                                                text="Update"
                                                type="primary"
                                                onclick={(e) => updateComment()}
                                            />
                                        }
                                        {/* <div>{item[2]}</div> */}
                                    </div>
                                    {/* If the user is allowed to edit or remove, show icons */}
                                    {item[6] === 1 &&
                                        <div className="comment-actions">
                                            <img 
                                                src={editIcon_2} 
                                                alt="edit"
                                                onClick={(e) => switchCommentMode(item[0], item[1], item[2])}
                                            />
                                            <img 
                                                src={deleteIcon} 
                                                alt="del"
                                                onClick={(e) => removeComment(item[1])}
                                            />
                                        </div>
                                    }
                                </div>
                            )})
                         : "")
                    }
                    <div ref={scrollToLastMessage}/>
                </div>
                }
            {isLoading ? "" :
             <>
                <textarea id="comment-text-area" placeholder="Enter your comment here" rows="3" onKeyDown={(e)=>handleKeyPressComment(e)}/>
                <Btn
                    text="Add Comment"
                    type="primary"
                    onclick={addNewComment}
                />
            </>}

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </div>
    )
}
