// import ReactPDF from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';

// assets
import paraIcon from "../../../assets/icons/logo-icon.png";
import oswaldFont from "../../../assets/fonts/oswald_font.ttf";
import robotoFont from "../../../assets/fonts/roboto_regular.ttf";
// import roboto400 from "../../../assets/fonts/roboto_400.woff2";
// import roboto700 from "../../../assets/fonts/roboto_700.woff2";

// const items = [
//   {'subject': 'Missing chair', 'description': 'Where: living room-dining room-kitchen\nPosition: Next to the dinner table.', 'img': "https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/1/banner.jpg"},
//   {'subject': 'Missing chair', 'description': 'Where: living room-dining room-kitchen\nPosition: Next to the dinner table.', 'img': "https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/1/banner.jpg"},
//   {'subject': 'Missing chair', 'description': 'Where: living room-dining room-kitchen\nPosition: Next to the dinner table.', 'img': "https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/1/banner.jpg"},
//   {'subject': 'Missing chair', 'description': 'Where: living room-dining room-kitchen\nPosition: Next to the dinner table.', 'img': "https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/1/banner.jpg"},
//   {'subject': 'Missing chair', 'description': 'Where: living room-dining room-kitchen\nPosition: Next to the dinner table.', 'img': "https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/1/banner.jpg"},
// ];

export default function ReportPDF(props) {

    Font.register({
        family: 'Oswald',
        src: oswaldFont,
        format: 'truetype'
    });
    Font.register({
        family: 'Roboto',
        src: robotoFont,
        format: 'truetype',
        fontWeight: 400
    });
    Font.register({
        family: 'Roboto-Bold',
        src: robotoFont,
        format: 'truetype',
        fontWeight: 700
    });
    
    const styles = StyleSheet.create({
        body: {
            paddingTop: 0,
            paddingBottom: 77,
        },
        textGeneral: {
            fontFamily: 'Roboto',
            lineHeight: 1.5,
        },
        boldTextGeneral: {
            fontFamily: 'Roboto-Bold',
            lineHeight: 1.22,
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        flexSpaceBetween: {
            justifyContent: 'space-between',
        },
        navbar: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 50,
            paddingRight: 50,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderBottom: 1,
            borderStyle: 'solid',
            borderColor: '#c4c4c4',
        },
        logo: {
            height: 36.8,
            width: 33,
        },
        imgWrapperL1: {
            display: 'hidden',
        },
        imgWrapperL3: {
            display: 'block',
        },
        companyLogo: {
            height: 40,
            width: 40,
            // border: '1 solid #c4c4c4',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            overflow: 'hidden',
        },
        companyLogoFlexBox: {
            display: 'flex',
            flexDirection: 'row',
            height: 40,
            width: 40,
        },
        companyLogoImg: {
            display: 'block',
            maxHeight: 40,
            maxWidth: 40,
        },
        pageHeader: {
            paddingLeft: 50,
            paddingRight: 50,
            marginTop: 40,
            marginBottom: 25,
        },
        h1: {
            fontSize: 18,
            marginBottom: 4,
            color: '#1890ff',
        },
        h2: {
            fontSize: 16,
        },
        h3: {
            fontSize: 16,
        },
        h4: {
            fontSize: 14,
        },
        text1: {
            fontFamily: 'Roboto',
            fontSize: 12,
            lineHeight: 1.5,
            color: 'rgba(0, 0, 0, 0.85)',
        },
        text3: {
            fontFamily: 'Roboto',
            fontSize: 10,
            lineHeight: 1.3,
            color: 'rgba(0, 0, 0, 0.85)',
        },
        mainImageWrapper: {
            display: 'flex',
            position: 'relative',
            overflow: 'hidden',
            marginLeft: 50,
            width: 250,
            height: 150,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
        },
        mainImage: {
            position: 'absolute',
            height: 150,
        },
        mainSection: {
            marginTop: 40,
            paddingLeft: 50,
            paddingRight: 50,
        },
        mainSectionHeaderContainer: {
            width: '100%',
            borderBottom: '0.5 solid #444444',
        },
        mainSectionHeader: {
            fontFamily: 'Roboto-Bold',
            fontSize: 16,
            lineHeight: 1.22,
        },
        mainSectionHeaderPadding: {
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 16,
            paddingRight: 16,
        },
        headerMargin: {
            marginBottom: 25,
        },
        subHeaderMargin: {
            marginBottom: 10,
        },
        
        pageNumber: {
            position: 'absolute',
            fontFamily: 'Roboto',
            fontSize: 14,
            bottom: 18,
            left: 50,
            color: 'rgba(0, 0, 0, 0.85)',
        },
    });


    const Br = () => "\n";

    return (
        <Document 
        author="Paraspot"
        title={`Report ${props.subject}`}
        subject={`Report of ${props.subject} from ${props.report_datetime}`}
        language="en">
            <Page size="A4" style={styles.body}>
                <View style={styles.navbar} fixed>
                    <View style={styles.companyLogo}>
                        <View style={styles.companyLogoFlexBox}>
                            <Image 
                                style={styles.companyLogoImg}
                                src={{ uri: props.clientLogo, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}
                            />
                        </View>
                    </View>
                </View>
                
                <View style={styles.pageHeader} fixed>
                    <Text style={[styles.h1, styles.boldTextGeneral]}>{props.subject}</Text>
                    <Text style={styles.text1}>Report Date: {props.report_datetime}</Text>
                </View>
                
                {/* <View style={styles.mainImageWrapper}>
                    <Image 
                        style={styles.mainImage}
                        src="https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/1/banner.jpg"
                    />
                </View> */}
                
                <View style={styles.mainSection}>
                    <View style={[styles.mainSectionHeaderContainer, styles.subHeaderMargin]}>
                        <Text style={[styles.h4, styles.boldTextGeneral, styles.mainSectionHeader, styles.mainSectionHeaderPadding]}>Notes</Text>
                    </View>
                    <Text style={styles.text1}>
                        {props.customText}
                    </Text>
                </View>
                
                <View style={styles.mainSection}>
                    <View style={[styles.mainSectionHeaderContainer, styles.headerMargin, {marginTop: 0}]}>
                        <Text style={[styles.h3, styles.boldTextGeneral, styles.mainSectionHeader, {marginTop: 0}]}>Documentation</Text>
                    </View>
                    {
                        ['Inventory', 'Defects', 'Inventory Defects', 'Structural Defects', 'Organization'].map((presentationSectionName) => {
                            console.log("presentationSectionName:", presentationSectionName);
                            let sectionName = presentationSectionName.toLowerCase().replace(' ', '_');
                            console.log("reportItems:", props.reportItems[sectionName]);
                            const sectionContent = props.reportItems[sectionName] === undefined || props.reportItems[sectionName].length === 0 ?
                                                    null :
                                                    props.reportItems[sectionName].map( (i, idx) => {
                                                        console.log("report list item:", i);
                                                        return (
                                                            <View break={idx !== 0}>
                                                                <View style={[{flexGrow: 1,}]}>
                                                                    <Text style={[styles.h4, styles.boldTextGeneral, {marginBottom: 6}]}>{i['subject']}</Text>
                                                                    <Text style={[styles.text1]}>{i['description']}</Text>
                                                                    <Text style={[styles.text3, {marginTop: 6, marginBottom: 2}]}>(Before [{i['before_date']}]: Left, After: Right)</Text>
                                                                </View>
                                                                <View style={[styles.flexRow, styles.flexSpaceBetween, {marginBottom: 80,}]}>
                                                                    <View style={[styles.flexRow, {marginLeft: 10, marginRight: 10,}]}>
                                                                        {i['img_before'] !== undefined &&
                                                                            <View style={[styles.imgWrapperL1, {height: 400, width: 225, marginRight: 10}]}>
                                                                                <View style={[styles.flexRow, {height: 400, width: 225}]}>
                                                                                    <Image style={[styles.imgWrapperL3, {height: 400, width: 225}]} src={i['img_before']}/>
                                                                                </View>
                                                                            </View>
                                                                        }
                                                                        <View style={[styles.imgWrapperL1, {height: 400, width: 225}]}>
                                                                            <View style={[styles.flexRow, {height: 400, width: 225}]}>
                                                                                <Image style={[styles.imgWrapperL3, {height: 400, width: 225}]} src={i['img']}/>
                                                                            </View>
                                                                        </View>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        )
                                                    });
                            console.log("Finished building section content");
                            return (
                                <View>
                                    {sectionContent}
                                </View>
                            )
                        })
                    }
                </View>
                
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`Page  ${pageNumber} / ${totalPages}`)} fixed />
            </Page>
        </Document>
    );
}

// ReactPDF.render(<ReportPDF />);






