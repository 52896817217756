import React, { useState } from "react";
//components
import Btn from "../../buttons/standard/btn";
import HideTextInput from "../../inputs/hide_text_input/input";
//utils
import { capitalizeString } from "../../../utils/main_utils";
//styles
import "../main_popups.css";
import "./change_password.css";

export default function ChangeSmtpPasswordPopup (props) {
    const [inputError, setInputError] = useState(null);

    const handleChangePassword = () => {
        if (inputError) setInputError(null);
        const newPassword = document.getElementById("new-pass").value?.trim();
        const confirmNewPassword = document.getElementById("new-pass-confirm").value?.trim();

        let _inputErrors = {};
        for (let [k, v] of Object.entries({"new-password": newPassword, "confirm-password": confirmNewPassword})) {
            if (!v || v.length <= 6) _inputErrors[k] = "Please enter " + capitalizeString(k.replace("-", " "), true) + " with at least 6 characters";
        }
        if (Object.keys(_inputErrors).length > 0) {
            setInputError(_inputErrors);
        } else if (newPassword !== confirmNewPassword) {
            setInputError({'confirm-password': "Passwords do not match"});
        } else {
            props.onSuccess(newPassword);
            props.closeFunc();
        }
    };

    return (
        <section className="change-smtp-pass-container">
            <section className="cp-main">
                <div className="h1 blue-headline">Update SMTP Password</div>
                <HideTextInput
                    label="New Password"
                    id="new-pass"
                    placeholder="Enter new password"
                    inputError={inputError?.hasOwnProperty("new-password") ? inputError["new-password"] : undefined}/>
                <HideTextInput
                    label="Confirm new Password"
                    id="new-pass-confirm"
                    placeholder="Confirm new password"
                    inputError={inputError?.hasOwnProperty("confirm-password") ? inputError["confirm-password"] : undefined}/>
                <div className="flexRow" style={{justifyContent: 'right', marginTop: '24px'}}>
                    <Btn
                        text="Approve change"
                        type="primary"
                        onclick={handleChangePassword}/> 
                </div>
            </section>
        </section>
    )
}