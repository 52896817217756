import { createSlice } from '@reduxjs/toolkit';


const scansDoneSlice = createSlice({
    name: 'scansDone',
    initialState: {
        modals: {
            searchFilterModal: false, 
        }
    },
    reducers: {
        openModal(state, action) {
            const { modalName } = action.payload;
            state.modals[modalName] = true;
        },
        closeModal(state, action) {
            const modalName = action.payload;
            state.modals[modalName] = false;
        },
    },
});

export const { openModal, closeModal } = scansDoneSlice.actions;

export default scansDoneSlice.reducer;