import React, { useState, useEffect, useRef } from "react";
//components
import Notification from "../../side_notification/side_notification";
import Btn from "../../buttons/standard/btn";
import SimpleInput from "../../inputs/simple_input/input";
//styles
import "./add_new_tenancy.css";
//assets


export default function AddNewTenancy(props) {
    const [notifState, setNotifState] = useState(false);
    const [inputError, setInputError] = useState(null);
    const [check, setCheck] = useState("");
    const [newTenancy, setNewTenancy] = useState({
        check_in: "",
        check_out: "",
        rent: "",
        deposit: "",
        email: "",
        mobile: "",
        metadata: "",
        name: "",
    });

    const handleApprove = () => {
        setNotifState(false);
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                withCredentials: true,
            },
            body: JSON.stringify(newTenancy),
        };
        fetch(props.para_be + "/pms/add_new_tenancy", requestOptions)
            .then((response) => response.json())
            .then((response) => {
                if (response.status === 200) {
                    props.setRefresh(!props.refresh);
                    handleClose(true);
                    console.log("respnse", response);
                } else {
                    setNotifState(response.msg);
                }
            })
            .catch((error) => {
                console.log(error);
                setNotifState("An error has occurred, please try again later");
            });
    };
    
    const handleCloseNotif = () => {
        setNotifState(null);
    };
    
    const handleClose = (refresh = false) => {
        props.closeFunc(refresh);
    };
    
    useEffect(() => {
        const rentValue = document.querySelector("#rent").value;
        const depositValue = document.querySelector("#deposit").value;
        const checkInDate = new Date(document.querySelector("#in").value);
        const checkOutDate = new Date(document.querySelector("#out").value);
        
        // Format check_in and check_out as strings in "YYYY-MM-DD HH:mm:ss" format
        let checkInString;
        let checkOutString;
        if (!isNaN(checkInDate.getTime()) && !isNaN(checkOutDate.getTime())) {
            checkInString = checkInDate
                .toISOString()
                .slice(0, 19)
                .replace("T", " ");
            checkOutString = checkOutDate
                .toISOString()
                .slice(0, 19)
                .replace("T", " ");
        }
        // Convert rent and deposit to JSON
        const rentJson = { rent: rentValue };
        const depositJson = { Deposit: depositValue };
        
        setNewTenancy(() => ({
            check_in: checkInString,
            check_out: checkOutString,
            rent: rentJson,
            deposit: depositJson,
            email: document.querySelector("#email").value,
            mobile: document.querySelector("#phone").value,
            name: document.querySelector("#name").value,
        }));
    }, [check]);
    
    const handleChange = (e) => {
        setCheck(e.target.value);
    };

    
    return (
        <div className="new-unit-container">
            <div className="flexColumn">
                <div className="h2 blue-headline">Add New Tenancy</div>
                <div className="fields-ten">
                    <SimpleInput
                        id={"in"}
                        name={"in"}
                        label={"Check In"}
                        placeholder={"Select Check In"}
                        type={"date"}
                        extraClasses={"input-w space"}
                        on_change={handleChange}
                        inputError={
                            inputError === "in"
                                ? "Please Select Date"
                                : undefined
                        }
                    />
                    <SimpleInput
                        id={"out"}
                        name={"out"}
                        label={"Check Out"}
                        placeholder={"Select Check Out"}
                        type={"date"}
                        extraClasses={"input-w space"}
                        on_change={handleChange}
                        inputError={
                            inputError === "out"
                                ? "Please Select Date"
                                : undefined
                        }
                    />
                </div>

                <div className="fields-ten">
                    <SimpleInput
                        id={"rent"}
                        name={"rent"}
                        label={"Rent"}
                        placeholder={"Enter Your Rent"}
                        type={"number"}
                        extraClasses={"input-w space"}
                        on_change={handleChange}
                        inputError={
                            inputError === "in"
                                ? "Please Select Date"
                                : undefined
                        }
                    />
                    <SimpleInput
                        id={"deposit"}
                        name={"deposit"}
                        label={"Deposit"}
                        placeholder={"Enter Your Deposit"}
                        type={"number"}
                        extraClasses={"input-w space"}
                        on_change={handleChange}
                        inputError={
                            inputError === "out"
                                ? "Please Select Date"
                                : undefined
                        }
                    />
                </div>

                <div className="fields-ten">
                    <SimpleInput
                        id={"name"}
                        name={"name"}
                        label={"Name"}
                        placeholder={"Enter Your Name"}
                        type={"text"}
                        extraClasses={"input-w space"}
                        on_change={handleChange}
                        inputError={
                            inputError === "in"
                                ? "Please Select Date"
                                : undefined
                        }
                    />
                    <SimpleInput
                        id={"email"}
                        name={"email"}
                        label={"Email"}
                        placeholder={"Enter Your Email"}
                        type={"email"}
                        extraClasses={"input-w space"}
                        on_change={handleChange}
                        inputError={
                            inputError === "out"
                                ? "Please Select Date"
                                : undefined
                        }
                    />
                </div>

                <div className="fields-ten-2">
                    <SimpleInput
                        id={"phone"}
                        name={"phone"}
                        label={"Mobile No"}
                        placeholder={"Enter Your Mobile No"}
                        type={"tel"}
                        extraClasses={"input-w space"}
                        on_change={handleChange}
                        inputError={
                            inputError === "out"
                                ? "Please Select Date"
                                : undefined
                        }
                    />
                    <Btn
                        text="Create"
                        type="primary"
                        onclick={() => handleApprove()}
                        extraClasses={"f-end"}
                    />
                </div>
            </div>

            {notifState ? (
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"
                />
            ) : ""}
        </div>
    );
}