import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; 
//components
import UpdateCustomerInfo from '../../../Payments/components/popups/editCustomerEntity';
import SnackBar from '../../../../../../components/SnackBar';
import TableOptionsMenu from '../../../../../../components/Tables/TableWidget/components/TableOptionsMenu';
//assets
import { PencilIcon } from '@heroicons/react/20/solid';
//constants
import { SeverityLevels } from '../../../../../../constants';
import { fetchCustomerData } from '../../../../../../store/slices/customerSlice';


const CustomerInfo = () => {
    const dispatch = useDispatch();
    const { customerData } = useSelector(state => state.customer);
    const [openEditCsInfo, setOpenEditCsInfo] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        severity: SeverityLevels.SUCCESS,
        message: '',
    });

    const handleNotificationClose = () => {
        setNotification({ ...notification, open: false });
    };

    if (!customerData) {
        return <div>No customer data available</div>;
    }

    return (
        <>
            {openEditCsInfo &&
                <UpdateCustomerInfo 
                    open={openEditCsInfo}
                    handleClose={() => {
                        setOpenEditCsInfo(false);
                        dispatch(fetchCustomerData());
                    }}
                    setNotification={setNotification}
                />
            }
            <div className="min-h-full">
                <div className="flex flex-1 flex-col">
                    <main className="flex-1 pb-8">
                        <div className="flex justify-between items-center mb-6 mt-6"> 
                            <div>
                                <h2 className="mx-auto max-w-6xl text-lg font-medium leading-6 text-gray-900 mb-4">Customer Info</h2>
                            </div>
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}  
                                    onClick={()=>{}}
                                    sx={{
                                        backgroundColor: '#4F46E5',
                                        color: 'white',
                                        fontSize: '12px',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        padding: '6px 12px',
                                        '&:hover': { backgroundColor: '#4338CA' } 
                                    }}
                                    disabled
                                >
                                    Add Paying Entity
                                </Button>
                            </div>
                        </div>

                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl">
                                <div className="mt-2 flex flex-col">
                                    {/* <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"> */}
                                    <div className="min-w-full align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 min-w-32 text-left text-sm font-semibold text-gray-900">
                                                        Name
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Email
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Balance
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Created
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                <tr className="bg-white">
                                                    <td className="w-full max-w-0 whitespace-nowrap sm:px-2 lg:px-6 py-4 text-sm text-gray-900">
                                                        <span className='truncate text-gray-500 group-hover:text-gray-900'>
                                                            {customerData?.name}
                                                        </span>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        <span className='truncate text-gray-500 group-hover:text-gray-900'>
                                                            {customerData?.email}
                                                        </span>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        <span className='truncate text-gray-500 group-hover:text-gray-900'>
                                                            {customerData.currency === "eur" ? "€" : "$"}
                                                            {Number(customerData.balance).toLocaleString("en-US")}
                                                        </span>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        <time dateTime={new Date(customerData?.created * 1000).toISOString()}>{new Date(customerData?.created * 1000).toLocaleDateString()}</time>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-right text-sm text-gray-500">
                                                        <div className="flex justify-end gap-x-2">
                                                            <TableOptionsMenu 
                                                                extraBtnClasses={"h-full align-middle"}
                                                                items={[
                                                                    {
                                                                        label: 'Edit Entity Email',
                                                                        icon: () => <PencilIcon className="h-5 w-5" />,
                                                                        onClick: () => { setOpenEditCsInfo(true); }
                                                                    }
                                                                ]}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shadow sm:hidden">
                            <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                <li>
                                    <div className="block bg-white px-4 py-4 hover:bg-gray-50">
                                        <span className="flex items-center space-x-4">
                                            <span className="flex flex-1 space-x-2 truncate">
                                                <span className="flex flex-col truncate text-sm text-gray-500">
                                                    <span className="truncate">
                                                        {customerData?.name}
                                                    </span>
                                                    <span className="truncate">
                                                        Email: &nbsp;
                                                        {customerData?.email}
                                                    </span>
                                                    <span className="truncate">
                                                        Balance: &nbsp;
                                                        {customerData.currency === "eur" ? "€" : "$"}
                                                        {Number(customerData.balance).toLocaleString("en-US")}
                                                    </span>
                                                    <span className="truncate">
                                                        Created on: &nbsp;
                                                        <time dateTime={new Date(customerData?.created * 1000).toISOString()}>{new Date(customerData?.created * 1000).toLocaleDateString()}</time>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <div className="flex justify-end mt-4">
                                            <div className="flex gap-x-2">
                                                <TableOptionsMenu 
                                                    extraBtnClasses={"h-full align-middle"}
                                                    items={[
                                                        {
                                                            label: 'Edit Subscription',
                                                            icon: () => <PencilIcon className="h-5 w-5" />,
                                                            onClick: () => { setOpenEditCsInfo(true); }
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </main>
                </div>
            </div>

            {/* Notification */}
            {notification.open &&
                <SnackBar 
                    open={notification.open}
                    handleClose={handleNotificationClose}
                    severity={notification.severity}
                    message={notification.message}
                    duration={6000} />
            }
        </>
    );
};

export default CustomerInfo;
