import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getClientData } from '../../utils/main_utils';


// Slice for fetching end user data
export const fetchEndUserData = createAsyncThunk(
    'config/fetchEndUserData',
    async (_, { rejectWithValue }) => {
        try {
            const endUserData = await getClientData();
            return { data: {ip: endUserData[0], locality: endUserData[1]} };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const configSlice = createSlice({
    name: 'config',
    initialState: {
        isMobile: null,
        sidebarOpenMobile: false,
        sidebarOpen: true,
        lastPage: null,
        endUserData: null,
    },
    reducers: {
        setIsMobile(state, action) {
            state.isMobile = action.payload;
        },
        setSidebarOpenMobile(state, action) {
            state.sidebarOpenMobile = action.payload;
        },
        setSidebarOpen(state, action) {
            state.sidebarOpen = action.payload;
        },
        setLastPage(state, action) {
            state.lastPage = action.payload;
        },
        clearIsMobile(state) {
            state.isMobile = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEndUserData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEndUserData.fulfilled, (state, action) => {
                state.loading = false;
                state.endUserData = action.payload.data;
            })
            .addCase(fetchEndUserData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching client PMS data';
            });
    }
});

export const { setIsMobile, clearIsMobile, setSidebarOpenMobile, setSidebarOpen, setLastPage } = configSlice.actions;

export default configSlice.reducer;
