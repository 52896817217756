import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import SnackBar from '../../../SnackBar';
import TableWidget from '../../TableWidget';
//modals
import AllReports from '../../TableWidget/components/Modals/AllReports/AllReports';
import VideoUploaderModal from '../../TableWidget/components/Modals/VideoUploaderModal';
import VideoModal from '../../TableWidget/components/Modals/VideoModal';
import AllTenancies from '../../TableWidget/components/Modals/AllTenancies';
//slices
import { closeModal } from '../../../../store/slices/searchSlice';
//constants
import { CategoryFilter, INSPECTION_TYPES, MODAL_TYPES } from '../../../../constants'
//utils
import { randomInt } from '../../../../utils/main_utils';


const MiMoTable = () => {
    const dispatch = useDispatch();
    const { rid } = useSelector(state => state.table.selectedRowInTable);
    const { selectedRowInTable, modals, categoryFilter } = useSelector(state => state.table);
    const [notifState, setNotifState] = useState(null);
    const [preventCloseModal, setPreventCloseModal] = useState(false);
    
    const handleCloseModal = (modalName) => {
        if (modals.uploaderModal && preventCloseModal) return;
        dispatch(closeModal(modalName));
    };


    return (
        <>
            {/* Modals */}
            {modals.videoModal && (
                <VideoModal
                    open={modals.videoModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.VIDEO_MODAL)}
                    videoLink={selectedRowInTable.videoLink}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.uploaderModal && (
                <VideoUploaderModal
                    open={modals.uploaderModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.UPLOADER_MODAL)}
                    pid={`${categoryFilter === CategoryFilter.MOVE_OUT ? 
                                INSPECTION_TYPES.MOVE_OUT : 
                                INSPECTION_TYPES.MOVE_IN
                            }${randomInt(1000000, 9999999)}_${selectedRowInTable.pid}`
                    }
                    changeBlockStatus={(s) => { setPreventCloseModal(s); }}
                    setNotifState={setNotifState}
                    scanType={categoryFilter === CategoryFilter.MOVE_OUT ? INSPECTION_TYPES.MOVE_OUT : INSPECTION_TYPES.MOVE_IN}
                />
            )}
            {modals.allReportsModal && (
                <AllReports
                    open={modals.allReportsModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.ALL_REPORTS_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.allTenanciesModal && (
                <AllTenancies
                    open={modals.allTenanciesModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.ALL_TENANCIES_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                />
            )}
            {/* Main Table Layout */}
            <TableWidget showExpandedRows={true} />
            {/* Snackbar Notification */}
            {Boolean(notifState) && (
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={notifState.type === 'error' ? 'error' : 'success'}
                    message={notifState.text}
                    duration={6000}
                />
            )}
        </>
    );
};

export default MiMoTable;