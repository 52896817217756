class MockError extends Error {
    constructor(message) {
        super(message);
        this.mockData = null;
        this.config = null;
        this.code = null;
    }
}

const base_url = window.location.protocol + "//" + window.location.hostname;
const para_be = base_url + (window.location.port ? ':5000' : "") + "/api";

const mocks = {
    [`${para_be}/payments/create_new_customer`]: {
        "address": null,
        "balance": 0,
        "created": 1723801525,
        "currency": null,
        "default_source": null,
        "delinquent": false,
        "description": null,
        "discount": null,
        "email": "shays@paraspot.co",
        "id": "cus_Qfi9tBeSvDIg2o",
        "invoice_prefix": "19CBFA2E",
        "invoice_settings": {
            "custom_fields": null,
            "default_payment_method": null,
            "footer": null,
            "rendering_options": null
        },
        "livemode": false,
        "metadata": {},
        "name": "shay",
        "object": "customer",
        "phone": null,
        "preferred_locales": [],
        "shipping": null,
        "tax_exempt": "none",
        "test_clock": null
    },
    // Simulating an error scenario for testing
    [`${para_be}/payments/add_or_update_billing_details`]: {
        "customer_id": "cus_Qfi9tBeSvDIg2o",
        "msg": "Billing details updated successfully",
        "status": 200
    },
    [`${para_be}/payments/create_product_and_price`]: {
        "msg": "Product and price created successfully",
        "price_id": "price_1PoMlyClUvluewg75p8JlTDm",
        "status": 200
    },
    [`${para_be}/payments/create_and_update_subscription`]: {
        "msg": "Subscription created successfully!",
        "status": 200
    },
    [`${para_be}/payments/get_subscriptions`]: {
        "msg": "Subscriptions retrieved successfully",
        "status": 200,
        "subscriptions": [
            {
                "application": null,
                "application_fee_percent": null,
                "automatic_tax": {
                    "enabled": false,
                    "liability": null
                },
                "billing_cycle_anchor": 1723932208,
                "billing_cycle_anchor_config": null,
                "billing_thresholds": null,
                "cancel_at": null,
                "cancel_at_period_end": false,
                "canceled_at": null,
                "cancellation_details": {
                    "comment": null,
                    "feedback": null,
                    "reason": null
                },
                "collection_method": "charge_automatically",
                "created": 1723932208,
                "currency": "usd",
                "current_period_end": 1726610608,
                "current_period_start": 1723932208,
                "customer": "cus_Qfi9tBeSvDIg2o",
                "days_until_due": null,
                "default_payment_method": null,
                "default_source": null,
                "default_tax_rates": [],
                "description": null,
                "discount": null,
                "discounts": [],
                "ended_at": null,
                "id": "sub_1PouiaClUvluewg7kobqifRa",
                "invoice_settings": {
                    "account_tax_ids": null,
                    "issuer": {
                        "type": "self"
                    }
                },
                "items": {
                    "data": [
                        {
                            "billing_thresholds": null,
                            "created": 1723932209,
                            "discounts": [],
                            "id": "si_QgHHGybKgaJEJf",
                            "metadata": {},
                            "object": "subscription_item",
                            "plan": {
                                "active": true,
                                "aggregate_usage": null,
                                "amount": 2000,
                                "amount_decimal": "2000",
                                "billing_scheme": "per_unit",
                                "created": 1723801722,
                                "currency": "usd",
                                "id": "price_1PoMlyClUvluewg75p8JlTDm",
                                "interval": "month",
                                "interval_count": 1,
                                "livemode": false,
                                "metadata": {},
                                "meter": null,
                                "nickname": null,
                                "object": "plan",
                                "product": "prod_QcMg1Iwsj0D1rF",
                                "tiers_mode": null,
                                "transform_usage": null,
                                "trial_period_days": null,
                                "usage_type": "licensed"
                            },
                            "price": {
                                "active": true,
                                "billing_scheme": "per_unit",
                                "created": 1723801722,
                                "currency": "usd",
                                "custom_unit_amount": null,
                                "id": "price_1PoMlyClUvluewg75p8JlTDm",
                                "livemode": false,
                                "lookup_key": null,
                                "metadata": {},
                                "nickname": null,
                                "object": "price",
                                "product": "prod_QcMg1Iwsj0D1rF",
                                "recurring": {
                                    "aggregate_usage": null,
                                    "interval": "month",
                                    "interval_count": 1,
                                    "meter": null,
                                    "trial_period_days": null,
                                    "usage_type": "licensed"
                                },
                                "tax_behavior": "unspecified",
                                "tiers_mode": null,
                                "transform_quantity": null,
                                "type": "recurring",
                                "unit_amount": 2000,
                                "unit_amount_decimal": "2000"
                            },
                            "quantity": 1,
                            "subscription": "sub_1PouiaClUvluewg7kobqifRa",
                            "tax_rates": []
                        }
                    ],
                    "has_more": false,
                    "object": "list",
                    "total_count": 1,
                    "url": "/v1/subscription_items?subscription=sub_1PouiaClUvluewg7kobqifRa"
                },
                "latest_invoice": "in_1PouiaClUvluewg7GXXYwry6",
                "livemode": false,
                "metadata": {},
                "next_pending_invoice_item_invoice": null,
                "object": "subscription",
                "on_behalf_of": null,
                "pause_collection": null,
                "payment_settings": {
                    "payment_method_options": null,
                    "payment_method_types": null,
                    "save_default_payment_method": "off"
                },
                "pending_invoice_item_interval": null,
                "pending_setup_intent": null,
                "pending_update": null,
                "plan": {
                    "active": true,
                    "aggregate_usage": null,
                    "amount": 2000,
                    "amount_decimal": "2000",
                    "billing_scheme": "per_unit",
                    "created": 1723801722,
                    "currency": "usd",
                    "id": "price_1PoMlyClUvluewg75p8JlTDm",
                    "interval": "month",
                    "interval_count": 1,
                    "livemode": false,
                    "metadata": {},
                    "meter": null,
                    "nickname": null,
                    "object": "plan",
                    "product": "prod_QcMg1Iwsj0D1rF",
                    "tiers_mode": null,
                    "transform_usage": null,
                    "trial_period_days": null,
                    "usage_type": "licensed"
                },
                "quantity": 1,
                "schedule": null,
                "start_date": 1723932208,
                "status": "active",
                "test_clock": null,
                "transfer_data": null,
                "trial_end": null,
                "trial_settings": {
                    "end_behavior": {
                        "missing_payment_method": "create_invoice"
                    }
                },
                "trial_start": null
            }
        ]
    },
    [`${para_be}/payments/remove_subscription`]: {
        "msg": "Subscription removed successfully",
        "status": 200
    },
    [`${para_be}/payments/get_billing_details`]: {
        "billing_details": {
            "default_payment_method": "pm_1PouYoClUvluewg7LZusKn3C",
            "description": null,
            "email": "shays@paraspot.co",
            "name": "shay",
            "payment_methods": [
                {
                    "brand": "visa",
                    "exp_month": 12,
                    "exp_year": 2034,
                    "id": "pm_1PouYoClUvluewg7LZusKn3C",
                    "last4": "1111"
                },
                {
                    "brand": "visa",
                    "exp_month": 12,
                    "exp_year": 2034,
                    "id": "pm_1PoMkNClUvluewg7U0eTPVdy",
                    "last4": "4242"
                }
            ]
        },
        "msg": "Billing details retrieved successfully",
        "status": 200
    },
    [`${para_be}/payments/remove_payment_method`]: {
        "msg": "Cannot remove the last billing method while subscription is active",
        "status": 400
    },
    [`${para_be}/payments/get_customer_data`]: {
        "customer_data": {
            "address": null,
            "balance": 0,
            "created": 1723801525,
            "currency": "usd",
            "default_source": null,
            "delinquent": false,
            "description": null,
            "discount": null,
            "email": "shays@paraspot.co",
            "id": "cus_Qfi9tBeSvDIg2o",
            "invoice_prefix": "19CBFA2E",
            "invoice_settings": {
                "custom_fields": null,
                "default_payment_method": "pm_1PouhwClUvluewg7xBvrjSSI",
                "footer": null,
                "rendering_options": null
            },
            "livemode": false,
            "metadata": {},
            "name": "shay",
            "object": "customer",
            "phone": null,
            "preferred_locales": [],
            "shipping": null,
            "tax_exempt": "none",
            "test_clock": null
        },
        "msg": "Customer data retrieved successfully",
        "status": 200
    }
};

// Utility to check if the URL is mocked
export const isUrlMocked = (url) => url in mocks;

// Get mock error based on the config
export const getMockError = (config) => {
    const mockError = new MockError('Mock error occurred');
    const { url } = config;
    mockError.mockData = mocks[url];
    mockError.config = config;

    return Promise.reject(mockError);
};

// Check if the error is a mock error
export const isMockError = (error) => Boolean(error.mockData);

// Return mock response or mock error based on status code
export const getMockResponse = (mockError) => {
    const { mockData } = mockError;

    // Check if the mock data contains a msg field indicating an error
    if (mockData.msg && mockData.status >= 400) {
        const err = new MockError(mockData.msg || 'Mock error');
        err.code = mockData.status || 500;
        err.config = mockError.config;
        return Promise.reject(err);
    }

    return Promise.resolve({
        data: mockData,
        status: mockData.status || 200,
        statusText: 'OK',
        headers: {},
        config: '',
        isMock: true,
    });
};