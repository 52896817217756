import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//slice
import { fetchCustomerData } from '../../../../store/slices/customerSlice';
import { fetchSubscriptionData } from '../../../../store/slices/subscriptionsSlice';
import { fetchBillingDetails } from '../../../../store/slices/billingSlice';
//steps
import CreateSubscription from './pages/CreateSubscription';
import ShowSubscription from '../Payments/pages/ShowSubscription';
import ShowBillingDetails from './pages/ShowBillingDetails';
import AddBillingDetailsWrapper from './pages/AddBillingDetailsWrapper';
import CustomerInfo from './pages/CustomerInfo';
import CustomerUpdate from './pages/CustomerUpdate';
//components
import Backdrop from '@mui/material/Backdrop';
import SubNavigation from './components/SubNavigation';
import { paymentAccountExists } from './utils';
import CustomerInvoices from './pages/Invoices';


const Payments = props => {
    const dispatch = useDispatch();
    const { customerData } = useSelector(state => state.customer);
    const { data: subscriptionsData, loading: subscriptionLoading } = useSelector(state => state.subscription);
    const { path } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        dispatch(fetchCustomerData());
        dispatch(fetchSubscriptionData());
        dispatch(fetchBillingDetails());
    }, [dispatch]);

    useEffect(() => {
        console.log(subscriptionsData?.subscription);
        if (
                path === '/settings/payments' || 
                path === '/settings/payments/createSubscription' || 
                path === '/settings/payments/showSubscription'
            ) {
            if (!subscriptionsData?.subscription) {
                history.push(`/settings/payments/createSubscription`);
            } else {
                history.push(`/settings/payments/showSubscription`);
            }
        }
    }, [subscriptionsData, history, path]);

    const loading = subscriptionLoading;

    console.log(subscriptionsData);
    let subscription = null;
    if (subscriptionsData) {
        subscription = subscriptionsData.subscription;
    }

    return (
        <>
            <div className="relative min-h-screen">
                <div className="max-w-7xl lg:flex lg:gap-x-16 flex-col">
                    <h1 className="sr-only">General Settings</h1>

                    { (paymentAccountExists(customerData) && subscriptionsData?.subscription) && <SubNavigation /> }

                    <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                        <Switch>
                            <Route exact path={`/settings/payments/createSubscription`} render={() => <CreateSubscription {...props} />} />
                            <Route path={`/settings/payments/showSubscription`} render={() => <ShowSubscription {...props} />} />
                            <Route path={`/settings/payments/paymentMethod`} render={() => <ShowBillingDetails {...props} />} />
                            {/* <Route path={`/settings/payments/addBillingDetails`} render={() => <AddBillingDetailsWrapper {...props} />} /> */}
                            {/* <Route path={`/settings/payments/customerUpdate`} render={() => <CustomerUpdate {...props} />} /> */}
                            <Route path={`/settings/payments/invoices`} render={() => <CustomerInvoices {...props} />} />
                            <Route path={`/settings/payments/customerInfo`} render={() => <CustomerInfo {...props} />} />
                            <Route path={path} render={() => (subscription ? <ShowSubscription {...props} /> : <CreateSubscription {...props} />)} />
                        </Switch>
                    </main>
                </div>

                {loading && currentPath === '/settings/payments/createSubscription' &&
                    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={true}>
                        {/* Your loading component */}
                    </Backdrop>
                }
            </div>
        </>
    );
};

export default Payments;
