import React from "react";
import { Helmet } from 'react-helmet';

//style
import "./baseline_scan.css";
//components
import VideoStreamSocket from "../../components/scanner/socket/socket";
import SimpleLoader from "../../components/scanner/loader/simple-loader";
//containers
//utils
import { isIOS, postReqOptBuilder } from "../../utils/main_utils";
//assets
import MainLoader from "../../components/loaders/main_loader/main_loader";
import ScannerError from "../../components/errorMessages/scannerError/scannerError";
//constants
const SOCKET_NS = "test_socket";
const FPS = 20;
const CAMERA_CONSTRAINTS = {
    video: {
        facingMode: { exact: 'environment' },
        ...((isIOS()) ? { 
            aspectRatio: 16/9,
            width: { ideal: 1920 },
            height: { ideal: 1080 }
        } : {
            width: { ideal: 1920 },  // 1920},  // 3840 },
            height: { ideal: 1080 },  // 1080}, // 2160 },
            frameRate: { ideal: FPS },
            aspectRatio: 16/9,  // 1.777777778,
            zoom: 1.5
        })
    },
    audio: false
};
const STEP_LOBBY = 0;
const STEP_EXP = 1;
const STEP_EXP2 = 2;
const STEP_SCAN = 3;
const STEP_DONE = 4;


const CAM_STATE_PAUSE = 0;
const CAM_STATE_PLAY = 1;
const MAX_WIDTH = 1280;
const MAX_HEIGHT = 1280;
const MIME_TYPE = "image/jpeg";
const QUALITY = 0.8;

export default class VideoUploader extends React.Component {

    constructor(props) {
        super(props);
        // state based variables
        this.state = {
            socket: null,
            sockets: null,
        };

        // reference based variables
        this.scanner = React.createRef();
        this.canvas = React.createRef();
		this.scannerSrc = React.createRef();
        this.videoInput = React.createRef();
        
        // general variables
        this.pid = props.match.params.pid;
        this.uid = props.uid_cid['uid'];
        this.cid = props.uid_cid['cid'];
        this.token = props.token;

        this.sockIdx = 0;
        this.sessionID = null;
        this.context = null;
        this.intervalTransmitter = null;
        this.frame_index = -1;
        this.intervalCount = 0;
        this.corrupted_blobs = 0;
        this.canvasSizeSet = false;
		this.frames = [];
		
        // bindings
		this.onVideoInput = this.onVideoInput.bind(this);
		this.onVideoLoaded = this.onVideoLoaded.bind(this);
		this.getVideoTrack = this.getVideoTrack.bind(this);
		this.readChunk = this.readChunk.bind(this);
        this.ogIntervalStreamer = this.ogIntervalStreamer.bind(this);
        this.blobHandler = this.blobHandler.bind(this);
        this.finalizeStream = this.finalizeStream.bind(this);
        this.emitToSocket = this.emitToSocket.bind(this);
    }

    componentDidMount() {
        if (this.sessionID === null) {
            // this.setState({socket: VideoStreamSocket.initSocket(SOCKET_NS)}, () => {
            //     VideoStreamSocket.initSocketEventListeners(this.state.socket, true, true, true, false, false, false, null, null, this.onFrameReceived, null, null, null);
            //     // init scan session
            //     console.log("Session ID is null.\nInitializing...");
            //     this.initializeScanSession();
            // });
            VideoStreamSocket.initMultiSockets(SOCKET_NS, this.props.para_be)
            .then( (out) => {
                this.setState({sockets: out}, () => {
                    console.log("Sockets:\n", this.state.sockets);
                    if (this.state.sockets) {
                        for (let s of this.state.sockets) {
                            console.log("Init socket even listeners");
                            VideoStreamSocket.initSocketEventListeners(s, true, true, true, false, false, true, null, null, this.onFrameReceived, null, null, null);
                        }
                        console.log("Session ID is null.\nInitializing...");
                        this.initializeScanSession();
                    }
                });
            })
        }
    }

    componentWillUnmount() {
        // if (this.intervalTransmitter) clearInterval(this.intervalTransmitter);
    }
    
    onFrameReceived(frame_idx) {
        console.log("Received frame:", frame_idx);
    };

    emitToSocket(ev, ...args) {
        if (this.state.sockets) {
            let localSockIdx = this.sockIdx;
            if (this.sockIdx+1 === this.state.sockets.length) {
                this.sockIdx = 0;
            } else {
                this.sockIdx++;
            }
            this.state.sockets[localSockIdx].emit(ev, ...args);
        } else this.state.socket.emit(ev, ...args);
    }

    initializeScanSession() {
        this.sessionID = new Date().getTime().toString() + Math.random().toString(36).substr(2);
        console.log("My new Session ID:", this.sessionID);
        this.emitToSocket('init_scan', this.sessionID, false, true, {'uid': this.uid, 'cid': this.cid, 'pid': this.pid, 'token': this.token});
        // this.props.socket.emit('init_scan', this.sessionID, this.props.camType === CAM_TYPE_DEMO, this.props.camType === CAM_TYPE_BASELINE, this.props.endUserInfo ? this.props.endUserInfo : "");
    }
	
	async getVideoTrack() {
	  await this.scanner.current.play();
	  const [track] = this.scanner.current.captureStream().getVideoTracks();
	  this.scanner.current.onended = (evt) => track.stop();
	  return track;
	}

    calculateSize(bmapImage) {
        let w = bmapImage.width;
        let h = bmapImage.height;
        if (w > h) {
            w = bmapImage.height;
            h = bmapImage.width;
        }
        console.log("Calculating size for video.\nInitial Size:\nWidth =>", w, "\nHeight =>", h)
        
        if (w > h && isIOS()) {
            return [0, 0]
        }
        let tmax = this.props.compressSize || MAX_WIDTH;
        if (w > h && w > tmax) {
            h = Math.round((h * tmax) / w);
            w = tmax;
        } 
        else if (h > w && h > tmax) {
            w = Math.round((w * tmax) / h);
            h = tmax;
        }
        console.log("Final Size:\nWidth =>", w, "\nHeight =>", h)
        return [w, h]
    };

    async ogIntervalStreamer(bmapImage) {
        this.frame_index++;
        let localIndex = this.frame_index;
        console.log(`[i/F-${localIndex}] Starting ogIntervalTransmitter`);
        if (!this.canvasSizeSet) {
            console.log(`[i/F-${localIndex}] Setting canvas size`);
            const [newWidth, newHeight] = this.calculateSize(bmapImage);
            // const newWidth = this.scanner.current.videoWidth, newHeight = this.scanner.current.videoHeight;
            this.canvas.current.width = newWidth;
            this.canvas.current.height = newHeight;
            if ((this.props.compressSize && newWidth === this.props.compressSize) || newWidth === MAX_WIDTH || newHeight === MAX_HEIGHT) this.canvasSizeSet = true;
        }
        if (!this.context || !(this.context instanceof CanvasRenderingContext2D)) this.context = this.canvas.current.getContext('2d');
        console.log(`[i/F-${localIndex}] Drawing Frame`);
        this.context.drawImage(bmapImage, 0, 0, this.canvas.current.width, this.canvas.current.height);
        console.log(`[i/F-${localIndex}] Converting canvas to blob`);
        if (this.canvas.current.width !== 0 && this.canvas.current.height !== 0) {
            this.canvas.current.toBlob((blob) => this.blobHandler(blob, localIndex), MIME_TYPE, QUALITY);
            console.log(`[i/F-${localIndex}] Adding to interval count`);
            this.intervalCount++;
        } else {
            console.log(`[i/F-${localIndex}] Added to corrupted blobs due to blob corrupted size...`);
            this.corrupted_blobs++;
            this.emitToSocket('stream_ignore', this.sessionID, localIndex);
            // this.props.socket.emit('stream_ignore', this.sessionID, localIndex);
        }
        console.log(`[i/F-${localIndex}] Clearing context`);
        this.context.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
        console.log(`[i/F-${localIndex}] Finished iteration`);
    }

    blobHandler(blob, localIndex) {
        // console.log(`[i/F-${localIndex}] At toBlob`);
        if (blob) {
            console.log(`[i/F-${localIndex}] Blob is not null`);
            // this.frame_index++;
            // console.log(`[i/F-${localIndex}/blob] File Size - ${this.readableBytes(blob.size)}`);
            // console.log(`[i/F-${localIndex}] Sending frame for processing...`);
            this.emitToSocket('frame_processor', this.sessionID, blob, localIndex);
            console.log(`[i/F-${localIndex}] Sent frame`);
        } else if (localIndex >= 0) {
            console.log(`[i/F-${localIndex}] Decreased from interval count due to no blob...`);
            this.intervalCount--;
            this.corrupted_blobs++;
            this.emitToSocket('stream_ignore', this.sessionID, localIndex)
        }
    }

    finalizeStream() {
        console.log("Running finalize stream");
        console.log("intervalCount:", this.intervalCount);
        console.log("frame_index:", this.frame_index);
        console.log("corrupted_blobs:", this.corrupted_blobs);
        // this.props.switchToLoading(this.intervalCount, this.frame_index, this.corrupted_blobs);
        if (this.intervalCount-1 === this.frame_index-this.corrupted_blobs) {
            console.log(`Finalizing scan for ${this.intervalCount} frames...`);
            if (this.props.finalizationCallback) {
                this.props.finalizationCallback(this.sessionID, this.intervalCount);
            } else {
                this.emitToSocket('finalize_scan', this.sessionID, this.intervalCount-1);
            }
        }
    }
	
	readChunk(reader) {
		reader.read().then(async({ done, value }) => {
			if (!done && value) {
				const bitmap = await createImageBitmap(value);
				const index = this.frames.length;
				console.log((new Date()).toISOString(), " | ", "At frame index ", index);
				this.ogIntervalStreamer(bitmap);
                // this.frames.push(bitmap);
				value.close();
			}
			if (done) {
				clearInterval(this.intervalTransmitter);
                // this.finalizeStream();
			}
		});
	}
	
	async onVideoLoaded(event) {
		if (window.MediaStreamTrackProcessor) {
			const track = await this.getVideoTrack();
			const processor = new window.MediaStreamTrackProcessor(track);
			const reader = processor.readable.getReader();
			this.intervalTransmitter = setInterval(() => this.readChunk(reader), 1000/this.props.FPS);
		} else {
			console.error("your browser doesn't support this API yet");
		}
	}
	
	onVideoInput(event) {
		console.log(event.target.files);
		if (event.target.files && event.target.files[0]) {
			console.log("Found file in event target");
			this.scannerSrc.current.src = URL.createObjectURL(event.target.files[0]);
			this.scanner.current.load();
			this.scanner.current.oncanplay = this.onVideoLoaded;
		}
		console.log("Done");
	}

    render() {
        // const {camType} = this.props;
        return (
            <div className="main" style={{marginTop: 150}}>
                <div className="myForm">
                    <input ref={this.videoInput} onChange={this.onVideoInput} accept="video/*" type="file" id="videoUpload"/>
                </div>

                <div className="video-display">
					<video ref={this.scanner} controls={false} playsInline>
						<source ref={this.scannerSrc} src="#"/>
					</video>
                    <canvas ref={this.canvas} className="d-none"></canvas>
                </div>

                <button onClick={this.finalizeStream}>Finalize Stream</button>
            </div>
        )
    }
}