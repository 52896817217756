import React from 'react'

const ManagementHeader = ({ userName }) => {
    return (
      <div className="px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-6">
        <div className="min-w-0 flex-1">
          <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">{userName} 👋</h1>
          <p className="truncate text-gray-500 text-sm group-hover:text-gray-900">Here’s your overview for today!</p>
        </div>
      </div>
    );
  };

export default ManagementHeader
