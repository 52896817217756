import React from 'react';

const DocsIcon = ({ fill = 'none', width = '18px', height = '18px', styleClass = "", disabled = false }) => {
  // Define the disabled styles
  const disabledStroke = '#A0A0A0';  // Light gray color for disabled state
  const disabledFill = 'none';       // Default fill for disabled state (can be changed as needed)

  // Determine the final stroke and fill colors based on the disabled prop
  const finalStroke = disabled ? disabledStroke : '#0C1222';  // Default stroke color when not disabled
  const finalFill = disabled ? disabledFill : fill;

  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={finalFill}
      viewBox='0 0 18 18'
    >
      <path
        d="M14.959 10.6875V8.71875C14.959 8.04742 14.6923 7.40359 14.2176 6.92889C13.7429 6.45418 13.0991 6.1875 12.4277 6.1875H11.3027C11.079 6.1875 10.8643 6.09861 10.7061 5.94037C10.5479 5.78214 10.459 5.56753 10.459 5.34375V4.21875C10.459 3.54742 10.1923 2.90359 9.7176 2.42889C9.2429 1.95418 8.59906 1.6875 7.92773 1.6875H6.52148M6.52148 11.25H12.1465M6.52148 13.5H9.33398M8.20898 1.6875H4.55273C4.08698 1.6875 3.70898 2.0655 3.70898 2.53125V15.4688C3.70898 15.9345 4.08698 16.3125 4.55273 16.3125H14.1152C14.581 16.3125 14.959 15.9345 14.959 15.4688V8.4375C14.959 6.64729 14.2478 4.9304 12.982 3.66453C11.7161 2.39866 9.9992 1.6875 8.20898 1.6875Z"
        stroke={finalStroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocsIcon;