import { fetchData } from '../../../store/slices/searchSlice';


export const initfetchTableData = async (dispatch, page, pageSize, filters, searchValue, sortDirection, sortColumn,categoryFilter) => {
    const body = {
        lowerLimit: (page - 1) * pageSize,
        upperLimit: page * pageSize,
        filters,
        searchValue,
        sortDirection,
        sortColumn,
        categoryFilter
    };

    dispatch(fetchData(body));
};

export const formatKey = key => {
    return key
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
