import React, { forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';

const Step5Congratulations = forwardRef(({}, ref) => {
    useImperativeHandle(ref, () => ({ handleSubmit, }));
    const history = useHistory();

    const handleSubmit = async () => {
        history.push(`/settings/payments/showSubscription`);
    };

    return (
        <div className="p-6 bg-white">
            <h2 className="text-3xl font-bold mb-6 text-green-600">Congratulations!</h2>
            <p className="text-xl text-gray-700 mb-4">Your subscription has been successfully created.</p>
            <p className="text-lg text-gray-600">Thank you for your purchase. You will receive a confirmation email shortly.</p>
        </div>
    );
});

export default Step5Congratulations;
