import React, { useState } from 'react';
//components
import Btn from '../../../../../../components/buttons/standard/btn';
import { SimpleInputDropdown } from '../../../../../../components/dropdowns/simple_dropdown/simple_dropdown';
import SimpleInput from '../../../../../../components/inputs/simple_input/input';
//utils
//styles
import './roi_calculator.css';
//assets
import ddArrow from "../../../../../../assets/icons/dd-arrow.svg";
import security from "../../../../../../assets/icons/shieldIcon.svg";
import savings from "../../../../../../assets/icons/savingsIcon.svg";

export default function RoiCalculator(props) {
    const { executeScroll, clientData } = props;

    const [errorText, setErrorText] = useState(null);
    const [roiResults, setRoiResults] = useState(null);
    // ROI Calculator Inputs
    const [companyType, setCompanyType] = useState(null);
    const [aum, setAUM] = useState(null);
    const [inspectionsType, setInspectionsType] = useState([]);
    

    const handleFormSubmit = (e) => {
        // Contact Us
        // ["cu-company-name", "cu-aum-input", "cu-fn", "cu-ln", "cu-email", "cu-pn"] + "Company Type" dropdown
        e.preventDefault();
        setErrorText(null);
        
        let reqBody = {"aum_input": aum, "companyType": companyType, "inspectionsType": inspectionsType};
        let inputNames = {"aum_input": "Units Under Management", "companyType": "Company Type", "inspectionsType": "Inspections Type"};
        for (let [k, item] of Object.entries(reqBody)) {
            if (!item || item.length === 0) {
                setErrorText("Please enter " + inputNames[k]);
                return;
            }
        }

        let turnoverInspectionsCount = 0;
        if (reqBody.inspectionsType.includes("move-in")) turnoverInspectionsCount++;
        if (reqBody.inspectionsType.includes("move-out")) turnoverInspectionsCount++;
        if (reqBody.inspectionsType.includes("turn")) turnoverInspectionsCount++;
        
        let results;
        if (companyType === "multifamily" || companyType === "single_family") {
            let yearInspectionsCount = 0;
            if (reqBody.inspectionsType.includes("periodical")) yearInspectionsCount=2;
            if (reqBody.inspectionsType.includes("annual") || reqBody.inspectionsType.includes("lease_renewal")) yearInspectionsCount++;
            results = calculateROI(1, reqBody.aum_input, 24, yearInspectionsCount, turnoverInspectionsCount, 4500);
        } else if (companyType === "student_housing") {
            results = calculateROI(
                1, reqBody.aum_input, 12, 1, turnoverInspectionsCount, 1500, 
                {occupancy_rate: 0.92, inspectionTime: 1, disputeRate: 0.4, avgDisputeTime: 5}
            );
        } else if (companyType === "serviced_furnished_rental") {
            results = calculateROI(
                1, reqBody.aum_input, 12, 0, turnoverInspectionsCount, 1500, 
                {occupancy_rate: 0.85, inspectionTime: 1, disputeRate: 0.2, avgDisputeTime: 3}
            );
        } else if (companyType === "co_living") {
            results = calculateROI(
                1, reqBody.aum_input, 12, 0, turnoverInspectionsCount, 1500, 
                {occupancy_rate: 0.92, inspectionTime: 1, disputeRate: 0.2, avgDisputeTime: 3}
            );
        } else if (companyType === "short_term_rentals") {
            results = calculateROI(
                1, reqBody.aum_input, 12, 0, turnoverInspectionsCount, 1500, 
                {occupancy_rate: 0.6, inspectionTime: 0.2, disputeRate: 0.1, avgDisputeTime: 1}
            );
        } else {
            setErrorText("Unknown Company Type");
            return;
        }

        console.log(results);
        setRoiResults(results);
    };

    const calculateROI = (
        ourEstPrice, units, avg_stay, yearInspections, turnoverInspections, avgDeposit, 
        {
            occupancy_rate=0.96, inspectionTime=1.5, hourlyRate=38, disputeRate=0.3, 
            avgDisputeTime=10, disputeHourlyRate=38, avgDeduction=0.2
        }={}
    ) => {
        const totalTurnovers = units * 12 / avg_stay*occupancy_rate;
        const totalInspections = (turnoverInspections * totalTurnovers) + (yearInspections * units * occupancy_rate);
        const totalInspectionTime = totalInspections * inspectionTime; // In hours
        // Sum - Inspections
        const totalInspectionCost = totalInspectionTime * hourlyRate;
        const avgCostPerUnit = totalInspectionCost / units;

        const totalDisputes = totalTurnovers * disputeRate;
        // Sum - Disputes
        const totalDisputeCost = totalDisputes * avgDisputeTime * disputeHourlyRate;
        const totalDisputesCollected = avgDeposit * totalDisputes 
        const totalDisputeDeduction = totalDisputesCollected * avgDeduction;

        // Return
        const totalSavings = totalInspectionCost + totalDisputeCost;
        const ourCost = units * ourEstPrice * 12;
        const roi = (totalSavings - ourCost) / ourCost * 100;

        return {
            "totalInspectionCost": totalInspectionCost,
            "avgCostPerUnit": avgCostPerUnit,
            "totalDisputeCost": totalDisputeCost,
            "totalDisputesCollected": totalDisputesCollected,
            "totalDisputeDeduction": totalDisputeDeduction,
            "totalSavings": totalSavings,
            "ourCost": ourCost,
            "roi": roi
        }
    };

    const checkboxBuilder = (item) => {
        return (
            <div 
                className="checkbox-container-1 flexRow" 
                onClick={(e) => {
                    if (e.target.classList.contains("mul-selection-checkbox")) return;
                    const t = e.target.classList.contains("checkbox-container-1") ? e.target : e.target.parentElement;
                    const value = t.querySelector("input").value;
                    const checked = t.querySelector("input").checked;
                    t.querySelector("input").checked = !checked;
                    if (!checked) {
                        setInspectionsType([...inspectionsType, value]);
                    } else {
                        setInspectionsType(inspectionsType.filter((i) => i !== value));
                    }
                }}
            >
                <input 
                    type="checkbox" 
                    name="inspections_type" 
                    class="mul-selection-checkbox" 
                    value={item.toLowerCase().replaceAll(" ", "_")}
                    onChange={(e) => {
                        const { value, checked } = e.target;
                        console.log("Value:", value, "Checked:", checked);
                        if (checked) {
                            setInspectionsType([...inspectionsType, value]);
                        } else {
                            setInspectionsType(inspectionsType.filter((i) => i !== value));
                        }
                    }}
                />
                <div class="text-2">{item}</div>
            </div>
        );
    }

    return (
        <div className="in-homepage-roi-calc flexRow">
            <div className='in-homepage-roi-gradient1'/>
            <div className='in-homepage-roi-gradient2'/>
            <div className='in-homepage-roi-calc-content flexRow'>
                <div className='in-homepage-roi-calc-text flexColumn'>
                    <div className='h2'>
                        ROI Calculator
                    </div>
                    <div className='text-1'>
                        Quickly estimate your savings and operational efficiency with our ROI calculator. 
                        Discover how Paraspot's AI-powered inspections can reduce costs, minimize disputes, 
                        and optimize property management.
                    </div>
                    <Btn 
                        text={
                            <>
                                Get Your Free Trial
                                <img src={ddArrow} alt="arrow-right"/>
                            </>
                        } 
                        onclick={executeScroll}
                        type="primary" 
                        extraClasses="text-1-3 cta-arrow-btn btn-radius6" />
                </div>
                <div className='in-homepage-roi-calc-media'>
                    {roiResults ?
                        <>
                            <div className='h3'>Result</div>
                            <div className='in-roi-results'>
                                <div className="flexColumn">
                                    <div className="flexRow">
                                        <img src={savings} alt="icon"/>
                                        <div className='text-1'>Money Saved</div>
                                    </div>
                                    <div className='h3'>${roiResults.totalSavings.toLocaleString('en-US')}</div>
                                </div>
                                <div className="flexColumn">
                                    <div className="flexRow">
                                        <img src={security} alt="icon"/>
                                        <div className='text-1'>Security Deposits Secured</div>
                                    </div>
                                    <div className='h3'>${roiResults.totalDisputesCollected.toLocaleString('en-US')}</div>
                                </div>
                            </div>
                            <Btn 
                                text="Retry"
                                type="primary" 
                                onclick={() => setRoiResults(null)}
                                extraClasses="text-1-3 btn-radius6" />
                        </> :
                        <>
                            <SimpleInputDropdown
                                label={"Company Type"}
                                placeholder={"Choose Type"}
                                extraClasses={"dd-full-width"}
                                // selectorClasses={"dd-minw-140"}
                                value={null}
                                items={{
                                    multifamily: { present: "Multifamily" },
                                    single_family: { present: "Single Family" },
                                    student_housing: { present: "Student Housing" },
                                    serviced_furnished_rental: { present: "Serviced/Furnished Rentals" },
                                    co_living: { present: "Co-Living" },
                                    short_term_rentals: { present: "Short-Term Rentals" },
                                    built_to_rent: { present: "Built to Rent" }
                                }}
                                closeOnSelection={true}
                                onclick={(selectedValue, e) => {
                                    setCompanyType(selectedValue);
                                }}
                            />
                            <SimpleInput
                                label={"Units Under Management"}
                                type={"number"}
                                id={"aum-input"}
                                placeholder={"Enter Amount"}
                                inputProps={{min: 0}}
                                on_change={(e) => setAUM(e.target.value)}/>
                            <div className='checkbox-group-container'>
                                <div className='text-1'>Inspections Done</div>
                                <div className='checkbox-group-items flexRow'>
                                    <div>
                                        {["Move-In", "Periodical", "Lease Renewal"].map((item) => checkboxBuilder(item))}
                                    </div>
                                    <div>
                                        {["Move-Out", "Annual", "Turn"].map((item) => checkboxBuilder(item))}
                                    </div>
                                </div>
                            </div>
                            <div className={`text-1-2 in-input-err${errorText ? "" : " d-none"}`}>
                                {errorText}
                            </div>
                            <Btn 
                                text="Calculate"
                                type="primary" 
                                onclick={handleFormSubmit}
                                extraClasses="text-1-3 btn-radius6" />
                        </>
                    }
                </div>
            </div>
        </div>
    )
}