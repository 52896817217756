import React, { useState } from "react";

//styles
import "./index.css";
//assets
import wa_logo from "../../assets/icons/whatsapp-icon.png"
//utils
import { postReqOptBuilder } from "../../utils/main_utils";
import Btn from "../buttons/standard/btn";
//constants
const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB chunks (adjust as needed)

export default function VideoUploader(props) {
    const [isDragOver, setIsDragOver] = useState(false);
    const [uploadedFile, setUploadedFile] = useState("");
    const [chunck, setChunck] = useState(0); //progress bar data
    const [show, setShow] = useState(false); //show the progressbar
    const [loader, setLoader] = useState(false); //show the progressbar
    const [complete, setcomplete] = useState(false); // Indicates whether a process or task is complete
    
    const fetchPresignedUrls = (file) => {
        const data = {
            total_parts: Math.ceil(file.size / CHUNK_SIZE),
            id: props.pid,
            filename: file.name,
            filetype: file.type,
        };
    
        return fetch(`${props.para_be}/media/generate_presigned_url`, postReqOptBuilder(data))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                return {
                    upload_id: response.result.upload_id,
                    presigned_urls: response.result.presign_urls,
                };
            } else {
                console.log("Failed generating presigned url");
                setShow(false);
                return null;
            }
        })
        .catch((error) => {
            console.error("An error occurred:", error);
            setShow(false);
            return null;
        });
    };
    
    const uploadFile = (file, presignedUrls, uploadId) => {
        const parts = [];
        const totalChunks = presignedUrls.length;
        let uploadedChunks = 0;
    
        // Upload each chunk sequentially
        const chunkPromises = presignedUrls.map((presignedUrl, i) => {
            const blob = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
    
            // Upload a single chunk to the presigned URL
            return fetch(presignedUrl, {
                method: "PUT",
                headers: {"content-type": file.type},
                body: blob,
            })
            .then((response) => {
                if (response.ok) {
                    uploadedChunks++;
                    const progressPercentage = Math.floor(uploadedChunks / totalChunks * 100);
                    setChunck(progressPercentage);
            
                    const etag = response.headers.get("etag");
                    parts.push({
                        ETag: etag,
                        PartNumber: i + 1,
                    });
                }
            })
            .catch((error) => {
                // Handle error for this chunk upload here
                console.error("Chunk upload failed:", error);
            });
        });
    
        // Wait for all chunk uploads to complete
        Promise.all(chunkPromises)
        .then(() => {
            setLoader(true);
            // After all chunks are uploaded, send a request to finalize the upload
            fetch(`${props.para_be}/media/upload_video`, postReqOptBuilder({uploadId: uploadId, parts: parts, id: props.pid, filename: file.name}))
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {

                    try {
                        fetch(`${props.para_be}/scan/scanStarted`, postReqOptBuilder(
                            props.pid.includes('_') ?
                                {pid: props.pid.split("_")[1], scanType: 'checkout'} :
                                {pid: props.pid, scanType: 'baseline'}
                        ))
                    } catch (error) {
                        console.error("An error occurred while sending scanStarted notification:", error);
                    }
                    if (!props.pid.includes('_')) {
                        try {
                            fetch(`${props.para_be}/scan/building_baseline`, postReqOptBuilder(
                                    {uid: '', cid: '', pid: props.pid}, 
                                    true, {Authorization: document.cookie.split("AuthToken=")[1].split(";")[0]}
                            ))
                            .then(response2 => response2.json())
                            .then(response2 => {
                                if (response2.status === 200) {
                                    props.setNotifState({"type": "success", "text": "Updated unit status"});
                                } else {
                                    props.setNotifState({"type": "error", "text": "Failed to update unit status"});
                                }
                            })
                            .catch((x) => {
                                props.setNotifState({"type": "error", "text": "Failed to update unit status"});
                            });
                        } catch (error) {
                            console.error("An error occurred while updating baseline status:", error);
                        }
                    }
                    
                    setLoader(false);
                    setcomplete(true);
                    props.changeBlockStatus(false);
                    if (props.onUploadComplete) props.onUploadComplete();
                } else {
                    setLoader(false);
                    props.changeBlockStatus(false);
                    console.error("Failed to upload video");
                }
            })
            .catch((error) => {
                // Handle any errors that occurred during the final steps
                setLoader(false);
                console.error("An error occurred:", error);
                setShow(false);
                props.changeBlockStatus(false);
            });
        })
        .catch((error) => {
            // Handle any errors that occurred during the final steps
            setLoader(false);
            console.error("An error occurred:", error);
            setShow(false);
            props.changeBlockStatus(false);
        });
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);

        const file = e.dataTransfer.files[0];
        setUploadedFile(file);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };

    const hanldeUploadVideo = () => {
        if (uploadedFile) {
            setShow(true);
            props.changeBlockStatus(true);
        
            fetchPresignedUrls(uploadedFile)
            .then(({ upload_id, presigned_urls }) => {
                console.log({ upload_id, presigned_urls });
                if (upload_id && presigned_urls && presigned_urls.length) {
                    return uploadFile(uploadedFile, presigned_urls, upload_id);
                } else {
                    throw new Error("Presigned URLs not available");
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                setShow(false);
                props.changeBlockStatus(false);
            });
        }
    };
    
    const removeVideo = () => {
        setUploadedFile(null);
        setShow(false);
        props.changeBlockStatus(false);
        setChunck(0);
    };

    return (
        <div className="video-uploader-container">
            {/* {props.showWhatsapp && */}
            <button 
                className="btn-standard flexRow h3 whatsapp-support" 
                onClick={() => window.open("https://wa.me/message/NMWJBUHZPRIHA1")}>
                <div>Need Help?</div>
                <img src={wa_logo} />
            </button>
            {/* } */}
            <div className={`video-uploader ${isDragOver ? "drag-over" : ""}`} onDrop={handleDrop}>
                {uploadedFile ? 
                    <div className="uploaded-file">
                        <video controls className="video-show">
                        <source
                            src={URL.createObjectURL(uploadedFile)}
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    :
                    <label className="upload-box">
                        <p>
                            {props.customText ? props.customText : "Drag & drop a video file here or click to select a file"}
                        </p>
                        <input type="file" accept="video/*" onChange={handleFileChange} />
                    </label>
                }
            </div>
            {show &&
                <>
                    {loader && 
                        <div style={{textAlign: 'center'}}>
                            <span>Processing</span>
                            <br/>
                            <span className="text-2">It might take a few moments to start</span>
                        </div>
                    }
                    {complete &&
                        <h3 className="upload-complete h2">
                            Upload Complete
                        </h3>
                    }
                    <div className="progress-container">
                        <div className="spinner"></div>
                        <progress max={`100`} value={`${chunck}`} className="progress-style"></progress>
                        <p className="progress-value text-1-2">{chunck}%</p>
                    </div>
                </>
            }
            {(uploadedFile && !show) &&
                <div className="flex-row">
                    <Btn
                        text="Change Video"
                        type="secondary"
                        onclick={removeVideo}/>
                    <Btn
                        text="Upload Video"
                        type="primary"
                        onclick={hanldeUploadVideo}/>
                </div>
            }
        </div>
    );
}
