import {useEffect} from "react";

//components
import ScreenDimmer from "../screen-dimmer/screen_dimmer";
import CloseBtn from "../buttons/close_btn/close_btn";
import BackBtn from "../buttons/back_btn/back";
//utils
import {disableBodyScroll} from "../../utils/main_utils";
//styles
import "./main_popups.css"

export default function MasterPopup(props) {
  
    useEffect(() => {
        disableBodyScroll(true);
        document.querySelector(".screen-dimmer")?.addEventListener('click', screenDimmerClosePopup);
        
        return () => {
            disableBodyScroll(false);
            document.querySelector(".screen-dimmer")?.removeEventListener('click', screenDimmerClosePopup);
        };
    }, []);
    
    // useEffect(() => {
    //     document.querySelector(".screen-dimmer").addEventListener('click' , screenDimmerClosePopup);
    //     return () => document.querySelector(".screen-dimmer").removeEventListener('click', screenDimmerClosePopup);
    // }, []);

    const screenDimmerClosePopup = () => {
        props.closeFunc();
    }

    return (
        <>
            <div className={"popup-container" + (props.extraClasses ? (" " + props.extraClasses) : "")}>
                <CloseBtn closeFunc={props.closeFunc}/>
                <BackBtn clickFunction={props.closeFunc}/>
                {props.children}
            </div>
            <ScreenDimmer/>
        </>
    )
}