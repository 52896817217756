import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Button from '@mui/material/Button';
//slices
import { fetchCustomerData } from '../../../../../../store/slices/customerSlice';
import { setPaymentMethod, setError, clearError } from '../../../../../../store/slices/billingSlice';
//constants
import { SeverityLevels } from '../../../../../../constants';
//components
import SnackBar from '../../../../../../components/SnackBar';
import CircularLoader from '../../../../../../components/loaders/CircularLoader';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddBillingDetails = () => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const history = useHistory();

    const { data, loading: customerLoading } = useSelector(state => state.customer);
    const { loading: billingLoading, error: billingError } = useSelector(state => state.billing);

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        } else if (!data.customer_data) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            dispatch(setError(error.message));
            return;
        }

        try {
            await dispatch(setPaymentMethod({ paymentMethodId: paymentMethod.id, })).unwrap();

            localStorage.setItem('payment_method_id', paymentMethod.id);
            history.push(`/settings/payments/billingDetails`);
        } catch (err) {
            console.error('Error setting billing details:', err);
        }
    };

    useEffect(() => {
        if (billingError) {
            const timer = setTimeout(() => {
                dispatch(clearError());
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [billingError, dispatch]);

    const loading = customerLoading || billingLoading;

    console.log(billingError);

    return (
        <div className="mb-10 mt-10 bg-white">
            <CardElement />
            <div className="mt-8 mr-8">
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                    {loading && <CircularLoader size={24} />}
                    Submit Billing Details
                </Button>
            </div>

            {/* Notification Component */}
            {Boolean(billingError) &&
                <SnackBar
                    open={Boolean(billingError)}
                    handleClose={() => { dispatch(clearError()); }}
                    severity={billingError ? SeverityLevels.ERROR : SeverityLevels.SUCCESS}
                    message={billingError || 'Billing details added successfully!'}
                    duration={6000}
                />
            }
        </div>
    );
};

const AddBillingDetailsWrapper = (props) => (
    <Elements stripe={stripePromise}>
        <AddBillingDetails {...props} />
    </Elements>
);


export default AddBillingDetailsWrapper;
