import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//slices
import { setSubscription } from '../../../../../../../../store/slices/subscriptionsSlice';
import { SimpleInputDropdown } from '../../../../../../../../components/dropdowns/simple_dropdown/simple_dropdown';
import useDebounce from '../../../../../../../../hooks/useDebounce';
import { para_be } from '../../../../../../../../config';
import { getCurrencyCode } from '../../../../../../../../utils/main_utils';
import { Card, CardContent, Divider, Typography } from '@mui/material';
import MainLoader from '../../../../../../../../components/loaders/main_loader/main_loader';


const portfolioTypes = {
    multifamily: { present: "Multifamily" },
    single_family: { present: "Single Family" },
    student_housing: { present: "Student Housing" },
    serviced_furnished_rental: { present: "Serviced/Furnished Rentals" },
    co_living: { present: "Co-Living" },
    short_term_rentals: { present: "Short-Term Rentals" },
    built_to_rent: { present: "Built to Rent" }
};

const Step4CreateSubscription = forwardRef(({}, ref) => {
    const [customerData, setCustomerData] = useState({
        portfolioType: null,
        portfolioSize: 0,
    });
    const [loadingQuote, setLoadingQuote] = useState(false);
    const [quote, setQuote] = useState(null);

    const debouncedCustomerData = useDebounce(customerData, 500);
    const dispatch = useDispatch();
    const paymentMethodId = sessionStorage.getItem('payment_method_id');
    

    useEffect(() => {
        if (debouncedCustomerData.portfolioType && debouncedCustomerData.portfolioSize > 0) {
            setLoadingQuote(true);
            fetch(
                para_be + '/payments/get_subscription_quote?' + 
                new URLSearchParams(
                    {
                        portfolio_type: 
                            ["multifamily", "single_family", "student_housing", "built_to_rent"].includes(debouncedCustomerData.portfolioType) ? "LTR" :
                            ['short_term_rentals'].includes(debouncedCustomerData.portfolioType) ? "STR" : "MTR",
                        quantity: debouncedCustomerData.portfolioSize,
                        currency: getCurrencyCode()
                    }
                ), { credentials: 'include' }
            )
            .then(response => response.json())
            .then(response => {
                setQuote(response.result);
            })
            .finally(() => {
                setLoadingQuote(false);
            })
        }
    }, [debouncedCustomerData]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setCustomerData(prev => ({...prev, [name]: value}));
    };

    useImperativeHandle(ref, () => ({ handleSubmit, }));

    const handleSubmit = async () => {
        if (!paymentMethodId) {
            throw new Error('Payment method information is missing. Please go back and restart the process.');
        }
        if (!customerData.portfolioType || !customerData.portfolioSize) {
            throw new Error('Please fill out all the fields.');
        }

        try {
            await dispatch(
                setSubscription({ 
                    paymentMethodId, 
                    planType: 'premium', 
                    plan: ["multifamily", "single_family", "student_housing", "built_to_rent"].includes(debouncedCustomerData.portfolioType) ? "LTR" :
                            ['short_term_rentals'].includes(debouncedCustomerData.portfolioType) ? "STR" : "MTR",
                    quantity: customerData.portfolioSize, 
                    price: quote.price_id
                })
            ).unwrap();
        } catch (err) {
            throw new Error('Failed to create subscription.');
        }
    };

    return (
        <div className="p-6 bg-white">
            <h2 className="text-xl font-bold mb-6">Select Subscription</h2>
            
            <SimpleInputDropdown
                label={"Portfolio Type"}
                placeholder={"Choose Type"}
                extraClasses={"dd-full-width"}
                // selectorClasses={"dd-minw-140"}
                value={null}
                items={{
                    multifamily: { present: "Multifamily" },
                    single_family: { present: "Single Family" },
                    student_housing: { present: "Student Housing" },
                    serviced_furnished_rental: { present: "Serviced/Furnished Rentals" },
                    co_living: { present: "Co-Living" },
                    short_term_rentals: { present: "Short-Term Rentals" },
                    built_to_rent: { present: "Built to Rent" }
                }}
                closeOnSelection={true}
                onclick={(selectedValue, e) => {
                    setCustomerData(prev => ({...prev, portfolioType: selectedValue}));
                }}
            />
            {customerData.portfolioType &&
                <span className='block text-gray-500 text-xs mt-1 pt-0'>
                    {
                        "short_tern_rentals" === customerData.portfolioType ?
                        'Unlimited number of inspections per property per year' :
                        <>
                            Limited to 
                            {
                                ["multifamily", "single_family", "student_housing", "built_to_rent"].includes(customerData.portfolioType) ? ' 2 ' : ' 8 '
                            }
                            inspections per property per year
                        </>
                    }
                </span>
            }

            <div className="mt-4 mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="portfolioSize">Portfolio Size</label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="portfolioSize"
                    type="number"
                    name="portfolioSize"
                    value={customerData.portfolioSize}
                    onChange={handleInputChange}
                    required />
            </div>

            {(quote || loadingQuote) &&
                <div className="mt-6 mb-6">
                    <Divider className="my-8 mt-4" />
                </div>
            }
            {loadingQuote ? 
                <MainLoader /> :
                quote ?
                    <>
                        <Card className='shadow-sm'>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    {quote === 'eur' ? "€" : "$"}{Number(quote.price).toLocaleString('en-US')} / Month
                                </Typography>
                                <Typography color="textSecondary">
                                    Duration: 1-Year | Auto-Renew<br/>
                                    Base Price: {quote === 'eur' ? "€" : "$"}{Number(quote.base_price).toLocaleString('en-US')} / Month
                                </Typography>
                            </CardContent>
                        </Card>
                    </> : ""
            }
        </div>
    );
});

export default Step4CreateSubscription;
