import React from "react";
//components
import Btn from "../../../../components/buttons/standard/btn";
import BackBtn from "../../../../components/buttons/back_btn/back";
import SimpleInput from "../../../../components/inputs/simple_input/input";
//styles
import "./index.css";

export default function UserSettings(props) {
    const { handleOpenPopup, handleBack, fullName, mobile, email } = props;
    
    const userSettings = [
        ["text", "full-name", "Full Name", fullName],
        ["tel", "mobile", "Mobile", mobile],
        ["email", "email", "Email", email],
    ];

    return (
        <>
            <section className="settings-header">
                <BackBtn clickFunction={handleBack} />
                <div className="blue-headline">User Settings</div>
            </section>
            <section className="inputs users d-flex-col">
                {userSettings.map(([type, id, text, state]) => {
                    return (
                    <SimpleInput
                        id={id}
                        label={text}
                        placeholder={text}
                        type={type}
                        value={state}
                    />
                    );
                })}
            </section>
            <section className="change-password">
                <Btn
                    text="Change Password"
                    type="secondary"
                    onclick={() => handleOpenPopup("change")}
                />
            </section>
        </>
    );
}