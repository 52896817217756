import React from 'react'
//components
import CustomTooltip from '../../../../CustomTooltip/CustomTooltip';
//utils
import { dateTimeFormater } from '../../../../../utils/date_utils';
//assets
import AlertIcon from '../../../icons/alertIcon';
//constants
import { colorKeysConfig } from '../../../tableConfig';
import { DATE_FORMAT } from '../../../../../constants';


const ScanStatus = ({ scan_status, latest_scan_date, scan_datetime, baseline_date }) => {
    const colorKey = colorKeysConfig[
        (scan_status === 'Done' || scan_status === "scan - done") ? 'done' : 
        (scan_status === 'Initialized' || scan_status === "scan - initialized" || scan_status === "scan - ongoing") ? 'init' : 
        scan_status === "scan - stale session" ? 'failed' : 
        (scan_status === 'No Status' && scan_datetime) ? 'done' : 'missing'
    ];
    return (
        <div className={`flex gap-x-1 items-center ${colorKey}`}>
            { 
                scan_status === "Done" ?
                    <>
                        <span>Done ({latest_scan_date ? dateTimeFormater(latest_scan_date, DATE_FORMAT) : scan_datetime ? dateTimeFormater(scan_datetime, DATE_FORMAT) : ""})</span>
                    </> :
                scan_status === "Initialized" ?
                    <>
                        <span>Failed attempt ({scan_datetime && dateTimeFormater(scan_datetime, DATE_FORMAT)})</span>
                    </> :
                (scan_status === "No Status" && scan_datetime) ?
                    <>
                        <span>{dateTimeFormater(scan_datetime, DATE_FORMAT)}</span>
                    </>
                :
                scan_status === "scan - done" ?
                    <>
                        <span>Done</span>
                    </> :
                scan_status === "scan - stale session" ?
                    <>
                        <span>Incomplete</span>
                    </> :
                scan_status === "scan - initialized" ?
                    <>
                        <span>Initialized</span>
                    </> :
                scan_status === "scan - ongoing" ?
                    <>
                        <span>In Progress</span>
                    </> :
                    <>
                        <span>Missing</span>
                    </>
            }
            { !baseline_date && 
                <CustomTooltip title={
                    <div className='flexRow'>
                        <AlertIcon className="mt-px" innerFill='#F8803D' />
                        <span className="text-sm ml-2">Missing Baseline</span>
                    </div>
                }>
                    <span>
                        <AlertIcon /> 
                    </span>
                </CustomTooltip>
            }
        </div> 
    )
}

export default ScanStatus;