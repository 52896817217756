import React from 'react'

const CircleTopUp = ({ fill = 'none', width = '20px', height = '20px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 18 18'
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5587 1.43999C12.7296 1.61084 12.7296 1.88785 12.5587 2.05871L8.05557 6.56185H10.1165C10.3582 6.56185 10.554 6.75772 10.554 6.99935C10.554 7.24097 10.3582 7.43685 10.1165 7.43685H6.99935C6.75772 7.43685 6.56185 7.24097 6.56185 6.99935V3.88216C6.56185 3.64054 6.75772 3.44466 6.99935 3.44466C7.24097 3.44466 7.43685 3.64054 7.43685 3.88216V5.94313L11.94 1.43999C12.1108 1.26914 12.3879 1.26914 12.5587 1.43999Z" fill="#918BEF"/>
      <path d="M11.947 3.90784L10.1671 5.6878C10.8685 5.71435 11.429 6.29139 11.429 6.99935C11.429 7.72422 10.8414 8.31185 10.1165 8.31185H6.99935C6.27448 8.31185 5.68685 7.72422 5.68685 6.99935V3.88216C5.68685 3.15729 6.27448 2.56966 6.99935 2.56966C7.70731 2.56966 8.28435 3.13018 8.31089 3.83165L10.0909 2.05168C9.19456 1.49045 8.13484 1.16602 6.99935 1.16602C3.77769 1.16602 1.16602 3.77769 1.16602 6.99935C1.16602 10.221 3.77769 12.8327 6.99935 12.8327C10.221 12.8327 12.8327 10.221 12.8327 6.99935C12.8327 5.86385 12.5082 4.80414 11.947 3.90784Z" fill="#918BEF"/>
    </svg>
  );
};

export default CircleTopUp
