import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//utils
import axios from '../../axios/axios';
//constants
import { para_be } from '../../config';


// Thunk to fetch client PMS data
export const fetchLimitsData = createAsyncThunk(
    'clientLimits/fetchLimits',
    async ({}, { rejectWithValue }) => {
        try {
            const limitsDataResponse = await axios.get(`${para_be}/config/fetch_limits`, { withCredentials: true });
            if (limitsDataResponse.data.status >= 400) {
                return rejectWithValue(limitsDataResponse.data.msg);
            }
            return { data: limitsDataResponse.data.result };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const clientLimitsSlice = createSlice({
    name: 'clientLimits',
    initialState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLimitsData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLimitsData.fulfilled, (state, action) => {
                // Update the state with the parameters used in the request
                state.data = action.payload.data;
                state.loading = false;
            })
            .addCase(fetchLimitsData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching client PMS data';
            });
    }
});


export const {
    setData,
} = clientLimitsSlice.actions;
export default clientLimitsSlice.reducer;