import React from 'react'

const AlertIcon = ({ fill = 'none', innerFill = "#C2C4C8", width = '18px', height = '18px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 18 18'
    >
      <path d="M9 16.4199C4.85775 16.4199 1.5 13.0622 1.5 8.91992C1.5 4.77767 4.85775 1.41992 9 1.41992C13.1423 1.41992 16.5 4.77842 16.5 8.91992C16.5 13.0614 13.1423 16.4199 9 16.4199ZM9 5.16992C8.80109 5.16992 8.61032 5.24894 8.46967 5.38959C8.32902 5.53024 8.25 5.72101 8.25 5.91992V9.66992C8.25 9.86883 8.32902 10.0596 8.46967 10.2003C8.61032 10.3409 8.80109 10.4199 9 10.4199C9.19891 10.4199 9.38968 10.3409 9.53033 10.2003C9.67098 10.0596 9.75 9.86883 9.75 9.66992V5.91992C9.75 5.72101 9.67098 5.53024 9.53033 5.38959C9.38968 5.24894 9.19891 5.16992 9 5.16992ZM9 12.6699C9.19891 12.6699 9.38968 12.5909 9.53033 12.4503C9.67098 12.3096 9.75 12.1188 9.75 11.9199C9.75 11.721 9.67098 11.5302 9.53033 11.3896C9.38968 11.2489 9.19891 11.1699 9 11.1699C8.80109 11.1699 8.61032 11.2489 8.46967 11.3896C8.32902 11.5302 8.25 11.721 8.25 11.9199C8.25 12.1188 8.32902 12.3096 8.46967 12.4503C8.61032 12.5909 8.80109 12.6699 9 12.6699Z"
        fill={innerFill} />
    </svg>
  );
};

export default AlertIcon