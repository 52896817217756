import { useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
//components
import InputError from "../../errorMessages/inputError/inputError";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
//styles
import "../../inputs/input.css";
import "../../inputs/simple_input/input.css";
import "./simple_dropdown.css";
//assets
import ddArrow from "../../../assets/icons/dd-arrow.svg";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function SimpleInputDropdown (props) {
    const [value, setValue] = useState(props.value);
    const [showDropdown, setShowDropdown] = useState(false);

    const selectionHandler = (k, item, e) => {
        setValue(item.present ? item.present : k);
        if (props.onclick) props.onclick(k, e);
        if (props.closeOnSelection) setShowDropdown(false);
    };

    // return (
    //     <div className={"dd-container-2 flexRow" + (props.extraClasses ? ` ${props.extraClasses}` : "")}>
    //         {props.label ? <div className="simple-input-label text-1-3">{props.label}</div> : ""}
    //         <div className={"dd-selector-2 flexRow" + (value ? " dd-item-selected" : "")} onClick={() => setShowDropdown(!showDropdown)}>
    //             <span className="text-1">{value ? value : props.placeholder ? props.placeholder : "Choose"}</span>
    //             <img src={ddArrow} alt="arrow-down"/>
    //         </div>
    //         {props.inputError ? <InputError text={props.inputError.text} type="error"/> : ""}
    //         <div className={"dd-items-2" + (props.borderedItems ? " dd-items-bordered" : "") + (showDropdown ? "" : " d-none")}>
    //             {props.items && props.items.map((item) => {
    //                 return (
    //                     <div className={"dd-item-2 text-1" + (value === item ? " dd-item-selected" : "")} onClick={(e) => selectionHandler(item, e)}>
    //                         {props.preItemText ? props.preItemText : ""}
    //                         {item}
    //                         {props.postItemText ? props.postItemText : ""}
    //                     </div>
    //                 )
    //             })}
    //         </div>
    //     </div>
    // )
    return (
        <SimpleDropdownContainer currentValue={value} selectionHandler={selectionHandler} showDropdown={showDropdown} {...props}>
            {props.label ? 
                props.description ? 
                <div className="simple-input-label text-1-3 flexRow">
                    <div className="mt-1">{props.label}</div> 
                    <CustomTooltip 
                        title={
                            <div className='flexRow'>
                                <span className="text-sm font-normal ml-2">{props.description}</span>
                            </div>
                        }
                        padding="12px"
                    >
                        <span className="block ml-1">
                            <HelpOutlineIcon 
                                fontSize="small"
                                // color="info"
                            /> 
                        </span>
                    </CustomTooltip>
                </div> :
                <div className="simple-input-label text-1-3">{props.label}</div> 
                : ""
            }
            <div className={"dd-selector-2 flexRow" + (props.selectorClasses ? ` ${props.selectorClasses}` : "") + (value || props.keepPlaceholder ? " dd-item-selected" : "")} onClick={() => setShowDropdown(!showDropdown)}>
                <span className="text-1">{value && !props.keepPlaceholder ? value : props.placeholder ? props.placeholder : "Choose"}</span>
                <img src={ddArrow} alt="arrow-down"/>
            </div>
            {props.inputError ? <InputError text={props.inputError.text} type="error"/> : ""}
        </SimpleDropdownContainer>
    )
}

function SimpleDropdownContainer(props) {
    const history = useHistory();

    // Function to handle navigation
    const handleNavigation = (path) => {
        history.push(path);
    };

    return (
        <div className={"dd-container-2" + (props.extraClasses ? ` ${props.extraClasses}` : "")}>
            {props.children ? props.children : ""}
            <div className={"dd-items-2" + (props.borderedItems ? " dd-items-bordered" : "") + (props.extraClassesIn ? ` ${props.extraClassesIn}` : "") + (props.showDropdown ? "" : " d-none")}>
                {props.items && Object.entries(props.items).map(([k, item]) => {
                    return (
                        <div 
                            className={"dd-item-2 text-1" + (item.disabled ? " dd-item-disabled" : "") + (item.extraClasses ? ` ${item.extraClasses}` : "") + (props.currentValue && props.currentValue === k ? " dd-item-selected" : "")} 
                            onClick={(e) => {
                                e.preventDefault();
                                if (item.disabled) return false;
                                
                                if (item.path) {
                                    handleNavigation(item.path);
                                } else if (item.onclick) {
                                    item.onclick(k, e);
                                } else {
                                    props.selectionHandler(k, item, e);
                                }
                            }}
                        >
                            {item.preItemText ? item.preItemText : ""}
                            {item.content ? item.content : item.present ? item.present : k}
                            {item.postItemText ? item.postItemText : ""}
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export {
    SimpleInputDropdown,
    SimpleDropdownContainer
}