import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//components
import InspectionSchedulesTable from '../../components/Tables/TableTypes/InspectionSchedulesTable/InspectionSchedulesTable';
import InspectionSchedulesHeader from './components/InspectionSchedulesHeader/InspectionSchedulesHeader';
//modals
//constants
import { CategoryFilter, MODAL_TYPES } from '../../constants';
//slices
import { fetchData, resetState, setFilters, setPage } from '../../store/slices/searchSlice';
import { closeModal } from '../../store/slices/inspectionSchedulesSlice';
//utils
import { createDictFromHashedUrl } from '../../utils/main_utils';
import { para_be } from '../../config';
import axios from 'axios';


const InspectionSchedules = (props) => {
    const dispatch = useDispatch();
    
    const journeyId = props.match.params.jid;
    const hashDict = createDictFromHashedUrl(window.location.hash);
    // const [searchTerm, setSearchTerm] = useState(Object.keys(hashDict).length > 0 ? (hashDict?.subject || '') : '');
    const { page, pageSize, sortColumn, sortDirection, searchValue, filters, categoryFilter, totalItems } = useSelector(state => state.table);
    const { modals } = useSelector(state => state.journeys);
    const { data: clientLimits } = useSelector(state => state.clientLimits);
    const [loadingJourney, setLoadingJourney] = useState(false);
    const [journeyName, setJourneyName] = useState(null);
    const [journeyStatus, setJourneyStatus] = useState(null);
    const location = useLocation();

    const fetchConfig = (filters={}, resetStats=false) => {
        const filtersObj = {
            ...filters,
            journey: journeyId,
            category: CategoryFilter.INSPECTION_SCHEDULES,
        };
        const usedSearchValue = Object.keys(hashDict).length > 0 ? decodeURIComponent(hashDict?.subject || '') : (resetStats ? "" : searchValue);
        const body = {
            lowerLimit: resetStats ? 0 : (page - 1) * pageSize,
            upperLimit: resetStats ? pageSize : page * pageSize,
            filters: filtersObj,
            searchValue: usedSearchValue,
            sortDirection: resetStats ? false : sortDirection,
            sortColumn: resetStats ? 0 : sortColumn,
        };

        return {body, filtersObj};
    };

    const performFetch = (filters={}, resetPage=false) => {
        const {body, filtersObj} = fetchConfig(filters);
        if (resetPage) {
            body.lowerLimit = 0;
            body.upperLimit = pageSize;
            dispatch(setPage(1));
        }
        dispatch(fetchData(body));
        dispatch(setFilters(filtersObj));
    };

    useEffect(() => {
        const {body, filtersObj} = fetchConfig({}, true);
        dispatch(resetState({filters: filtersObj, categoryFilter: CategoryFilter.INSPECTION_SCHEDULES}));
        dispatch(fetchData(body));
    }, [dispatch]);

    useEffect(() => {
        setLoadingJourney(true);
        const fetchJourneyData = async () => {
            try {
                const response = await axios.get(`${para_be}/inspections/journey/get?journey_id=${journeyId}`, { withCredentials: true });
                if (response.status === 200 && response.data.status === 200) {
                    setJourneyName(response.data.result.name);
                    setJourneyStatus(response.data.result.status);
                    setLoadingJourney(false);
                    return true;
                }
            } catch (error) {
                console.error('Error fetching journey data:', error);
            }
            setLoadingJourney(false);
            return false;
        };

        fetchJourneyData()
        .then((res) => {
            if (!res) {
                console.error('Failed to fetch journey data');
                // setNotifState({ text: 'Failed to fetch journey data', type: 'error' });
            }
        })
        .catch((error) => {
            console.error('Error fetching journey data:', error);
        });
    }, []);

    const handleCloseModal = (modalName) => {
        dispatch(closeModal(modalName));
    };


    return (
        <>
            {/* {modals.createJourneyModal && 
                <AddNewUnitModal 
                    open={modals.createJourneyModal} 
                    handleClose={()=> handleCloseModal(MODAL_TYPES.CREATE_JOURNEY_MODAL)} 
                    performFetch={() => performFetch({}, true)}
                />
            } */}
            <main className="flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-8">
                    <InspectionSchedulesHeader 
                        journeyName={journeyName || ""} 
                        journeyId={journeyId}
                        status={journeyStatus} 
                        totalItems={totalItems} 
                    />
                    <div className="mt-2 flex flex-col">
                        <InspectionSchedulesTable />
                    </div>
                </div>
            </main>
        </>
    )
};

export default InspectionSchedules;