import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import Pagination from './components/Pagination';
import SnackBar from '../../SnackBar';
import CircularLoader from '../../loaders/CircularLoader';
import EmptyState from '../../emptyState/empty_stateV2';
import TableView from './components/TableView/TableView';
//utils
import { initfetchTableData } from '../utils';
//hooks
import useDebounce from '../../../hooks/useDebounce';
//slices
import { fetchData, setPage } from '../../../store/slices/searchSlice';
//constants
import { tableConfigurations } from '../tableConfig';


const selectHeaderConfig = (category) => {
    return tableConfigurations[category] || [];
};

const TableWidget = (props) => {
    const dispatch = useDispatch();
    const { isMobile } = useSelector(state => state.config);
    const { data, page, sortColumn: currentSortCol, sortDirection: currentSortDir, pageSize, loading, totalItems, searchValue, filters, categoryFilter } = useSelector(state => state.table);
    const [notifState, setNotifState] = useState(null);
    const [tableColumns,setTableColumns] = useState([]); 
    const debouncedPage = useDebounce(page, 500);
    const pageChange = useRef(false);

    useEffect(() => {
        if (pageChange.current) {
            pageChange.current = false;
            const body = {
                lowerLimit: (debouncedPage - 1) * pageSize,
                upperLimit: debouncedPage * pageSize,
                filters,
                searchValue: searchValue,
                sortDirection: currentSortDir,
                sortColumn: currentSortCol
            };
            dispatch(fetchData(body));
        }
    }, [dispatch, debouncedPage]);
    
    useEffect(()=>{
        const headerConfig = selectHeaderConfig(categoryFilter);
        setTableColumns(headerConfig);
    }, [categoryFilter]);
    
    const sortColmn = async index => {
        const sortColumn = index+1;
        const sortDirection = sortColumn === currentSortCol ? !currentSortDir : false;
    
        await initfetchTableData(dispatch, page, pageSize, filters, searchValue, sortDirection, sortColumn);
    };


    return (
        <>
            {/* Main Table Layout */}
            {data && data.length > 0 ? (
                <div className="border border-gray-200 rounded">
                    <TableView
                        columns={tableColumns}
                        currentSortCol={[currentSortCol-1, currentSortDir]}
                        data={data}
                        loading={loading}
                        onSort={(index) => sortColmn(index)}  
                        isMobile={isMobile}
                        setNotifState={setNotifState}
                        showExpandedRows={props.showExpandedRows}
                    />
                    <Pagination
                        page={page}
                        setPage={(newPage) => {
                            pageChange.current = true;
                            dispatch(setPage(newPage));
                        }}
                        limit={Math.ceil(totalItems / pageSize)}
                        offset={1}
                        handleNext={() => {
                            if (page < Math.ceil(totalItems / pageSize)) {
                                pageChange.current = true;
                                dispatch(setPage(page + 1));
                            }
                        }}
                        handlePrevious={() => {
                            if (page > 1) {
                                pageChange.current = true;
                                dispatch(setPage(page - 1));
                            }
                        }}
                    />
                </div>
            ) : loading ? (
                <div className="flex justify-center items-center h-full">
                    <CircularLoader size={36} color="secondary" />
                </div>
            ) : (
                <div className="flex justify-center items-center h-48 mb-8 border border-gray-200 rounded">
                    <EmptyState headerText="No Data Found" decriptionText="No data available in the table" />
                </div>
            )}
            {/* Snackbar Notification */}
            {Boolean(notifState) && (
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={notifState.type === 'error' ? 'error' : 'success'}
                    message={notifState.text}
                    duration={6000}
                />
            )}
        </>
    );
};

export default TableWidget;