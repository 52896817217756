import React from "react";

//style
import "../../scanner/results/demoResults.css";
//components
//containers
//assets

export default function ScannerError (props) {
    const {errMsg} = props;

    return (
        <div className="result-wrapper">
            <div className="result-cover error-cover">
                <h2>Something Went Wrong</h2>
                <p className="err-msg">
                    {errMsg ?
                        errMsg :
                        <>
                            Something went wrong while trying to process your scan. Please try again later, or contact our system administrators.
                        </>
                    }
                </p>
            </div>
        </div>
    )
}