import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
//components
import BaseModal from '../../../../../../components/Modal';
import EmptyState from "../../../../../../components/emptyState/empty_state";
import CircularProgress from '../../../../../../components/loaders/CircularLoader';
//slices
import { fetchUnitBaseline } from '../../../../../../store/slices/baselineScanSlice';


const VideoModal = ({ open, handleClose, pid, subject, videoLink }) => {
    const dispatch = useDispatch();
    const { baselineLink, baselineTimestamp, isEmptyState, loading, error } = useSelector(state => state.baselineScan);
    
    useEffect(() => {
        console.log("videoLink: ", baselineLink);
        console.log("pid: ", pid);
        if (!videoLink && pid) {
            dispatch(fetchUnitBaseline(pid));
        }
    }, [pid, dispatch]);
    
    if (error) return <p>Error: {error}</p>;
    
    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            minWidth={400}
            maxWidth={400}
        >
            <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {subject}
                </Typography>
                {baselineTimestamp &&
                    <div className="text-3">From: {baselineTimestamp}</div>
                }
                {((isEmptyState && !loading) || (!videoLink && !pid)) ? 
                    <div className='h-72'>
                        <EmptyState text="No scan video available." size="md"/>
                    </div>
                    :
                    (loading ? 
                        <div className='flex justify-center items-center h-72'>
                            <CircularProgress size={24} />
                        </div>
                        :
                        <video src={videoLink ? videoLink : baselineLink} controls playsInline autoPlay/>
                    )
                }
            </> 
        </BaseModal>
    );
};

export default VideoModal;