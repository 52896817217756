/*CONNECT TO ARTHUR */
function saveArthurPMSCredentials(para_be) {
    
    const arthurClientId = document.getElementById("pms-client-id").value;
    const arthurSecretKey = document.getElementById("client-secret-key").value;
    const arthurEntityId = document.getElementById("entity").value;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'withCredentials': true},
        body: JSON.stringify({"pms":"arthur", "client_id": arthurClientId, "secret_key": arthurSecretKey,"extra_headers": {"entity_id": arthurEntityId}})
    };
    return fetch(para_be + '/settings/save_pms_creds', requestOptions)
    .then(response => response.json())
    .then(response => {
        if(response.status === 200) {
            const redirect_uri = window.location.origin + "/settings?connect_to_PMS=arthur";
            window.location.href = `https://auth.arthuronline.co.uk/oauth/authorize?client_id=${arthurClientId}&redirect_uri=${redirect_uri}`;
            return false;
        } else {
            return({"type":"error", "msg": response.msg});
        }
    })
    .catch ( error => {
        console.log(error);
        return({"type":"error", "msg": "Connection failed"});
    })
}

function connectToArthurPMS(para_be, code, state) {
    /*Connection TO Arthur PMS - function returns promise!
    1. Fetch credentials from DB
        2. Connect to arthur auth to get token
            3. save token to DB*/
    const getPMSRequestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'withCredentials': true},
    };
    return fetch(para_be + '/settings/get_pms_creds', getPMSRequestOptions)
    .then(response => response.json())
    .then(response => {
            console.log(response)
            if(response.status === 200 && response.result) {
                const client_id = response.result.arthur.client_id;
                const secret_key = response.result.arthur.secret_key;
                const rawBody = {
                    grant_type: 'authorization_code',
                    code: code,
                    client_id: client_id,
                    client_secret: secret_key,
                    state: state,
                    redirect_uri: window.location.origin + "/settings?connect_to_PMS=arthur"
                };
                let formBodyValues = [];
                for (let k in rawBody) formBodyValues.push(encodeURIComponent(k) + "=" + encodeURIComponent(rawBody[k]));
                const getPMSauthTokenOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    body: formBodyValues.join("&")
                };
                fetch("https://auth.arthuronline.co.uk/oauth/token", getPMSauthTokenOptions)
                .then(response => response.json())
                .then(response => {
                    if(response) {
                        const requestOptions = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json', 'withCredentials': true},
                            body: JSON.stringify({"token_data": response})
                        };
                        //change-password/send-recovery
                        fetch(para_be + '/settings/save_pms_token_data', requestOptions)
                        .then(response => response.json())
                        .then(response => {
                            if(response.status === 200) {
                                return {"type": "success", "msg": "Connected to Arthur PMS"};
                            } else {
                                return {"type": "error", "msg": response.msg};
                            }
                        })
                        .catch ( error => {
                            console.log(error);
                            return {"type": "error", "msg": "Connection failed"};
                        })
                    }
                    })
                .catch(error => {
                        console.log(error);
                        return {"type": "error", "msg": "Connection to PMS failed"};
                });
            } else {
                return {"type": "error", "msg": response.msg};
            }
    })
    .catch(error => {
        console.log(error);
        return "Connection to PMS failed";
    });
}

export {
    saveArthurPMSCredentials,
    connectToArthurPMS
}