import { createSlice } from '@reduxjs/toolkit';


const inspectionSchedulesSlice = createSlice({
    name: 'inspectionSchedules',
    initialState: {
        modals: {
            createScheduleModal: false,
            editScheduleModal: false,
            deleteScheduleModal: false,
        }
    },
    reducers: {
        openModal(state, action) {
            const { modalName } = action.payload;
            state.modals[modalName] = true;
        },
        closeModal(state, action) {
            const modalName = action.payload;
            state.modals[modalName] = false;
        },
    },
});

export const { openModal, closeModal } = inspectionSchedulesSlice.actions;

export default inspectionSchedulesSlice.reducer;