import React from "react";

//style
import "./demoResults.css";
//components
import ScannerError from "../../errorMessages/scannerError/scannerError";
//containers
//assets
//constants

export default function DemoScanResults (props) {
    const {result} = props;
    if (typeof results === "string") {
        console.log("[DemoScanResults] result is still string!");
        result = JSON.parse(result);
    }
    console.log("[DemoScanResults] Result:");
    console.log(result);
    console.log("-----");
    console.log(result['result']);
    console.log("-----");
    console.log(result.result);
    console.log(typeof result);
    console.log(!Object.keys(result['result'] || {}).includes("inventory"));

    return (
        <>
            {!result || !result['result'] || (result['status'] && result['status'] !== 200) ? (
                <ScannerError/>
            ) : (
                <div className={`result-wrapper`}>
                    <div className="result-cover">
                        <h2>Results</h2>
                        <div className="resi">
                            <div className="resi-header">
                                <h4>Inventory</h4>
                            </div>
                            <div className="resi-content">
                                <ul>
                                    {!Object.keys(result['result'] || {}).includes("inventory") ? "" :
                                        Object.keys(result['result']['inventory']).map((k, i) => {
                                            return (
                                                <li>
                                                    <div className="inv-container">
                                                        <div className="inv-label">{k}</div>
                                                        <div className="inv-sep"></div>
                                                        <div className="inv-quant">{result['result']['inventory'][k]}</div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        {!Object.keys(result['result'] || {}).includes("damages") ? "" :
                            (
                                <div className="resi">
                                    <div className="resi-header">
                                        <h4>Defects</h4>
                                    </div>
                                    <div className="resu-content">
                                        <p>
                                            Found {result['result']['damages']['cracks'] + result['result']['damages']['stains_and_dampness']} defects, {result['result']['damages']['cracks']} Cracks and {result['result']['damages']['stains_and_dampness']} Stains
                                        </p>
                                    </div>
                                </div>
                            )
                        }

                        <div className="action-buttons">
                            {/*<a className="main-button" id="download-button" href={result['video_path'] ? (AI_Server + result['video_path']) : "#"}>Download Scan</a>*/}
                            <button className="secondary-button" onClick={() => {window.location.reload()}}>New Scan</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}