import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import SnackBar from '../../../SnackBar';
import ConfirmationDialog from '../../../ConfirmationDialog';
import TableWidget from '../../TableWidget';
//modals
//utils
import axios from '../../../../axios/axios';
import { initfetchTableData } from '../../utils';
//slices
import { setPage , closeModal } from '../../../../store/slices/searchSlice';
import { postLogEntry } from '../../../../store/slices/activitySlice';
//constants
import { para_be } from '../../../../config';
import { MODAL_TYPES } from '../../../../constants'
import UpdateJourney from '../../TableWidget/components/Modals/UpdateJourney/UpdateJourney';


const JourneysTable = () => {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);
    const { page, pageSize, selectedRowInTable, modals, searchValue, filters, sortColumn, sortDirection } = useSelector(state => state.table);
    const cred = useSelector(state => state.auth.cred);
    const cid = cred?.cid ?? null;
    const [notifState, setNotifState] = useState(null);
    const [preventCloseModal, setPreventCloseModal] = useState(false);
    
    const handleCloseModal = (modalName) => {
        if (preventCloseModal) return;
        dispatch(closeModal(modalName));
    };
    
    const updateStateAfterDelete = async () => {
        await initfetchTableData(dispatch, page, pageSize, filters, searchValue, sortDirection, sortColumn);
        handleCloseModal(MODAL_TYPES.DELETE_JOURNEY_MODAL);
        dispatch(setPage(1));
        setNotifState({ type: 'success', text: `Deleted Successfully` });
    };
    
    const handleConfirmDeleteJourney = async () => {
        try {
            const response = await axios.post(`${para_be}/inspections/journey/delete`, { journey_id: selectedRowInTable.jid }, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                dispatch(postLogEntry( 
                    { 
                        activityID: selectedRowInTable.jid, activityType: 'journey', ip: endUserData?.ip, 
                        action: {action: "delete", target: "journey"} 
                    }
                ));
                await updateStateAfterDelete();
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
                handleCloseModal(MODAL_TYPES.DELETE_JOURNEY_MODAL)
            }
        } catch (error) {
            setNotifState({ text: 'Failed to Delete', type: 'error' });
            console.error('Error deleting unit:', error);
            handleCloseModal(MODAL_TYPES.DELETE_JOURNEY_MODAL)
        }
    };


    return (
        <>
            {/* Modals */}
            {/* {modals.editJourneyModal && (
                <UpdateUnit
                    open={modals.editJourneyModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.EDIT_JOURNEY_MODAL)}
                    pid={selectedRowInTable.jid}
                    setNotifState={setNotifState}
                />
            )} */}
            {modals.editJourneyModal && (
                <UpdateJourney 
                    open={modals.editJourneyModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.EDIT_JOURNEY_MODAL)}
                    jid={selectedRowInTable.jid}
                    journeyName={selectedRowInTable.journeyName}
                    setNotifState={setNotifState}
                />
            )}
            {modals.deleteJourneyModal && (
                <ConfirmationDialog
                    open={modals.deleteJourneyModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.DELETE_JOURNEY_MODAL)}
                    handleRemove={handleConfirmDeleteJourney}
                    text={`You won't be able to recover it.`}
                    header={'Please confirm that you want to delete this journey.'}
                />
            )}
            
            {/* Main Table Layout */}
            <TableWidget showExpandedRows={false} />
            {/* Snackbar Notification */}
            {Boolean(notifState) && (
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={notifState.type === 'error' ? 'error' : 'success'}
                    message={notifState.text}
                    duration={6000}
                />
            )}
        </>
    );
};

export default JourneysTable;