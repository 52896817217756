import React, { useEffect, useState } from 'react';
import {
    FormControl,
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    Box,
} from '@mui/material';
import { maxWidth, styled } from '@mui/system';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const StyledFormControl = styled(FormControl)({
    width: '100%',
});

const StyledSelect = styled(Select)({
    backgroundColor: '#fff',
    border: '1px solid #f2f2f3',
    width: '100%',
    padding: '8px',
    borderRadius: '8px',
    color: '#0C1222',
    '.MuiSelect-select': {
        padding: '8px',
        paddingRight: '24px',
    },
    '.MuiSelect-icon': {
        color: '#A8ADB8', // Arrow color
    },
});

const menuProps = {
    PaperProps: {
        style: {
            maxHeight: 200, // Set max height for the dropdown
            overflowY: 'auto', // Enable vertical scrolling
            maxWidth: "42rem"
        },
    },
};

const SearchInput = ({ onChange }) => {
    const [searchTerm, setSearchTerm] = useState('');
    
    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchTerm(value);
        onChange(value);
    };

    return (
        <div className="flex h-10 w-full" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="search-field" className="sr-only">Search</label>
            <div className="relative bg-gray-100 rounded-lg w-full" onClick={(e) => e.stopPropagation()}>
                <MagnifyingGlassIcon 
                    aria-hidden="true" 
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500 ml-2" />
                <input
                    placeholder="Search anything here"
                    className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 focus:ring-0 sm:text-sm"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        </div>
    );
};

export default function DropdownWithCheckboxes(props) {
    const MAX_ITEMS = 30;
    const { 
        onChange, selected: previouslySelected, label, options: propOptions, 
        scrollable, searchable, disabled, multipleSelection=true 
    } = props;
    const [selectedOptions, setSelectedOptions] = useState(previouslySelected ? previouslySelected : []);
    const [options, setOptions] = useState(propOptions);
    const [maxRows, setMaxRows] = useState(MAX_ITEMS);

    const handleChange = (event) => {
        const { value } = event.target;
        let finalValue = value;
        if (typeof value !== 'string') {
            if (value.includes('all')) {
                finalValue = value.filter((v) => v !== 'all');
                finalValue = Object.keys(options).length === finalValue.length ? [] : Object.keys(options);
            } else if (value.includes('more')) {
                finalValue = value.filter((v) => v !== 'more');
            }
        }
        setSelectedOptions(finalValue);
        onChange(finalValue);
    };

    const onSearchChange = (searchTerm) => {
        setMaxRows(MAX_ITEMS);
        const filteredOptions = Object.entries(propOptions).reduce((acc, [key, value]) => {
            if (
                value.primary.toLowerCase().includes(searchTerm.toLowerCase()) ||
                value.secondary.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                acc[key] = value;
            }
            return acc;
        }, {});
        setOptions(filteredOptions);
    };

    useEffect(() => {
        setOptions(propOptions);
    }, [propOptions]);

    return (
        <Box>
            <StyledFormControl disabled={disabled}>
                <StyledSelect
                    multiple={multipleSelection}
                    displayEmpty
                    disableAutoFocusItem
                    value={selectedOptions}
                    onClose={() => onSearchChange('')}
                    onChange={handleChange}
                    renderValue={(selected) => 
                        selected.length === 0 ? 
                            label : 
                        !multipleSelection ?
                            options[selected].primary :
                            (options[selected[0]].primary + (
                                selected.length > 1 ? 
                                ` +${selected.length - 1} Items Selected` : ''
                            ))
                    }
                    {...(scrollable ? {MenuProps: menuProps} : {})}
                >
                    {searchable &&
                        <div className="flex h-10 mx-2 my-2" onClick={(e) => e.stopPropagation()}>
                            <SearchInput onChange={onSearchChange} />
                        </div>
                    }
                    {multipleSelection &&
                        <MenuItem value="all">
                            <Checkbox checked={false} />
                            <ListItemText 
                                primary={
                                    Object.keys(options).length === selectedOptions.length ?
                                    <span>Clear Selection</span> :
                                    <span>Select All</span>
                                } 
                                secondary={<span>{Object.keys(options).length} Items</span>}
                            />
                        </MenuItem>
                    }
                    {Object.entries(options).slice(0,maxRows).map(([ optionValue, optionLabel ]) => (
                        <MenuItem key={optionValue} value={optionValue}>
                            <Checkbox checked={selectedOptions.indexOf(optionValue) > -1} />
                            <ListItemText 
                                primary={<span>{optionLabel.primary}</span>} 
                                secondary={<span>{optionLabel.secondary}</span>} 
                                sx={{
                                    '& .MuiListItemText-secondary': {
                                        wordBreak: 'break-word',
                                        whiteSpace: 'normal',
                                    }
                                }}
                            />
                        </MenuItem>
                    ))}
                    {Object.keys(options).length > maxRows &&
                        <MenuItem value="more">
                            <ListItemText 
                                primary={<span>View More</span>} 
                                secondary={<span>{Object.keys(options).length - maxRows} More Items</span>}
                                onClick={() => setMaxRows((o) => o+20)}
                            />
                        </MenuItem>
                    }
                </StyledSelect>
            </StyledFormControl>
        </Box>
    );
}
