import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import SnackBar from '../../../SnackBar';
import ConfirmationDialog from '../../../ConfirmationDialog';
import TableWidget from '../../TableWidget';
//modals
import AllReports from '../../TableWidget/components/Modals/AllReports';
import VideoUploaderModal from '../../TableWidget/components/Modals/VideoUploaderModal';
import VideoModal from '../../TableWidget/components/Modals/VideoModal';
import AllTenancies from '../../TableWidget/components/Modals/AllTenancies';
import UpdateUnit from '../../TableWidget/components/Modals/UpdateUnit';
//utils
import axios from '../../../../axios/axios';
import { initfetchTableData } from '../../utils';
//slices
import { setPage , closeModal } from '../../../../store/slices/searchSlice';
import { postLogEntry } from '../../../../store/slices/activitySlice';
//constants
import { para_be } from '../../../../config';
import { MODAL_TYPES } from '../../../../constants'


const UnitsTable = () => {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);
    const { page, pageSize, selectedRowInTable, modals, searchValue, filters, sortColumn, sortDirection } = useSelector(state => state.table);
    const cred = useSelector(state => state.auth.cred);
    const cid = cred?.cid ?? null;
    const [notifState, setNotifState] = useState(null);
    const [preventCloseModal, setPreventCloseModal] = useState(false);
    
    const handleCloseModal = (modalName) => {
        if (modals.uploaderModal && preventCloseModal) return;
        dispatch(closeModal(modalName));
    };
    
    const updateStateAfterDelete = async () => {
        await initfetchTableData(dispatch, page, pageSize, filters, searchValue, sortDirection, sortColumn);
        handleCloseModal(MODAL_TYPES.DELETE_UNIT_MODAL)
        dispatch(setPage(1));
        setNotifState({ type: 'success', text: `Deletion successful.` });
    };
    
    const handleConfirmDeleteUnit = async () => {
        try {
            const response = await axios.post(`${para_be}/units/remove`, { cid, pid: selectedRowInTable.pid }, { withCredentials: true });
            if (response.status === 200) {
                dispatch(postLogEntry( 
                    { 
                        activityID: selectedRowInTable.pid, activityType: 'unit', ip: endUserData?.ip, 
                        action: {action: "remove", target: "unit"} 
                    }
                ));
                await updateStateAfterDelete();
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
            }
        } catch (error) {
            setNotifState({ text: 'Delete failed', type: 'error' });
            console.error('Error deleting unit:', error);
        }
    };


    return (
        <>
            {/* Modals */}
            {modals.videoModal && (
                <VideoModal
                    open={modals.videoModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.VIDEO_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.uploaderModal && (
                <VideoUploaderModal
                    open={modals.uploaderModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.UPLOADER_MODAL)}
                    pid={selectedRowInTable.pid}
                    changeBlockStatus={(s) => { setPreventCloseModal(s); }}
                    setNotifState={setNotifState}
                />
            )}
            {modals.allReportsModal && (
                <AllReports
                    open={modals.allReportsModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.ALL_REPORTS_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.allTenanciesModal && (
                <AllTenancies
                    open={modals.allTenanciesModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.ALL_TENANCIES_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                />
            )}
            {modals.updateUnitsAddressModal && (
                <UpdateUnit
                    open={modals.updateUnitsAddressModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.UPDATE_UNIT_ADDRESS_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                    ext_ref={selectedRowInTable.ext_ref}
                    updatedItem="Unit Address"
                    itemKey="address"
                    setNotifState={setNotifState}
                />
            )}
            {modals.updateUnitReferenceModal && (
                <UpdateUnit
                    open={modals.updateUnitReferenceModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.UPDATE_UNIT_REFERENCE_MODAL)}
                    pid={selectedRowInTable.pid}
                    subject={selectedRowInTable.subject}
                    ext_ref={selectedRowInTable.ext_ref}
                    updatedItem="Reference ID"
                    itemKey="extRef"
                    setNotifState={setNotifState}
                />
            )}
            {modals.deleteUnitModal && (
                <ConfirmationDialog
                    open={modals.deleteUnitModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.DELETE_UNIT_MODAL)}
                    handleRemove={handleConfirmDeleteUnit}
                    text={`You won't be able to recover it.`}
                    header={'Please confirm that you want to delete this unit.'}
                />
            )}
            {/* Main Table Layout */}
            <TableWidget showExpandedRows={true} />
            {/* Snackbar Notification */}
            {Boolean(notifState) && (
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={notifState.type === 'error' ? 'error' : 'success'}
                    message={notifState.text}
                    duration={6000}
                />
            )}
        </>
    );
};

export default UnitsTable;