import React, {useState, useEffect} from "react";
//containers
import ChecklistItem from "../checklist_item/checklist_item";
//components
import Btn from "../../../components/buttons/standard/btn";
import BackBtn from "../../../components/buttons/back_btn/back";
import EmptyState from "../../../components/emptyState/empty_state";
import MainLoader from "../../../components/loaders/main_loader/main_loader";
import Notification from "../../../components/side_notification/side_notification";
//styles
import "./checklist_popup.css";
//assets
import shrinkIcon from "../../../assets/icons/shrink.svg";
import deleteIcon from "../../../assets/icons/DeleteOutlined.svg"; 
import commentIcon from "../../../assets/icons/Comment.svg";
import editIcon from "../../../assets/icons/Edit.svg";
import { postReqOptBuilder } from "../../../utils/main_utils";

export default function ChecklistPopup (props) {
    const [checklistItemEdit, setCheckListItemEdit] = useState(null);
    const [activeChecklistItem, setActiveChecklistItem] = useState(false);
    const [viewComments, setViewComments] = useState(null);
    const [isEmptyState, setEmptyState] = useState(false);
    const [isLoading, setLoader] = useState(true);
    const [checklistItems, setChecklistItems] = useState(null);
    const [textAreaValues, setTextAreValues] = useState(null);//multiple textarea values in react are managed via state.
    const [notifState, setNotifState] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [hashParams, setHashParams] = useState(props.hashParams)

    useEffect(() => {
        console.log(props.hashParams);
        fetch(props.para_be + '/checklists/get_items?cid=' + props.cid + "&report_id=" + props.reportId)
        .then(response => response.json())
        .then(response => {
            if(response.status === 200) {
                if(Array.isArray(response.result) && response.result.length > 0) {
                    let textAreas = []
                    for(let i=0; i<response.result.length; i++) {
                        textAreas.push(response.result[i][1]);
                    }
                    setTextAreValues(textAreas);
                    setChecklistItems(response.result);
                } else {
                    setEmptyState(true);
                }
            } else {
                setNotifState({text:"Failed to fetch checklist", type:"error"});
            }
            setLoader(false);
        })
        .catch ( error => {
            setNotifState({text:"Failed to fetch checklist", type:"error"});
            setLoader(false);
            setEmptyState(true);
        });

        if (hashParams.ci_id) {
            fetch(props.para_be + '/checklists/get_single_item?cid=' + props.cid + "&report_id=" + props.reportId + "&ci_id=" + hashParams.ci_id)
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    if (response.result.length > 0) {
                        const resp = response.result;
                        console.log("in checklist popup ", resp, hashParams.ci_id);
                        setViewComments({
                            'ci_id': hashParams.ci_id,
                            "subject": resp[0],
                            "description": resp[1],
                            "status": resp[4],
                            "mediaUrl": resp[5]
                        });
                    } else {
                        setEmptyState(true);
                    }
                } else {
                    setNotifState({text: "Failed to fetch checklist", type: "error"});
                }
                setLoader(false);
            })
            .catch (error => {
                setNotifState({text: "Failed to fetch checklist", type: "error"});
                setLoader(false);
                setEmptyState(true);
            });
        }
    }, [forceRefresh]);

    const handleCheckCheckbox = (ci_id, status) => {
        fetch(props.para_be + '/checklists/update_item', postReqOptBuilder({'report_id': props.reportId, 'ci_id': ci_id, 'status': status, 'type': 'status'}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                let newData = checklistItems.splice(0);
                for (let i=0; i<newData.length; i++) {
                    if (newData[i][0] === ci_id.toString()) {
                        newData[i][5] = status;
                        break;
                    }
                }
                setChecklistItems(newData);
            } else {
                console.log(response);
                setNotifState({text:"Failed to change status", type:"error"});
                handleUncheckItem(ci_id, status);
            }
        })
        .catch( error => {
            setNotifState({text:"Failed to change status", type:"error"});
            handleUncheckItem(ci_id, status);
        });
        props.updateOpenItems(status === 0 ? 1 : -1);
    };

    const updateTextAreasValues =(value, index) => {
        let newText = textAreaValues.slice(0);
        newText[index] = value;
        setTextAreValues(newText);
    };

    const editCheckListItem = (item, index) => {
        setCheckListItemEdit(item);
        const keyDownEvent = (event) => {
            console.log(event.key)
            if (event.key === "Escape") {
                setCheckListItemEdit(null);
                updateTextAreasValues(textAreaValues[index], index);
                document.getElementById(item).removeEventListener("keydown", keyDownEvent);
                
            } else if (event.key === "Enter") {
               //only on press enter
                const checklistItemValue = document.getElementById(item).innerHTML;
                fetch(props.para_be + "/checklists/update_item", postReqOptBuilder({'type': 'subject', 'report_id': props.reportId, 'ci_id': item, 'text_to_update': checklistItemValue}))
                .then(response => response.json())
                .then(response => {
                    if (response.status === 200) {
                        setNotifState({text:"Edited successfully", type:"success"});
                        setForceRefresh(!forceRefresh);
                    } else {
                        setNotifState({text:"Failed to edit item", type:"error"});
                    }
                })
                .catch ( error => {
                    setNotifState({text:"Failed to edit item", type:"error"});
                });
                setCheckListItemEdit(null);
                document.getElementById(item).removeEventListener("keydown", keyDownEvent);
                }
            
            }
        //remove previously attached event listener
        document.getElementById(item).addEventListener("keydown", keyDownEvent);
    };

    const deleteItem = (item,index) => {
        fetch(props.para_be + '/checklists/delete_item', postReqOptBuilder({'report_id': props.reportId, 'ci_id': item}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setTextAreValues(textAreaValues.filter((elem, elem_index)=> elem_index !== index));
                setChecklistItems(checklistItems.filter((elem)=> elem[0] !== item));
                setNotifState({text:"Deleted successfully", type:"success"});
            } else {
                setNotifState({text:"Failed to delete", type:"error"});
            }
        })
        .catch ( error => {
            setNotifState({text:"Failed to delete", type:"error"});
        });
        props.updateOpenItems(-1);
    };

    const handleUncheckItem = (id ,status) => {
        const elem = document.querySelector("#chkbx-" + id);
        if (elem) elem.value = status === 1;
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    const handleBack = () => {
        if (viewComments) {
            setViewComments(null);
        } else {
            props.hide();
        }
    };

    const handleBackFromActivity = (status) => {
        setViewComments(null);
        setHashParams({
            ci_id: "",
            comment_id: ""
        });
        console.log("return from checklist item: ", status, forceRefresh);
        if (status) setForceRefresh(!forceRefresh);
    };

    const handleInnerSubjectChange = (ci_id, new_subject) => {
        let tmpTextAreaValues = textAreaValues.slice(0);
        for (let idx in tmpTextAreaValues) {
            if (tmpTextAreaValues[idx][0] == ci_id) {
                tmpTextAreaValues[idx] = new_subject;
                break;
            }
        }
        if (tmpTextAreaValues !== textAreaValues) setTextAreValues(tmpTextAreaValues);
    };

    return (
        <div className="cl-container custom-scrollbar-1">
            <img src={shrinkIcon} onClick={() => props.hide()} alt="shrink-icon"/>
            <BackBtn clickFunction={() => handleBack()}/>
            {!viewComments ?
                <div className="cl-body-container">
                    <div className="cl-body">
                        <div className="blue-headline">Checklist</div>
                        <div>{props.reportSubject}</div>
                        <div>{props.reportDate}</div>
                        <div className="cl-items-list custom-scrollbar">
                            {isLoading ? <MainLoader/> :
                                (isEmptyState ? <EmptyState text="Your checklist is empty." size="md"/> :
                                    checklistItems.map((item, index) => {
                                        const commentData = {
                                            "ci_id": item[0],
                                            "subject": item[1],
                                            "description": item[2],
                                            "status": item[5],
                                            "mediaUrl": item[8],
                                        };
                                        // item: 0-ci_id, 1-subject(text), 2-description, 3-created_by, 4-assigned_to, 5-status, 6-metadata, 7-new comment indicator(not active yet), 8-media-url(not yet active)
                                        return (
                                            <div
                                                className={"cl-item " + (activeChecklistItem === item[0] ? "active": "")}
                                                key={item[0]} onMouseLeave={() => setActiveChecklistItem(null)}
                                            >
                                                <label className="unselectable chkbx-container">
                                                    <textarea
                                                        onMouseEnter={() => setActiveChecklistItem(item[0])}
                                                        readOnly={checklistItemEdit === item[0] ? false : true}
                                                        id={item[0]}
                                                        value={textAreaValues[index]}
                                                        onChange={(e)=> updateTextAreasValues(e.target.value, index)}
                                                    />
                                                    <input type="checkbox" id={"chkbx-"+item[0]} checked={item[5] === 1 ? true : false}/>
                                                    <span className="checkmark" onClick={()=>handleCheckCheckbox(item[0], item[5] === 1 ? 0 : 1)}></span>
                                                </label>

                                                <div className={"cl-icons"}>
                                                    <img
                                                        src={editIcon}
                                                        alt="edit item"
                                                        onClick={() => editCheckListItem(item[0], index)}
                                                        className={activeChecklistItem === item[0] ? "" : "d-none"}/>
                                                    <img
                                                        src={commentIcon}
                                                        onClick={() => setViewComments(commentData)}
                                                        alt="comment"
                                                        // className={activeChecklistItem === item[0] || item[7] ? (item[7] ? "new-comment" : ""): "d-none"}/>
                                                        className={activeChecklistItem === item[0] ? "" : "d-none"}/>
                                                    <img
                                                        src={deleteIcon}
                                                        onClick={()=>deleteItem(item[0], index)}
                                                        alt="delete item"
                                                        className={activeChecklistItem === item[0] ? "" : "d-none"}/>
                                                    {item[7] > 0 &&
                                                        <div className="icon-counter">
                                                            <span>{item[7]}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    </div>
                    {isEmptyState || isLoading ? "" :
                        <div className="cl-buttons">
                            <Btn text="Download" type="secondary"/>
                            <Btn text="Share" type="primary"/>
                        </div>
                    }
                </div> :
                <ChecklistItem
                    handleBack ={handleBackFromActivity}
                    para_be={props.para_be}
                    reportId={props.reportId}
                    ci_id={viewComments.ci_id}
                    subject={viewComments.subject}
                    status={viewComments.status}
                    reportSubject={props.reportSubject}
                    reportDate={props.reportDate}
                    mediaUrl={viewComments.mediaUrl}
                    description={viewComments.description}
                    hashParams={hashParams}
                    handleSubjectChange={handleInnerSubjectChange}
                />
            }
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </div>
    )
}