import React, {useState, useEffect} from "react";

//style
import "./obi_one.css";
import Btn from "../../buttons/standard/btn";
//components
//containers
//assets

export default function ScanVideoStep(props) {
    const {vid, text, btnText, onSkip, onBtnClick, step, totalSteps} = props;


    return (
        <div className="obi-one">
            <div className="vid-wrapper">
                <video src={vid} autoPlay controls playsInline/>
            </div>
            <div className="step-content">
                {text}
                {
                    totalSteps < 2 ? "" :
                        <div className="steps-wrapper">
                            <div className="sw">
                                {() => {
                                    let r = "";
                                    for (let i=0; i++; i<totalSteps) {
                                        r += <div className={`step-rep${step === i ? " active-step" : ""}`}></div>;
                                    }
                                    return r;
                                }}
                            </div>
                        </div>

                }
                <Btn type="primary" text={btnText} onclick={(e) => onBtnClick()}/>
                {onSkip ? <Btn type="text" text="Skip" onclick={(e) => onSkip()}/> : ""}
            </div>
        </div>
    )
}