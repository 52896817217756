import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
//slices
import { fetchCustomerData } from '../../../../../../store/slices/customerSlice';
//components
import Step1CreateCustomer from './components/Step1CreateCustomer';
import Step2Wrapper from './components/Step2AddPaymentMethod';
import Step3CreateSubscription from './components/Step3CreateSubscription';
import Step4Congratulations from './components/Step4Congratulations';
import SnackBar from '../../../../../../components/SnackBar';
import CustomButton from '../../../../../../components/buttons/CustomButton';
//utils
import { paymentAccountExists } from '../../utils';
//constants
import { SeverityLevels } from '../../../../../../constants';

const steps = ['Create Customer', 'Add Payment Method', 'Setup Subscription', 'Congratulations'];

const CustomStepLabel = styled(StepLabel)({
    // Targeting the label part of the StepLabel component
    '& .MuiStepLabel-label': {
        fontSize: '0.7rem',
        // Add any other styles you wish to override
    },
});

const Subscription = () => {
    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [isHaveAccount, setHaveAccount] = useState(false);
    const dispatch = useDispatch();

    const { customerData } = useSelector(state => state.customer);

    const step1Ref = useRef();
    const step2Ref = useRef();
    const step3Ref = useRef();
    const step4Ref = useRef();

    const stepRefs = [step1Ref, step2Ref, step3Ref, step4Ref];


    useEffect(() => {
        if (customerData) {
            const haveAccount = paymentAccountExists(customerData);
            setHaveAccount(haveAccount);
            if (activeStep === 0 && haveAccount) {
                setActiveStep(1);
            }
        }
    }, [customerData]);

    const handleNext = async () => {
        setLoading(true);
        try {
            await stepRefs[activeStep].current.handleSubmit();
            setActiveStep(prev => prev + 1);
        } catch (err) {
            setError(err.message ?? 'somthing went wrong');
            showMessage();
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const showMessage = () => {
        setOpen(true);
    };

    const handleBack = () => {
        setActiveStep(prev => prev - 1);
    };

    const getStepContent = stepIndex => {
        switch (stepIndex) {
            case 0:
                return <Step1CreateCustomer ref={step1Ref} handleNext={handleNext} />;
            case 1:
                return <Step2Wrapper ref={step2Ref} />;
            case 2:
                return <Step3CreateSubscription ref={step3Ref} />;
            case 3:
                return <Step4Congratulations ref={step4Ref} />;
            default:
                return 'Unknown Step';
        }
    };

    return (
        <>
            {open && <SnackBar open={open} handleClose={handleClose} severity={SeverityLevels.ERROR} message={error} duration={6000} />}
            <div className="w-full max-w-md mx-auto mb-4">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <CustomStepLabel>{label}</CustomStepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {getStepContent(activeStep)}
                    {(activeStep !== 0) &&
                        <div className="flex justify-between">
                            <Button disabled={activeStep === 0 || activeStep === 3} onClick={handleBack}>
                                Back
                            </Button>

                            <CustomButton variant="contained" color="primary" onClick={handleNext} disabled={loading} loading={loading}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </CustomButton>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Subscription;
