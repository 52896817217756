import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//external
import axios from 'axios';
import ReactQuill from 'react-quill';
//components
import SnackBar from '../../../../components/SnackBar';
import DropdownWithCheckboxes from '../../../../components/dropdowns/muiSelect/muiMultiSelect';
import SimpleInput from '../../../../components/inputs/simple_input/input';
import CustomButton from '../../../../components/buttons/CustomButton';
import MainLoader from '../../../../components/loaders/main_loader/main_loader';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import { SimpleInputDropdown } from '../../../../components/dropdowns/simple_dropdown/simple_dropdown';
import Btn from '../../../../components/buttons/standard/btn';
import { Button, Checkbox } from '@mui/material';
//modals
//slices
//utils
//assets
import collapsedIcon from "../../../../assets/icons/dd-arrow.svg";
import expandIcon from "../../../../assets/icons/up-arrow.svg";
import deleteIcon from "../../../../assets/icons/DeleteOutlined.svg";
import copyIcon from "../../../../assets/icons/copy.svg";
import paraIcon from "../../../../assets/icons/logo.svg";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
//constants
import { para_be } from '../../../../config';


const INSPECTIONS = {
    "move_in": {
        name: "Move-In",
        description: "A comprehensive inspection that records the condition of a unit upon move-in. It ensures a clear reference point for the unit's condition at the beginning of the lease.",
    },
    "move_out": {
        name: "Move-Out",
        description: "A detailed inspection that documents the condition of a unit at the end of a lease. It helps compare the current state of the unit to its condition at move-in, ensuring accurate assessments for repairs, cleaning, or deposit deductions.",
    },
    "pre_move_out": {
        name: "Pre-Move-Out",
        description: "An inspection conducted before a resident vacates the unit. It identifies potential damages or issues that need addressing, giving the resident an opportunity to resolve them before the final move-out inspection.",
    },
    "quarterly": {
        name: "Quarterly",
        description: "A routine inspection performed every three months to ensure the unit is being properly maintained. It helps identify and address minor issues before they escalate into costly repairs.",
    },
    "semi_annual": {
        name: "Semi-Annual",
        description: "A twice-yearly inspection to check the condition of the unit, ensuring it meets safety standards and identifying any maintenance needs to protect the property’s value.",
    },
    "annual": {
        name: "Annual",
        description: "A yearly inspection that provides a comprehensive overview of the unit’s condition. It ensures compliance with lease agreements and helps maintain the property in excellent condition.",
    },
    "turn": {
        name: "Turn",
        description: "A thorough inspection conducted between tenants to prepare the unit for the next resident. It identifies required repairs, cleaning, and upgrades, ensuring the unit is move-in ready.",
    }
};

const variablesOptions = {
    name: { 
        present: "Name",
        content: <span className='text-sm font-medium text-gray-700'>Name</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>The name of the assignee the notice will be sent to</span>
        </>
    },
    deposit: { 
        present: "Deposit",
        content: <span className='text-sm font-medium text-gray-700'>Deposit</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>The deposit amount set for the tenancy of the unit</span>
        </>
    },
    rent: { 
        present: "Rent",
        content: <span className='text-sm font-medium text-gray-700'>Rent</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>The rent amount set for the tenancy of the unit</span>
        </>
    },
    unit_address: { 
        present: "Unit Address",
        content: <span className='text-sm font-medium text-gray-700'>Unit Address</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>The address of the unit the inspection is scheduled for</span>
        </>
    },
    move_in: { 
        present: "Move-In",
        content: <span className='text-sm font-medium text-gray-700'>Move-In</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>The move-in date of the resident to the unit</span>
        </>
    },
    move_out: { 
        present: "Move-Out",
        content: <span className="text-sm font-medium text-gray-700">Move-Out</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>The move-out date of the resident from the unit</span>
        </>
    },
    us_support_number: { 
        present: "US Support Number",
        content: <span className='text-sm font-medium text-gray-700'>US Support Number</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>
                Paraspot's US Support Number
            </span>
        </>
    },
    german_support_number: { 
        present: "German Support Number",
        content: <span className='text-sm font-medium text-gray-700'>German Support Number</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>
                Paraspot's German Support Number
            </span>
        </>
    },
    whatsapp_support_number: { 
        present: "WhatsApp Support Number",
        content: <span className='text-sm font-medium text-gray-700'>WhatsApp Support Number</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>
                Paraspot's WhatsApp Support Number
            </span>
        </>
    },
    paraspot_support: { 
        present: "Paraspot Support",
        content: <span className='text-sm font-medium text-gray-700'>Paraspot Support</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>
                Sentence including contact information for Paraspot's support lines
            </span>
        </>
    },
    scan_link: { 
        present: "Scan Link",
        content: <span className='text-sm font-medium text-gray-700'>Scan Link</span>,
        postItemText: <>
            <br/>
            <span className='text-xs text-gray-500'>A button linking to the inspection scan page</span>
        </>
    },
};

const initialSubject = "Instructions for move-in/move-out day";
const initialContent = `<h2>Dear %name%,</h2><br/>
<p>We hope you're enjoying your stay. We would like to inform you about the upcoming move-in/move-out from %unit_address%.<br/>
Our goal is to ensure a smooth transition for you, and we appreciate your cooperation in this matter.<br/><br/>
As part of the move-in/move-out process, we're asking you to perform a guided video of the property, prior to moving in/out. Click on the button below to get started.
<br/><br/><br/>
%scan_link%<br/>
<br/><br/><br/>
%paraspot_support%<br/>
<br/>
Thank you for staying with us and we hope to see you again soon!<br/>
<br/><br/><br/>
Warm regards,<br/></p>
`;
const initialEmailData = () => {
    return {
        when: {amount: "", type: ""},
        method: "",
        msg: {
            subject: initialSubject,
            body: initialContent,
        },
        isCollapsed: false, // Initially, email box is collapsed,
        variable: "",
    };
};


const UpdateSchedule = (props) => {
    const scheduleId = props.match?.params ? props.match?.params?.sid : null;
    const journeyId = props.match?.params ? props.match?.params?.jid : null;
    const isCreationMode = props?.creationMode;
    // global states
    // const { data: clientLimits } = useSelector(state => state.clientLimits);
    const { sidebarOpen } = useSelector(state => state.config);
    // local states
    const [notifState, setNotifState] = useState(null);
    // loading states
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    // states
    const [fetchedJourneyId, setFetchedJourneyId] = useState(null);
    const [inspectionType, setInspectionType] = useState(null);
    const [prevAssignedData, setPrevAssignedData] = useState(null);
    const [assignTo, setAssignTo] = useState({type: null, members: []});
    const [dueRange, setDueRange] = useState([null, null]);
    const [notices, setNotices] = useState([]);
    const [availableTeamMembers, setAvailableTeamMembers] = useState({});

    const history = useHistory();


    const verifyInputs = () => {
        if (!inspectionType) {
            setNotifState({ text: "Please select an inspection type", type: 'error' });
            return false;
        }
        if (!dueRange[0] || !dueRange[1]) {
            setNotifState({ text: "Please enter the due by - days range", type: 'error' });
            return false;
        }
        if (assignTo.type === null) {
            setNotifState({ text: "Please select who's the schedule assigned to", type: 'error' });
            return false;
        }
        if (assignTo.type === "team" && assignTo.members.length === 0) {
            setNotifState({ text: "Please select team members", type: 'error' });
            return false;
        }
        // if (notices.length === 0) {
        //     setNotifState({ text: "Please add at least one notice", type: 'error' });
        //     return false;
        // }
        for (let i = 0; i < notices.length; i++) {
            if (!notices[i].method) {
                setNotifState({ text: `Please select a method for notice ${i+1}`, type: 'error' });
                return false;
            }
            if (!notices[i].when.amount || !notices[i].when.type) {
                setNotifState({ text: `Please enter when to send notice ${i+1}`, type: 'error' });
                return false;
            }
            if (notices[i].method === "email" && !notices[i].msg.subject) {
                setNotifState({ text: `Please enter the email subject for notice ${i+1}`, type: 'error' });
                return false;
            }
            if (!notices[i].msg.body) {
                setNotifState({ text: `Please enter the message body for notice ${i+1}`, type: 'error' });
                return false;
            }
        }
        return true;
    };
    
    const onSubmit = async () => {
        setLoadingBtn(true);
        const allowedToProceed = verifyInputs();
        if (!allowedToProceed) {
            setLoadingBtn(false);
            return;
        }

        const body = {
            schedule_id: scheduleId,
            ...(isCreationMode ? {journey_id: journeyId} : {}),
            inspection_type: inspectionType,
            due_range: dueRange,
            assigned_to: assignTo,
            notices: notices.reduce((acc, notice) => {
                return [
                    ...acc,
                    {
                        method: notice.method,
                        when: notice.when,
                        msg: {
                            body: notice.msg.body,
                            ...(notice.method === "email" ? {subject: notice.msg.subject} : {}),
                        }
                    }
                ];
            }, [])
        };

        try {
            const response = await axios.post(
                `${para_be}/inspections/schedule/${isCreationMode ? 'create' : 'update'}`, 
                body, { withCredentials: true }
            );
            if (response.status === 200 && response.data.status === 200) {
                setNotifState({ text: response.data.msg, type: 'success' });
                setLoadingBtn(false);
                if (journeyId || fetchedJourneyId) {
                    history.push(`/journey/${journeyId || fetchedJourneyId}`);
                } else {
                    history.push('/journeys');
                }
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
                setLoadingBtn(false);
            }
        } catch (error) {
            console.error('Error submitting schedule:', error);
            setNotifState({ text: 'Failed to submit schedule', type: 'error' });
            setLoadingBtn(false);
        }
    };

    const fetchAvailableTeamMembers = async () => {
        try {
            const response = await axios.get(`${para_be}/client/get_all_users`, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                const users = response.data.result.reduce((acc, user) => {
                    return {
                        ...acc,
                        [user.uid]: {
                            primary: `${user.fullName} - ${user.role}`,
                            secondary: user.email
                        }
                    }
                }, {});
                setAvailableTeamMembers(users);
                return true;
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
            }
        } catch (error) {
            console.error('Error fetching team members:', error);
            setNotifState({ text: 'Failed fetching team members', type: 'error' });
        }
        return false;
    }

    const fetchScheduleData = async () => {
        try {
            const response = await axios.get(`${para_be}/inspections/schedule/get?schedule_id=${scheduleId}`, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                const scheduleData = response.data.result;
                setInspectionType(scheduleData.inspection_type);
                setDueRange(scheduleData.due_range);
                setAssignTo(scheduleData.assigned_to);
                setNotices(scheduleData.notices);
                setPrevAssignedData({
                    inspectionType: scheduleData.inspection_type,
                    dueRange: scheduleData.due_range,
                    assignTo: scheduleData.assigned_to,
                    notices: scheduleData.notices
                });
                if (scheduleData?.journey_id) {
                    setFetchedJourneyId(scheduleData.journey_id);
                }
                return true;
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
            }
        } catch (error) {
            console.error('Error fetching schedule data:', error);
            setNotifState({ text: 'Failed to fetch schedule data', type: 'error' });
        }
        return false;
    };

    useEffect(() => {
        if (notifState) {
            setTimeout(() => {
                setNotifState(null);
            }, 6000);
        }
    }, [notifState]);
    
    useEffect(() => {
        if (isCreationMode && !journeyId) {
            setErrorLoading(true);
            setNotifState({ text: 'Invalid creation form', type: 'error' });
            return;
        }

        setLoading(true);
        Promise.all(isCreationMode ? [fetchAvailableTeamMembers()] : [fetchScheduleData(), fetchAvailableTeamMembers()])
        .then((results) => {
            if (results.includes(false)) {
                setErrorLoading(true);
            }
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            setErrorLoading(true);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    const prepEmailPreview = (msg) => {
        return msg.replace("%scan_link%", '<a class="primary-btn" href="#">Move-In/Out Scan</a>')
                    .replaceAll("%name%", "Isaac").replaceAll("%unit_address%", "1234 Main St, New York, NY 10001")
                    .replaceAll("%number%", "+1 123 456 7890").replaceAll("%inspection_type%", "Move-In")
                    .replaceAll("%move_in%", "2021-08-01").replaceAll("%move_out%", "2021-08-31")
                    .replaceAll("%deposit%", "$1000").replaceAll("%rent%", "$1000")
                    .replaceAll("%us_support_number%", `<a href="tel:+12012334728">(201) 233-4728</a>`)
                    .replaceAll("%german_support_number%", `<a href="tel:+493062931420">+49 (0)3062931420</a>`)
                    .replaceAll("%whatsapp_support_number%", `<a href="https://wa.me/message/NMWJBUHZPRIHA1">WhatsApp</a>`)
                    .replaceAll("%paraspot_support%", `We are here to help and ensure a seamless transition. If you have any questions don't hesitate to contact our team on <a href="tel:+493062931420">+49 (0)3062931420</a> or over <a href="https://wa.me/message/NMWJBUHZPRIHA1">WhatsApp</a>.`)
                    .replaceAll("<p>", "<p style=\"margin: 0;\">").replaceAll("<h2>", "<h3>").replaceAll("</h2>", "</h3>");
    };

    
    return (
        <>
            <main className="flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-8">
                    <div className="mt-2 pb-16 max-w-2xl mx-auto flex flex-col">
                        <div className="flex flex-col">
                            <span className="text-gray-700 text-lg font-medium">
                                { isCreationMode ? "Create Inspection Schedule" : "Update Schedule" }
                            </span> 
                            <span className="text-sm text-gray-500">Fill the form below to {isCreationMode ? "create a new" : "update your"} inspection schedule.</span>
                        </div>
                        <div className="mt-8">
                            {loading ?
                                <div className='h-64'>
                                    <MainLoader/>
                                </div> 
                            : errorLoading ?
                                <div className='h-64 flex flex-col justify-center center'>
                                    <span className='text-gray-500 text-md text-center'>
                                        Something went wrong while loading the modal. Please try again.
                                        <br/>
                                        If the problem persists, please contact support.
                                    </span>
                                </div> 
                            :
                                <div className="flex flex-col gap-6">
                                    <div className="flex flex-col gap-2">
                                        <label className="text-sm font-medium text-gray-700">
                                            Inspection Type
                                        </label>
                                        <DropdownWithCheckboxes 
                                            options={Object.keys(INSPECTIONS).reduce((acc, inspection) => {
                                                return {
                                                    ...acc,
                                                    [inspection]: {
                                                        primary: INSPECTIONS[inspection].name,
                                                        secondary: INSPECTIONS[inspection].description
                                                    }
                                                };
                                            }, {})}
                                            disabled={!isCreationMode}
                                            selected={prevAssignedData?.inspectionType}
                                            multipleSelection={false}
                                            scrollable={true}
                                            onChange={(selected) => {
                                                setInspectionType(selected);
                                            }}
                                            label="Select Inspection Type"
                                        />
                                    </div>
                                    
                                    
                                    <div className="relative flex flex-col gap-2">
                                        <label className="text-sm font-medium text-gray-700">
                                            Due By - Days Range
                                        </label>
                                        <div className="flex flexRow">
                                            <SimpleInput 
                                                type="number"
                                                si_input_text="Enter Days Before"
                                                containerClasses="no-margin flex-grow w-1/2"
                                                inputStyle={{paddingTop: '1rem'}}
                                                value={dueRange[0]}
                                                on_change={(e) => {
                                                    setDueRange(
                                                        (prev) => [e.target.value, prev[1]]
                                                    );
                                                }}
                                            />
                                            <div className="text-md font-medium text-gray-700 mx-3 my-auto">—</div>
                                            <SimpleInput 
                                                type="number"
                                                si_input_text="Enter Days After"
                                                containerClasses="no-margin flex-grow w-1/2"
                                                inputStyle={{paddingTop: '1rem'}}
                                                value={dueRange[1]}
                                                on_change={(e) => {
                                                    setDueRange(
                                                        (prev) => [prev[0], e.target.value]
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="flex flex-col gap-2">
                                        <label className="text-sm font-medium text-gray-700">
                                            Assign To
                                        </label>
                                        <DropdownWithCheckboxes 
                                            options={{
                                                tenant: {
                                                    primary: "Resident",
                                                    secondary: "The resident will be responsible for the inspection"
                                                },
                                                team: {
                                                    primary: "Team",
                                                    secondary: "A member of your team will be responsible for the inspection"
                                                }
                                            }}
                                            selected={prevAssignedData?.assignTo?.type}
                                            multipleSelection={false}
                                            onChange={(selected) => {
                                                setAssignTo((prev) => {
                                                    return { ...prev, type: selected };
                                                });
                                            }}
                                            label="Select Assignee"
                                        />
                                    </div>
                                    
                                    
                                    <div className="flex flex-col gap-2">
                                        <label className="text-sm font-medium text-gray-700">
                                            Select Team Members
                                        </label>
                                        <DropdownWithCheckboxes 
                                            options={availableTeamMembers}
                                            selected={prevAssignedData?.assignTo?.members}
                                            onChange={(selected) => { 
                                                setAssignTo(
                                                    (prev) => {
                                                        return {
                                                            ...prev,
                                                            members: selected
                                                        }
                                                    }
                                                );
                                            }}
                                            label="Select Team Members"
                                            scrollable={true}
                                            searchable={true}
                                            disabled={assignTo.type !== "team"}
                                        />
                                    </div>


                                    <div className='flex flex-col mt-2 gap-3'>
                                        <div className='flex flex-col'>
                                            <label className="text-md font-medium text-gray-700">
                                                Notices
                                            </label>
                                            <span className="text-sm text-gray-500">
                                                Add notices to be sent to the assignee(s) at specific intervals prior to the inspection occurrence.
                                            </span>
                                        </div>
                                        <div className="addmore">
                                            <div></div>
                                            <Btn 
                                                onclick={() => setNotices([...notices, initialEmailData()])} 
                                                text="Add More +" 
                                                type="primary" />
                                        </div>
                                        <div className='auto-email-section'>
                                            {notices.map((emailBox, index) => (
                                                <section className='email-container' key={"notice-" + index}>
                                                    <div className='email-box'>
                                                        <div className='text-sm font-medium text-gray-700'>Notice #{index+1}</div>
                                                        <div className="email-action-btns">
                                                            <button 
                                                                onClick={
                                                                    () => setNotices((prev) => prev.map((item, i) => {
                                                                        return i === index ? { ...item, isCollapsed: !item.isCollapsed } : item;
                                                                    }))
                                                                }
                                                            >
                                                                {emailBox.isCollapsed ?
                                                                    <img src={collapsedIcon} width={15} alt="Closed" />
                                                                :
                                                                    <img src={expandIcon} width={15} alt="Expanded" />
                                                                }
                                                            </button>
                                                            <button 
                                                                onClick={
                                                                    () => setNotices((prev) => prev.filter((_, i) => { 
                                                                        return i !== index;
                                                                    }))
                                                                }
                                                            >
                                                                <img src={deleteIcon} alt="Delete" />
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {!emailBox.isCollapsed &&
                                                        <>
                                                            <div>
                                                                <hr/>
                                                            </div>
                                                            <div className="auto-email-config flex flex-col gap-y-4">
                                                                <div className='max-w-in200'>
                                                                    <SimpleInputDropdown
                                                                        label={"Form of Communication"}
                                                                        description={
                                                                            <>
                                                                                Select the form of communication to send the notice to the assignee(s).
                                                                            </>
                                                                        }
                                                                        value={emailBox.method ? (emailBox.method.charAt(0).toUpperCase() + emailBox.method.slice(1)) : null}
                                                                        onclick={(k) => setNotices(
                                                                            (prev) => prev.map((item, idx) => {
                                                                                return idx === index ? {
                                                                                    ...item,
                                                                                    method: k
                                                                                } : item;
                                                                            })
                                                                        )}
                                                                        selectorClasses={"dd-minw-140"}
                                                                        items={{
                                                                            email: { 
                                                                                present: "Email",
                                                                                content: <span className='text-sm font-medium text-gray-700'>Email</span>,
                                                                                postItemText: <>
                                                                                    <br/>
                                                                                    <span className='text-xs text-gray-500'>Send notice over Email</span>
                                                                                </>
                                                                            },
                                                                            sms: { 
                                                                                present: "SMS",
                                                                                content: <span className='text-sm font-medium text-gray-700'>SMS</span>,
                                                                                postItemText: <>
                                                                                    <br/>
                                                                                    <span className='text-xs text-gray-500'>Coming Soon</span>
                                                                                </>,
                                                                                disabled: true
                                                                            },
                                                                        }}
                                                                        closeOnSelection={true}
                                                                    />
                                                                </div>
                                                                <div className="m-0 auto-email-when">
                                                                    <div className="simple-input-label text-1-3 flexRow">
                                                                        <div className="mt-1">When?</div> 
                                                                        <CustomTooltip 
                                                                            title={
                                                                                <div className='flexRow'>
                                                                                    <span className="text-sm font-normal ml-2">
                                                                                        The number of days, weeks, or months to send the notice to the assignee(s) before the due date.
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                            padding="12px"
                                                                        >
                                                                            <span className="block ml-1">
                                                                                <HelpOutlineIcon 
                                                                                    fontSize="small"
                                                                                /> 
                                                                            </span>
                                                                        </CustomTooltip>
                                                                    </div>
                                                                    <div className="flexRow">
                                                                        <SimpleInput
                                                                            id={"e" + index + "-when-amount"}
                                                                            name={"e" + index + "-when-amount"}
                                                                            placeholder={"Amount"}
                                                                            type={'number'}
                                                                            value={emailBox.when.amount}
                                                                            on_change={(e) => {
                                                                                setNotices((prev) => prev.map((item, i) => {
                                                                                    return i === index ? { 
                                                                                        ...item, 
                                                                                        when: {
                                                                                            ...item.when,
                                                                                            amount: e.target.value
                                                                                        }
                                                                                    } : item;
                                                                                }));
                                                                            }}
                                                                        />
                                                                        <div className="center-wrapper">
                                                                            <SimpleInputDropdown
                                                                                items={{
                                                                                    "day": {
                                                                                        present: "Day/s",
                                                                                        content: <span className='text-sm font-medium text-gray-700'>Day/s</span>,
                                                                                        postItemText: <>
                                                                                            <br/>
                                                                                            <span className='text-xs text-gray-500'>Days before the inspection due date</span>
                                                                                        </>
                                                                                    },
                                                                                    "week": { 
                                                                                        present: "Week/s",
                                                                                        content: <span className='text-sm font-medium text-gray-700'>Week/s</span>,
                                                                                        postItemText: <>
                                                                                            <br/>
                                                                                            <span className='text-xs text-gray-500'>Weeks before the inspection due date</span>
                                                                                        </>
                                                                                    },
                                                                                    "month": {
                                                                                        present: "Month/s",
                                                                                        content: <span className='text-sm font-medium text-gray-700'>Month/s</span>,
                                                                                        postItemText: <>
                                                                                            <br/>
                                                                                            <span className='text-xs text-gray-500'>Months before the inspection due date</span>
                                                                                        </>
                                                                                    },
                                                                                }}
                                                                                value={emailBox.when.type ? (emailBox.when.type.charAt(0).toUpperCase() + emailBox.when.type.slice(1)) : null}
                                                                                onclick={(k) => setNotices(
                                                                                    (prev) => prev.map((item, idx) => {
                                                                                        return idx === index ? {
                                                                                            ...item,
                                                                                            when: {
                                                                                                ...item.when,
                                                                                                type: k
                                                                                            }
                                                                                        } : item;
                                                                                    })
                                                                                )}
                                                                                closeOnSelection={true}
                                                                                placeholder="Select Interval Type"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="flexRow">
                                                                    <SimpleInputDropdown
                                                                        extraClasses={"max-w-in200"}
                                                                        extraClassesIn={"max-h-56 custom-scrollbar-1 overflow-y-auto pr-1/2"}
                                                                        label={"Email Variables"}
                                                                        description={
                                                                            <>
                                                                                Select the variable to insert in the email body.
                                                                                <br/>
                                                                                Variables are placeholders that will be replaced with the actual data when the email is sent.
                                                                                <br/>
                                                                                Once selected, click on the copy button on the right to copy the variable into your clipboard.
                                                                            </>
                                                                        }
                                                                        selectorClasses={"dd-minw-140"}
                                                                        items={variablesOptions}
                                                                        onclick={(k) => setNotices(
                                                                            (prev) => prev.map((item, idx) => {
                                                                                return idx === index ? {
                                                                                    ...item,
                                                                                    var: k
                                                                                } : item;
                                                                            })
                                                                        )}
                                                                        closeOnSelection={true}
                                                                    />
                                                                    <div className="copy-icon-container">
                                                                        <img
                                                                            className="copy-icon mt-1"
                                                                            width="15"
                                                                            height="15"
                                                                            src={copyIcon}
                                                                            alt="copy-text"
                                                                            onClick={() => {
                                                                                navigator.clipboard.writeText(`%${emailBox.var}%`);
                                                                                setNotifState({text: "Variable copied to clipboard", type: "success"});
                                                                            }}
                                                                            style={{ cursor: "pointer" }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {emailBox.method === "email" &&
                                                                    <div>
                                                                        <SimpleInput
                                                                            label="Email Subject"
                                                                            placeholder="Enter Email Subject"
                                                                            type="text"
                                                                            value={emailBox.msg.subject}
                                                                            on_change={(e) => setNotices(
                                                                                (prev) => prev.map((item, i) => {
                                                                                    return i === index ? { 
                                                                                        ...item, 
                                                                                        msg: {
                                                                                            ...item.msg,
                                                                                            subject: e.target.value
                                                                                        }
                                                                                    } : item;
                                                                                })
                                                                            )}
                                                                        />
                                                                    </div>
                                                                }

                                                                <div className="simple-input-label wrapper-text">
                                                                    <label className="simple-input-label text-1-3">
                                                                        Email Body
                                                                    </label>

                                                                    <ReactQuill 
                                                                        className="email-body min-h-60"
                                                                        theme={"snow"}
                                                                        value={emailBox.msg.body}
                                                                        onChange={(content) => setNotices(
                                                                            (prev) => prev.map((item, i) => {
                                                                                return i === index ? { 
                                                                                    ...item, 
                                                                                    msg: {
                                                                                        ...item.msg,
                                                                                        body: content
                                                                                    }
                                                                                } : item;
                                                                            })
                                                                        )}
                                                                        modules={{
                                                                            toolbar: [
                                                                                [{ 'header': [1, 2, false]}],
                                                                                [],
                                                                                ['bold', 'italic', 'underline'],
                                                                                [],
                                                                                [],
                                                                                []
                                                                            ],
                                                                            clipboard: {
                                                                                // toggle to add extra line breaks when pasting HTML:
                                                                                matchVisual: false,
                                                                            }
                                                                        }}
                                                                        formats={['header', 'bold', 'italic', 'underline']}
                                                                        bounds={'.app'}
                                                                    />

                                                                    <div className="preview-email-title h3">Email Preview</div>
                                                                    <frame className="email-preview-frame">
                                                                        <div>
                                                                            <section className="email-content">
                                                                                <div className="email-header">
                                                                                    <img src={paraIcon} />
                                                                                </div>
                                                                                <div className="main-content" dangerouslySetInnerHTML={{__html: prepEmailPreview(emailBox.msg.body)}}>
                                                                                </div>
                                                                                <div className="email-footer">
                                                                                    <p>Need help? Ask at <a href="mailto:info@paraspot.co">info@paraspot.co</a></p>
                                                                                    <div className="contact-info">
                                                                                        <span className="cname">Paraspot AI</span>
                                                                                        <div className="rd-pt-sn flexRow">
                                                                                            <a href="https://instagram.com/paraspot_ai" target="_blank"><img src="https://www.paraspot.ai/icons/email_icons/instagram.jpeg" width="16.2px" height="16.2px" alt="Instagram"/></a>
                                                                                            <a href="https://facebook.com/paraspotAI" target="_blank"><img src="https://www.paraspot.ai/icons/email_icons/facebook.jpeg" width="16.2px" height="16.2px" alt="Facebook"/></a>
                                                                                            <a href="https://linkedin.com/company/paraspot-gmbh" target="_blank"><img src="https://www.paraspot.ai/icons/email_icons/linkedin.jpeg" width="16.2px" height="16.2px" alt="LinkedIn"/></a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                        </div>
                                                                    </frame>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </section>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    
                    <div className={"fixed border-t border-slate-500 bottom-0 right-0 py-4 px-6 bg-white " + (sidebarOpen ? "left-64" : "left-8")}>
                        <div className='flex justify-between'>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: '#fff', 
                                    strokeWidth: '1px',
                                    stroke: '#F2F2F3',
                                    fontSize: '12px',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    padding: '9px 15px',
                                    color: '#0C1222',
                                    minWidth: 0,
                                    '&:hover': {
                                        backgroundColor: '#F7F7F7', 
                                    },
                                }}
                                disabled={loadingBtn}
                                onClick={() => {
                                    if (journeyId || fetchedJourneyId) {
                                        history.push(`/journey/${journeyId || fetchedJourneyId}`);
                                    } else {
                                        history.push('/journeys');
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                            <CustomButton 
                                variant="contained"
                                sx={{
                                    backgroundColor: '#4F46E5', 
                                    color: 'white',
                                    fontSize: '12px',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    padding: '9px 15px',
                                    '&:hover': { backgroundColor: '#4338CA' } 
                                }}
                                loading={loadingBtn}
                                onClick={onSubmit}
                            >
                                { isCreationMode ? "Create New Schedule" : "Update Schedule" }
                            </CustomButton>
                        </div>
                    </div>
                </div>

                {/* Snackbar Notification */}
                {Boolean(notifState) && (
                    <SnackBar
                        open={Boolean(notifState)}
                        handleClose={() => setNotifState(null)}
                        severity={notifState.type}
                        message={notifState.text}
                        duration={6000}
                    />
                )}
            </main>
        </>
    )
};

export default UpdateSchedule;