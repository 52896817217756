import React, {useState, useEffect, useRef} from "react";
import { Helmet } from "react-helmet";

//styles
import "../management/management.css";
//components
import Btn from "../../components/buttons/standard/btn";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_state";
import Notification from "../../components/side_notification/side_notification";
//utils
//assets
//constants

export default function AdminScanFramesViewer (props) {
    const sid = props.match.params.sid;
    // state based variables
    const [listOfFrames, setListOfFrames] = useState();
    const [isLoader, setLoader] = useState(true);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [notifState, setNotifState] = useState(null);
    
    //load of initial data
    useEffect(() => {
        fetchFrameIndexes();
        // setInterval(() => {
        //     fetchFrameIndexes();
        // }, 60000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchFrameIndexes = () => {
        setLoader(true);
        fetch(props.para_be + "/scan/listScanFrames?sid=" + sid + "&token=paraspotAdmin22!")
        .then(response => response.json())
        .then(response => {
            console.log("getActiveScans result:", response);
            if (response.status === 200) {
                setListOfFrames(response.result);
            } else {
                setMainEmptyState(true);
            }
            setLoader(false);
        })
        .catch(error => {
            setMainEmptyState(true);
            setNotifState({text: "Can't fetch data", type: "error"});
            setLoader(false);
        })
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    }


    return (
        <section className="mgmt-main">
            <Helmet>
                <title>Paraspot Admin | Scans Management | View Scan</title>
                <meta name="description" content="."/>
                <meta property="og:title" content="Paraspot Admin | Scans Management"/>
                <meta property="og:description" content="."/>
            </Helmet>
            <div className="flexRow" style={{flexWrap: 'wrap'}}>
                {isLoader ? <MainLoader/> : 
                    isMainEmptyState ? <EmptyState text={"No frames to fetch from scan at the moment"}  size="lg"/> : listOfFrames.map((frame_idx) => {
                        return (
                            <Btn 
                                type="secondary" 
                                text={`Frame ${frame_idx}`} 
                                onclick={() => { window.open(`https://aiv2.paraspot.de/scan/viewFrame?sid=${sid}&frame_idx=${frame_idx}`, '_blank'); }} 
                                style={{margin: '8px'}} />
                        )
                    })
                }
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </section>
    )
}