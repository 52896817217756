import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
//components
import SnackBar from '../../../../components/SnackBar';
import BasicDateTimePicker from '../../../../components/BasicDateTimePicker';
import RadioGroupCards from './components/RadioGroupCards';
//slices
import { setTabIndex, setDateDifference,setValueDateStart,setValueDateEnd } from '../../../../store/slices/dashboardSlice';
//utils
import { dateTimeFormater } from '../../../../utils/date_utils'; 
//constants
import { SeverityLevels } from '../../../../constants';


const dateDaysFormat = (days = 0) => {
    const dateObj = new Date();
    dateObj.setDate(dateObj.getDate() + days);
    return dateTimeFormater(dateObj.toISOString(), "yyyy/mm/dd");
};

const daysRange = {
    today: [dateDaysFormat(0), dateDaysFormat(0)],
    tomorrow: [dateDaysFormat(0), dateDaysFormat(1)],
    nextSevenDays: [dateDaysFormat(0), dateDaysFormat(7)],
    pastSevenDay: [dateDaysFormat(-7), dateDaysFormat(0)], 
};

const TabStyle = { fontSize: '12px', fontWeight: 700, padding: '2px' };
const formatDate = (date) => dayjs(date).format('YYYY/MM/DD');


const CardsSection = () => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null);
    const { tabIndex , dateDifference, valueDateStart, valueDateEnd } = useSelector(state => state.dashboard);

    const handleChangeTabIndex = (event, newValue) => {
        dispatch(setTabIndex(newValue))
    };

    useEffect(() => {
        const formattedStartDate = !valueDateStart ? formatDate(dayjs().subtract(7, 'day')):  formatDate(valueDateStart);
        const formattedEndDate = !valueDateEnd ? formatDate(dayjs()) : formatDate(valueDateEnd);
        
        dispatch(setValueDateStart(formattedStartDate));
        dispatch(setValueDateEnd(formattedEndDate));
        dispatch(setDateDifference([formattedStartDate, formattedEndDate])); 
    }, [valueDateStart, valueDateEnd]);

    const handleStartDateChange = (newDate) => {
        if (newDate.isAfter(valueDateEnd)) {
            setErrorMessage('Start date cannot be later than end date!');
        } else {
            dispatch(setValueDateStart(newDate));
            setErrorMessage(null);
        }
    };

    const handleEndDateChange = (newDate) => {
        if (newDate.isBefore(valueDateStart)) {
            setErrorMessage('End date cannot be earlier than start date!');
        } else {
            dispatch(setValueDateEnd(newDate));
            setErrorMessage(null);
        }
    };


    return (
        <>
            <div className="sm:w-full lg:w-6/12 xl:w-7/12 relative rounded-lg bg-white shadow">
                <div className="flex flex-1 items-center justify-between">
                    <div className="flex-1 truncate px-4 py-2">
                        <span className="text-xl font-medium text-gray-900 hover:text-gray-600">Updates</span>
                    </div>
                </div>

                {/* Tabs Section */}
                <TabContext value={tabIndex}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChangeTabIndex} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                            <Tab label="Today" value="1" sx={TabStyle} />
                            <Tab label="Tomorrow" value="2" sx={TabStyle} />
                            <Tab label="Next 7 days" value="3" sx={TabStyle} />
                            <Tab label="Past 7 days" value="4" sx={TabStyle} />
                            <Tab label="Custom" value="5" sx={TabStyle} />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                        <RadioGroupCards rangeValue={daysRange.today} />
                    </TabPanel>
                    <TabPanel value="2" sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                        <RadioGroupCards rangeValue={daysRange.tomorrow} />
                    </TabPanel>
                    <TabPanel value="3" sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                        <RadioGroupCards rangeValue={daysRange.nextSevenDays} />
                    </TabPanel>
                    <TabPanel value="4" sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                        <RadioGroupCards rangeValue={daysRange.pastSevenDay} />
                    </TabPanel>
                    <TabPanel value="5" sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                        <div className="grid grid-cols-2 gap-x-6 mb-8">
                            <BasicDateTimePicker label="Start" value={valueDateStart} setValue={handleStartDateChange} />
                            <BasicDateTimePicker label="End" value={valueDateEnd} setValue={handleEndDateChange} />
                        </div>
                        <RadioGroupCards rangeValue={dateDifference} />
                    </TabPanel>
                </TabContext>
            </div>
        
            {/* Snackbar Notification */}
            {Boolean(errorMessage) && 
                <SnackBar
                    open={Boolean(errorMessage)}
                    handleClose={() => setErrorMessage(null)}
                    severity={SeverityLevels.ERROR}
                    message={errorMessage}
                    duration={6000} />
            }
        </>
    );
};

export default CardsSection;