import React, { useState } from 'react';
import { useSelector } from 'react-redux';
//hooks
import useFetchExtendedInfo from '../../hooks/useFetchExtendedInfo';
//components
import ActionLog from './components/ActionLog';
import TenantDetails from './components/TenantDetails';
import CircularLoader from '../../../../../../../components/loaders/CircularLoader';


// Mobile version of ExpandedDetails
const ExpandedDetailsMobile = ({ extendedInfoArr, actionLogs, isExtendedInfoLoading }) => (
    <div className="mt-3">
        <div className="grid grid-cols-1 gap-2">
            <div>
                {!isExtendedInfoLoading &&
                    (extendedInfoArr?.labels || []).map((label) => {
                        return (
                            <div className="mb-2">
                                <span 
                                    className={
                                        "inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset " + 
                                        (label === 'Connected to PMS' ? "text-green-600 ring-green-500" : "text-blue-600 ring-blue-500")
                                    }
                                >
                                    {label}
                                </span>
                            </div>
                        );
                    })
                }
                {/* <div className="mb-2">
                <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500">
                    Connected to PMS
                </span>
                </div>
                <div className="mb-2">
                <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500">
                    Occupied
                </span>
                </div> */}
                {/* <h4>Reference</h4>
                <p className="text-gray-500 mb-3">21312321</p> */}
                {/* <a
                href="/"
                className="whitespace-nowrap inline-flex items-center gap-x-1 font-medium text-indigo-600 hover:text-indigo-800"
                >
                View unit
                <ArrowRightIcon />
                </a> */}
            </div>
            <div>
                {!isExtendedInfoLoading ? (
                        <TenantDetails extendedInfoArr={extendedInfoArr} />
                    ) : (
                        <CircularLoader size={36} color="secondary" />
                    )
                }
            </div>
            <div>
                <ActionLog actions={actionLogs} />
            </div>
        </div>
    </div>
);

// Desktop version of ExpandedDetails
const ExpandedDetailsDesktop = ({ columnsNumber, extendedInfoArr, actionLogs, isExtendedInfoLoading }) => (
    <tr className="bg-gray-100">
        <td colSpan={columnsNumber}>
            <div className='py-3 px-1 grid grid-cols-5 gap-4'>
                <div className='col-span-1'>
                    {!isExtendedInfoLoading &&
                        (extendedInfoArr?.labels || []).map((label) => {
                            return (
                                <div className="mb-2">
                                    <span 
                                        className={
                                            "inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset " + 
                                            (label === 'Connected to PMS' ? "text-green-600 ring-green-500" : "text-blue-600 ring-blue-500")
                                        }
                                    >
                                        {label}
                                    </span>
                                </div>
                            )
                        })
                    }
                    {/* <h4>Reference</h4>
                    <p className="text-gray-500 mb-3">21312321</p> */}
                    {/* <a
                        href="/"
                        className="whitespace-nowrap inline-flex items-center gap-x-1 font-medium text-indigo-600 hover:text-indigo-800"
                    >
                        View unit
                        <ArrowRightIcon />
                    </a> */}
                </div>
                <div className='col-span-3'>
                    {!isExtendedInfoLoading ? (
                            <TenantDetails extendedInfoArr={extendedInfoArr} />
                        ) : (
                            <CircularLoader size={36} color="secondary" />
                        )
                    }
                </div>
                <div className='col-span-1'>
                    <ActionLog actions={actionLogs} />
                </div>
            </div>
        </td>
    </tr>
);

// Main ExpandedDetails component that chooses which version to render
const ExpandedDetails = ({ pid, isOpen, columnsNumber }) => {
    console.log(pid, isOpen)
    const { extendedInfo, isExtendedInfoLoading } = useFetchExtendedInfo(pid, isOpen);
    const [actionLogs, setActionLogs] = useState([]);
    const { isMobile } = useSelector((state) => state.config);
    
    console.log("ExpandedDetails: ", extendedInfo);
    console.log("isExtendedInfoLoading: ", isExtendedInfoLoading);
    
    // Conditionally render mobile or desktop based on isMobile flag
    return (
        <>
            {isMobile ? (
                    <ExpandedDetailsMobile extendedInfoArr={extendedInfo} isExtendedInfoLoading={isExtendedInfoLoading}  actionLogs={actionLogs} />
                ) : (
                    <ExpandedDetailsDesktop columnsNumber={columnsNumber} extendedInfoArr={extendedInfo} isExtendedInfoLoading={isExtendedInfoLoading} actionLogs={actionLogs} />
                )
            }
        </>
    );
};


export default ExpandedDetails;