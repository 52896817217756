import React from 'react';
import { Rings } from 'react-loader-spinner'

const RingsLoader = ({height,width,color,radius,wrapperStyle,wrapperClass}) => {
  return (
    <Rings
        visible={true}
        height={height}
        width={width}
        color={color}
        ariaLabel="rings-loading"
        wrapperStyle={wrapperStyle}
        wrapperClass={wrapperClass}
    />
  )
}

export default RingsLoader
