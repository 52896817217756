import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//mui
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
//components
import TableSearch from '../../../../components/TableSearch';
//slices
import { openModal } from '../../../../store/slices/journeysSlice';
//constants
import { MODAL_TYPES } from '../../../../constants';
//assets


const JourneysHeader = ({totalItems}) => {
    const { data: clientLimits } = useSelector(state => state.clientLimits);
    const { isMobile } = useSelector(state => state.config);
    const history = useHistory();
    
    const dispatch = useDispatch();
    
    const handleOpenModal = (modalName) => {
        dispatch(openModal({modalName}));
    };

    const openCreateJourney = () => {
        history.push('/journeys/create');
    };

    
    return (
        <>
        <div className="flex flex-col">
            <span className="text-gray-700 text-lg font-medium">Inspection Journeys</span> 
            <span className="text-sm text-gray-500">{totalItems} Total Inspection Journeys</span>     
        </div>
        <div className="flex justify-between items-center flex-wrap gap-y-4 mb-6 mt-6"> 
            <div className="flex">
                <div>
                    <TableSearch />
                </div>
            </div>
            
            <div className={"flex" + (isMobile ? " flex-row-reverse justify-end" : "")}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}  
                    onClick={()=>{openCreateJourney()}}
                    sx={{
                        backgroundColor: '#4F46E5', 
                        color: 'white',
                        fontSize: '12px',
                        textTransform: 'none',
                        borderRadius: '8px',
                        padding: '6px 12px',
                        '&:hover': { backgroundColor: '#4338CA' } 
                    }}
                >
                    Create New Journey
                </Button>
            </div>
        </div>
        </>
    );
};

export default JourneysHeader;