import { createSlice } from '@reduxjs/toolkit';


const unitsSlice = createSlice({
    name: 'units',
    initialState: {
        modals: {
            addUnitModal: false,
            bulkUploadModal: false,
            searchFilterModal: false, 
        }
    },
    reducers: {
        openModal(state, action) {
            const { modalName } = action.payload;
            state.modals[modalName] = true;
        },
        closeModal(state, action) {
            const modalName = action.payload;
            state.modals[modalName] = false;
        },
    },
});

export const { openModal, closeModal } = unitsSlice.actions;

export default unitsSlice.reducer;