import React from 'react';
import { useDispatch } from 'react-redux';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
//assets
import FileStack from '../../../../icons/fileStack';
//constants
import { MODAL_TYPES } from '../../../../../../constants';


const OptionsMenu = React.memo(({ pid, subject }) => {
    const dispatch = useDispatch();
    const menuItems = [
        {
            label: 'All Reports',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_REPORTS_MODAL, subject, pid })),
        },
    ];

    return <TableOptionsMenu items={menuItems} />;
});

const ActionsPanelScansDone = ({ pid, subject, changeSetNotifState }) => {
    
    return (
        <>
            <OptionsMenu
                pid={pid}
                subject={subject}
            />
        </>
    );
};

export default ActionsPanelScansDone;