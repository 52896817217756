const Notifications = {
    N_NEW_COMMENT: "NC",
    N_MISSING_B_SCAN: "MBS",
    N_NEW_TASK: "NT",
    N_NEW_REPORT: "NR",
    N_TASK_STATUS_UPDATE: "TSU",
    N_ASSIGN_PERSON_TO_TASK: "APTT",
    N_TENANT_SCAN_MISSING: "TSM",
    N_NEW_UNIT: "NU",
    N_NEW_REGISTERD_USER: "NRU"
};

export {
    Notifications
}
