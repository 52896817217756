
import { postReqOptBuilder } from "./main_utils";


const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB chunks (adjust as needed)


const getFileExtFromBlob = (theBlob) => {
    const blobType = theBlob.type;
    var fileExt = "";
    if (blobType.includes("video/webm")) {
        fileExt += ".webm";
    } else if (blobType.includes("video/mp4")) {
        fileExt += ".mp4";
    } else if (blobType.includes("video/x-matroska")) {
        fileExt += ".mkv";
    } else {
        fileExt += "." + blobType.split("video/")[1].split(";")[0];
    }
    return fileExt;
}


const blobToFile = (theBlob, fileName) => {
    var finalFileName = fileName + getFileExtFromBlob(theBlob);
    return new File([theBlob], finalFileName, {type: theBlob.type.split(";")[0]});
}


const fetchPresignedUrls = async (para_be, file, file_id, retryCount=0) => {
    const data = {
        total_parts: Math.ceil(file.size / CHUNK_SIZE),
        id: file_id,
        filename: file.name,
        filetype: file.type,
    };
    const onFail = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const d = fetchPresignedUrls(para_be, file, file_id, retryCount + 1);
                resolve(d);
            }, 5000 + (retryCount * 2000));
        });
    }

    try {
        const resp = await fetch(`${para_be}/media/generate_presigned_url`, postReqOptBuilder(data));
        const jsonResponse = await resp.json();
        console.log("Presigned URL response:", jsonResponse);
        if (jsonResponse.status === 200) {
            return {
                upload_id: jsonResponse.result.upload_id,
                presigned_urls: jsonResponse.result.presign_urls,
            };
        } else {
            console.log("Failed generating presigned url");
            if (retryCount < 5) {
                return await onFail();
            } else return null;
        }
    } catch (error) {
        console.error("An error occurred:", error);
        if (retryCount < 5) {
            return await onFail();
        } else return null;
    }
};

const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const uploadFileChunk = async (
    presignedUrl, file, blob, uploadedChunks, totalChunks, 
    updatePercentage, parts, i, onRetryUpload, retryCount=0, setUploadRate=null
) => {
    if (retryCount > 0 && retryCount < 5) {
        if (retryCount === 1) onRetryUpload();
        console.log("Checking if there is a network connection");
        const testResp = await fetch("https://aiv2.paraspot.ai/", {credentials: "include"});
        if (testResp.status !== 200) {
            await delay(1000 + (retryCount * 1000));
            return await uploadFileChunk(
                presignedUrl, file, blob, uploadedChunks, totalChunks, 
                updatePercentage, parts, i, onRetryUpload, retryCount + 1, setUploadRate
            );
        }
    } else if (retryCount === 5) {
        console.log("Failed to upload chunk after 5 retries");
        return false;
    }

    try {
        const startTime = performance.now();
        const response = await fetch(presignedUrl, {
            method: "PUT",
            headers: {"content-type": file.type},
            body: blob,
        });
        const endTime = performance.now();
        if (setUploadRate) {
            try {
                // Calculate upload Rate
                const timeTaken = (endTime - startTime) / 1000; // Convert to seconds
                const uploadRate = (blob.size / timeTaken) / 1e6; // Convert to MB/s
                setUploadRate(uploadRate);
            } catch (uploadRateError) {
                console.error("Failed to calculate upload rate:", uploadRateError);
            }
        }
        
        if (response.ok) {
            uploadedChunks++;
            
            const etag = response.headers.get("etag");
            parts.push({
                ETag: etag,
                PartNumber: i + 1,
            });
            return true;
        } else {
            await delay(1000 + (retryCount * 1000));
            return await uploadFileChunk(
                presignedUrl, file, blob, uploadedChunks, totalChunks, 
                updatePercentage, parts, i, onRetryUpload, retryCount + 1, setUploadRate
            );
        }
    } catch (error) {
        console.error("Chunk upload failed:", error);
        await delay(1000 + (retryCount * 1000));
        return await uploadFileChunk(
            presignedUrl, file, blob, uploadedChunks, totalChunks, 
            updatePercentage, parts, i, onRetryUpload, retryCount + 1, setUploadRate
        );
    }
}


const uploadFile = (
    para_be, file, upload_id, presigned_urls, file_id, updatePercentage, setLoader, 
    setNotifState, onUploadComplete, onUploadFail, onRetryUpload, setUploadRate=null
) => {
    const parts = [];
    const totalChunks = presigned_urls.length;
    const results = [];
    const BATCH_SIZE = 7;

    // Function to upload the file parts in batches based on the BATCH SIZE. 
    // Returns a list of true/false based on the success of the operation
    const processBatch = async (batch, _uploadedChunks) => {
        return Promise.all(
            batch.map(async (presignedUrl, i) => {
                const chunkIndex = _uploadedChunks + i;
                const blob = file.slice(chunkIndex * CHUNK_SIZE, (chunkIndex + 1) * CHUNK_SIZE);
                const retVal = await uploadFileChunk(
                    presignedUrl, file, blob, _uploadedChunks, totalChunks, 
                    updatePercentage, parts, chunkIndex, onRetryUpload, 0, setUploadRate
                );
                if (retVal === true) updatePercentage((o) => o + Math.floor(1 / totalChunks) * 100);
                return retVal;
            })
        )
    }

    // Function to call the processBatch function in a loop until all chunks are uploaded
    const collectBatches = async () => {
        let failsCount = 0;
        let lastTimeOnline = new Date();
        let batchIdx = 0;
        while (results.length < totalChunks) {
            if (navigator.onLine) {
                lastTimeOnline = new Date();
                batchIdx += 1;
                const batch = presigned_urls.slice(results.length, results.length + BATCH_SIZE);
                const batchResults = await processBatch(batch, results.length);
                results.push(...(batchResults.filter((x) => x === true)));
                failsCount += batchResults.filter((x) => x === false).length;
                updatePercentage(Math.floor(results.length / totalChunks * 100));
    
                // If failed to upload parts of the video 10 times after all retries, stop the process
                if (failsCount > 10) {
                    console.error("Fail count passed 10. Failed to upload video");
                    results.push(...Array.from({ length: failsCount }, () => false));
                    return results;
                }
            } else {
                if (new Date() - lastTimeOnline >= 120000) {
                    setNotifState({
                        "type": "error", 
                        "msg": "Network is offline for too long. Stopping upload"
                    });
                    return [...results, false];
                } else {
                    setNotifState({
                        "type": "error", 
                        "msg": "Network is offline. Waiting for network to come back online..."
                    });
                    await delay(5000);
                }
            }
        }
        return results;
    }
    // // Upload each chunk sequentially
    // const chunkPromises = presigned_urls.map(async (presignedUrl, i) => {
    //     const blob = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
    //     // Upload a single chunk to the presigned URL
    //     return await uploadFileChunk(presignedUrl, file, blob, uploadedChunks, totalChunks, updatePercentage, parts, i, onRetryUpload);
    // });

    // // Wait for all chunk uploads to complete
    // setLoader(true);
    // Promise.all(chunkPromises)

    // Wait for all chunk uploads to complete
    collectBatches()
    .then((res) => {
        const allTrue = res.every((x) => x === true);
        if (!allTrue) {
            const failedOnes = res.map((x, idx) => x !== true ? idx : null).filter((x) => x !== null);
            setLoader(false);
            onUploadFail();
            console.error("Failed to upload video");
            console.error("Failed on chunks:", failedOnes);
            setNotifState({"type": "error", "msg": "Failed to upload parts of the video"});
            return;
        }
        // After all chunks are uploaded, send a request to finalize the upload
        fetch(`${para_be}/media/upload_video`, postReqOptBuilder({uploadId: upload_id, parts: parts, id: file_id, filename: file.name}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                try {
                    fetch(`${para_be}/scan/scanStarted`, postReqOptBuilder(
                        file_id.includes('_') ?
                            {pid: file_id.split("_")[1], scanType: 'checkout'} :
                            {pid: file_id, scanType: 'baseline'}
                    ))
                } catch (error) {
                    console.error("An error occurred while sending scanStarted notification:", error);
                }
                if (!file_id.includes('_')) {
                    try {
                        fetch(`${para_be}/scan/building_baseline`, postReqOptBuilder(
                                {uid: '', cid: '', pid: file_id}, 
                                true, {Authorization: document.cookie.split("AuthToken=")[1].split(";")[0]}
                        ))
                        .then(response2 => response2.json())
                        .then(response2 => {
                            if (setNotifState) {
                                if (response2.status === 200) {
                                    setNotifState({"type": "success", "msg": "Updated unit status"});
                                } else {
                                    setNotifState({"type": "error", "msg": "Failed to update unit status"});
                                }
                            }
                        })
                        .catch((x) => {
                            if (setNotifState) setNotifState({"type": "error", "msg": "Failed to update unit status"});
                        });
                    } catch (error) {
                        console.error("An error occurred while updating baseline status:", error);
                    }
                }
                
                setLoader(false);
                if (onUploadComplete) onUploadComplete();
            } else {
                setLoader(false);
                onUploadFail();
                console.error("Failed to upload video");
            }
        })
        .catch((error) => {
            // Handle any errors that occurred during the final steps
            setLoader(false);
            console.error("An error occurred:", error);
            onUploadFail();
        });
    })
    .catch((error) => {
        // Handle any errors that occurred during the final steps
        setLoader(false);
        console.error("An error occurred:", error);
        onUploadFail();
    });
};


export { blobToFile, fetchPresignedUrls, uploadFile, getFileExtFromBlob };
