import React, { useState } from 'react';
import { Button, Typography, Checkbox } from '@mui/material';
//components
import BaseModal from '../../../../../Modal';


const ApproveModal = ({ open, handleClose, onApprove }) => {
    // Checkbox state
    const [checked, setChecked] = useState(false);
    
    // Handle checkbox state change
    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };
    
    return (
        <BaseModal 
            open={open}
            onClose={handleClose}
            minWidth={400}
            maxWidth={400}
        >
            <>
                {/* Header */}
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Approve Submission
                </Typography>
                {/* Small Text */}
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Are you sure you want to approve this submission?
                </Typography>
                {/* Text and Checkbox in Flex Layout */}
                <div className="flex items-center mt-2">
                    <div className="mr-2">Send Tenant a Message of Approval</div>
                    <Checkbox 
                        checked={checked} 
                        onChange={handleCheckboxChange} 
                        color="primary" 
                    />
                </div>
                {/* Buttons */}
                <div className="flex justify-end gap-4 mt-4">
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={ () => onApprove("approve", null, checked)}>
                        Approve
                    </Button>
                </div>
            </>
        </BaseModal>
    );
};

export default ApproveModal;