

export const scanCompletionChartColorsConfig = [
  // TODO - change the colors here
  '#3366CC', // Move-Outs
  '#8C9EFF', // Move-Ins
  '#99CCFF', // New Scans
  '#D9E8FB', // New Reports
];


export const scanCompletionChartPieOptions = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 20, 
      bottom: 20,   
    },
  },
  plugins: {
    responsive: true,
    maintainAspectRatio: true,
    offset: false,
    legend: {
      position: 'right',
      // align: 'start',
      labels: {
        padding: 15,
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          return chart.data.labels.map((label, i) => ({
            text: label,
            fillStyle: datasets[0].backgroundColor[i],
            strokeStyle: '#ffffff',
            lineWidth: 6,
            index: i,
          }));
        },
        font: {
          size: 12,
          color: '#000000',
        },
      },
    },
    datalabels: {
      color: '#ffffff', 
      display: true, 
      align: 'end', 
      anchor: 'end', 
      offset: -20, 
      formatter: (value, context) => {
        const total = context.dataset.data.reduce((acc, currentValue) => acc + currentValue, 0);
        const percentage = ((value / total) * 100).toFixed(0); 
        return `${percentage}%`; 
      },
      backgroundColor: (context) => context.dataset.backgroundColor[context.dataIndex],
      borderRadius: 50,
      borderColor: '#ffffff', 
      borderWidth: 1, 
      padding: 8,
      font: {
        size: 11, 
      },
    },
  },
};


