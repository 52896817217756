import {io} from "socket.io-client";

//style
//components
//containers
//assets
const AI_SERVER = window.location.origin.includes("webv2.dev") ? "https://ai.dev.paraspot.ai" : "https://aiv2.paraspot.ai";

export default class VideoStreamSocket {

    static initSocket(namespace) {
        console.log("[i] Init socket");
        return io(
            `${AI_SERVER}/${namespace}`,
            {
                'transports': ["websocket"],
                // 'rememberUpgrade': true
            }
        );
    };

    static async initMultiSockets(namespace, para_be, grouping=null, fallbackOption=false) {
        return fetch(para_be + "/scan/getScanSockets")
        .then(response => response.json())
        .then(response => {
            let sockets = [];
            if (fallbackOption) {
                console.log("[i] Init socket:", AI_SERVER);
                sockets.push(io(
                    `${AI_SERVER}/${namespace}`,
                    {
                        'transports': ["websocket"],
                        reconnectionDelay: 2000,
                    }
                ));
                return sockets;
            }

            if (grouping !== null) {
                const { reverse, group_size } = grouping;
                let iter = response.result.slice(0);
                if (reverse) iter.reverse();
                for (let ep of iter) {
                    if (sockets.length === group_size) {
                        break;
                    }
                    console.log("[i] Init socket:", ep);
                    sockets.push(io(
                        `${ep}/${namespace}`,
                        {
                            'transports': ["websocket"],
                            reconnectionDelay: 2000,
                            // 'rememberUpgrade': true
                        }
                    ));
                }
            } else {
                for (let ep of response.result) {
                    console.log("[i] Init socket:", ep);
                    sockets.push(io(
                        `${ep}/${namespace}`,
                        {
                            'transports': ["websocket"],
                            reconnectionDelay: 2000,
                            // 'rememberUpgrade': true
                        }
                    ));
                }
            }
            return sockets;
        });
    }

    static initSocketEventListeners(socket, sock_ref,
        // static initSocketEventListeners(socket,
        // Whether Should activate event listeners
        onSocketConnect, onSocketDisconnect, onFrameReceived, onFrameDone, onScanResults, onFramesResendReq,
        // Callbacks
        callbackOnConnect, callbackOnDisconnect, callbackOnFrameReceived, callbackOnFrameDone, callbackOnScanResults, callbackOnFramesResendReq) {
        if (socket !== null) {
            console.log("[i] socket is not null");
            if (onSocketConnect || callbackOnConnect) socket.on('connect', () => {
                console.log("[i] Connected to socket...!", socket.id);
                if (callbackOnConnect) callbackOnConnect(sock_ref);
                // if (callbackOnConnect) callbackOnConnect();
            });
            if (onSocketDisconnect || callbackOnDisconnect) socket.on('disconnect', () => {
                console.log("[i] Connection to socket closed...", socket.id);
                if (callbackOnDisconnect) callbackOnDisconnect(sock_ref);
                // if (callbackOnDisconnect) callbackOnDisconnect();
            });
            if (onFrameReceived || callbackOnFrameReceived) socket.on('received_frame', (frame_idx) => {
                console.log("[i] Backend received frame idx. " + frame_idx + "!");
                if (callbackOnFrameReceived) callbackOnFrameReceived(frame_idx);
            });
            if (onFrameDone || callbackOnFrameDone) socket.on('frame_done', (frame_idx) => {
                console.log("[i] Backend finished processing frame idx. " + frame_idx + "!!!");
                if (callbackOnFrameDone) callbackOnFrameDone(frame_idx);
            });
            if (onScanResults || callbackOnScanResults) socket.on('scan_results', (results) => {
                console.log("[i] Got scan results!");
                console.log(results);
                results = JSON.parse(results);
                if (callbackOnScanResults) callbackOnScanResults(results);
            });
            if (onFramesResendReq || callbackOnFramesResendReq) socket.on('resend_frames_request', (sid, frames_to_resend) => {
                console.log("Received a request to resend the following frames:", frames_to_resend);
                let ftr_list = JSON.parse(frames_to_resend);
                if (callbackOnFramesResendReq) callbackOnFramesResendReq(sid, ftr_list);
            })
            //TODO prepare socket event for accepting instructions from the server
        } else console.log("[!] socket is null")
    };
}
