import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
//assets
import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import MaterialSymbols from '../../../../icons/materialSymbols';
// import EyeIcon from '../../../../icons/eyeIcon';
//constants
import { MODAL_TYPES } from '../../../../../../constants';


const OptionsMenu = React.memo(({ dispatch, jid }) => {
    const menuItems = [
        {
            label: 'Delete Journey',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.DELETE_JOURNEY_MODAL, jid })),
        },
    ];
    
    return (
        <TableOptionsMenu items={menuItems} />
    );
});

const ActionsPanelJourneys = ({ jid, journeyName }) => {
    const dispatch = useDispatch();
    
    return (
        <>
            <Link to={`/journey/${jid}`} className="cursor-pointer" title={"View Journey"}>
                <EyeIcon stroke="#0C1222" width="18px" height="18px" />
            </Link>
            <span 
                className='cursor-pointer' 
                title="Edit Journey"
                onClick={() => dispatch(openModal({ modalName: MODAL_TYPES.EDIT_JOURNEY_MODAL, jid, journeyName }))}
            >
                <PencilSquareIcon stroke="#0C1222" width="18px" height="18px" />
            </span>
            <OptionsMenu jid={jid} dispatch={dispatch} />
        </>
    )
}

export default ActionsPanelJourneys;