import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
import { SimpleDropdownContainer } from '../../../../../dropdowns/simple_dropdown/simple_dropdown';
//assets
import DocsIcon from '../../../../icons/docsIcon';
import PlayIcon from '../../../../icons/playIcon';
import PlainIcon from '../../../../icons/plainIcon';
import EyeIcon from '../../../../icons/eyeIcon';
import UploadIcon from '../../../../icons/uploadIcon';
import FileStack from '../../../../icons/fileStack';
import MaterialSymbols from '../../../../icons/materialSymbols';
import BuildingIcon from '../../../../icons/buildingIcon';
import ScanText from '../../../../icons/scanText';
//utils
import { addTextToClipboard, postReqOptBuilder } from '../../../../../../utils/main_utils';
//constants
import { MODAL_TYPES, SeverityLevels } from '../../../../../../constants';
import { para_be } from '../../../../../../config';


const OptionsMenu = React.memo(({ pid, subject, ext_ref, baseline_date }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const menuItems = [
        ...(baseline_date ? [
                {
                    label: 'Update Baseline Scan',
                    icon: ScanText,
                    onClick: () => history.push(`/${pid}/baseline-scan`),
                }
            ] : [
                {
                    label: 'Create Baseline Scan',
                    icon: BuildingIcon,
                    onClick: () => history.push(`/${pid}/baseline-scan`),
                }
            ]
        ),
        {
            label: 'Upload Baseline',
            icon: UploadIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPLOADER_MODAL, pid })),
        },
        {
            label: 'All Reports',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_REPORTS_MODAL, subject, pid })),
        },
        {
            label: 'All Tenancies',
            icon: FileStack,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.ALL_TENANCIES_MODAL, subject, pid })),
        },
        {
            label: 'Edit Unit Address',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPDATE_UNIT_ADDRESS_MODAL, subject, pid, ext_ref })),
        },
        {
            label: 'Edit Reference ID',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPDATE_UNIT_REFERENCE_MODAL, subject, pid, ext_ref })),
        },
        {
            label: 'Remove Unit',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.DELETE_UNIT_MODAL, subject, pid, ext_ref })),
        },
    ];

    return <TableOptionsMenu items={menuItems} />;
});

const ActionsPanelUnits = ({ pid, subject, latest_report_id, changeSetNotifState, scan_id, ext_ref, baseline_date }) => {
    const dispatch = useDispatch();
    const [showShareLinkDD, setShowShareLinkDD] = React.useState(false);
    const [shareLink, setShareLink] = React.useState(scan_id);
    const [loadingShareLink, setLoadingShareLink] = React.useState(false);
    
    
    const generateURL = (e) => {
        e.stopPropagation();
        setLoadingShareLink(true);
        
        fetch(para_be + '/units/generate_scan_link', postReqOptBuilder({"pid": pid}))
        .then(response => response.json())
        .then(response => {
            if(response.status === 200) {
                setShareLink(response.result);
                setLoadingShareLink(false);
            } else {
                changeSetNotifState({text: response.msg, type: SeverityLevels.ERROR});
                setLoadingShareLink(false);
            }
        })
        .catch(error => {
            changeSetNotifState({text: "Something went wrong", type: SeverityLevels.ERROR});
            console.log(error);
        });
        
        return false;
    };

    const handleCopy = (e, inspection_type=null) => {
        e.stopPropagation();
        
        // const textToCopy = `${window.location.origin}/checkout/${pid}`;
        const textToCopy = window.location.origin + "/inspection/" + (inspection_type === null ? 'checkout' : inspection_type) + "/" + shareLink;
        const copyStatus = addTextToClipboard(textToCopy);
        if (copyStatus) {
            changeSetNotifState({text: "Link copied successfully!", type: SeverityLevels.SUCCESS});
        } else {
            changeSetNotifState({text: "Failed to copy.", type: SeverityLevels.ERROR});
        }
    
        setShowShareLinkDD(false);
        setTimeout(() => changeSetNotifState(null), 3000);
    };


    return (
        <>
            {baseline_date ? (
                    <>
                        <span
                            className={`cursor-pointer`}
                            onClick={(e) => {
                            e.stopPropagation();
                            dispatch(openModal({ modalName: 'videoModal', subject, pid }));
                            }}
                            title={"View Baseline Scan"}
                        >
                            <PlayIcon />
                        </span>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <PlayIcon disabled />
                        </span>
                    </>
                )
            }
            {latest_report_id ? (
                    <>
                        <Link to={`/report/${latest_report_id}`} className="cursor-pointer" title={"View Report"}>
                            <DocsIcon />
                        </Link>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <DocsIcon disabled />
                        </span>
                    </>
                )
            }

            <SimpleDropdownContainer
                extraClasses="relative flexRow"
                extraClassesIn="min-w-28 right-0 top-7"
                showDropdown={showShareLinkDD}
                borderedItems={true}
                items={loadingShareLink ? {
                        'loading': {
                            'present': 'Loading...',
                            'onclick': (k, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }
                    } :
                    shareLink ? {
                        're_gen_link': {
                            'present': 'Re-Generate Link',
                            'onclick': (k, e) => {
                                e.preventDefault();
                                generateURL(e);
                            }
                        },
                        'check_in': {
                            'present': 'Check-In',
                            'onclick': (k, e) => {
                                e.preventDefault();
                                handleCopy(e, 'checkin');
                            }
                        },
                        'check_out': {
                            'present': 'Check-Out',
                            'onclick': (k, e) => {
                                e.preventDefault();
                                handleCopy(e, 'checkout');
                            }
                        }
                    } :
                    {
                        'gen_link': {
                            'present': 'Generate Link',
                            'onclick': (k, e) => {
                                e.preventDefault();
                                generateURL(e);
                            }
                        }
                    }
                }
            >
                {/* <span className="cursor-pointer" onClick={(e) => handleCopy(e)}> */}
                <span className="cursor-pointer" onClick={(e) => { e.stopPropagation(); setShowShareLinkDD(!showShareLinkDD); }} title={"Share"}>
                    <PlainIcon />
                </span>
            </SimpleDropdownContainer>

            <OptionsMenu
                pid={pid}
                subject={subject}
                ext_ref={ext_ref}
                baseline_date={baseline_date}
            />
        </>
    );
};

export default ActionsPanelUnits;