import {useState} from 'react';
//components
import InputError from '../../errorMessages/inputError/inputError';
//styles
import "../input.css";
import "./input.css";
//assets
import Eye from "../../../assets/icons/eye.svg";
import CrossEye from "../../../assets/icons/cross-eye.svg";

export default function HideTextInput (props) {
    const [showText, setShowText] = useState(null);
    return (
        <div className="simple-input-container">
            {props.label ? <div className="simple-input-label text-1-3">{props.label}</div> : ""}
            <div className="simple-input-wrapper">
                <input 
                    id={props.id}
                    name={props.name}
                    placeholder={props.si_input_text ? " " : props.placeholder}
                    type={showText ? "text" : "password"}
                    className={"text-0 text-no-resp" + (props.classes ? ` ${props.classes}` : "") + (props.inputError ? " input-err" : "")}
                />
                
                {props.si_input_text ? <div className="si-input-title">{props.si_input_text}</div> : ""}
                
                <div className="input-icon" onClick={()=>setShowText(!showText)}>
                    <img src={showText ? CrossEye : Eye} alt="eye"/>
                </div>
            </div>
            
            {props.inputError ? (typeof props.inputError === "string" ?
                    <InputError text={props.inputError} type={"error"}/> : 
                    <InputError text={props.inputError.text} type={props.inputError.type}/>
                ) : ""
            }
        </div>
    )
}