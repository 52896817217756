import React, {useState} from "react";
import { Link } from "react-router-dom";

import "./back.css";

export default function BackBtn (props) {

    const children = () => {
        return (
            <>
                <div className="back-icon">
                    <div/>
                </div>
                <div>Back</div>
            </>
        )
    }

    return (props.linkType ? 
        <Link className="back-container" to={props.to ? props.to : ""}>
            {children()}
        </Link>
        :
        <div className="back-container" onClick={() => props.clickFunction()}>
            {children()}
        </div>
    )
}