import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add'; 
import LockIcon from '@mui/icons-material/Lock';
//components
import TableSearch from '../../../../components/TableSearch';
//slices
import { openModal } from '../../../../store/slices/unitSlice';
//constants
import { MODAL_TYPES } from '../../../../constants';
//assets
import UnitSettingIcon from './components/UnitsOptionsMenu/icons/unitSettingIcon';
import CustomTooltip from '../../../../components/CustomTooltip/CustomTooltip';
import AlertIcon from '../../../../components/Tables/icons/alertIcon';


const UnitsHeader = ({totalItems}) => {
    const { data: clientLimits } = useSelector(state => state.clientLimits);
    const { isMobile } = useSelector(state => state.config);
    
    const dispatch = useDispatch();
    
    const handleOpenModal = (modalName) => {
        dispatch(openModal({modalName}));
    };

    
    return (
        <>
        <div className="flex flex-col">
            <span className="text-gray-700 text-lg font-medium">Units</span> 
            <span className="text-sm text-gray-500">{totalItems} Total Units</span>     
        </div>
        <div className="flex justify-between items-center flex-wrap gap-y-4 mb-6 mt-6"> 
            <div className="flex">
                <div>
                    <TableSearch  />
                </div>
                <div className="ml-2">
                    <Button
                        onClick={()=>{handleOpenModal(MODAL_TYPES.SEARCH_FILTER_UPLOAD)}}
                        sx={{
                            backgroundColor: '#F2F2F3', 
                            padding: '10px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            color: '#333',              
                            minWidth: 0,                
                            '&:hover': {
                                backgroundColor: '#E0E0E1', 
                            },
                        }}
                    >
                        <UnitSettingIcon 
                            aria-hidden="true" 
                            className="h-5 w-5"  
                        />
                    </Button>
                </div>
            </div>
            
            <div className={"flex" + (isMobile ? " flex-row-reverse justify-end" : "")}>
                <div className={isMobile ? "ml-2" : "mr-2"}>
                    {clientLimits?.units >= totalItems ?
                        <CustomTooltip
                            title={
                                <div className='flexRow'>
                                    <AlertIcon className="mt-px" innerFill='#F8803D' />
                                    <span className="text-sm ml-2">You've reached your units limit for this plan</span>
                                </div>
                            } 
                        >
                            <div>
                                <Button
                                    variant="contained"
                                    startIcon={<LockIcon sx={{ width: 20, height: 20 }} />}  
                                    onClick={()=>{handleOpenModal(MODAL_TYPES.BULK_UPLOAD)}}
                                    sx={{
                                        backgroundColor: '#4F46E5',
                                        color: 'white',
                                        fontSize: '12px',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        padding: '6px 12px',
                                        '&:hover': { backgroundColor: '#4338CA' } 
                                    }}
                                    disabled={true}
                                >
                                    Bulk Upload
                                </Button>
                            </div>
                        </CustomTooltip> :
                        <Button
                            variant="contained"
                            startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}  
                            onClick={()=>{handleOpenModal(MODAL_TYPES.BULK_UPLOAD)}}
                            sx={{
                                backgroundColor: '#4F46E5',
                                color: 'white',
                                fontSize: '12px',
                                textTransform: 'none',
                                borderRadius: '8px',
                                padding: '6px 12px',
                                '&:hover': { backgroundColor: '#4338CA' } 
                            }}
                        >
                            Bulk Upload
                        </Button>
                    }
                </div>
                {clientLimits?.units >= totalItems ?
                    <CustomTooltip
                        title={
                            <div className='flexRow'>
                                <AlertIcon className="mt-px" innerFill='#F8803D' />
                                <span className="text-sm ml-2">You've reached your units limit for this plan</span>
                            </div>
                        } 
                    >
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<LockIcon sx={{ width: 20, height: 20 }} />}  
                                onClick={()=>{handleOpenModal(MODAL_TYPES.ADD_UNIT_MODAL)}}
                                sx={{
                                    backgroundColor: '#4F46E5', 
                                    color: 'white',
                                    fontSize: '12px',
                                    textTransform: 'none',
                                    borderRadius: '8px',
                                    padding: '6px 12px',
                                    '&:hover': { backgroundColor: '#4338CA' } 
                                }}
                                disabled={clientLimits?.units >= totalItems}
                            >
                                Add Unit
                            </Button>
                        </div>
                    </CustomTooltip> :
                    <Button
                        variant="contained"
                        startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}  
                        onClick={()=>{handleOpenModal(MODAL_TYPES.ADD_UNIT_MODAL)}}
                        sx={{
                            backgroundColor: '#4F46E5', 
                            color: 'white',
                            fontSize: '12px',
                            textTransform: 'none',
                            borderRadius: '8px',
                            padding: '6px 12px',
                            '&:hover': { backgroundColor: '#4338CA' } 
                        }}
                        disabled={clientLimits?.units >= totalItems}
                    >
                        Add Unit
                    </Button>

                }
            </div>
        </div>
        </>
    );
};

export default UnitsHeader;