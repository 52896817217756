import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


const CircularLoader= ({size,color}) => {
    return (
        <CircularProgress size={size} color={color} />
    )
}

export default CircularLoader
