import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//stripe
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
//mui
import Button from '@mui/material/Button';
//components
import CircularLoader from '../../../../../../../components/loaders/CircularLoader';
import BaseModal from '../../../../../../../components/Modal';
//slices
import { setPaymentMethod } from '../../../../../../../store/slices/billingSlice';
//constants
import { SeverityLevels } from '../../../../../../../constants';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const AddPaymentMethod = ({ open, handleClose, setNotification }) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const { customerData } = useSelector(state => state.customer);
    const { loading: billingLoading } = useSelector(state => state.billing);

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            console.log("Stripe or Elements not loaded");
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Something went wrong while adding payment method'});
            return;
        } else if (!customerData) {
            console.log("Customer data not loaded");
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Something went wrong while adding payment method'});
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Failed to add payment method.\nError: ' + error.message});
            return;
        }

        try {
            await dispatch(setPaymentMethod({ paymentMethodId: paymentMethod.id, })).unwrap();
            setNotification({open: true, severity: SeverityLevels.SUCCESS, message: 'Payment method added successfully!'});
            handleClose();
        } catch (err) {
            console.error('Error adding payment method:', err);
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Something went wrong while adding payment method'});
        }
    };
    const loading = billingLoading;

    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            maxWidth={800}
        >
            <div className="p-6 max-w-md mx-auto bg-white">
                <div className="flex flex-col space-y-6">
                    <div className="mb-10 mt-10 bg-white">
                        <CardElement />
                        <div className="mt-8 mr-8">
                            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
                                {loading && <CircularLoader size={24} />}
                                Submit Billing Details
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </BaseModal>
    );
};

const AddPaymentMethodsWrapper = (props) => (
    <Elements stripe={stripePromise}>
        <AddPaymentMethod {...props} />
    </Elements>
);


export default AddPaymentMethodsWrapper;
