export const SeverityLevels = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
    INFO: "info"
};


export const CustomerAction = {
    CREATE_CUSTOMER: "create_customer",
    LOG_IN: "Log_In"
};


export const CategoryFilter = {
    MOVE_IN: 'move-in',
    MOVE_OUT: 'move-out',
    REPORTS: 'reports',
    SCANS: 'scans',
    UNITS: 'units',
    SCANS_DONE: 'scans_done',
    INSPECTION_JOURNEYS: 'journeys',
    INSPECTION_SCHEDULES: 'inspection_schedules',
};

export const MODAL_TYPES = {
    VIDEO_MODAL: 'videoModal',
    UPLOADER_MODAL: 'uploaderModal',
    ALL_REPORTS_MODAL: 'allReportsModal',
    ALL_TENANCIES_MODAL: 'allTenanciesModal',
    UPDATE_UNIT_ADDRESS_MODAL: 'updateUnitsAddressModal',
    UPDATE_UNIT_REFERENCE_MODAL: 'updateUnitReferenceModal',
    DELETE_UNIT_MODAL: 'deleteUnitModal',
    APPROVE_MODAL: 'approveModal',
    REJECT_MODAL: 'rejectModal',
    ADD_UNIT_MODAL: 'addUnitModal',
    BULK_UPLOAD: 'bulkUploadModal',
    SEARCH_FILTER_UPLOAD: 'searchFilterModal',
    DELETE_JOURNEY_MODAL: 'deleteJourneyModal',
    EDIT_JOURNEY_MODAL: 'editJourneyModal',
    CREATE_SCHEDULE_MODAL: 'createScheduleModal',
    EDIT_SCHEDULE_MODAL: 'editScheduleModal',
    DELETE_SCHEDULE_MODAL: 'deleteScheduleModal',
};

export const INSPECTION_TYPES = {
    BASELINE: 'baseline',
    MOVE_IN: 'checkin',
    MOVE_OUT: 'checkout',
}

export const DATE_FORMAT = navigator.language === "en-US" ? 'mm/dd/yyyy' : "dd/mm/yyyy";