import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//components
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
//assets
import { EyeIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import MaterialSymbols from '../../../../icons/materialSymbols';
// import EyeIcon from '../../../../icons/eyeIcon';
//constants
import { MODAL_TYPES } from '../../../../../../constants';


const OptionsMenu = React.memo(({ dispatch, sid }) => {
    const menuItems = [
        {
            label: 'Delete Schedule',
            icon: MaterialSymbols,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.DELETE_SCHEDULE_MODAL, sid })),
        },
    ];
    
    return (
        <TableOptionsMenu items={menuItems} />
    );
});

const ActionsPanelSchedules = ({ sid, assigned_to, due_range }) => {
    const dispatch = useDispatch();
    
    return (
        <>
            {/* <Link to={`/journey/${sid}`} className="cursor-pointer" title={"View Journey"}>
                <EyeIcon stroke="#0C1222" width="18px" height="18px" />
            </Link> */}
            <span className='cursor-default' title="View Stats - Coming Soon" style={{opacity: '60%'}}>
                <EyeIcon width="18px" height="18px" disabled />
            </span>
            <Link className="cursor-pointer" title="Edit Schedule" to={`/schedule/edit/${sid}`}>
                <PencilSquareIcon stroke="#0C1222" width="18px" height="18px" />
            </Link>
            {/* <span 
                className='cursor-pointer' 
                title="Edit Schedule"
                onClick={() => dispatch(openModal({ modalName: MODAL_TYPES.EDIT_SCHEDULE_MODAL, sid, assigned_to, due_range }))}
            >
                <PencilSquareIcon stroke="#0C1222" width="18px" height="18px" />
            </span> */}
            <OptionsMenu sid={sid} dispatch={dispatch} />
        </>
    )
}

export default ActionsPanelSchedules;