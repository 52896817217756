import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
//components
import { TextField, Box } from '@mui/material';
import CustomButton from '../../../../../../components/buttons/CustomButton';
import SnackBar from '../../../../../../components/SnackBar';
//slices
import { clearError, setCustomer } from '../../../../../../store/slices/customerSlice';
//constants
import { SeverityLevels } from '../../../../../../constants';

const CustomerUpdate = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { data, loading: customerLoading, error: customerError } = useSelector(state => state.customer);
    const { customer_data } = data;

    const [formData, setFormData] = useState({
        name: customer_data?.name,
        email: customer_data?.email,
        description: customer_data?.description ?? '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Form Data:', formData);
        try {
            await dispatch(setCustomer(formData)).unwrap();
            history.push(`/settings/payments/customerInfo`);
        } catch (err) {
            console.error('Error setting billing details:', err);
        }
    };

    useEffect(() => {
        if (customerError) {
            const timer = setTimeout(() => {
                dispatch(clearError());
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [customerError, dispatch]);

    return (
        <>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    maxWidth: 400,
                    margin: 'auto',
                }}
            >
                <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">Customer Update</h2>
                </div>

                <TextField label="Name" name="name" value={formData.name} onChange={handleChange} variant="outlined" fullWidth />
                <TextField label="Email" name="email" value={formData.email} onChange={handleChange} variant="outlined" fullWidth />
                <TextField 
                    label="Description" 
                    name="description" 
                    value={formData.description} 
                    onChange={handleChange}
                    variant="outlined"
                    multiline 
                    rows={4}
                    fullWidth />
                <CustomButton 
                    variant="contained" 
                    color="primary" 
                    onClick={handleSubmit} 
                    disabled={customerLoading} 
                    loading={customerLoading} 
                    autoFocus
                >
                    Submit
                </CustomButton>
            </Box>
            {/* Notification Component */}
            {Boolean(customerError) &&
                <SnackBar
                    open={Boolean(customerError)}
                    handleClose={() => { dispatch(clearError()); }}
                    severity={customerError ? SeverityLevels.ERROR : SeverityLevels.SUCCESS}
                    message={customerError || 'Billing details added successfully!'}
                    duration={6000}
                />
            }
        </>
    );
};

export default CustomerUpdate;
