import { postReqOptBuilder } from "./main_utils";

const setCookie = (para_be, key, value) => fetch(para_be + '/handlers/cookies/set-cookie', postReqOptBuilder({'key': key, 'value': value})).catch(error => console.log(error));
const getCookie = (para_be, key) => fetch(para_be + '/handlers/cookies/get-cookie?cookie=' + key, {credentials: "include"}).then(response => response.json()).catch(error => console.log(error));
const removeCookie = (para_be, key) => fetch(para_be + '/handlers/cookies/remove-cookie', postReqOptBuilder({'key': key})).catch(error => console.log(error));


export {
    setCookie,
    getCookie,
    removeCookie
}