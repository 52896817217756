import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
//slices
import { fetchData, setPage, setSearchValue } from '../../store/slices/searchSlice';
//hooks
import useDebounce from '../../hooks/useDebounce';


const SearchInput = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 700);
    const dispatch = useDispatch();

    const { page, pageSize, filters, categoryFilter, sortColumn, sortDirection } = useSelector(state => state.table);
    const previousSearchTerm = useRef('');
    
    const isFirstSearchEffect = useRef(true);
    const isFirstCategoryEffect = useRef(true);

    // Handle search term changes
    useEffect(() => {
        if (isFirstSearchEffect.current) {
            isFirstSearchEffect.current = false;
            return;
        }
        
        previousSearchTerm.current = searchTerm;
        dispatch(fetchData({
            lowerLimit: (1 - 1) * pageSize,
            upperLimit: 1 * pageSize,
            filters,
            searchValue: debouncedSearchTerm,
            sortDirection,
            sortColumn
        }));
        dispatch(setPage(1));
        dispatch(setSearchValue(debouncedSearchTerm));
    }, [debouncedSearchTerm, dispatch]);
    
    // reset search input
    useEffect(() => {
        console.log("Resetting search input");
        if (isFirstCategoryEffect.current) {
            isFirstCategoryEffect.current = false;
            return;
        }
        
        setSearchTerm('');
        previousSearchTerm.current = '';
        dispatch(setPage(1));
        dispatch(setSearchValue(""));
    }, [categoryFilter, dispatch]);
    
    // Search input handler
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    
    
    return (
        <>
            <div className="flex h-10 w-full max-w-sm">
                <label htmlFor="search-field" className="sr-only">
                    Search
                </label>
                <div className="relative bg-gray-100 lg:w-64 rounded-lg w-full">
                    <MagnifyingGlassIcon aria-hidden="true" className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500 ml-2" />
                    <input
                        id="search-field"
                        name="search"
                        type="search"
                        placeholder="Search anything here"
                        className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 focus:ring-0 sm:text-sm"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
        </>
    );
};

export default SearchInput;