import axios from 'axios';
import { isMockError, getMockResponse, getMockError } from './mock-axios';


const apiResponses = {
    OK: 200,
    BAD_REQUEST: 400,
    UN_AUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    NOT_ALLOWED: 405,
    CONFLICT: 409,
    ERROR: 500,
    UN_SUPPORTED_MEDIA_TYPE: 415,
    REQUEST_CANCELLED: 'REQUEST_CANCELLED',
};

const mockingEnabled = false;

const axiosCustomInstance = axios.create();

axiosCustomInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor
axiosCustomInstance.interceptors.request.use((config) => {
    if (!config) config = {};
    if (!config.headers) config.headers = {};
    
    config.headers['x-auth-token'] = localStorage.getItem('token') || '';
    
    if (mockingEnabled) {
        return getMockError(config);
    }

    return config;
}, (error) => Promise.reject(error));

// Add a response interceptor
axiosCustomInstance.interceptors.response.use((response) => response, (error) => {
    if (isMockError(error)) {
        return getMockResponse(error);
    }

    const { status, data } = error?.response || {};
    const err = error;

    if (status === apiResponses.ERROR) {
        if (!data) {
            err.response.data = 'Internal Server Error';
        }
    }

    return Promise.reject(err);
});

/**
 * Assign a common header to all outgoing requests being made;
 * @param {string} name - header name/key
 * @param {string} value  - header value
*/

export const setDefaultCommonHeaders = (name, value) => {
    axiosCustomInstance.defaults.headers.common[name] = value;
};
export default axiosCustomInstance;