import React from "react";
//styles
import "./main_loader.css";

export default function MainLoader(props) {

    return (
        <section className="loader-container">
            <div>
                <div>
                    <span className="l-one l-h6"></span>
                    <span className="l-two l-h3"></span>
                </div>
            </div>
            <div>
                <div>
                    <span className="l-one l-h1"></span>
                    <span className="l-two l-h4"></span>
                </div>
            </div>
            <div>
                <div>
                    <span className="l-one l-h5"></span>
                    <span className="l-two l-h2"></span>
                </div>
            </div>
            {props.text && <span className="text-1">{props.text}</span>}
        </section>
    )
}