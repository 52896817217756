import React, {useEffect, useState, useRef} from "react";
import { Helmet } from "react-helmet";

//style
import "./demo_scan.css";
//components
import VideoCamera, {CAM_TYPE_DEMO} from "../../components/scanner/camera/videoCamera";
import DemoScanResults from "../../components/scanner/results/demoResults";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import SimpleLoader from "../../components/scanner/loader/simple-loader";
import VideoStreamSocket from "../../components/scanner/socket/socket";
import ScannerError from "../../components/errorMessages/scannerError/scannerError";
import { isIOS, postReqOptBuilder } from "../../utils/main_utils";
//containers
//assets
//constants
const SOCKET_NS = "test_socket";
const FPS = 20;
const CAMERA_CONSTRAINTS = {
    video: {
        facingMode: { exact: 'environment' },
        ...((isIOS()) ? { aspectRatio: 16/9 } : {
            width: { ideal: 1920 },  // 1920},  // 3840 },
            height: { ideal: 1080 },  // 1080}, // 2160 },
            frameRate: { ideal: FPS },
            aspectRatio: 16/9,  // 1.777777778,
            zoom: 1.0
        })
    },
    audio: false
};

export default function DemoScan (props) {
    // state based variables
    const [loadingPage, setLoadingPage] = useState(true);
    const [socket, setSocket] = useState(null);
    const [result, setResult] = useState({});
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [showResults, setShowResults] = useState(false);
    const [loadingResults, setLoadingResults] = useState(false);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    // general variables
    let scannedFrames = useRef(0);
    let framesSent = useRef(0);
    let lp_frameIdx = useRef(0);
    let lp_frameIdxReceived = useRef(0);
    let framesIgnored = useRef(0);
    let finalizeBsInterval = useRef(null);
    let sessionID = useRef(null);


    const switchToLoading = (totalFrames, sentFrames, corrupted_blobs) => {
        if (!loadingResults) setLoadingResults(true);
        framesSent.current = sentFrames;
        scannedFrames.current = totalFrames;
        framesIgnored.current = corrupted_blobs;
        updateLoadedPercentage();
    };

    const updateLoadedPercentage = () => {
        let lp = Math.round((lp_frameIdx.current+lp_frameIdxReceived.current+framesSent.current-framesIgnored.current)/(scannedFrames.current*3)*100);
        console.log(`Loaded: ${lp}`);
        setLoadedPercentage(lp);
    };

    const showError = () => {
        // setShowResults(true);
        setShowErrorScreen(true);
    };

    const onFrameReceived = (frame_idx) => {
        lp_frameIdxReceived.current++;
        updateLoadedPercentage();
    };

    const onFrameDone = (frame_idx) => {
        console.log("[i] Backend finished processing frame idx. " + frame_idx + "!!!");
        lp_frameIdx.current++;
        updateLoadedPercentage();
    };

    const onScanResults = (results) => {
        if (typeof results === "string") {
            console.log("results is still string!");
            results = JSON.parse(results);
        }
        setResult(results);
        setLoadingResults(false);
        setShowResults(true);
    };

    const finalizeScan = (sid, intervalCount) => {
        finalizeBsInterval.current = setInterval(() => {
            if (lp_frameIdxReceived.current === intervalCount) {
                socket.emit('finalize_scan', sid, intervalCount-1);
                clearInterval(finalizeBsInterval.current);
                // clearLock(sid);
            }
        }, 1000);
    };

    const clearLock = (sid) => {
        fetch('https://aiv2.paraspot.de/disconnectMovingScan', postReqOptBuilder({'sid': sid}, false))
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    console.log("Lock is down");
                } else {
                    console.log("Failed to take down Lock");
                }
            });
    }

    useEffect(() => {
        sessionID.current = new Date().getTime().toString() + Math.random().toString(36).substr(2);
        fetch('https://aiv2.paraspot.de/authMovingScan', {method: 'POST', 
                                                            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}, 
                                                            body: `${encodeURIComponent('token')}=${encodeURIComponent((new URLSearchParams(window.location.search)).get('token'))}`})
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    console.log("Can proceed");
                } else {
                    console.log("Can't proceed");
                    setErrMsg("Invalid token...");
                    setShowErrorScreen(true);
                }
                setLoadingPage(false);
            });
        // window.onbeforeunload = () => {
        //     clearLock(sessionID.current);
        // };
        setSocket(VideoStreamSocket.initSocket(SOCKET_NS));
    }, []);

    useEffect(() => {
        VideoStreamSocket.initSocketEventListeners(socket, true, true, true, true, true, false, null, null, onFrameReceived, onFrameDone, onScanResults, null);

        return () => {
            if (socket) {
                console.log("[i] Closing socket...");
                socket.close();
            }
        }
    }, [socket]);

    
    return (
        <section className={`scan-main${showResults ? " results-mode" : ""}`}>
            <Helmet>
                <title>Paraspot - Demo</title>
                <meta name="description" content="Experience Paraspot's leading AI scan from your mobile device."/>
                <meta property="og:title" content="Paraspot - Demo"/>
                <meta property="og:description" content="Experience Paraspot's leading AI scan from your mobile device."/>
            </Helmet>
            {
                loadingPage ?
                    <MainLoader/> :
                showErrorScreen ?
                    <ScannerError errMsg={errMsg}/> :
                <>
                    {socket && <VideoCamera
                                    socket={socket}
                                    FPS={FPS}
                                    cameraConstraints={CAMERA_CONSTRAINTS}
                                    switchToLoading={switchToLoading}
                                    finalizationCallback={finalizeScan}
                                    showError={showError}
                                    camType={CAM_TYPE_DEMO}
                                    scanLimit={20}
                                    compressSize={680}
                                    showTimeStamp={true}
                                    sessionID={sessionID.current}/>
                    }
                    {!socket && <div>Connecting...</div>}
                    {loadingResults && <SimpleLoader loadedPercentage={loadedPercentage}/>}
                    {showResults && <DemoScanResults result={result}/>}
                </>
            }
        </section>
    )
}