import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const SnackBar = ({ open, handleClose, severity, message, duration }) => {
    return (
        <Snackbar open={open} autoHideDuration={duration || 6000} onClose={handleClose}>
            <Alert
                onClose={handleClose}
                severity={severity || 'info'}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackBar;