import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//components
import UnitsTable from '../../components/Tables/TableTypes/UnitsTable/UnitsTable';
import UnitsHeader from './components/UnitsHeader/UnitsHeader';
//modals
import AddNewUnitModal from './components/modals/AddNewUnitModal';
import BulkUploadModal from './components/modals/BulkUploadModal/BulkUploadModal';
import SearchFilterModal from './components/modals/SearchFilterModal/SearchFilterModal';
//constants
import { CategoryFilter, MODAL_TYPES } from '../../constants';
//slices
import { fetchData, resetState, setFilters, setPage } from '../../store/slices/searchSlice';
import { closeModal } from '../../store/slices/unitSlice';
//utils
import { createDictFromHashedUrl } from '../../utils/main_utils';


const Units = () => {
    const dispatch = useDispatch();
    const hashDict = createDictFromHashedUrl(window.location.hash);
    // const [searchTerm, setSearchTerm] = useState(Object.keys(hashDict).length > 0 ? (hashDict?.subject || '') : '');
    const { page, pageSize, sortColumn, sortDirection, searchValue, filters, categoryFilter, totalItems } = useSelector(state => state.table);
    const { modals } = useSelector(state => state.units);
    const { data: clientLimits } = useSelector(state => state.clientLimits);
    const location = useLocation();

    const fetchConfig = (filters={}, resetStats=false) => {
        const filtersObj = {
            ...filters,
            category: CategoryFilter.UNITS,
        };
        const usedSearchValue = Object.keys(hashDict).length > 0 ? decodeURIComponent(hashDict?.subject || '') : (resetStats ? "" : searchValue);
        const body = {
            lowerLimit: resetStats ? 0 : (page - 1) * pageSize,
            upperLimit: resetStats ? pageSize : page * pageSize,
            filters: filtersObj,
            searchValue: usedSearchValue,
            sortDirection: resetStats ? false : sortDirection,
            sortColumn: resetStats ? 0 : sortColumn,
        };

        return {body, filtersObj};
    };

    const performFetch = (filters={}, resetPage=false) => {
        const {body, filtersObj} = fetchConfig(filters);
        if (resetPage) {
            body.lowerLimit = 0;
            body.upperLimit = pageSize;
            dispatch(setPage(1));
        }
        dispatch(fetchData(body));
        dispatch(setFilters(filtersObj));
    };

    useEffect(() => {
        const {body, filtersObj} = fetchConfig({}, true);
        dispatch(resetState({filters: filtersObj, categoryFilter: CategoryFilter.UNITS}));
        dispatch(fetchData(body));
    }, [dispatch, location]);

    const handleCloseModal = (modalName) => {
        dispatch(closeModal(modalName));
    };


    return (
        <>
            {modals.addUnitModal && 
                <AddNewUnitModal 
                    open={modals.addUnitModal} 
                    handleClose={()=> handleCloseModal(MODAL_TYPES.ADD_UNIT_MODAL)} 
                    performFetch={() => performFetch({}, true)}
                />
            }
            {modals.bulkUploadModal && 
                <BulkUploadModal
                    open={modals.bulkUploadModal} 
                    handleClose={()=> handleCloseModal(MODAL_TYPES.BULK_UPLOAD)} 
                    performFetch={() => performFetch({}, true)}
                />
            }
            {modals.searchFilterModal && 
                <SearchFilterModal
                    open={modals.searchFilterModal} 
                    handleClose={()=> handleCloseModal(MODAL_TYPES.SEARCH_FILTER_UPLOAD)} 
                    currentFilters={filters}
                    performFetch={(f) => performFetch(f, true)}
                />
            }
            <main className="flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-8">
                    <UnitsHeader totalItems={totalItems} />
                    <div className="mt-2 flex flex-col">
                        <UnitsTable />
                    </div>
                </div>
            </main>
        </>
    )
};

export default Units;