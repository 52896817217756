
import { useState } from "react";
//styles
import "./radioInput.css";

function RadioInput(props) {
    const [radioValue, setRadioValue] = useState(props.currentValue ? props.currentValue : props.values[0]);
    const handleClickRadio = (value) => {
        setRadioValue(value);
        if (props.onChange) props.onChange(value);
    }

    return (
        <div className="radio-group-container">
            <div className="input-label text-1-3">{props.label}</div>
            {props.values?.map(function (value) {
                return (
                    <SingleRadioInput
                        extraClasses={"radio-input"}
                        value={value}
                        name={props.groupName}
                        checked={radioValue === value}
                        onclick={()=>handleClickRadio(value)}
                    />
                )
            })}
            {/* return(
                <div onClick={()=>handleClickRadio(value)}>
                    <input className="radio-input" type="radio" value={value} name={props.groupName} checked={radioValue === value}/>
                    <span>{value}</span>
                </div>
            ) */}
        </div>
    )
}

function SingleRadioInput(props) {
    return (
        <div 
            className={"radio-container-1" + (props.disabled ? " radio-disabled" : "") + (props.containerExtraClasses ? ` ${props.containerExtraClasses}` : "")}
            onClick={props.onclick}
        >
            <input 
                name={props.name}
                id={props.id}
                type="radio"
                className={props.extraClasses}
                value={props.value}
                disabled={props.disabled}
                checked={props.checked}
            />
            <span className={"text-1"}>{props.label ? props.label : props.value}</span>
            {props.children && props.children}
        </div>
    )
}

export {
    RadioInput,
    SingleRadioInput
}