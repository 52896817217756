import { createSlice } from '@reduxjs/toolkit';


const journeysSlice = createSlice({
    name: 'journeys',
    initialState: {
        modals: {
            deleteJourneyModal: false,
            editJourneyModal: false,
        }
    },
    reducers: {
        openModal(state, action) {
            const { modalName } = action.payload;
            state.modals[modalName] = true;
        },
        closeModal(state, action) {
            const modalName = action.payload;
            state.modals[modalName] = false;
        },
    },
});

export const { openModal, closeModal } = journeysSlice.actions;

export default journeysSlice.reducer;