import React from 'react';
import { useSelector } from 'react-redux';
//components
import ManagementHeader from './components/ManagementHeader';
import CardsSection from './components/CardsSection';
import ScanCompletionSection from './components/ScanCompletionSection';
//tables
import MiMoTable from '../../components/Tables/TableTypes/MiMoTable/MiMoTable';
import ScansSubmissionsTable from '../../components/Tables/TableTypes/ScansSubmissionTable/ScansSubmissionTable';
import ReportsTable from '../../components/Tables/TableTypes/ReportsTable/ReportsTable';
//hooks
import useAuthenticate from '../../hooks/useAuthenticate';
//constants
import { CategoryFilter } from '../../constants';


const ManagementDev = () => {
    const { settingUser } = useAuthenticate();
    const { isMobile } = useSelector(state => state.config);
    const { categoryFilter: dashboardCategory } = useSelector(state => state.dashboard);

    const selectTable = () => {
        switch (dashboardCategory) {
            case CategoryFilter.MOVE_IN:
                return <MiMoTable />;
            case CategoryFilter.SCANS:
                return <ScansSubmissionsTable />;
            case CategoryFilter.REPORTS:
                return <ReportsTable />;
            default:
                return <MiMoTable />;
        }
    }
    
    return (
        <>
            <main className="flex-1">
                {/* Header */}
                <ManagementHeader userName={settingUser?.fullName} />

                {/* Cards Section */}
                <div className="mt-2">
                    <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-6">
                        <div className="mt-2 flex w-full h-full flex flex-col sm:flex-row">
                            <CardsSection />
                            {/* Scan Completion Section */}
                            {!isMobile && <ScanCompletionSection />}
                        </div>
                    </div>
                </div>
                {/* Table Section */}
                <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-6 mt-8 mb-8">
                    <div className="mt-2 flex flex-col">
                        {/* TableWidget */}
                        {selectTable()}
                    </div>
                </div>
            </main>
        </>
    );
};

export default ManagementDev;
