//components
import MainLoader from "../../components/loaders/main_loader/main_loader";
//styles
import "./loading_page.css";

export default function LoadingPage () {

    return (
        <MainLoader/>
    )
}