import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//utils
import axios from '../../axios/axios';
//constants
import { para_be } from '../../config';


// Thunk to fetch client PMS data
export const postLogEntry = createAsyncThunk(
    'activity/postLogEntry',
    async ({ activityID, activityType, ip, action }, { rejectWithValue }) => {
        try {
            const body = {
                id: activityID,
                type: activityType,
                ip,
                action
            };

            const { data } = await axios.post(`${para_be}/activity/log`, body, { withCredentials: true, });
            if (data.status >= 400) {
                const cleanedMessage = data.msg.replace(/^Request\s+\w+:\s*/, '');
                return rejectWithValue(cleanedMessage);
            }
            return { body };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

// Thunk to fetch client PMS data
export const fetchLogs = createAsyncThunk(
    'activity/fetchLogEntries',
    async ({ activityID, activityType }, { rejectWithValue }) => {
        try {
            const queryString = `id=${activityID}&type=${activityType}`;
            const limitsDataResponse = await axios.get(`${para_be}/activity/fetch_logs?${queryString}`, { withCredentials: true });
            if (limitsDataResponse.data.status >= 400) {
                return rejectWithValue(limitsDataResponse.data.msg);
            }
            return { data: limitsDataResponse.data.result };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postLogEntry.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(postLogEntry.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(postLogEntry.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching client PMS data';
            })
            .addCase(fetchLogs.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLogs.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchLogs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching client PMS data';
            });
    }
});


export const {
    setData,
} = activitySlice.actions;
export default activitySlice.reducer;