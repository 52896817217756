import React from 'react'

const PlainIcon = ({ fill = 'none', width = '18px', height = '18px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 18 18'
    >
      <path d="M14.6428 11.7526L15.9301 7.8906C17.0547 4.51681 17.617 2.82992 16.7266 1.93947C15.8361 1.04901 14.1492 1.61131 10.7754 2.73591L6.91342 4.02324C4.19044 4.9309 2.82895 5.38473 2.44206 6.05024C2.074 6.68334 2.074 7.46527 2.44206 8.09837C2.82895 8.76388 4.19044 9.21771 6.91341 10.1254C7.35063 10.2711 7.56924 10.344 7.75196 10.4663C7.92905 10.5849 8.08117 10.737 8.19971 10.9141C8.32204 11.0968 8.39491 11.3154 8.54065 11.7526C9.4483 14.4756 9.90213 15.8371 10.5676 16.224C11.2007 16.592 11.9827 16.592 12.6158 16.224C13.2813 15.8371 13.7351 14.4756 14.6428 11.7526Z"
        stroke="#0C1222" strokeWidth="1.5" />
      <path d="M12.9567 6.769C13.2512 6.47773 13.2538 6.00287 12.9625 5.70835C12.6713 5.41384 12.1964 5.41121 11.9019 5.70248L12.9567 6.769ZM8.79496 10.8848L12.9567 6.769L11.9019 5.70248L7.7402 9.8183L8.79496 10.8848Z"
        fill="#0C1222" />
    </svg>
  );
};

export default PlainIcon