import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios/axios';
import { para_be } from '../../config';

// Helper functions to handle loading, success, and error states
const startLoading = state => {
    state.loading = true;
    state.error = null;
};

const loadingFailed = (state, action) => {
    state.loading = false;
    state.error = action.payload;
};

const loadingSuccess = payloadSetter => (state, action) => {
    state.loading = false;
    payloadSetter(state, action.payload);
};

export const fetchSubscriptionData = createAsyncThunk('subscription/fetchSubscriptionData', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${para_be}/payments/get_subscriptions`, { withCredentials: true, });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const cancelSubscription = createAsyncThunk('subscription/cancelSubscription', async (subscriptionId, { rejectWithValue }) => {
    try {
        await axios.delete(
            `${para_be}/payments/cancel_subscription`,
            {
                withCredentials: true,
                data: { subscription_id: subscriptionId },
            }
        );
        return null;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const setSubscription = createAsyncThunk('subscription/setSubscription', async ({ paymentMethodId, planType, price, quantity, plan }, { rejectWithValue }) => {
    try {
        const requestBody = {
            payment_method_id: paymentMethodId,
            plan_type: planType,
            plan,
            price,
            quantity,
        };
        const response = await axios.post(`${para_be}/payments/set_subscription`, requestBody, { withCredentials: true, });

        if (response.status !== 200 || response.data.status !== 200) {
            const cleanedMessage = response.data.msg.replace(/^Request\s+\w+:\s*/, '');
            return rejectWithValue(cleanedMessage || 'Failed to create subscription.');
        }
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

// Initial state and slice definition
const initialState = {
    data: { subscription: null },
    loading: false,
    error: null,
    cancellation_status: null
};

const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        clearSubscriptionState: (state) => {
            state.loading = false;
            state.error = null;
            state.data = { subscription: null };
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchSubscriptionData.pending, startLoading)
        .addCase(
            fetchSubscriptionData.fulfilled,
            loadingSuccess((state, data) => (state.data = data))
        )
        .addCase(fetchSubscriptionData.rejected, loadingFailed)
        .addCase(cancelSubscription.pending, startLoading)
        .addCase(
            cancelSubscription.fulfilled,
            loadingSuccess((state, data) => {
                state.data = { subscription: null };
                state.cancellation_status = data.result;
            })
        )
        .addCase(cancelSubscription.rejected, loadingFailed)
        .addCase(setSubscription.pending, startLoading)
        .addCase(
            setSubscription.fulfilled,
            loadingSuccess((state, data) => (state.data = data))
        )
        .addCase(setSubscription.rejected, loadingFailed);
    },
});

export const { clearSubscriptionState } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
