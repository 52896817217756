import React, { useState, useEffect } from "react";
import { isIOS } from "../../../utils/main_utils";

//style
//components
//containers
//assets
import MainLoader from "../../loaders/main_loader/main_loader";

export default function CamSettingsLoader(props) {
    const { onFinishedLoading } = props;
    const [retry, setRetry] = useState(false);

    const selectVideoDevice = () => {
        if (!isIOS()) {
                navigator.mediaDevices.enumerateDevices()
                .then( async (devices) => {
                    let devicesData = null;
                    for (const device of devices) {
                        if (device.kind === "videoinput") {
                            console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
                            const stream = await navigator.mediaDevices.getUserMedia({video: {deviceId: device.deviceId}});
                            console.log( "Device Tracks:" );
                            const tracks = stream.getVideoTracks();
                            for (const track of tracks) {
                                const capabilities = track.getCapabilities();
                                if (capabilities.facingMode[0] === "environment") {
                                    console.log( "Found environment video input" );
                                    if ( devicesData === null || (capabilities.height.max >= devicesData.height.max && capabilities.width.max >= devicesData.width.max && (!capabilities.focusDistance || capabilities.focusDistance.max <= devicesData.focusDistance.max)) ) {
                                        console.log( "Updating the selected device" );
                                        devicesData = {
                                            deviceId: device.deviceId, 
                                            aspectRatio: capabilities.aspectRatio, 
                                            height: capabilities.height, 
                                            width: capabilities.width, 
                                            focusDistance: capabilities?.focusDistance
                                        };
                                    }
                                }
                                console.log( '[i] Moving to next input.' );
                                track.stop();
                                stream.removeTrack(track);
                            };
                        }
                    }
                    console.log( "Final Selected Device:", JSON.stringify( devicesData ) );
                    if ( devicesData !== null ) {
                        onFinishedLoading(devicesData);
                    } else if (retry) {
                        console.log("There is no camera device that supports performing the scan. Please contact support.");
                        onFinishedLoading(false);
                    } else {
                        setRetry(true);
                    }
                }
            );
        } else onFinishedLoading(true);
    }

    useEffect(selectVideoDevice, []);
    useEffect(() => { if (retry) selectVideoDevice(); }, [retry])

    return (
        <MainLoader text="We are setting up your camera settings, it might take a couple of seconds"/>
    )
}