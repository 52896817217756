import React, {useState} from "react";
//components
import Notification from "../../side_notification/side_notification";
import Btn from "../../buttons/standard/btn";
//utils
import { postReqOptBuilder } from "../../../utils/main_utils"
//styles
import "../add_new_unit/add_new_unit.css";
//assets
import SimpleInput from "../../inputs/simple_input/input";

export default function CreateNewClient (props) {
    const [notifState, setNotifState] = useState(false);
    const [inputError, setInputError] = useState(null);

    const handleApprove = () => {
        const companyInput = document.querySelector("#company-name").value;
        const emailInput = document.querySelector("#email").value;
        if (companyInput.length > 0) {
            setInputError("company-name");
            return;
        }
        if (emailInput.length > 0) {
            setInputError("email");
            return;
        }
        setNotifState(false);
        fetch(props.para_be + '/auth/signup', postReqOptBuilder({"company_name": companyInput, "email": emailInput, "admin_creation": 1}))
        .then(response => response.json())
        .then(response => {
                if(response.status === 200) {
                    handleClose(true);
                } else {
                    setNotifState(response.msg);
                }
        })
        .catch(error => {
            console.log(error);
            setNotifState("An error has occurred, please try again later");
        });
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const handleClose = (refresh=false) => {
        props.closeFunc(refresh);
    };

    return (
        <div className="new-unit-container">
            <div className="flexColumn">
                <div className="h2 blue-headline">Create New Client</div>
                <SimpleInput 
                    id={"company-name"}
                    name={"company-name"}
                    label={"Company Name"}
                    placeholder={"Enter Company Name"}
                    type={"text"}
                    inputError={inputError === "company-name" ? "Please enter Company Name" : undefined}
                />
                <SimpleInput 
                    id={"email"}
                    name={"email"}
                    label={"Email"}
                    placeholder={"Enter Client's Email"}
                    type={"text"}
                    inputError={inputError === "email" ? "Please enter Client's Email" : undefined}
                />
                <Btn
                    text="Approve"
                    type="primary"
                    onclick = {handleApprove}
                />
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}
