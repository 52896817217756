import React, {useState, useEffect} from "react";
//components
import EmptyState from "../../emptyState/empty_state";
import MainLoader from "../../loaders/main_loader/main_loader";
import Notification from "../../side_notification/side_notification";
//utils
//styles
import "./view_baseline.css";

export default function ViewBaseline(props) {
    /* 
    Expects in props:
        - pid (the unit id)
        - subject (the unit subject)
        - para_be (the link to the paraspot api)
    
    Requires has-bsp-container class set on the parent element
    */
    const [isLoading, setIsLoading] = useState(true);
    const [baselineLink, setBaselineLink] = useState(null);
    const [baselineTimestamp, setBaselineTimestamp] = useState(null);
    const [isEmptyState, seEmptyState] = useState(false);
    const [notifState, setNotifState] = useState(null);
    
    useEffect(() => {
        if (props.scan_link) {
            setBaselineLink(props.scan_link);
            let ts = props.scan_link.split("/").slice(-1)[0].split(".mp4")[0];
            setBaselineTimestamp(`${ts.slice(0,2)}/${ts.slice(2,4)}/${ts.slice(4,8)} ${ts.slice(8,10)}:${ts.slice(10,12)}:${ts.slice(12,14)} UTC`);
            setIsLoading(false);
            return;
        }
        fetch(props.para_be + '/units/get_unit_baseline?pid=' + props.pid, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200 || response.result.length > 0) {
                setBaselineLink(response.result);
                let ts = response.result.split("/").slice(-1)[0].split(".mp4")[0];
                setBaselineTimestamp(`${ts.slice(0,2)}/${ts.slice(2,4)}/${ts.slice(4,8)} ${ts.slice(8,10)}:${ts.slice(10,12)}:${ts.slice(12,14)} UTC`);
            } else if (response.status === 500) {
                setNotifState("An error has occurred, please try again later");
                setIsLoading(false);
            } else seEmptyState(true);
            setIsLoading(false);
        })
        .catch(error => {
            setNotifState("An error has occurred, please try again later");
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    return (
        <div className="bsp-container">
            {isLoading ? <MainLoader/> :
                <div>
                    <div className="h2 blue-headline">{props.scan_type ? props.scan_type : "Baseline"} {props.subject}</div>
                    <div className="text-3">From: {baselineTimestamp}</div>
                    <div className="bsp-body custom-scrollbar-1">
                        {isEmptyState ? <EmptyState text="No scan video available." size ="md"/> :
                            <video src={baselineLink} controls playsInline autoPlay/>
                        }
                    </div>
                </div>
            }

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}