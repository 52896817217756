import React, {useState, useEffect} from "react";
import { useHistory, useParams } from "react-router";
import { Helmet } from "react-helmet";
//components
import Btn from "../../components/buttons/standard/btn";
import HideTextInput from "../../components/inputs/hide_text_input/input"
//utils
import {getClientData} from "../../utils/main_utils";
//styles
import "../login/login.css";
//assets
import logo from "../../assets/icons/logo.svg";
import SimpleInput from "../../components/inputs/simple_input/input";
import { isEmail } from "../../utils/main_utils";

export default function SignUp(props) {

    const [inputError, setInputError] = useState(null);
    const [clientData, setClientData] = useState(null);
    // const clientData = getClientData();
    // const ip = clientData ? clientData[0] : null;
    const history = useHistory();
    let params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const verification_token = params.get('verification_code');

    useEffect(()=> {
        getClientData().then(data => {
            setClientData(data);
        });
    // eslint-disable-next-line no-sequences
    }, []);

    const handleSignIn = (event) => {
        event.preventDefault();
        const password = document.getElementById("password").value;
        const confPassword = document.getElementById("conf-password").value;
        if (password.length <=0) {
            setInputError("Please enter password");
            return;
        } else if (password !== confPassword) {
            setInputError("Passwords don't match");
            return;
        } else if (!isEmail(email)) {
            setInputError("Email is not formatted correctly");
            return;
        }
        const ip = clientData ? clientData[0] : null;
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ "email": email, "pass": password, "token": verification_token, "ip": ip}),
            credentials: 'include'
        };
        fetch(props.para_be + '/auth/signup', requestOptions)
        .then(response => response.json())
        .then(response => {
                if(response.status === 200) {
                    history.push({pathname:"/login", state:{from: "/auth/signup", auth: response.result}})
                } else {
                    setInputError(response.msg);
                }
        })
        .catch(error => {
            console.log(error);
            setInputError("Something went wrong, please try again");
        });
    };

    return (
        <div>
            <Helmet>
                <title>Paraspot | Sign Up</title>
                <meta name="description" content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office."/>
                <meta property="og:title" content="Paraspot | Sign up"/>
                <meta property="og:description" content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office."/>
            </Helmet>
            <section className="page-content">
            <div className="para-logo image-container">
                <a href="/">
                    <img id="para-logo" src={logo} alt="Paraspot Logo"/>
                </a>
            </div>
            <div className="login-page user_sign_page">
                <div className="access-form">
                    <form className="login-form" method="post">
                        <div className="user-sign-header">
                            <h3>Sign-Up</h3>
                            <div className="switch-user-sign d-none">
                                <span>
                                Or<a href="/login">Sign In</a>
                                </span>
                            </div>
                        </div>
                        <div className="sign-in-options row-view">
                            <div className="sio sio-selected">
                                <span>Email Address</span>
                            </div>
                            <div className="sio">
                                <span>SMS SOON</span>
                            </div>
                        </div>
                        <input type="hidden" name="client_ip"/>
                        <div className="login-container login-container-active simple-login-container">
                            <SimpleInput
                                id="email_pn"
                                name="email"
                                type="text"
                                si_input_text="Email Address"
                                extraClasses="input-2"
                                value={email}
                                readOnly={true}
                                error_state={inputError ? "input-err" : ""}
                            />
                            <HideTextInput
                                id="password"
                                name="password"
                                si_input_text="New Password"
                                classes="input-2"
                                error_state={inputError ? "input-err" : null}
                            />
                            <HideTextInput
                                id="conf-password"
                                name="conf-password"
                                si_input_text="Confirm Password"
                                classes="input-2"
                                error_state={inputError ? "input-err" : ""}
                            />
                            <div className={"sign-form-err " + (inputError ? "" : "d-none")}>
                                <span>{inputError}</span>
                            </div>
                            <Btn
                                text="Sign Up"
                                type="sign"
                                onclick = {handleSignIn}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </section>
        </div>
    )
}