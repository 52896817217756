import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios/axios';  
import { para_be } from '../../config';


// Thunk to fetch search results
export const fetchSearchResults = createAsyncThunk(
    'globalSearch/fetchSearchResults',
    async (query, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${para_be}/dashboard/search`, {
                params: {
                    upperLimit: 5,
                    lowerLimit: 0,
                    filters: JSON.stringify({
                        category: "units"
                    }),
                    searchValue: query,
                    sortDirection: false,
                    sortColumn: 0
                },
                withCredentials: true
            });
            return response?.data?.data?.rows ?? [];
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const initialState = {
    results: [],
    loading: false,
    open: false,
    isMobileSearch: false, 
    error: null,
};

const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        setIsMobileSearch: (state, action) => { 
            state.isMobileSearch = action.payload;
        },
        resetSearch: (state) => {
            state.results = [];
            state.loading = false;
            state.open = false;
            state.isMobileSearch = false; 
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchSearchResults.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchSearchResults.fulfilled, (state, action) => {
            state.loading = false;
            state.results = action.payload;
            state.open = action.payload.length > 0;
        })
        .addCase(fetchSearchResults.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || 'Failed to fetch search results';
            state.open = false;
        });
    },
});

export const { setOpen, setIsMobileSearch, resetSearch } = globalSearchSlice.actions;
export default globalSearchSlice.reducer;