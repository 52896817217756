import React from 'react'

const MaterialSymbols = ({ fill = 'none', width = '16px', height = '16px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 16 16'
    >
        <path d="M2 13.3337V12.0003H14V13.3337H2ZM2 4.00033V2.66699H14V4.00033H2ZM3.33333 10.667C2.96667 10.667 2.65289 10.5365 2.392 10.2757C2.13111 10.0148 2.00044 9.70077 2 9.33366V6.66699C2 6.30033 2.13067 5.98655 2.392 5.72566C2.65333 5.46477 2.96711 5.3341 3.33333 5.33366H12.6667C13.0333 5.33366 13.3473 5.46433 13.6087 5.72566C13.87 5.98699 14.0004 6.30077 14 6.66699V9.33366C14 9.70033 13.8696 10.0143 13.6087 10.2757C13.3478 10.537 13.0338 10.6674 12.6667 10.667H3.33333ZM3.33333 9.33366H12.6667V6.66699H3.33333V9.33366Z" fill="#7F8594"/>
    </svg>
  );
};

export default MaterialSymbols