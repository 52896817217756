import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { para_be } from '../../config';

// Async thunk for fetching all notifications
export const fetchAllNotifications = createAsyncThunk(
    'notifications/fetchAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${para_be}/notifications/get_all`, { withCredentials: true });
            return response.data.result;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error fetching notifications');
        }
    }
);

// Async thunk for fetching new notifications
export const fetchNewNotifications = createAsyncThunk(
    'notifications/fetchNew',
    async (_, { getState, rejectWithValue }) => {
        try {
            const response = await axios.get(`${para_be}/notifications/get_new_notifs`, { withCredentials: true });
            const currentNotifAmount = getState().notifications.currentNotifAmount;

            if (response.status === 200 && response.data.result !== 0) {
                return currentNotifAmount + response.data.result;
            }

            return currentNotifAmount; // No new notifications
        } catch (error) {
            return rejectWithValue('Error fetching new notifications');
        }
    }
);

// Async thunk for marking a notification as read
export const markNotificationAsRead = createAsyncThunk(
    'notifications/markAsRead',
    async ({ id, readValue }, { rejectWithValue }) => {
        try {
            await axios.post(`${para_be}/notifications/read`, { nid: id, readValue }, { withCredentials: true });
            return { id, readValue };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error marking notification as read');
        }
    }
);

// Async thunk for deleting a notification
export const deleteNotification = createAsyncThunk(
    'notifications/delete',
    async (id, { rejectWithValue }) => {
        try {
            await axios.post(`${para_be}/notifications/delete`, { nid: id }, { withCredentials: true });
            return id;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error deleting notification');
        }
    }
);

// Utility function to check if all notifications are read
const checkIfAllNotificationsAreRead = (notifications) => {
    return notifications.every(notification => notification[7] === 1);
};

// Initial state for the notifications slice
const initialState = {
    notifications: [],
    loading: false,
    error: null,
    currentNotifAmount: 0,
    allNotificationAsRead: false
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllNotifications.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllNotifications.fulfilled, (state, action) => {
                state.loading = false;
                state.notifications = action.payload;
                state.currentNotifAmount = action.payload.filter(item => item[7] === 0).length;
                state.allNotificationAsRead = checkIfAllNotificationsAreRead(state.notifications);
            })
            .addCase(fetchAllNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchNewNotifications.fulfilled, (state, action) => {
                state.currentNotifAmount = action.payload;
                state.allNotificationAsRead = checkIfAllNotificationsAreRead(state.notifications);
            })
            .addCase(fetchNewNotifications.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(markNotificationAsRead.fulfilled, (state, action) => {
                const { id, readValue } = action.payload;
                const notification = state.notifications.find(notif => notif[0] === id);
                if (notification) {
                    notification[7] = readValue;
                }
                state.currentNotifAmount = state.notifications.filter(item => item[7] === 0).length;
                state.allNotificationAsRead = checkIfAllNotificationsAreRead(state.notifications);
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                state.notifications = state.notifications.filter(notif => notif[0] !== action.payload);
                state.currentNotifAmount = state.notifications.filter(item => item[7] === 0).length;
                state.allNotificationAsRead = checkIfAllNotificationsAreRead(state.notifications);
            })
            .addCase(deleteNotification.rejected, (state, action) => {
                state.error = action.payload;
            });
    },
});

export const { clearError } = notificationsSlice.actions;
export default notificationsSlice.reducer;