import React from "react";

//components
import Btn from "../../../../components/buttons/standard/btn";
import SimpleInput from "../../../../components/inputs/simple_input/input";
import EmailConfigurations from "./emailConfiguration";
// styles
import "./index.css";

export default function Mailing(props) {
    const {
        smtpEditMode,
        smtpMetaData,
        smtpInputError,
        handleOpenPopup,
        setSmtpEditMode,
        closeSmtpEditMode,
        updateSmtpData,
    } = props;

    return (
        <>
            <section className="smtp-section">
                <div className="h3">SMTP Integration</div>
                <div className={`presentation-block${smtpEditMode ? " edit-mode" : ""}`}>
                    <SimpleInput
                        id="smtp-server"
                        label={"SMTP Server" + (smtpEditMode ? "" : ":")}
                        placeholder="smtp.gmail.com"
                        type="text"
                        value={smtpMetaData?.smtp_server}
                        readOnly={smtpEditMode ? undefined : true}
                        inputError={smtpInputError?.hasOwnProperty("smtp-server") ? smtpInputError["smtp-server"] : undefined} />
                    <SimpleInput
                        id="smtp-port"
                        label={"SMTP Port" + (smtpEditMode ? "" : ":")}
                        placeholder="587"
                        type="text"
                        value={smtpMetaData?.smtp_port}
                        readOnly={smtpEditMode ? undefined : true}
                        inputError={smtpInputError?.hasOwnProperty("smtp-port") ? smtpInputError["smtp-port"] : undefined} />
                    <SimpleInput
                        id="smtp-email"
                        label={"Email Address" + (smtpEditMode ? "" : ":")}
                        placeholder="Email Address"
                        type="text"
                        value={smtpMetaData?.email}
                        readOnly={smtpEditMode ? undefined : true}
                        inputError={smtpInputError?.hasOwnProperty("smtp-email") ? smtpInputError["smtp-email"] : undefined} />
                    <div className="update-smtp-pass-section">
                        <Btn
                            text="Update SMTP Password"
                            type="secondary"
                            onclick={() => handleOpenPopup("smtp_pass_change")} />
                    </div>
                    <div className="btns-section">
                        <Btn
                            text="Edit"
                            type="secondary"
                            extraClasses="edit-btn"
                            onclick={() => { setSmtpEditMode(true); }} />
                        <Btn text="Cancel" type="secondary" onclick={closeSmtpEditMode} />
                        <Btn text="Save" type="primary" onclick={updateSmtpData} />
                    </div>
                </div>
            </section>
            <EmailConfigurations {...props} />
        </>
    );
};
