import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    
    return (
        <main className="relative isolate min-h-screen">
            <img
                alt=""
                src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
                className="absolute inset-0 z-negative w-full h-full object-cover object-top"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50"></div> 
            <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8 z-10 relative">
                <p className="text-lg font-semibold text-white leading-relaxed">404</p>
                <h1 className="mt-4 text-white text-5xl font-semibold tracking-tight sm:text-7xl">
                    Page not found
                </h1>
                <p className="mt-6 text-xl font-medium text-white opacity-80 sm:text-2xl">
                    Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div className="mt-10 flex justify-center">
                    <Link to="/" className="text-base font-semibold text-white">
                        <span aria-hidden="true">&larr;</span> Back to home
                    </Link>
                </div>
            </div>
        </main>
    );
};

export default NotFound;