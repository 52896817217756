import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//mui
import Divider from '@mui/material/Divider';
//components
import CustomButton from '../../../../../../../../components/buttons/CustomButton';
import MainLoader from '../../../../../../../../components/loaders/main_loader/main_loader';
//slices
import { createCustomer } from '../../../../../../../../store/slices/customerSlice';
//utils
import { paymentAccountExists } from '../../../../utils';

const Step1CreateCustomer = forwardRef(({ handleNext }, ref) => {
    const dispatch = useDispatch();
    const [isHaveAccount, setHaveAccount] = useState(false);
    const { customerData, fetchCustomerData: { loading }, createCustomer: { loading: creationLoading } } = useSelector(state => state.customer);


    useEffect(() => {
        if (customerData) {
            setHaveAccount(paymentAccountExists(customerData));
        }
    }, [customerData]);

    useImperativeHandle(ref, () => ({ handleSubmit, }));

    const handleSubmit = async () => {
        try {
            if (!isHaveAccount) {
                const resultAction = await dispatch(createCustomer({}));
                if (createCustomer.rejected.match(resultAction)) {
                    throw new Error(resultAction.payload || 'Failed to create customer');
                }
            }
        } catch (error) {
            throw error;
        }
    };

    return (
        <div className="p-4">
            {loading ?
                <MainLoader /> 
                :
                <>
                    <div className="mt-6">
                        <Divider className="my-8 mt-4" />
                    </div>
                    <div className='flexRow mt-12 justify-center'>
                        <CustomButton variant="contained" color="primary" onClick={handleNext} disabled={loading} loading={creationLoading}>
                            Create Payment Account
                        </CustomButton>
                    </div>
                </>
            }
        </div>
    );
});

export default Step1CreateCustomer;
