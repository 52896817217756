import React, { useState } from 'react';
//components
import Btn from '../../../../../../components/buttons/standard/btn';
import { SimpleInputDropdown } from '../../../../../../components/dropdowns/simple_dropdown/simple_dropdown';
import SimpleInput from '../../../../../../components/inputs/simple_input/input';
//utils
import { postReqOptBuilder } from '../../../../../../utils/main_utils';
//styles
// import './roi_calculator.css';
//assets
import contactUsImg from "../../../../../../assets/icons/contactUs.png";
import emailIcon from "../../../../../../assets/icons/emailIcon-2.svg";

export default function ContactForm(props) {
    const { scrollToForm, clientData, setNotifState, para_be } = props;

    const [errorText, setErrorText] = useState(null);
    // Contact-Us Inputs
    const [companyType, setCompanyType] = useState(null);
    const [aum, setAUM] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [role, setRole] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    

    const handleFormSubmit = (e) => {
        // Contact Us
        // ["cu-company-name", "cu-aum-input", "cu-fn", "cu-ln", "cu-email", "cu-pn"] + "Company Type" dropdown
        e.preventDefault();
        setErrorText(null);
        
        let reqBody = {
            "units": aum, "companyType": companyType, "company": companyName, 
            "name": fullName, "role": role, "email": email, "phone": phone
        };
        let inputNames = {
            "units": "Units Under Management", "companyType": "Company Type", "company": "Company Name",
            "name": "Full Name", "role": "Role", "email": "Email", "phone": "Phone Number"
        };
        for (let [k, item] of Object.entries(reqBody)) {
            if (!item || item.length === 0) {
                setErrorText("Please enter " + inputNames[k]);
                return;
            }
        }
        reqBody['ip'] = clientData ? clientData[0] : null;

        fetch(para_be + '/request_demo_meeting', postReqOptBuilder(reqBody, false))
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                if (errorText) setErrorText(null);
                setNotifState({text: "Request to contact sales was sent successfully!", type: "success"});
            } else setErrorText("Oops, something went wrong.\nPlease try again later or contact us at info@paraspot.co");
        })
        .catch(error => {
            console.log(error);
            setErrorText("Oops, something went wrong.\nPlease try again later or contact us at info@paraspot.co");
        });

    };

    
    return (
        <div ref={scrollToForm} className='in-homepage-contact-us'>
            <div className='in-homepage-contact-us-content flexRow'>
                <div className='in-homepage-contact-us-media'>
                    <img src={contactUsImg} alt="contact-us"/>
                </div>
                <div className='in-homepage-contact-us-text flexColumn'>
                    <div className='h2'>
                        We're excited to speak<br/>
                        with you!
                    </div>
                    <div className='in-bordered-box-left-icon flexRow'>
                        <div className='in-bbox-icon'>
                            <img src={emailIcon} alt="email"/>
                        </div>
                        <a className='text-1-3' href="mailto:info@paraspot.co">info@paraspot.co</a>
                    </div>
                    <div className='in-homepage-contact-us-inputs flexColumn'>
                        <SimpleInput
                            label={"* Company Name"}
                            type={"text"}
                            id={"cu-company-name"}
                            on_change={(e) => setCompanyName(e.target.value)}
                            placeholder={"Enter your company name"}/>
                        <SimpleInput
                            label={"* Units Under Management"}
                            type={"number"}
                            id={"cu-aum-input"}
                            on_change={(e) => setAUM(e.target.value)}
                            placeholder={"Enter number of units"}
                            inputProps={{min: 0}}/>
                        <SimpleInputDropdown
                            label={"* Your Company Type"}
                            placeholder={"Choose Type"}
                            extraClasses={"dd-full-width"}
                            value={null}
                            items={{
                                multifamily: { present: "Multifamily" },
                                single_family: { present: "Single Family" },
                                student_housing: { present: "Student Housing" },
                                serviced_furnished_rental: { present: "Serviced/Furnished Rentals" },
                                co_living: { present: "Co-Living" },
                                short_term_rentals: { present: "Short-Term Rentals" },
                                built_to_rent: { present: "Built to Rent" }
                            }}
                            closeOnSelection={true}
                            onclick={(selectedValue, e) => {
                                setCompanyType(selectedValue);
                            }}
                        />
                        <div className='flexRow'>
                            <SimpleInput
                                label={"* Full Name"}
                                type={"text"}
                                on_change={(e) => setFullName(e.target.value)}
                                id={"cu-name"}
                                placeholder={"Enter your full name"}/>
                            <SimpleInput
                                label={"* Role"}
                                type={"text"}
                                on_change={(e) => setRole(e.target.value)}
                                id={"cu-role"}
                                placeholder={"Enter your role at the company"}/>
                        </div>
                        <div className='flexRow'>
                            <SimpleInput
                                label={"* Email"}
                                type={"text"}
                                on_change={(e) => setEmail(e.target.value)}
                                id={"cu-email"}
                                placeholder={"Enter your email"}/>
                            <SimpleInput
                                label={"Phone Number"}
                                type={"text"}
                                on_change={(e) => setPhone(e.target.value)}
                                id={"cu-pn"}
                                placeholder={"Enter your phone number"}/>
                        </div>
                    </div>
                    <div className={`text-1-2 in-input-err${errorText ? "" : " d-none"}`}>
                        {errorText}
                    </div>
                    <Btn 
                        text="Contact Sales"
                        type="primary" 
                        onclick={handleFormSubmit}
                        extraClasses="text-1-3 btn-radius6" />
                </div>
            </div>
        </div>
    )
}