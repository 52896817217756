import React,{ useState , useCallback } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import styles from '../../../table-widget.module.css';
//components
import ThreeDotsLoader from '../../../../loaders/ThreeDotsLoader';
import ExpandedDetails from './components/ExpandedDetails';
import SortSelect from './components/SortSelect';
import { CommonHeader } from '../../../tableConfig';


// DesktopRow Component
const DesktopRow = ({ columns, rowData, setNotifState, onToggle, isExpanded }) => {
    console.log(rowData)
 
    return (
        <>
            <tr key={rowData.pid} className="cursor-pointer" onClick={onToggle}>
                {columns.map((column) => (
                    <td 
                        key={column.key} 
                        className={"p-4 text-sm border-b border-gray-100" + column.cellsExtraClasses} 
                        {...((column.showTitle && !column.customRenderer) ? {title: rowData[column.key]} : {})}
                    >
                        {column.customRenderer ? 
                            column.customRenderer(rowData, setNotifState) : 
                            rowData[column.key]}
                    </td>
                ))}
            </tr>
            {isExpanded && <ExpandedDetails pid={rowData.pid} isOpen={isExpanded} columnsNumber={columns.length} />}
        </>
    );
};

// MobileRow Component
const MobileRow = ({ columns, rowData, setNotifState, onToggle, isExpanded }) => {
    return (
        <div className="border border-gray-200 rounded-lg p-4 mb-4 bg-white shadow-sm" onClick={onToggle}>
            {columns.map((column) => {
                if (!column.isVisibleOnMobile) return null; // Skip columns not visible on mobile
                // If the column key is 'actions', we want to handle it differently
                if (column.key === CommonHeader.ACTION) {
                    return (
                        <div className="mb-2 flex justify-end" key={column.key} >
                                {column.customRenderer ? 
                                    column.customRenderer(rowData, setNotifState) : 
                                    rowData[column.key]}   
                        </div>
                    );
                }

                // Default rendering for non-action columns
                return (
                    <div className="flex mb-2" key={column.key}>
                        <span className="text-sm font-light text-gray-700">{`${column.value}:`}</span>
                        <span className="text-sm font-normal text-gray-900 truncate ml-1">
                            {column.customRenderer ? 
                                column.customRenderer(rowData, setNotifState) : 
                                rowData[column.key]}
                        </span>
                    </div>
                );
            })}
            {isExpanded && <ExpandedDetails pid={rowData.pid} isOpen={isExpanded} />}
        </div>
    );
};

const TableView = ({ columns, data, loading, onSort, isMobile, setNotifState, currentSortCol, showExpandedRows=false }) => {
    const [expandedRows, setExpandedRows] = useState({});
    
    const onToggle = useCallback((pid) => {
        if (showExpandedRows) {
            setExpandedRows(prev => ({
                ...prev,
                [pid]: !prev[pid], 
            }));
        }
    }, []);
    
    const SortableColumns = columns.filter(column => column.isSortable);
    const visibleColumns = isMobile ? 
        columns.filter(column => column.isVisibleOnMobile) : 
        columns.filter(column => column.isVisibleOnDesktop);
    
    return (
        <>
            {!isMobile ? (
                <>
                <table className={styles.table} style={{tableLayout: "fixed"}}>
                    <thead className="bg-white cursor-default">
                        <tr>
                            {visibleColumns.map((column, index) => (
                                <th 
                                    key={column.key} 
                                    className={"text-left text-sm font-semibold text-gray-900 cursor-pointer p-3" + column.headersExtraClasses}
                                    onClick={() => onSort(index)}
                                    {...(column?.title ? {title: column.title} : {})}
                                >
                                    <div className="flex items-center justify-between">
                                        <span>{column.value}</span>
                                        {column.isSortable && (
                                            <ChevronDownIcon
                                                className={`w-4 h-4 ${currentSortCol && currentSortCol[0] === index ? 'text-blue-400 hover:text-blue-600' : 'text-gray-400 hover:text-gray-600'}${currentSortCol && currentSortCol[0] === index && currentSortCol[1] ? ' rotate-180' : ''} transition-transform transform hover:scale-110`}
                                            />
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {(data && !loading) && 
                            data.map((rowData) => (
                                <DesktopRow 
                                    key={rowData.pid} 
                                    columns={visibleColumns} 
                                    rowData={rowData} 
                                    setNotifState={setNotifState} 
                                    onToggle={() => onToggle(rowData.pid)} 
                                    isExpanded={expandedRows[rowData.pid] || false} 
                                />
                            ))             
                        }
                    </tbody>
                </table>
                { (data && loading) &&
                    <div className="flex justify-center items-center">
                        <ThreeDotsLoader height="40" width="40" color="#1890ff" radius="9" />
                    </div>
                } 
                </>
            ) : (
                <ul className="block w-full">
                   <div className="p-4">
                        <SortSelect options={SortableColumns} sortColmn={onSort} />
                   </div>
                
                    {data && !loading ? (
                        data.map((rowData) => (
                            <MobileRow 
                                key={rowData.pid} 
                                columns={visibleColumns} 
                                rowData={rowData} 
                                setNotifState={setNotifState} 
                                onToggle={() => onToggle(rowData.pid)} 
                                isExpanded={expandedRows[rowData.pid] || false} 
                            />
                        ))
                    ) : (
                        <li className="flex justify-center items-center">
                            <ThreeDotsLoader height="40" width="40" color="#1890ff" radius="9" />
                        </li>
                    )}
                </ul>
            )}
        </>
    );
};


export default TableView;
