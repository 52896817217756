import React from 'react'

const checkIcon = ({ fill = 'none', width = '18px', height = '18px', styleClass = "" }) => {
  return (
    <svg
        className={`transition duration-75 ${styleClass}`}
        width={width}
        height={height}
        fill={fill}
        viewBox='0 0 18 18'
      >
        <path d="M9.33398 16.5C13.4761 16.5 16.834 13.1421 16.834 9C16.834 4.85786 13.4761 1.5 9.33398 1.5C5.19185 1.5 1.83398 4.85786 1.83398 9C1.83398 13.1421 5.19185 16.5 9.33398 16.5Z" stroke="#3FAB0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.08398 9L8.58398 10.5L11.584 7.5" stroke="#3FAB0B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default checkIcon

