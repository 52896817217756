import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
//components
import BaseModal from '../../../../../components/Modal'
import { SingleRadioInput } from "../../../../../components/radioInput/radioInput";
import Btn from "../../../../../components/buttons/standard/btn";
import Notification from "../../../../../components/side_notification/side_notification";
//constants
import { para_be } from "../../../../../config";
import { fetchPmsData } from "../../../../../store/slices/pmsSlice";
import { dateTimeFormater } from "../../../../../utils/date_utils";


const SearchFilterModal = ({ open, handleClose, performFetch }) => {
    const dispatch = useDispatch();
    const { activePMS } = useSelector(state => state.pms);
    const { filters: currentFilters } = useSelector(state => state.table);

    const [allProperties, setAllProperties] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const [searchFilterModalVals, setSearchFilterModalVals] = useState({});
    
    useEffect(() => {
        console.log("calling fetchPmsData");
        dispatch(fetchPmsData({}));
        setSearchFilterModalVals({
            date_range: currentFilters?.date_range?.opt || "any",
            scan_type: currentFilters?.scan_type?.opt || "any",
            scan_status: currentFilters?.scan_status?.opt || "any",
            selected_properties: currentFilters?.["select_properties"] || []
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        console.log("activePMS:", activePMS);
        if (activePMS) {
            fetch(para_be + '/units/get_all_properties', {credentials: "include"})
            .then(response => response.json())
            .then(response => {
                //DATA 0-report id, 1-status, 2-datetime
                if (response.status === 200) {
                    setAllProperties(response.result);
                } else setNotifState(response.msg);
            })
            .catch(error => {
                setNotifState("An error has occurred while fetching all properties, please try again later");
            });
        }
    }, [activePMS]);

    const dateDaysFormat = (days = 0) => {
        const dateObj = new Date();
        dateObj.setDate(dateObj.getDate() + days);
        return dateTimeFormater(dateObj.toISOString(), "yyyy/mm/dd");
    };

    const applyChanges = () => {
        let newFilters = {...currentFilters};
        for (let k of Object.keys(searchFilterModalVals)) {
            if (k === "date_range") {
                if (searchFilterModalVals[k].endsWith("d")) {
                    const num = parseInt(searchFilterModalVals[k].replace("d", ""))*-1;
                    newFilters['date_range'] = {
                        opt: "range", 
                        filter_type: "date",
                        value: [dateDaysFormat(num), dateDaysFormat(0)]
                    };
                } else if (searchFilterModalVals[k] === "range") {
                    if (
                        !searchFilterModalVals["date_range_value"]?.[0] || 
                        !searchFilterModalVals["date_range_value"]?.[1]
                    ) {
                        setNotifState({msg: "Please select a valid date range", type: "error"});
                    }
                    newFilters['date_range'] = {
                        opt: searchFilterModalVals[k], 
                        filter_type: "date",
                        value: [
                            searchFilterModalVals["date_range_value"]?.[0],
                            searchFilterModalVals["date_range_value"]?.[1]
                        ]
                    };
                } else {
                    delete newFilters['date_range'];
                }
            } else if (k === "selected_properties") {
                newFilters['select_properties'] = searchFilterModalVals[k];
                if (newFilters['select_properties'].length === 0) {
                    delete newFilters['select_properties'];
                }
            } else if (k === "scan_type" || k === "scan_status") {
                if (searchFilterModalVals[k] === "any") {
                    delete newFilters[k];
                } else {
                    newFilters[k] = {opt: searchFilterModalVals[k], value: searchFilterModalVals[k]};
                }
            }
        }

        performFetch(newFilters);
        handleClose();
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    const createRadioGroup = (rg_id, rg_name, k, rg_label, rg_items) => {
        return (
            <div className="radio-group-container" id={rg_id}>
                <div className="text-1-3">{rg_label}</div>
                {
                    rg_items.map( (radioItem) => {
                        return (
                            <SingleRadioInput
                                name={rg_name}
                                label={radioItem.label}
                                value={radioItem.value}
                                checked={searchFilterModalVals[k] === radioItem.value}
                                onclick={() => (
                                        setSearchFilterModalVals({...searchFilterModalVals, [k]: radioItem.value})
                                    )
                                }
                            >
                                {radioItem.customRenderer && radioItem.customRenderer(searchFilterModalVals[k] === radioItem.value)}
                            </SingleRadioInput>
                        )
                    })
                }
            </div>
        )
    };

    const createCheckboxGroup = (checkboxG_label, groupName, checkboxItems) => {
        return (
            <div className="checkbox-group-container">
                <div className="text-1-3">{checkboxG_label}</div>
                {checkboxItems.map( (checkboxItem) => (
                    <div className="checkbox-container-1 flexRow" onClick={() => {
                        let selectedItems = Array.prototype.slice.call((searchFilterModalVals[groupName] || []));
                        if (selectedItems.includes(checkboxItem.value)) {
                            selectedItems.splice(selectedItems.indexOf(checkboxItem.value), 1);
                        } else selectedItems.push(checkboxItem.value);
                        setSearchFilterModalVals({...searchFilterModalVals, [groupName]: selectedItems});
                    }}>
                        <input 
                            class="mul-selection-checkbox" 
                            type="checkbox" 
                            name={groupName} 
                            value={checkboxItem.value} 
                            checked={searchFilterModalVals[groupName]?.includes(checkboxItem.value)}
                        />
                        <div class="text-2">{checkboxItem.label}</div>
                    </div>
                ))}
            </div>
        )
    };

    
    return (
        <>
            <BaseModal
                open={open}
                onClose={handleClose}
                maxWidth={800}
            >
                <div>
                    <div className="h2 blue-headline">Filter</div>
                    <div className="sf-body custom-scrollbar-1">
                        {createRadioGroup(
                                "scan-date", "scan-date", "date_range", "Scan Date", 
                                [
                                    {'label': "Any", 'value': "any"},
                                    {'label': "Yesterday", 'value': "1d"},
                                    {'label': "Past 7 Days", 'value': "7d"},
                                    {'label': "Past 30 Days", 'value': "30d"},
                                    {
                                        'label': "Set Range", 
                                        'value': "range", 
                                        'customRenderer': (isChecked) => (
                                            isChecked ? 
                                                <div className="range-input flexRow">
                                                    <input 
                                                        id="date-range-start" 
                                                        type="date" 
                                                        className="text-1" 
                                                        onChange={(e) => setSearchFilterModalVals(
                                                            {...searchFilterModalVals, "date_range_value": [e.target.value, searchFilterModalVals["date_range_value"]?.[1]]}
                                                        )}
                                                        value={currentFilters?.date_range_value?.[0]} />
                                                    <span>-</span>
                                                    <input 
                                                        id="date-range-end" 
                                                        type="date" 
                                                        className="text-1" 
                                                        onChange={(e) => setSearchFilterModalVals(
                                                            {...searchFilterModalVals, "date_range_value": [searchFilterModalVals["date_range_value"]?.[0], e.target.value]}
                                                        )}
                                                        value={currentFilters?.date_range_value?.[1]} />
                                                </div>
                                                : null
                                        )
                                    }
                                ]
                            )
                        }
                        {createRadioGroup(
                                "scan-type", "scan-type", "scan_type", "Scan Type", 
                                [
                                    {'label': "Any", 'value': "any"},
                                    {'label': "Baseline Scan", 'value': "baseline"},
                                    {'label': "Move-Out Scan", 'value': "checkout"},
                                    {'label': "Move-In Scan", 'value': "checkin"}
                                ]
                            )
                        }
                        {createRadioGroup(
                                "scan-status", "scan-status", "scan_status", "Scan Status", 
                                [
                                    {'label': "Any", 'value': "any"},
                                    {'label': "Initialized", 'value': "initialized"},
                                    {'label': "In Progress", 'value': "ongoing"},
                                    {'label': "Done", 'value': "done"},
                                    {'label': "Incomplete", 'value': "stale session"},
                                ]
                            )
                        }
                        
                        { activePMS &&
                            createCheckboxGroup(
                                "Properties", "selected_properties",
                                (allProperties || []).map( (propertyItem) => {
                                    return {
                                        label: `${propertyItem.name} (${propertyItem.count})`, 
                                        value: propertyItem.name.toLowerCase()
                                    }
                                })
                            )
                        }
                    </div>
                    <div className="btns-wrapper flexRow mt-6">
                        <Btn 
                            text="Cancel"
                            type="secondary"
                            extraClasses="text-1-3 mr-2"
                            onclick={() => handleClose()}/>
                        <Btn 
                            text="Apply Filters"
                            type="primary"
                            extraClasses="text-1-3"
                            onclick={() => applyChanges()}/>
                    </div>
                </div>

                {notifState ?
                    <Notification
                        closeFunc={handleCloseNotif}
                        text={notifState}
                        type="error"/> : ""
                }
            </BaseModal>
        </>
    )
}

export default SearchFilterModal;