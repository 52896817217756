import React from "react";
//styles
import "./empty_state.css"
//assets
import EmptyStateImg from "../../assets/icons/empty-state.svg"

export default function EmptyState (props) {

    return (
        <section className={"base-empty-state" + (props.nonAbs ? " non-abs-empty-state" : " empty-state")}>
            <img src={EmptyStateImg} className={props.size} alt="empty selection"/>
            <div className="text-1-2">{props.text}</div>
        </section>
    )
}