import React, {useState, useEffect, useRef} from "react";
import { Helmet } from "react-helmet";

//styles
import "../management/management.css";
//components
import Btn from "../../components/buttons/standard/btn";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_state";
import Notification from "../../components/side_notification/side_notification";
import { postReqOptBuilder } from "../../utils/main_utils";
//utils
//assets
//constants

export default function AdminViewUniFrames(props) {
    const baselineID = props.match.params.baseline_id;
    // state based variables
    const [skippedFramesList, setSkippedFramesList] = useState();
    const [listOfFrames, setListOfFrames] = useState();
    const [isLoader, setLoader] = useState(true);
    const [isLoadingBtn, setLoadingBtn] = useState(null);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [notifState, setNotifState] = useState(null);
    
    //load of initial data
    useEffect(() => {
        fetchFrameIndexes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchFrameIndexes = () => {
        setLoader(true);
        fetch(props.para_be + "/scan/fetchScanUframes?baseline_id=" + baselineID)
        .then(response => response.json())
        .then(response => {
            console.log("fetch unique frames result:", response);
            if (response.status === 200) {
                setSkippedFramesList(response.result[1]);
                setListOfFrames(Object.keys(response.result[0][2]).map( (frame, idx) => idx));
            } else {
                setMainEmptyState(true);
            }
            setLoader(false);
        })
        .catch(error => {
            setMainEmptyState(true);
            setNotifState({text: "Can't fetch data", type: "error"});
            setLoader(false);
        })
    };

    const updateUFrameSkipList = (frame_idx, skip) => {
        setLoadingBtn(frame_idx);
        fetch(
            props.para_be + "/scan/updateUFrameSkipList", 
            postReqOptBuilder({baseline_id: baselineID, frame_idx, skip}, true)
        )
        .then(response => response.json())
        .then(response => {
            console.log("update unique frame skip list:", response);
            if (response.status === 200) {
                if (skip === "true") {
                    setSkippedFramesList((prev) => [...prev, frame_idx]);
                } else {
                    setSkippedFramesList((prev) => prev.map((x) => x === frame_idx ? null : x).filter((x) => x));
                }
                setNotifState({text: "Successfully updated the unique frames", type: "success"});
            } else {
                setNotifState({text: "Failed fetching data", type: "error"});
            }
            setLoadingBtn(null);
        })
        .catch(error => {
            setMainEmptyState(true);
            setNotifState({text: "Can't fetch data", type: "error"});
            setLoadingBtn(null);
        })
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    }


    return (
        <section className="mgmt-main">
            <Helmet>
                <title>Paraspot Admin | Scans Management | View Scan</title>
                <meta name="description" content="."/>
                <meta property="og:title" content="Paraspot Admin | Scans Management"/>
                <meta property="og:description" content="."/>
            </Helmet>
            <div className="flexRow" style={{flexWrap: 'wrap'}}>
                {isLoader ? <MainLoader/> : 
                    isMainEmptyState ? <EmptyState text={"No frames to fetch from scan at the moment"}  size="lg"/> : 
                    listOfFrames.map((frame_idx) => {
                        return (
                            <div className="mb-4 mr-4">
                                <h2 className="w-full text-center font-bold py-1">Frame {frame_idx}</h2>
                                <div className="max-h-60 overflow-hidden">
                                    <img
                                        className="max-h-60 h-full cursor-pointer"
                                        src={`https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_${baselineID}?idx=${frame_idx}_orig_size&lt=1`}
                                        alt="frame" 
                                        onClick={() => { 
                                            window.open(
                                                `https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_${baselineID}?idx=${frame_idx}_orig_size&lt=1`, 
                                                '_blank'
                                            ); 
                                        }} />
                                </div>
                                <Btn 
                                    type={skippedFramesList.includes(frame_idx) ? "secondary" : "primary"}
                                    text={
                                        isLoadingBtn===frame_idx ? "Loading..." :
                                        skippedFramesList.includes(frame_idx) ? "Include Frame" : "Skip Frame"
                                    } 
                                    extraClasses={"w-full"}
                                    onclick={() => { 
                                        updateUFrameSkipList(
                                            frame_idx, 
                                            skippedFramesList.includes(frame_idx) ? "false" : "true"
                                        );
                                    }} 
                                />
                            </div>
                        )
                    })
                }
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </section>
    )
}