import React from 'react'

const PlayIcon = ({ fill = 'none', width = '20px', height = '20px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 18 18'
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M0.3125 6C0.3125 2.85875 2.85875 0.3125 6 0.3125C9.14125 0.3125 11.6875 2.85875 11.6875 6C11.6875 9.14125 9.14125 11.6875 6 11.6875C2.85875 11.6875 0.3125 9.14125 0.3125 6ZM8.49317 5.42658C8.59535 5.48346 8.68048 5.5666 8.73975 5.66742C8.79901 5.76823 8.83027 5.88305 8.83027 6C8.83027 6.11695 8.79901 6.23177 8.73975 6.33258C8.68048 6.4334 8.59535 6.51654 8.49317 6.57342L5.22475 8.38933C5.12488 8.44477 5.01226 8.47318 4.89805 8.47175C4.78383 8.47032 4.67196 8.43911 4.5735 8.38119C4.47505 8.32327 4.39342 8.24066 4.33668 8.14152C4.27994 8.04238 4.25006 7.93014 4.25 7.81592V4.18408C4.25 3.68417 4.78725 3.36742 5.22475 3.61067L8.49317 5.42658Z" fill="#918BEF"/>
    </svg>
  );
};

export default PlayIcon