import React, {useState, useEffect, useRef} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from "react-helmet";
import { pdf } from '@react-pdf/renderer';
//containers
import ChecklistPopup from "./checklist_popup/checklist_popup";
//components
import Btn from "../../components/buttons/standard/btn";
import BackBtn from "../../components/buttons/back_btn/back"
import ReportCard from "../../components/cards/report_card/report_card";
import EmptyState from "../../components/emptyState/empty_state";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import Notification from "../../components/side_notification/side_notification";
import MasterPopup from "../../components/popups/main_popup";
import ReportPDF from "../../components/pdf/report_pdf/report_pdf";
import ViewBaseline from "../../components/popups/view_baseline/view_baseline";
import ConfirmPopup from "../../components/popups/confirmation/confirmation_popup";
import SimpleLoader from "../../components/scanner/loader/simple-loader";
//style
import "./review_page.css";
//utils
import { disableBodyScroll, createDictFromHashedUrl, postReqOptBuilder } from "../../utils/main_utils";
import { dateTimeFormater } from "../../utils/date_utils";
//assets
import chIcon from "../../assets/icons/check-circle.svg";
import { postLogEntry } from "../../store/slices/activitySlice";



const hashInitailState = {
    ci_id: "",
    comment_id: ""
};

export default function ReviewPage (props) {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);
    const cred = useSelector((state) => state.auth.cred);
    const { lastPage } = useSelector(state => state.config);

    const cid = cred?.cid ?? null;
    const uid = cred?.uid ?? null;
    
    const reportId = props.match.params.rid;
    const [reportData, setReportData] = useState(null); //structure: [subject, report_date, [overview issues], [major_issues], media_src]
    const [hasBaseline, setHasBaseline] = useState(null); //structure: [subject, report_date, [overview issues], [major_issues], media_src]
    const [ignoreList, setIgnoreList] = useState([]);
    const [inventoryCategories, setInventoryCategories] = useState(null);
    const [selectedInventoryCategories, setSelectedInventoryCategories] = useState([]);
    const [inspectionType, setInspectionType] = useState(null);
    const [kitchenwareViewData, setKitchenwareViewData] = useState(null);
    const [meterViewData, setMeterViewData] = useState(null);
    const [guestData, setGuestData] = useState(null);
    const [presentImState, setPresentImState] = useState(null);
    const [pid, setPid] = useState(null);
    const [videoURL, setVideoURL] = useState("#");
    const [isOverviewLoading, setOverviewLoader] = useState(true);
    const [activeTab, setActiveTab] = useState(true);
    const [activeBd, setActiveBd] = useState();
    const [editPicView, setEditPicView] = useState(0);
    const [mediaData, setMediaData] = useState(null);
    const [presentImView, setPresentImView] = useState(null);
    const [presentPrevScan, setPresetPrevScan] = useState(null);
    const [selectedImgViews, setSelectedImgViews] = useState(null);
    const [currentImgView, setCurrentImgView] = useState(0);
    const [checklistView, setChecklistView] = useState(false);
    const [moreBtn, setMoreBtn] = useState(false);
    const [pastScansList, setPastScansList] = useState(null);
    const [showPastScans, setShowPastScans] = useState(false);
    const [showExportPDF, setShowExportPDF] = useState(false);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [numberOfChecklistItems, setNumberOfChecklistItems] = useState(0);
    const [hashParams, setHashParams] = useState(hashInitailState);
    const [forceRefresh, setForceRefresh] = useState(true);
    //state for all screen data
    //0-overview, 1-major issues, 2-inventory, 3-structural defects, 4-inventory defects, 5-organization
    const [emptyStates, setEmptyStates] = useState([true,true,true,true,true,true]);
    const linkState = {
            "from": window.location.pathname,
            "sortColumn": props.location.state ? props.location.state.sortColumn : null,
            "sortDirection": props.location.state ? props.location.state.sortDirection : null,
            "search": props.location.state ? props.location.state.search : null,
            "page": props.location.state ? props.location.state.page : null,
            "pid": props.location.state ? props.location.state.pid : null,
            "subject": props.location.state ? props.location.state.subject : null,
            "isAllReports": props.location.state ? props.location.state.isAllReports : null,
            "reportId": props.location.state ? props.location.state.reportId : window.location.pathname.split("/")[2]
    };
    const historyKey = window.history.state ? window.history.state.key : "";

    //Disable body scroll when checklist opened
    useEffect(() => {
        disableBodyScroll(checklistView);
    }, [checklistView]);

    useEffect(() => {
        setCurrentImgView(0);
        if (presentImView !== null && !Array.isArray(presentImView)) {
            fetch(presentImView['media'].replace("getObjectFromScan", "getObjectViewsFromScan"))
            .then(response => response.json())
            .then(response => {
                setSelectedImgViews(response.result);
            });
        } else if (presentImView === null) {
            setSelectedImgViews(null);
            setPresentImState(null);
            setEditPicView(0);
        }
    }, [presentImView]);

    useEffect(() => {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {'Content-Type': 'application/json', 'withCredentials': true},
        //     body: JSON.stringify({ "report_id":linkState.reportId})
        // };
        if (!document.body.onhashchange) document.body.onhashchange = e => handleHashUrl(e);
        console.log(linkState);
        console.log("lastPage:", lastPage);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        fetch(props.para_be + '/checklists/get_openItems_amount?report_id=' + linkState.reportId, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                setNumberOfChecklistItems(response.result);
            } else {
                setNotifState({text: "Failed to load open Checklist items", type: "error"});
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: "Failed to load open Checklist items", type: "error"});
        });

        fetch(props.para_be + '/reports/get_report_data?report_id=' + linkState.reportId, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if (response.status === 200) {
                //result 0-subject,1-report date, 2-overview, 3-major issues, 4-video src, 5-summary json
                let newEmptyState = emptyStates.splice(0);
                if (response.result[2].length > 0) newEmptyState[0] = false;
                if (response.result[3].length > 0) newEmptyState[1] = false;
                // if (!response.result[11]) {
                //     if (response.result[5].inventory.length > 0) newEmptyState[2] = false;
                //     if (response.result[5].structural_defects.length > 0) newEmptyState[3] = false;
                //     if (response.result[5].inventory_defects.length > 0) newEmptyState[4] = false;
                //     if (response.result[5].organization.length > 0) newEmptyState[5] = false;
                // } else {
                //     if (response.result[5].inventory.length > 0) newEmptyState[2] = false;
                //     if (response.result[5].defects.length > 0) newEmptyState[3] = false;
                // }
                setReportData(response.result);
                validateVideoLink(response.result[4]);
                validateVideoLink(response.result[4].replace(".webm", ".mp4"));
                setPid(response.result[6]);
                if (response.result[7].kitchen_view.indexes.length > 0) setKitchenwareViewData(response.result[7].kitchen_view);
                if (response.result[7].meter_view.indexes.length > 0) setMeterViewData(response.result[7].meter_view);
                if (JSON.stringify(newEmptyState) !== JSON.stringify(emptyStates)) setEmptyStates(newEmptyState);
                // setNumberOfChecklistItems(response.result[5].inventory.length + response.result[5].structural_defects.length + response.result[5].inventory_defects.length + response.result[5].organization.length);
                const hashString = window.location.hash;
                if (hashString) {
                    setHashParams(createDictFromHashedUrl(hashString));
                    setChecklistView(true);
                    setForceRefresh(false);
                }
                setInspectionType(response.result[10]);
                if (response.result[8]) setGuestData(response.result[8]);
                if (response.result[12]) setInventoryCategories(response.result[12]);
                if (response.result[13]) setMediaData(response.result[13]);
            } else {
                console.log(response);
                setNotifState({text: "Failed to load report", type: "error"});
            }
            setOverviewLoader(false);
        })
        .catch ( error => {
            setOverviewLoader(false);
            setNotifState({text: "Failed to load report", type: "error"});
        });

        fetch(props.para_be + '/reports/get_past_scans?report_id=' + linkState.reportId, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setPastScansList(response.result);
            } else {
                setNotifState({text: "Failed to load past scans", type: "error"});
            }
        })

        dispatch(postLogEntry( 
            { 
                activityID: linkState.reportId, activityType: 'report', ip: endUserData?.ip, 
                action: {action: "view", target: "report"} 
            }
        ));
    }, [forceRefresh]);

    const handleHashUrl = (e) => {
        console.log("At handleHashUrl");
        console.log(e);
        const hashDict = createDictFromHashedUrl(window.location.hash);
        console.log("Hash:", hashDict);
        let l = Object.keys(hashDict).length > 0;
        setHashParams(l ? hashDict : hashInitailState);
        if (l) setChecklistView(true);
    };

    window.onpopstate = (e) => {
        window.history.replaceState({'key': window.history.state && window.history.state.key ? window.history.state.key : historyKey, 'state': linkState}, 'state');
    };

    const validateVideoLink = (l) => {
        fetch(l, {method: 'HEAD'})
        .then((response) => {
            if (response.status === 200) {
                setVideoURL(l);
            }
        });
    };
    
    const togglePanel = (panelNumber) => {
        setActiveBd(activeBd === panelNumber ? null : panelNumber);
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    const onAddToChecklist = () => {
        setNumberOfChecklistItems(parseInt(numberOfChecklistItems) + 1);
    };

    const handleClosePopup = () => {
        setPopupState(null);
    };

    const turnReportToBaselinePopup = () => {
        setPopupState([<ConfirmPopup
            headlineText={"Are you sure?"}
            mainText={"Are you sure you want to turn this scan to your baseline?"}
            confirmText={"Approve"}
            declineText={"Cancel"}
            confirmChoice={() => { turnReportToBaseline() }}
            declineChoice={handleClosePopup}
            closeFunc={handleClosePopup}/>,
        {closeFunc: handleClosePopup}]);
    }

    const turnReportToBaseline = () => {
        setPopupState([<SimpleLoader />, {}]);
        fetch(props.para_be + "/scan/set_scan_as_baseline", postReqOptBuilder({rid: reportId}))
        .then((response) => response.json())
        .then((response) => {
            handleClosePopup(true);
            if (response.status === 200) {
                setNotifState({
                    text: "Successfully turned the scan to baseline", 
                    type: "success"
                });
            } else {
                setNotifState({
                    text: "Something went wrong while turning this scan to baseline", 
                    type: "error"
                });
            }
        })
        .catch((error) => {
            handleClosePopup(true);
            setNotifState({
                text: "Something went wrong while turning this scan to baseline", 
                type: "error"
            });
        })
    }

    const exportPDF = async () => {
        let clientLogo = await fetch(props.para_be + '/media/get_company_logo', {credentials: "include"})
        .then(response => response.json())
        .then(response => (response.status === 200 && response.result && response.result.length > 0) ? response.result : null)
        .catch(error => { console.log(error); return null; });
        let includeIssues = Array.from(document.getElementsByName("export-pdf-include")).filter((x) => x.checked).map((x) => x.value);
        let objIdsDescriptions = Object.fromEntries(includeIssues.map((obj_id) => [obj_id, document.querySelector("#exp-item-" + obj_id + " textarea").value]));
        let customText = document.getElementById("exp-pdf-customText").value;
        let reportItems = Object.fromEntries(
            Object.entries(reportData[5]).map(
                ([k,v]) => [k, Array.from(v).map((o) => { 
                    return {
                        'img': (reportData[11] || o['issue_type'] === 'Defect') ? (o['media'] + "&zoom_out=true") : o['before_media'], 
                        ...(!reportData[11] ? 
                            {
                                'img_before': o['issue_type'] !== 'Defect' ? (o['media'] + "&zoom_out=true") : o['before_media'],
                                'before_date': o['before_date']
                            } : {}
                        ), 
                        'object_id': o['object_id'], 
                        'subject': o['subject'], 
                        'issue_type': o['issue_type'], 
                        'description': Object.keys(objIdsDescriptions).includes(o['object_id']) ? objIdsDescriptions[o['object_id']] : ""
                    }; 
                }).filter(
                    (o) => includeIssues.includes(o['object_id'])
                )]
            )
        );

        dispatch(postLogEntry( 
            { 
                activityID: linkState.reportId, activityType: 'report', ip: endUserData?.ip, 
                action: {action: "export-pdf", target: "report"} 
            }
        ));

        console.log(`Rendering PDF of the report.\nSubject: ${reportData[0]}\nDate-Time: ${reportData[1]}\nCustom Text: ${customText}`);
        console.log("Report Items:", reportItems);
        console.log("Client Logo:", clientLogo);
        // ReactPDF.render(<ReportPDF
        //     subject={reportData[0]} report_datetime={reportData[1]} clientLogo={clientLogo} customText={customText} reportItems={reportItems}
        // />, `Report__${reportData[0].replace(" ", "_").replace(".", "")}__${reportData[1].split(" ")[0].replace("/", "")}`);
        let pdfDoc = await pdf((<ReportPDF subject={reportData[0]} report_datetime={reportData[1]} clientLogo={clientLogo} customText={customText} reportItems={reportItems}/>)).toBlob();
        let pdfURL = window.URL.createObjectURL(pdfDoc);
        let downloadLink = document.createElement('a');
        downloadLink.href = pdfURL;
        downloadLink.setAttribute('download', `Report__${reportData[0].replace(" ", "_").replace(".", "")}__${reportData[1].split(" ")[0].replace("/", "")}`);
        downloadLink.click();
    };

    const handleHide = () => {
        setChecklistView(false);
        window.location.hash = '';
        setHashParams(hashInitailState);
    }

    return (
        <section className="report-body flexColumn">
            <Helmet>
                <title>Paraspot | {inspectionType === "checkin" ? 'Move In' : 'Move Out'} Report - {reportData ? reportData[0] : ""}</title>
                <meta name="description" content={`${inspectionType === "checkin" ? 'Move in' : 'Move out'} report of the unit ${reportData ? reportData[0] : ""} from ${reportData ? reportData[1] : ""}`}/>
                <meta property="og:title" content={`Paraspot | ${inspectionType === "checkin" ? 'Move In' : 'Move Out'} Report - ${reportData ? reportData[0] : ""}`}/>
                <meta property="og:description" content={`${inspectionType === "checkin" ? 'Move in' : 'Move out'} report of the unit ${reportData ? reportData[0] : ""} from ${reportData ? reportData[1] : ""}`}/>
            </Helmet>
            {presentImView &&
                <MasterPopup extraClasses="non-padded-popup image-presentation-popup" closeFunc={() => { console.log("Closing popup..."); setPresentImView(null); }}>
                    <>
                        {!Array.isArray(presentImView) && presentImView?.media?.includes("/cva/v2/getObjectFromScan") && 
                            <div className="images-gallery">
                                { !reportData[11] && 
                                    <div className="switch-img-view text-2 unselectable" onClick={() => setPresentImState(presentImState === 1 ? 0 : 1)}>Show {(presentImState || 0) === 0 ? "After" : "Before"}<br/>View</div>
                                }
                                {
                                    [...Array(selectedImgViews ? selectedImgViews : 0).keys()].map((i) => {
                                        return <img 
                                                    alt="issue-pic-item" 
                                                    src={presentImView['media'] + "&view_idx=" + i + "&zoom_out=true"} 
                                                    onClick={() => {
                                                        setCurrentImgView(i);
                                                        if (document.querySelector(".image-presentation > img")) document.querySelector(".image-presentation > img").src = presentImView['media'] + "&view_idx=" + i + "&zoom_out=true";
                                                        if (document.querySelector(".image-presentation > div:first-child > img")) document.querySelector(".image-presentation > div:first-child > img").src = (presentImView['issue_type'] !== 'Defect' ? presentImView['media'] : presentImView['before_media']) + "&view_idx=" + i + "&zoom_out=true";
                                                        if (document.querySelector(".image-presentation > div:last-child > img")) document.querySelector(".image-presentation > div:last-child > img").src = (presentImView['issue_type'] === 'Defect' ? presentImView['media'] : presentImView['before_media'])+ "&view_idx=" + i + "&zoom_out=true";
                                                    }}/>
                                    })
                                }
                            </div>
                        }
                        <div className="image-presentation">
                            {/* Style for im-presentation-opt
                                font-size: 14px;
                                text-align: center;
                                padding: 8px 0;
                                color: var(--colorB4);
                                cursor: pointer;
                                font-weight: bold;
                                color: #f0f0f0;
                                background: rgba(20,100,180,0.6);
                                text-shadow: 1px 1px black; */}
                            {/* <div>
                                <div class="im-presentation-opt">Show Before</div>
                            </div> */}
                            {}
                            {Array.isArray(presentImView) ?
                                <>
                                    <div className={(presentImState || 0) === 0 && "active-img"}>
                                        <div className="title-label h3 unselectable clickable" onClick={() => { setPresentImState(1) }}>Before</div>
                                        <img alt="issue-pic" src={presentImView[0]}/>
                                    </div>
                                    <div className={presentImState === 1 && "active-img"} onClick={() => { setPresentImState(0) }}>
                                        <div className="title-label h3 unselectable clickable">After</div>
                                        <img alt="issue-pic" src={presentImView[1]}/>
                                    </div>
                                    {/* <div class="image-presentation desktop-only">
                                        <div class="im-label h2">Before</div>
                                        <img alt="issue-pic" src={presentImView[0]} />
                                    </div>
                                    <div class="image-presentation desktop-only">
                                        <div class="im-label h2">After</div>
                                        <img alt="issue-pic" src={presentImView[1]} />
                                    </div>
                                    <div class="image-presentation non-desktop">
                                        <div class="im-label h2">Show {presentImState === 1 ? "Before" : "After"}</div>
                                        <img alt="issue-pic" src={presentImView[presentImState || 0]} />
                                    </div> */}
                                </> :
                                (reportData && (new Date("20" + reportData[1].split(" ")[0].split("/").reverse().join("-")) - new Date("2023-05-25")) >= 0) && presentImView.hasOwnProperty('media') && presentImView.hasOwnProperty('before_media') ?
                                <>
                                    <div className={(presentImState || 0) === 0 && "active-img"}>
                                        <div className="title-label h3">Before ({presentImView['before_date']})</div>
                                        {editPicView !== 0 ?
                                            <div className={"edit-pic-nav" + (editPicView === 1 ? " edit-mod-2" : "")}>
                                                <BackBtn clickFunction={() => { setEditPicView(1); }} />
                                                <div className="inner-edit-btns-layer flexRow">
                                                    <Btn 
                                                        text="Cancel"
                                                        type="secondary"
                                                        onclick={() => { setEditPicView(0); }}
                                                        extraClasses="text-3"
                                                    />
                                                    <Btn 
                                                        text="Select"
                                                        type="primary"
                                                        onclick={() => { 
                                                            setEditPicView(0); 
                                                            fetch(
                                                                props.para_be + "/reports/change_before_pic", postReqOptBuilder(
                                                                    {
                                                                        report_id: reportId, 
                                                                        itemID: presentImView['object_id'], 
                                                                        viewIdx: currentImgView, 
                                                                        new_pic_index: presentImView['before_media'].split("idx=")[1].split("_")[0], 
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                        extraClasses="text-3"
                                                    />
                                                </div>
                                            </div>
                                            : (
                                                presentImView['issue_type'] === 'Defect' ?
                                                    <Btn 
                                                        text="Change Before Picture"
                                                        type="secondary"
                                                        onclick={() => { setEditPicView(1); }}
                                                        extraClasses="change-pic-btn text-3"
                                                    /> : ""
                                            )
                                        }
                                        {editPicView === 1 ?
                                            <div className="imgs-selection-gallery flexRow custom-scrollbar custom-scrollbar-1">
                                                {
                                                    (mediaData && mediaData.available_frames > 0) &&
                                                    [...Array(mediaData.available_frames).keys()].map((i) => { 
                                                        return (
                                                            <div 
                                                                className="imgs-selection-gallery-item" 
                                                                onClick={() => { 
                                                                    setPresentImView({...presentImView, 'before_media': "https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_" + mediaData.ts + "?idx=" + i + "_orig_size&lt=1"});
                                                                    setEditPicView(2);
                                                                }}
                                                            >
                                                                <img src={"https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_" + mediaData.ts + "?idx=" + i + "_orig_size&lt=1"} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> :
                                            <img alt="issue-pic" src={(presentImView['issue_type'] !== 'Defect' ? presentImView['media'] : presentImView['before_media']) + "&zoom_out=true"}/>
                                        }
                                    </div>
                                    <div className={presentImState === 1 && "active-img"}>
                                        <div className="title-label h3">After</div>
                                        <img alt="issue-pic" src={(presentImView['issue_type'] === 'Defect' ? presentImView['media'] : presentImView['before_media']) + "&zoom_out=true"}/>
                                    </div>
                                </> :
                                <img alt="issue-pic" src={presentImView['media'] + "&zoom_out=true"}/>
                            }
                        </div>
                    </>
                </MasterPopup>
            }
            {presentPrevScan &&
                <MasterPopup extraClasses="has-bsp-container" closeFunc={() => { setPresetPrevScan(null); }}>
                    <ViewBaseline 
                        closeFunc={() => { setPresetPrevScan(null); }} 
                        para_be={props.para_be} 
                        pid={pid} 
                        subject={reportData ? reportData[0] : ""} 
                        extraClasses="has-bsp-container" 
                        {...presentPrevScan} />
                </MasterPopup>
            }
            <BackBtn linkType={true} to={{pathname: lastPage ? lastPage : "/management", state: linkState}} />
            {/* <Link to={{pathname: "/management", state: linkState}}>
                <BackBtn
                clickFunction={()=>(null)}/>
            </Link> */}
            <article className="ov-header">
                <div>
                    <div className="h2">{inspectionType === "checkin" ? 'Move In' : 'Move Out'} Report</div>
                    <div className="h2 blue-headline">{reportData ? reportData[0] : ""}</div>
                    <div className="text-1">{"Report date: " + (reportData ? reportData[1] : "")}</div>
                </div>

                <div className="ov-buttons flexRow">
                    <div className="exp-report-container dd-container-2 flexRow">
                        {(reportData && reportData[11]) ? 
                            <Btn
                                text="Turn to Baseline"
                                type="secondary"
                                onclick={() => { turnReportToBaselinePopup() }}
                                extraClasses="space-right1"
                            /> : ""
                        }
                        <Btn
                            text="View Past Scans"
                            type="secondary"
                            onclick={() => { setShowExportPDF(false); setShowPastScans(!showPastScans); }}
                            extraClasses="space-right1"
                        />
                        <div className={"past-scans-dd dd-items-2 dd-items-bordered custom-scrollbar-1" + (showPastScans ? "" : " d-none")}>
                            {
                                pastScansList ?
                                    pastScansList.map((scan) => {
                                        return (
                                            <div className="dd-item-2 flexRow" onClick={() => { setPresetPrevScan(scan); }}>
                                                <div className="text-1-3">{scan['scan_type']}</div>
                                                <div className="text-2">{scan['date']}</div>
                                            </div>
                                        )
                                    }) : 
                                    <EmptyState text="No past scan videos available." size ="md" />
                            }
                        </div>
                        <Btn
                            text="Export Report"
                            type="primary"
                            onclick={() => { setShowPastScans(false); setShowExportPDF(!showExportPDF) }}
                        />
                        <div className={"dd-items-2 dd-items-bordered custom-scrollbar-1" + (showExportPDF ? "" : " d-none")}>
                            <div className="dd-explanation">
                                <h2 className="h2">Export report to PDF</h2>
                                <p className="text-2">Here you can select the items of the report you would like to have included in a PDF that we will generate for you and add custom text you would like to include</p>
                            </div>
                            <div className="exp-pdf-note">
                                <div className="text-1-2">Custom Text</div>
                                <textarea id="exp-pdf-customText" placeholder="Enter here the text you would like to include in the generated PDF"></textarea>
                            </div>
                            <div className="dd-item-2 flexRow">
                                <input type="checkbox" className="mul-selection-checkbox" value="all" onChange={(e) => { if (e.currentTarget.checked) { document.getElementsByName("export-pdf-include").forEach((o) => { o.checked=true; }); e.currentTarget.checked = false; }}}/>
                                <div className="text-2">Select all</div>
                            </div>

                            {
                                reportData ? 
                                    (reportData[11] ?
                                        reportData[5].inventory.concat(reportData[5].defects) :
                                        reportData[5].inventory.concat(reportData[5].inventory_defects, reportData[5].structural_defects, reportData[5].organization)
                                    ).map((item) => {
                                    return (
                                        <div className="dd-item-2 bd-data-container flexRow" id={"exp-item-" + item['object_id']}>
                                            <input type="checkbox" className="mul-selection-checkbox" name="export-pdf-include" value={item['object_id']}/>
                                            <img src={item['media']} alt="report-Item"/>
                                            <div className="flexColumn">
                                                <div className="text-1-3">{item['subject']}</div>
                                                <div className="text-1">{item['description']}</div>
                                                <div className="exp-pdf-note exp-pdf-item-note">
                                                    <textarea placeholder="Describe item / charge" className="exp-pdf-item-text" rows="3"></textarea>
                                                </div>
                                                <div className="flexRow buttons-wrapper">
                                                    <Btn
                                                        text="Show full image"
                                                        type="primary"
                                                        onclick={() => { setPresentImView(item); }}
                                                        extraClasses="text4"
                                                    />
                                                    {/* <button class="btn-standard btn-primary text-4">Show full image</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : ""
                            }

                            <div className="eol-notice text-3">You are at the end of the line.</div>
                            <div className="dd-buttons-panel">
                                <button className="btn-standard btn-primary text-0" onClick={exportPDF}>Download PDF</button>
                            </div>
                        </div>
                    </div>
                    {/* <SimpleDropdownContainer
                        showDropdown={moreBtn}
                        borderedItems={true}
                        items={{'1': {'present': 'Lorem Ipsum'}, '2': {'present': 'Lorem Ipsum'}, '3': {'present': 'Lorem Ipsum'}}}
                    >
                        <img src={verticalDots} className="dots" onClick={() => setMoreBtn(!moreBtn)} alt="more button"/>
                    </SimpleDropdownContainer> */}
                </div>
            </article>

            <article className="ov-body">
                <video src={videoURL} controls muted playsInline>
                    {/* <source src={reportData ? reportData[4] : "#"}/>
                    <source src={reportData ? reportData[4].replace(".webm", ".mp4") : "#"}/> */}
                    <p>The video is not available or is not supported by your browser</p>
                </video>

                <div className={"ov-summary-container flexColumn " + ((emptyStates[0] || emptyStates[1] || isOverviewLoading)? "pos-relative" : "") }>
                    <div className="ov-summary-tabs flexRow">
                        <div className={"ov-tab text-1-3 " + (activeTab ? "active-tab" : "")} onClick={()=> setActiveTab(true)}>Tenant Details</div>
                        <div className={"ov-tab text-1-3 " + (activeTab ? "" : "active-tab")} onClick={()=> setActiveTab(false)}>Overview</div>
                    </div>

                    <div className="divider"/>

                    <div className={"ov-summary-data" + ((activeTab && emptyStates[0]) || (!activeTab && emptyStates[1]) ? " ov-empty-mobile": "")}>
                        {
                            isOverviewLoading ? <MainLoader/> :
                            <>
                                {activeTab ? (
                                        guestData ?
                                            <ul className={"ov-data text-1" + (activeTab ? " active-data" : "")}>
                                                {guestData &&
                                                    <li>
                                                        Tenant: {guestData['name']}<br/>
                                                        Stay: {dateTimeFormater(guestData['check-in'], "dd/mm/yyyy")} - {dateTimeFormater(guestData['checkout'], "dd/mm/yyyy")}<br/>
                                                        Email: {guestData['email']}<br/>
                                                        Mobile: {guestData['mobile']}<br/>
                                                    </li>
                                                }
                                            </ul> :
                                            <EmptyState text="No issues to present in Overview" size="sm" /> 
                                    ) : (
                                        emptyStates[0] ?
                                            <EmptyState text="No issues to present in Overview" size="sm" /> :
                                            <ul className={"ov-data text-1" + (activeTab ? "" : " active-data")}>
                                                {reportData[2].map((item) => <li>{item}</li>)}
                                            </ul>
                                    )
                                }
                                {/* {(activeTab && guestData) ? 
                                    <ul className={"ov-data text-1" + (activeTab ? " active-data" : "")}>
                                        {guestData &&
                                            <li>
                                                Tenant: {guestData['name']}<br/>
                                                Stay: {dateTimeFormater(guestData['check-in'], "dd/mm/yyyy")} - {dateTimeFormater(guestData['checkout'], "dd/mm/yyyy")}<br/>
                                                Email: {guestData['email']}<br/>
                                                Mobile: {guestData['mobile']}<br/>
                                            </li>
                                        }
                                    </ul>
                                    :
                                    <EmptyState text="No issues to present in Overview" size="sm" /> 
                                }
                                
                                {(!activeTab && emptyStates[0]) ? <EmptyState text="No issues to present in Overview" size="sm" /> :
                                    <ul className={"ov-data text-1" + (activeTab ? "" : " active-data")}>
                                        {reportData[2].map((item) => <li>{item}</li>)}
                                    </ul>
                                } */}
                            </>
                        }
                    </div>
                </div>
            </article>

            <article className="summary-breakdown">
                {
                    kitchenwareViewData && 
                    <>
                        <div className="kitchenware_view">
                            <div className="h3">Kitchenware View</div>
                            <div className="flexRow">
                                {kitchenwareViewData['indexes'].map( (i) => {
                                    let kv_after_addition = ((new Date("20" + reportData[1].split(" ")[0].split("/").reverse().join("-")) - new Date("2023-05-25")) >= 0) ? "_sims" : "";
                                    let kv_before = "https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_" + kitchenwareViewData["before"] + 
                                                    "?idx=" + i + "_orig_size&lt=1";
                                    let kv_after = "https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_" + kitchenwareViewData["after"] + kv_after_addition +
                                                    "?idx=" + i + "_orig_size&lt=1";
                                    return <section 
                                                className="view-t-card-body flexColumn" 
                                                id={"kit-v-" + i} 
                                                onClick={() => { 
                                                    setPresentImView({
                                                        'issue_type': 'Defect', 
                                                        'media': kv_after, 
                                                        'before_media': kv_before, 
                                                        'before_date': kitchenwareViewData['before_date']
                                                    }); 
                                                }}>
                                                <div className="view-t-card-label text-1-2">Click to View</div>
                                                <div className="flexRow">
                                                    <img src={kv_before} alt="" />
                                                    <img src={kv_after} alt="" />
                                                </div>
                                            </section>
                                })}
                            </div>
                        </div>
                        {/* <div className="h3">Report Issues</div> */}
                    </>
                }
                {
                    meterViewData && 
                    <>
                        <div className="kitchenware_view">
                            <div className="h3">Meter View</div>
                            <div className="flexRow">
                                {meterViewData['indexes'].map( (i) => {
                                    let mv_after_addition = ((new Date("20" + reportData[1].split(" ")[0].split("/").reverse().join("-")) - new Date("2023-05-25")) >= 0) ? "_sims" : "";
                                    let mv_before = "https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_" + meterViewData["before"] + 
                                                    "?idx=" + i + "_orig_size&lt=1";
                                    let mv_after = "https://aiv2.paraspot.ai/cva/presentImage/UniqueFrames_" + meterViewData["after"] + mv_after_addition +
                                                    "?idx=" + i + "_orig_size&lt=1";
                                    return <section 
                                                className="view-t-card-body flexColumn" 
                                                id={"mit-v-" + i} 
                                                onClick={() => {
                                                    setPresentImView({
                                                        'issue_type': 'Defect', 
                                                        'media': mv_after, 
                                                        'before_media': mv_before, 
                                                        'before_date': meterViewData['before_date']
                                                    }); 
                                                }}>  {/* [mv_before, mv_after] */}
                                                <div className="view-t-card-label text-1-2">Click to View</div>
                                                <div className="flexRow">
                                                    <img src={mv_before} alt="" />
                                                    <img src={mv_after} alt="" />
                                                </div>
                                            </section>
                                })}
                            </div>
                        </div>
                    </>
                }
                { (kitchenwareViewData || meterViewData) && <div className="h3">Report Issues</div> }
				{Object.entries(reportData && reportData[11] ?
                    {
						'Inventory': {
                            'emptyStateText': "No inventory was found.", 
                            'reportData': reportData ? reportData[5].inventory.filter((i) => !ignoreList.includes(i['object_id'])) : []
                        },
						'Defects': {
                            'emptyStateText': "No damages were found.", 
                            'reportData': reportData ? reportData[5].defects.filter((i) => !ignoreList.includes(i['object_id'])) : []
                        },
                    } :
                    {
						'Inventory': {
                            'emptyStateText': "All done. Nothing is missing.", 
                            'reportData': reportData ? reportData[5].inventory.filter((i) => !ignoreList.includes(i['object_id'])) : []
                        },
						'Inventory Defects': {
                            'emptyStateText': "No item in the inventory was damaged.", 
                            'reportData': reportData ? reportData[5].inventory_defects.filter((i) => !ignoreList.includes(i['object_id'])) : []
                        },
						'Structural Defects': {
                            'emptyStateText': "No structural defects were detected.", 
                            'reportData': reportData ? reportData[5].structural_defects.filter((i) => !ignoreList.includes(i['object_id'])) : []
                        },
						'Organization': {
                            'emptyStateText': "The unit is nicely organized.", 
                            'reportData': reportData ? reportData[5].organization.filter((i) => !ignoreList.includes(i['object_id'])) : []
                        }
					}
                    ).map(([k, v], iter_idx) => {
						const idx = iter_idx+1;
                        const orderedInventoryCategories = Object.keys((inventoryCategories || {})).sort((a, b) => {
                            const valueA = selectedInventoryCategories.indexOf(a);
                            const valueB = selectedInventoryCategories.indexOf(b);
                            return (valueA === -1 && valueB === -1) ? 0 : (valueA === -1) ? 1 : (valueB === -1) ? -1 : (valueA - valueB);
                        });
						return (
							<div className="bd-item">
								<div className="text-1" onClick={() => togglePanel(idx)}>{k}</div>
								<div className={"bd-data" + (activeBd === idx ? " active-bd" : "") + (v['reportData'].length === 0 || activeBd === idx ? " pos-relative" : "")}>
									{v['reportData'].length === 0 ? <EmptyState text={v['emptyStateText']} size="md"/> :
										<>
                                            {(k === "Inventory" && v['reportData'].length > 0) &&
                                                <div class="flexRow item-categories-wrapper">
                                                    <div 
                                                        class={"item-category text-2 flexRow" + (selectedInventoryCategories.length === 0 ? " selected-item-category" : "")}
                                                        onClick={() => { setSelectedInventoryCategories([]) }}
                                                    >
                                                        All ({Object.keys((inventoryCategories || {})).reduce((currentValue, acc) => inventoryCategories[acc].length + currentValue, 0)})
                                                        <div class="remove-ic-selection">x</div>
                                                    </div>
                                                    {inventoryCategories && orderedInventoryCategories.map((ic) => {
                                                        return inventoryCategories[ic].length === 0 ? null : (
                                                            <div 
                                                                class={"item-category text-2 flexRow" + (selectedInventoryCategories.includes(ic) ? " selected-item-category" : "")}
                                                                onClick={() => { 
                                                                    setSelectedInventoryCategories(
                                                                        selectedInventoryCategories.includes(ic) ? 
                                                                            selectedInventoryCategories.filter((i) => i !== ic) : 
                                                                            [...selectedInventoryCategories, ic]
                                                                    );
                                                                }}
                                                            >
                                                                {ic} ({inventoryCategories[ic].length})
                                                                <div class="remove-ic-selection">x</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            <div className="flexRow">
                                                {v['reportData'].map((item) => {
                                                    if (k === "Inventory" && selectedInventoryCategories.length > 0) {
                                                        const l = Object.keys(inventoryCategories).reduce(
                                                            (currentValue, acc) => [
                                                                ...currentValue, 
                                                                ...(selectedInventoryCategories.includes(acc) ? 
                                                                    inventoryCategories[acc] : [])
                                                            ],
                                                            []
                                                        );
                                                        if (!l.includes(item['object_id'])) return null;
                                                    }
                                                    return (
                                                        <ReportCard
                                                            ci_id={item['ci_id']}
                                                            media={item['media']}
                                                            subject={item['subject']}
                                                            description={item['description']}
                                                            category={k === "Inventory" ? item['category'] : undefined}
                                                            para_be={props.para_be}
                                                            report_id={reportId}
                                                            itemID={item['object_id']}
                                                            noRemoveOnIgnore={true}
                                                            onAddToChecklist={onAddToChecklist}
                                                            onImageClick={() => { setPresentImView(item); }}
                                                            onIgnoreItem={() => { setIgnoreList([...ignoreList, item['object_id']]); }}
                                                            onError={(msg=null) => { setNotifState({text: msg ? msg : "Oops, something went wrong", type: "error"}) }}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </>
									}
								</div>
							</div>
						)
					})
				}
            </article>

            <div className="checklist-btn flexRow">
                <div className="flexRow" onClick={()=> setChecklistView(true)}>
                    <img src={chIcon} alt="v-icon"/>
                    <span className="text-1">Check List ({numberOfChecklistItems})</span>
                    
                </div>
            </div>
            {checklistView ?
                <ChecklistPopup
                        status={checklistView}
                        hide={() => handleHide()}
                        cid={cid}
                        uid={uid}
                        reportId={reportId}
                        reportSubject={reportData[0]}
                        reportDate={reportData[1]}
                        para_be={props.para_be}
                        updateOpenItems={(n) => setNumberOfChecklistItems(parseInt(numberOfChecklistItems) + n)}
                        hashParams={hashParams}
                /> : ""}

            {popupState ? (
                <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup>
            ) : ""}

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </section>
    )
 }
