import React, {useState} from "react";
//components
import MainLoader from "../../loaders/main_loader/main_loader";
import Btn from "../../buttons/standard/btn";
import Notification from "../../side_notification/side_notification";
import SimpleInput from "../../inputs/simple_input/input";
import { RadioInput } from "../../radioInput/radioInput";
//utils
import { postReqOptBuilder } from "../../../utils/main_utils"
//styles
import "../add_new_employee/add_new_employee.css";


export default function UpdateEmployee(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [notifState, setNotifState] = useState(null);
    const [inputError, setInputError] = useState(null);
    
    const handleUpdateEmployee = () => {
        setInputError(null);
        setIsLoading(true);
        const userRole = document.querySelector('#role')?.value;
        if (!userRole || userRole.length === 0) {
            setInputError("Please enter employee role");
            setIsLoading(false);
            return;
        }
        const reqBody = {};
        const userType = document.querySelector('input[name="user-type"]:checked')?.value;
        const userStatus = document.querySelector('input[name="user-status"]:checked')?.value;
        if (userType !== props.userType) reqBody['user_type'] = userType;
        if (userRole !== props.userRole) reqBody['user_role'] = userRole;
        if (userStatus !== props.userStatus) reqBody['user_status'] = userStatus;
        if (Object.keys(reqBody).length === 0) {
            console.log(reqBody);
            setNotifState({text: "Nothing was changed", type: "error"});
            setIsLoading(false);
            return;
        }
        reqBody['user_id'] = props.userId;
        fetch(props.para_be + '/settings/update-employee', postReqOptBuilder(reqBody))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                props.closeFunc(true);
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: "An error has occurred, please try again later", type: "error"});
            setIsLoading(false);
        });
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    return (
        <section className="create-employee-container flexColumn">
            {isLoading ? <MainLoader/> :
                <>
                    <div className="h2 blue-headline">Update Employee</div>
                    <RadioInput
                        values={["User", "Admin"]}
                        groupName="user-type"
                        currentValue={props.userType}
                        label="User Type"/>
                    <SimpleInput
                                label={"Role Type"}
                                type={"text"}
                                id={"role"}
                                placeholder={"Manager, Housekeeping, Front Desk..."}
                                value={props.userRole}
                                inputError={inputError}/>

                    <RadioInput
                        values={["active", "inactive"]}
                        groupName="user-status"
                        currentValue={props.userStatus}
                        label="User Status"/>

                    <Btn
                        type="primary"
                        text="Update Employee"
                        extraClasses="submit-employee"
                        onclick={handleUpdateEmployee}/>
                </>
            }
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </section>
    )
}