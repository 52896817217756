import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//assets
import PlayIcon from '../../../../icons/playIcon';
import DocsIcon from '../../../../icons/docsIcon';
import CheckIcon from '../../../../icons/checkIcon';
import CancelIcon from '../../../../icons/cancelIcon';
//constants
import { colorKeysConfig } from '../../../../tableConfig';
import { MODAL_TYPES } from '../../../../../../constants';


const ActionsPanelScans = ({ pid, subject, report_id, videoLink, submission_status }) => {
    console.log(videoLink)
    const dispatch = useDispatch();
    
    return (
        <>
            <span className={`cursor-pointer`} title={"View Scan"} onClick={(e) => {
                e.stopPropagation();
                dispatch(openModal({ modalName: MODAL_TYPES.VIDEO_MODAL, subject, videoLink }));
            }}>
                <PlayIcon />
            </span>
            
            {
                submission_status==="1" ?
                    <span className={"font-medium " + colorKeysConfig.done}>Accepted</span> :
                submission_status==="2" ?
                    <span className={"font-medium " + colorKeysConfig.missing}>Rejected</span> 
                    :
                    <>
                        <span className="cursor-pointer" title={"Accept"} onClick={(e) => {
                                e.stopPropagation();
                                dispatch(openModal({ modalName: MODAL_TYPES.APPROVE_MODAL, rid: report_id }));
                        }}>
                            <CheckIcon />
                        </span>
                        <span className="cursor-pointer" title={"Reject"} onClick={(e) => {
                            e.stopPropagation();
                            dispatch(openModal({ modalName: MODAL_TYPES.REJECT_MODAL, rid: report_id }));
                        }}>
                            <CancelIcon />
                        </span>
                    </>
            }

            {report_id && submission_status === "1" ? (
                    <Link to={`/report/${report_id}`} className="cursor-pointer" title={"View Report"}>
                        <DocsIcon />
                    </Link>
                ) : (
                    <span className='cursor-default'>
                        <DocsIcon disabled />
                    </span>
                )
            }
        </>
    )
}

export default ActionsPanelScans;