import React, { useState } from 'react';
//mui
import { FormControlLabel } from '@mui/material';
//components
import IOSSwitch from '../../../../../switch/iosSwitch/iosSwitch';
//utils
import { postReqOptBuilder } from '../../../../../../utils/main_utils';
//constants
import { para_be } from '../../../../../../config';



const SwitchStatus = ({ jid, ogStatus, offLabel="Deactivate", onLabel="Activate", setNotifState }) => {
    const [status, setStatus] = useState(ogStatus);

    return (
        <>
            <FormControlLabel
                control={<IOSSwitch sx={{ m: 1 }} checked={status} />}
                label={status ? offLabel : onLabel}
                onClick={(e) => {
                    e.stopPropagation();
                    const newStatus = !status;
                    setStatus((prev) => !prev);
                    fetch(
                        `${para_be}/inspections/journey/change_status`, 
                        postReqOptBuilder(
                            { 
                                journey_id: jid, 
                                status: newStatus ? "active" : "inactive" 
                            }, 
                            true
                        )
                    )
                    .then((response) => response.json())
                    .then((response) => {
                        if (response.status === 200) {
                            setNotifState({text: response.msg, type: "success"});
                        } else {
                            setNotifState({text: response.msg, type: "error"});
                            setStatus((prev) => !prev);
                        }
                    })
                    .catch((error) => {
                        setStatus((prev) => !prev);
                        setNotifState({text: "Failed to change status", type: "error"});
                        console.error('Error changing status:', error);
                    });
                }}
            />
        </>
    )
}

export default SwitchStatus;





