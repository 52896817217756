export default function savePMSCredentials(para_be, pmsType) {
    
    const clientID = document.getElementById("pms-client-id").value;
    const secretKey = document.getElementById("client-secret-key").value;
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'withCredentials': true},
        body: JSON.stringify({"pms":pmsType, "client_id": clientID, "secret_key": secretKey})
    };
    return fetch(para_be + '/settings/save_pms_creds', requestOptions)
    .then(response => response.json())
    .then(response => {
        if(response.status === 200) {
            return({"type":"success", "msg": "Connected"});
        } else {
            return({"type":"error", "msg": response.msg});
        }
    })
    .catch ( error => {
        console.log(error);
        return({"type":"error", "msg": "Connection failed"});
    })
}