import React from 'react';
//utils
import { formatKey } from '../../../../../../../utils';


const TenantDetails = ({ extendedInfoArr }) => {
    const viewOrder = {
        'move_in': 1,
        'move_out': 2,
        'name': 3,
        'email': 4,
        'mobile': 5,
        'rent': 6,
        'deposit': 7,
    };

    const prepForOrder = (x) => {
        return Object.keys(viewOrder).includes(x) ? viewOrder[x] : 99;
    }

    return (
        Object.keys(extendedInfoArr).sort((a,b) => prepForOrder(a) - prepForOrder(b)).map((key) => (
            key === "labels" ? "" :
            <div className="mb-2">
                <h5 className="font-semibold">{formatKey(key)}</h5>
                <p className="text-gray-500">{extendedInfoArr[key] || 'N/A'}</p>
            </div>
        ))
    );
};


export default TenantDetails;
