import { createSlice } from '@reduxjs/toolkit';


const scanSlice = createSlice({
    name: 'scans',
    initialState: {
        tabIndex: '1',
    },
    reducers: {
        setTabIndex(state, action) {
            state.tabIndex = action.payload;
        },
    },
});

export const { setTabIndex } = scanSlice.actions;

export default scanSlice.reducer;