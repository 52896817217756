import React from 'react'

const CircleTopDown = ({ fill = 'none', width = '20px', height = '20px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 18 18'
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.45768 1.60352C8.45768 1.36189 8.65356 1.16602 8.89518 1.16602H12.3952C12.6368 1.16602 12.8327 1.36189 12.8327 1.60352V5.10352C12.8327 5.34514 12.6368 5.54102 12.3952 5.54102C12.1536 5.54102 11.9577 5.34514 11.9577 5.10352V2.65973L8.03787 6.57954C7.86702 6.7504 7.59001 6.7504 7.41916 6.57954C7.2483 6.40869 7.2483 6.13168 7.41916 5.96082L11.339 2.04102H8.89518C8.65356 2.04102 8.45768 1.84514 8.45768 1.60352Z" fill="#918BEF"/>
      <path d="M6.56185 1.60352C3.34019 1.60352 0.728516 4.21519 0.728516 7.43685C0.728516 10.6585 3.34019 13.2702 6.56185 13.2702C9.78351 13.2702 12.3952 10.6585 12.3952 7.43685C12.3952 7.08755 12.3645 6.74542 12.3056 6.41301C11.6225 6.36698 11.0827 5.79829 11.0827 5.10352V4.77217L8.65659 7.19826C8.14403 7.71082 7.313 7.71082 6.80044 7.19826C6.28787 6.6857 6.28788 5.85467 6.80044 5.3421L9.22653 2.91602H8.89518C8.20041 2.91602 7.63172 2.37618 7.58569 1.69307C7.25328 1.63422 6.91115 1.60352 6.56185 1.60352Z" fill="#918BEF"/>
    </svg>
  );
};

export default CircleTopDown