import React from "react";
//styles
import "./close_btn.css";
//assets
import Close from "../../../assets/icons/Close.svg"


export default function CloseBtn (props) {
    return (
        <div className="close-btn" onClick={()=>props.closeFunc()}> <img src={Close} alt="close button"/></div>
    )
}