import React, { useState } from "react";

//style
import "./lobby.css";
import "./feedback_chain.css";
//components
//containers
//assets
import appLogo from "../../../assets/icons/smartScan_logo.svg";
import dmParaspot from "../../../assets/icons/logo-darkMode.svg";
import Btn from "../../buttons/standard/btn";

export default function FeedbackBlock(props) {
    const {includeClientLogo, clientLogo, onNext, feedbackQuestion, thankForFeedback} = props;

    const [rateSelection, setRateSelection] = useState(null);
    const [thanksMode, setThanksMode] = useState(false);

    return (
        <div className={`sc-lobby${includeClientLogo ? " incl-cl" : ""}`}>
            <div className="lobby-content">
                {!includeClientLogo || !clientLogo ? "" : (
                    <div className="client-logo img-wrapper">
                        <img src={clientLogo} alt="Client Logo"/>
                    </div>
                )}
                <div className="app-logo img-wrapper">
                    <img src={appLogo} alt="App-logo"/>
                </div>
                {
                    thanksMode ?
                        <div className="feedback-block">
                            <div className="text-0 feedback-question">Thank you for your feedback!</div>
                        </div>
                        :
                        <>  
                            <div className="feedback-block">
                                {/* How would you rate your scanning experience */}
                                <div className="text-0 feedback-question">{feedbackQuestion}</div>
                                <div className="flexRow rating-btns">
                                    {
                                        ["1", "2", "3", "4", "5"].map((x) => 
                                            <Btn
                                                type="secondary" 
                                                text={x}
                                                onclick={() => setRateSelection(x)} 
                                                extraClasses={x===rateSelection ? "btn-selected" : undefined}
                                            />
                                        )
                                    }
                                </div>
                                <div className="flexRow rating-btns-labels">
                                    <div className="text-1">Not satisfied</div>
                                    <div className="text-1">Very satisfied</div>
                                </div>
                            </div>
                            <Btn
                                type="primary" 
                                text="Submit" 
                                onclick={() => {
                                    onNext(rateSelection);
                                    if (thankForFeedback) {
                                        setThanksMode(true);
                                    }
                                }} 
                            />
                        </>
                }
                <div className="powered-by-wrapper">
                    <div>
                        <div className="powered-by">
                            <span>Powered by</span>
                            <img src={dmParaspot} alt="dark-logo"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}