import React, {useEffect} from "react"
//components
import CloseBtn from "../buttons/close_btn/close_btn";
//styles
import "./side_notification.css"
//assets
import ErrorIcon from "../../assets/icons/error-icon.svg";
import SuccessIcon from "../../assets/icons/check-circle.svg";

export default function Notification(props) {

    const handleClose = () => {
        props.closeFunc();
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose();
        }, 5000);
        return () => clearTimeout(timer);
      }, []);

    return (
        <div className="side-notif-container">
            <CloseBtn closeFunc={handleClose}/>
            <div className="side-notif-body flexRow">
                <img src={props.type === "error" ? ErrorIcon : SuccessIcon} alt={props.type}/>
                <div className={"text-1 notif-" + props.type}>{props.text}</div>
            </div>
        </div>
    )
}