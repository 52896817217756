import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//stripe
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
//mui
import { Card, CardContent, Typography, Grid, Button, Radio, FormControlLabel } from '@mui/material';
//slices
import { setPaymentMethod, setDefaultPaymentMethod, clearError } from '../../../../../../../../store/slices/billingSlice';
//utils
import { paymentAccountExists as hasIdStartingWithCus } from '../../../../utils';
import { classNames } from '../../../../../../../../utils/main_utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const defaultStyles = {
    default: 'bg-blue-100 text-blue-800',
};

const Step2AddPaymentMethod = forwardRef(({}, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    
    const { billingDetails, loading, error: billingError } = useSelector(state => state.billing);
    const { customerData } = useSelector(state => state.customer);
    
    const [useNewMethod, setUseNewMethod] = useState(true);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [isHaveAccount, setHaveAccount] = useState(false);

    const defaultPaymentMethodId = billingDetails?.default_payment_method;

    useImperativeHandle(ref, () => ({ handleSubmit, }));

    useEffect(() => {
        if (customerData) {
            const haveAccount = hasIdStartingWithCus(customerData);
            setHaveAccount(haveAccount);
        }
    }, [customerData]);

    useEffect(() => {
        if (billingError) {
            const timer = setTimeout(() => {
                dispatch(clearError());
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [billingError, dispatch]);

    const handleSubmit = async () => {
        if (useNewMethod) {
            if (!stripe || !elements) {
                throw new Error('Stripe has not initialized yet.');
            }

            const cardElement = elements.getElement(CardElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card: cardElement });

            if (error) {
                throw error;
            }

            if (!customerData) {
                throw new Error('No customer data found.');
            }
            
            try {
                await dispatch(setPaymentMethod({ paymentMethodId: paymentMethod.id })).unwrap();
                sessionStorage.setItem('payment_method_id', paymentMethod.id);
            } catch (err) {
                throw new Error(err);
            }
        } else if (selectedMethod) {
            sessionStorage.setItem('payment_method_id', selectedMethod.id);
            dispatch(setDefaultPaymentMethod(selectedMethod.id));
        } else {
            throw new Error('No payment method selected.');
        }
    };

    return (
        <div className="mb-10 mt-10 bg-white">
            <h2 className="text-xl font-bold mb-4">Your Payment Methods</h2>

            {isHaveAccount && billingDetails && billingDetails.payment_methods.length > 0 &&
                <Grid container spacing={1} className="mb-4">
                    {billingDetails.payment_methods.map(method => (
                        <Grid item xs={12} sm={12} md={12} key={method.id}>
                            <Card className={classNames(method.id === defaultPaymentMethodId ? defaultStyles.default : '', 'shadow-sm')}>
                                <CardContent>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={selectedMethod && selectedMethod.id === method.id && !useNewMethod}
                                                onChange={() => {
                                                    setSelectedMethod(method);
                                                    setUseNewMethod(false);
                                                }}
                                                value={method.id}
                                                name="existing-payment-method" />
                                        }
                                        label={
                                            <>
                                                <Typography variant="h6" gutterBottom>
                                                    {method.brand.toUpperCase()} **** {method.last4}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                    Expires: {method.exp_month}/{method.exp_year}
                                                </Typography>
                                                {method.id === defaultPaymentMethodId &&
                                                    <span
                                                        className={classNames(
                                                            defaultStyles.default || 'bg-gray-100 text-gray-800',
                                                            'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                                                        )}
                                                    >
                                                        Default
                                                    </span>
                                                }
                                            </>
                                        }
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            }

            {(billingDetails && billingDetails.payment_methods.length === 0) &&
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setUseNewMethod(true);
                        setSelectedMethod(null);
                    }}
                    disabled={useNewMethod}
                    className="mb-4"
                >
                    + Add a new payment method
                </Button>
            }

            {useNewMethod &&
                <div className="mt-8">
                    <CardElement />
                </div>
            }
        </div>
    );
});

const Step2Wrapper = forwardRef((props, ref) => (
    <Elements stripe={stripePromise}>
        <Step2AddPaymentMethod ref={ref} {...props} />
    </Elements>
));

export default Step2Wrapper;
