import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//MUI imports for Tabs
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
//components
import ScansSubmissionsTable from '../../components/Tables/TableTypes/ScansSubmissionTable/ScansSubmissionTable';
//constants
import { CategoryFilter } from '../../constants';
//slices
import { fetchData, fetchDataTotal, resetState, setFilters, setPage } from '../../store/slices/searchSlice';
import { setTabIndex } from '../../store/slices/scanSlice';


const TabStyle = { fontSize: '12px', fontWeight: 700, padding: '0px 6px', boxSizing: 'content-box' };

const Scans = () => {
    const dispatch = useDispatch();
    const { page, pageSize, sortColumn, sortDirection, searchValue, totals } = useSelector(state => state.table);
    const { tabIndex } = useSelector(state => state.scans);
    const location = useLocation();
    const tabChange = useRef(false);
    
    const fetchConfig = (tabIndex, resetStats=false) => {
        const filtersObj = {
            category: CategoryFilter.SCANS,
            submission_status: (Number(tabIndex) - 1).toString(),
        };
        const body = {
            lowerLimit: resetStats ? 0 : (page - 1) * pageSize,
            upperLimit: resetStats ? pageSize : page * pageSize,
            filters: filtersObj,
            searchValue: resetStats ? "" : searchValue,
            sortDirection: resetStats ? false : sortDirection,
            sortColumn: resetStats ? 0 : sortColumn,
        };
        return { body, filtersObj };
    };

    const fetchTotals = () => {
        const { body: body1 } = fetchConfig(1, true);
        const { body: body2 } = fetchConfig(2, true);
        const { body: body3 } = fetchConfig(3, true);
        dispatch(fetchDataTotal({...body1, k: 'scansNotReviewed'}));
        dispatch(fetchDataTotal({...body2, k: 'scansAccepted'}));
        dispatch(fetchDataTotal({...body3, k: 'scansRejected'}));
    }

    useEffect(() => {
        dispatch(setTabIndex('1'));
        const { body, filtersObj } = fetchConfig("1", true);
        dispatch(resetState({filters: filtersObj, categoryFilter: CategoryFilter.SCANS}));
        dispatch(fetchData(body));
        fetchTotals();
    }, [dispatch, location]);

    useEffect(() => {
        if (tabChange.current) {
            tabChange.current = false;
            const { body, filtersObj } = fetchConfig(tabIndex);
            dispatch(setFilters(filtersObj));
            dispatch(fetchData(body));
        }
    }, [dispatch, tabIndex]);
    
    // Handle tab changes
    const handleTabChange = (event, newValue) => {
        tabChange.current = true;
        dispatch(setPage(1));
        dispatch(setTabIndex(newValue));
    };
    
    const renderTabPanel = () => (
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <ScansSubmissionsTable />
        </Box>
    );
    

    return (
        <>
            <main className="flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-8">
                    <div className="mt-2 flex flex-col">
                        {/* MUI Tabs Section */}
                        <TabContext value={tabIndex}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                                    <Tab label={`Not Reviewed (${totals.scansNotReviewed})`} value="1" sx={TabStyle} />
                                    <Tab label={`Accepted (${totals.scansAccepted})`} value="2" sx={TabStyle} />
                                    <Tab label={`Declined (${totals.scansRejected})`} value="3" sx={TabStyle} />
                                </TabList>
                            </Box>
                            {[1, 2, 3].map(index => (
                                <TabPanel key={index} value={String(index)} sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
                                    {renderTabPanel()}
                                </TabPanel>
                            ))}
                        </TabContext>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Scans;