import React from "react";
//components
import Btn from "../../../../components/buttons/standard/btn";
import BackBtn from "../../../../components/buttons/back_btn/back";
import SimpleInput from "../../../../components/inputs/simple_input/input";
//styles
import "./index.css";
//assets
import camera from "../../../../assets/icons/camera.png";

export default function General(props) {
    const {
        handleBack, 
        handleViewProfile,
        isBanner,
        handleImageUploadClick,
        handleImageUpload,
        isLogo,
        logoImage,
        companyName,
        bannerImage,
        companySettings,
        changeOccurred,
        setImprintTextArea,
    } = props;

    return (
        <>
            <section className="settings-header">
                <BackBtn clickFunction={handleBack} />
                <div className="blue-headline">Company Settings</div>
                <Btn
                    text="View Profile"
                    type="secondary"
                    extraClasses="d-none"
                    onclick={handleViewProfile}
                />
            </section>
            <section className="profile-img d-flex-col">
                <div className="banner-zone">
                    {isBanner ?
                        <img
                            src={isBanner + (isBanner.startsWith("blob") ? "" : `?${new Date().getTime()}`)}
                            alt="banner"
                            className="banner-img"
                        /> : ""}
                    <div className="banner-btn" onClick={() => handleImageUploadClick("banner")}>
                    <div className="absolute-center">
                        <img src={camera} alt="upload" />
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        name="banner"
                        id="banner-img"
                        ref={bannerImage}
                        className="d-none"
                        onChange={(e) => handleImageUpload(e, "banner")}
                    />
                    </div>
                </div>
                <div className="name-logo-container d-flex-col">
                    <div className="logo-img-container" onClick={() => handleImageUploadClick("logo")}>
                        {isLogo ?
                            <img
                                src={isLogo + (isLogo.startsWith("blob") ? "" : `?${new Date().getTime()}`)}
                                alt="logo"
                            />
                        :
                            <div className="absolute-center">
                                <img src={camera} alt="upload" />
                            </div>
                        }
                        <input
                            type="file"
                            accept="image/*"
                            name="logo"
                            id="logo-img"
                            ref={logoImage}
                            className="d-none"
                            onChange={(e) => handleImageUpload(e, "logo")}
                        />
                    </div>
                    <div className="c-name-box">
                        <input
                            type="text"
                            className="c-name-input"
                            name="company-name"
                            readOnly
                            value={companyName}
                        />
                    </div>
                    <Btn
                        type="secondary"
                        text="Upload photo"
                        extraClasses="btn-upload"
                        onclick={() => handleImageUploadClick("logo")} />
                </div>
            </section>

            <section className="progress-bar">{/*TODO - for later */}</section>
            
            <section className="inputs company d-flex-col">
                {companySettings.map(([type, id, extraClass, text, state]) => 
                    type === "text" ?
                        <SimpleInput
                            id={id}
                            label={text}
                            placeholder={text}
                            type={type}
                            extraClasses={extraClass}
                            value={state}
                            on_change={changeOccurred}
                        />
                        :
                        <div className={"input-container"}>
                            <div className="input-label">{text}</div>
                            <textarea
                                className="custom-scrollbar-1"
                                type="text"
                                rows="10"
                                id={id}
                                placeholder={text}
                                value={state}
                                onChange={(e) => setImprintTextArea(e.target.value)} />
                        </div>
                )}
            </section>
            {/* <Btn text="Connect to your PMS" type="primary" extraClasses="connect-pms" onclick={() => handleOpenPopup("pms")}/> */}
        </>
    );
}