import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import SnackBar from '../../../SnackBar';
import ConfirmationDialog from '../../../ConfirmationDialog';
import TableWidget from '../../TableWidget';
//modals
//utils
import axios from '../../../../axios/axios';
import { initfetchTableData } from '../../utils';
//slices
import { setPage , closeModal } from '../../../../store/slices/searchSlice';
import { postLogEntry } from '../../../../store/slices/activitySlice';
//constants
import { para_be } from '../../../../config';
import { MODAL_TYPES } from '../../../../constants'
import UpdateJourney from '../../TableWidget/components/Modals/UpdateJourney/UpdateJourney';
import UpdateSchedule from '../../TableWidget/components/Modals/UpdateSchedule/UpdateSchedule';


const InspectionSchedulesTable = () => {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);
    const { page, pageSize, selectedRowInTable, modals, searchValue, filters, sortColumn, sortDirection } = useSelector(state => state.table);
    const cred = useSelector(state => state.auth.cred);
    const cid = cred?.cid ?? null;
    const [notifState, setNotifState] = useState(null);
    const [preventCloseModal, setPreventCloseModal] = useState(false);
    
    const handleCloseModal = (modalName) => {
        if (preventCloseModal) return;
        dispatch(closeModal(modalName));
    };
    
    const updateStateAfterDelete = async () => {
        await initfetchTableData(dispatch, page, pageSize, filters, searchValue, sortDirection, sortColumn);
        handleCloseModal(MODAL_TYPES.DELETE_SCHEDULE_MODAL);
        dispatch(setPage(1));
        setNotifState({ type: 'success', text: `Deleted Successfully` });
    };
    
    const handleConfirmDeleteJourney = async () => {
        try {
            const response = await axios.post(`${para_be}/inspections/schedule/delete`, { schedule_id: selectedRowInTable.sid }, { withCredentials: true });
            if (response.status === 200 && response.data.status === 200) {
                dispatch(postLogEntry( 
                    { 
                        activityID: selectedRowInTable.sid, activityType: 'inspection_schedule', ip: endUserData?.ip, 
                        action: {action: "delete", target: "inspection_schedule"} 
                    }
                ));
                await updateStateAfterDelete();
            } else {
                setNotifState({ text: response.data.msg, type: 'error' });
                handleCloseModal(MODAL_TYPES.DELETE_SCHEDULE_MODAL)
            }
        } catch (error) {
            setNotifState({ text: 'Failed to Delete', type: 'error' });
            console.error('Error deleting unit:', error);
            handleCloseModal(MODAL_TYPES.DELETE_SCHEDULE_MODAL)
        }
    };

    const prepAssigneeType = (assignedTo) => {
        const d = typeof assignedTo === "string" ? JSON.parse(assignedTo) : assignedTo;
        return d?.type;
    };

    const prepAssignedMembers = (assignedTo) => {
        const d = typeof assignedTo === "string" ? JSON.parse(assignedTo) : assignedTo;
        return (d?.members || []);
    };

    useEffect(() => {
        if (notifState) {
            setTimeout(() => { 
                setNotifState(null);
            }, 6000);
        }
    }, [notifState]);


    return (
        <>
            {/* Modals */}
            {/* {modals.editJourneyModal && (
                <UpdateUnit
                    open={modals.editJourneyModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.EDIT_JOURNEY_MODAL)}
                    pid={selectedRowInTable.jid}
                    setNotifState={setNotifState}
                />
            )} */}
            {/* {modals.editScheduleModal && (
                <UpdateSchedule 
                    open={modals.editScheduleModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.EDIT_SCHEDULE_MODAL)}
                    sid={selectedRowInTable.sid}
                    assigneeType={prepAssigneeType(selectedRowInTable.assigned_to)}
                    assignedMembers={prepAssignedMembers(selectedRowInTable.assigned_to)}
                    dueRange={JSON.parse(selectedRowInTable.due_range || JSON.stringify([null, null]))}
                    notices={JSON.parse(selectedRowInTable.notices || JSON.stringify({}))}
                    setNotifState={setNotifState}
                />
            )} */}
            {modals.deleteScheduleModal && (
                <ConfirmationDialog
                    open={modals.deleteScheduleModal}
                    handleClose={() => handleCloseModal(MODAL_TYPES.DELETE_SCHEDULE_MODAL)}
                    handleRemove={handleConfirmDeleteJourney}
                    text={`You won't be able to recover it.`}
                    header={'Please confirm that you want to delete this inspection schedule.'}
                />
            )}
            
            {/* Main Table Layout */}
            <TableWidget showExpandedRows={false} />
            {/* Snackbar Notification */}
            {Boolean(notifState) && (
                <SnackBar
                    open={Boolean(notifState)}
                    handleClose={() => setNotifState(null)}
                    severity={notifState.type === 'error' ? 'error' : 'success'}
                    message={notifState.text}
                    duration={6000}
                />
            )}
        </>
    );
};

export default InspectionSchedulesTable;