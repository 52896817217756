import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';


const SortSelect = ({ options, sortColmn }) => {
    const [selectedOption, setSelectedOption] = useState('');
    
    const handleChange = (event) => {
        const option = options.find(opt => opt.value === event.target.value);
        setSelectedOption(option.value);
        sortColmn(Number(option.key) - 1);
    };
    
    return (
        <>
            <TextField id="outlined-select-currency" select label="Order By" value={selectedOption} onChange={handleChange} sx={{ width: 160 }}>
                {options.map(option => (
                    <MenuItem key={option.key} value={option.value}>
                        {option.value}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
};

export default SortSelect;
