import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//mui
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add'; 
//components
import ConfirmationDialog from '../../../../../../components/ConfirmationDialog';
import CircularLoader from '../../../../../../components/loaders/CircularLoader';
import TableOptionsMenu from '../../../../../../components/Tables/TableWidget/components/TableOptionsMenu';
//slices
import { fetchSubscriptionData, cancelSubscription } from '../../../../../../store/slices/subscriptionsSlice';
//assets
import { BanknotesIcon, PencilIcon } from '@heroicons/react/20/solid';
//utils
import { classNames } from '../../../../../../utils/main_utils';
import { addYears } from '../../../../../../utils/date_utils';
import UpdateSubscription from '../../components/popups/editSubscription';
import SnackBar from '../../../../../../components/SnackBar';
import { SeverityLevels } from '../../../../../../constants';

const statusStyles = {
    active: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
};

const ShowSubscription = () => {
    const dispatch = useDispatch();
    const { data: { subscription }, loading, error } = useSelector(state => state.subscription);
    const { SubscriptionMetadata, fetchCustomerData: { loading: fetchCustomerLoading } } = useSelector(state => state.customer);
    // const subscription = useSelector(state => state.subscription.data.subscription);
    
    const [open, setOpen] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    
    const [editSubOpen, setEditSubOpen] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        severity: SeverityLevels.SUCCESS,
        message: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchSubscriptionData()).unwrap();
            } catch (err) {
                console.error('Failed to fetch subscription data:', err);
            }
        };
        fetchData();
    }, []);

    const handleClickOpen = () => {
        setSelectedSubscription(subscription);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedSubscription(null);
    };

    const handleCancellation = async () => {
        if (!selectedSubscription) return;
        try {
            await dispatch(cancelSubscription(selectedSubscription.id)).unwrap();
            window.location.reload();
        } catch (err) {
            console.error('Failed to remove subscription:', err);
        }
    };

    const handleNotificationClose = () => {
        setNotification({ ...notification, open: false });
    };

    if (loading || fetchCustomerLoading) {
        return (
            <div className="flex justify-center items-center h-full">
                <CircularLoader size={36} color="secondary" />
            </div>
        );
    } else if (error) {
        return <div>Error: {error}</div>;
    } else if (!subscription) {
        return <h2 className="mx-auto max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">Subscription not found</h2>;
    }

    return (
        <>
            {/* Update Subscription Popup */}
            {editSubOpen && 
                <UpdateSubscription
                    open={editSubOpen} 
                    handleClose={() => { 
                        setEditSubOpen(false);
                        dispatch(fetchSubscriptionData());
                    }} 
                    setNotification={setNotification} />
            }

            <div className="min-h-full">
                <div className="flex flex-1 flex-col">
                    <main className="flex-1 pb-8">
                        <div className="flex justify-between items-center mb-6 mt-6"> 
                            <div>
                                <h2 className="mx-auto max-w-6xl text-lg font-medium leading-6 text-gray-900 mb-4">Subscription</h2>
                            </div>
                            <div className="flex">
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}  
                                    onClick={()=>{}}
                                    sx={{
                                        backgroundColor: '#4F46E5', 
                                        color: 'white',            
                                        fontSize: '12px',          
                                        textTransform: 'none',   
                                        borderRadius: '8px',       
                                        padding: '6px 12px',    
                                        '&:hover': { backgroundColor: '#4338CA' } 
                                    }}
                                    disabled
                                >
                                    Add Subscription
                                </Button>
                            </div>
                        </div>

                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl">
                                <div className="mt-2 flex flex-col">
                                    {/* <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"> */}
                                    <div className="min-w-full align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 min-w-32 text-left text-sm font-semibold text-gray-900">
                                                        Charge
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Limited to
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Start Date
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        End Date
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Auto-Renew
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 sm:px-2 lg:px-6 py-3 text-center text-sm font-semibold text-gray-900">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                <tr className="bg-white">
                                                    <td className="w-full max-w-0 whitespace-nowrap sm:px-2 lg:px-6 py-4 text-sm text-gray-900">
                                                        <div className="flex">
                                                            <BanknotesIcon 
                                                                aria-hidden="true"
                                                                className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                                                            <span className="ml-2 truncate text-gray-500 group-hover:text-gray-900">
                                                                {SubscriptionMetadata.subscription.rate.currency === "eur" ? "€" : "$"}
                                                                {(SubscriptionMetadata.subscription.rate.value*SubscriptionMetadata.subscription.units).toLocaleString("en-US")} / {subscription.plan.interval}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        <span className='truncate text-gray-500 group-hover:text-gray-900'>
                                                            {SubscriptionMetadata.subscription.units} {SubscriptionMetadata.subscription.units > 1 ? 'Units' : 'Unit'}
                                                        </span>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        <time dateTime={new Date(subscription.created * 1000).toISOString()}>{new Date(subscription.created * 1000).toLocaleDateString()}</time>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        {
                                                            (() => {
                                                                const start_d = new Date(subscription.created * 1000); // Convert timestamp to date
                                                                const now = new Date();
                                                                const nextTargetDate = addYears(start_d, (now.getFullYear() - start_d.getFullYear()) + 1);
                                                                const daysApart = (nextTargetDate - now) / (1000 * 60 * 60 * 24);
                                                                const targetDate = addYears(start_d, (daysApart > 30 ? 0 : 1));
                                                                return (
                                                                    <>
                                                                        <time dateTime={targetDate.toISOString()}>{targetDate.toLocaleDateString()}</time>
                                                                    </>
                                                                )
                                                            })()
                                                        }
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        {SubscriptionMetadata.subscription?.autoRenew ? "On" : "Off"}
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-center text-sm text-gray-500">
                                                        <span
                                                            className={classNames(
                                                                statusStyles[subscription.status] || 'bg-gray-100 text-gray-800',
                                                                'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                                                            )}
                                                        >
                                                            {subscription.status}
                                                        </span>
                                                    </td>
                                                    <td className="whitespace-nowrap sm:px-2 lg:px-6 py-4 text-right text-sm text-gray-500">
                                                        <div className="flex justify-end gap-x-2">
                                                            <button className="text-red-600 hover:text-red-900 py-2" onClick={handleClickOpen}>
                                                                Cancel
                                                            </button>
                                                            <TableOptionsMenu 
                                                                extraBtnClasses={"h-full align-middle"}
                                                                items={[
                                                                    {
                                                                        label: 'Update Subscription',
                                                                        icon: () => <PencilIcon className="h-5 w-5" />,
                                                                        onClick: () => { setEditSubOpen(true); }
                                                                    }
                                                                ]}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shadow sm:hidden">
                            <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                <li>
                                    <div className="block bg-white px-4 py-4 hover:bg-gray-50">
                                        <span className="flex items-center space-x-4">
                                            <span className="flex flex-1 space-x-2 truncate">
                                                <BanknotesIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                                <span className="flex flex-col truncate text-sm text-gray-500">
                                                    <span className="truncate">
                                                        {SubscriptionMetadata.subscription.rate.currency === "eur" ? "€" : "$"}
                                                        {(SubscriptionMetadata.subscription.rate.value*SubscriptionMetadata.subscription.units).toLocaleString("en-US")} / {subscription.plan.interval}
                                                    </span>
                                                    <span className="truncate">
                                                        Limited to: &nbsp;
                                                        {SubscriptionMetadata.subscription.units} {SubscriptionMetadata.subscription.units > 1 ? 'Units' : 'Unit'}
                                                    </span>
                                                    <span className="truncate">
                                                        Start Date: &nbsp;
                                                        <time dateTime={new Date(subscription.created * 1000).toISOString()}>{new Date(subscription.created * 1000).toLocaleDateString()}</time>
                                                    </span>
                                                    <span className="truncate">
                                                        End Date: &nbsp;
                                                        {
                                                            (() => {
                                                                const start_d = new Date(subscription.created * 1000); // Convert timestamp to date
                                                                const now = new Date();
                                                                const nextTargetDate = addYears(start_d, (now.getFullYear() - start_d.getFullYear()) + 1);
                                                                const daysApart = (nextTargetDate - now) / (1000 * 60 * 60 * 24);
                                                                const targetDate = addYears(start_d, (daysApart > 30 ? 0 : 1));
                                                                return (
                                                                    <>
                                                                        <time dateTime={targetDate.toISOString()}>{targetDate.toLocaleDateString()}</time>
                                                                    </>
                                                                )
                                                            })()
                                                        }
                                                    </span>
                                                    <span className="truncate">
                                                        Auto-Renew: &nbsp;
                                                        {SubscriptionMetadata.subscription?.autoRenew ? "On" : "Off"}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <div className="flex justify-between mt-4">
                                            <div className='flex'>
                                                <span
                                                    className={classNames(
                                                        statusStyles[subscription.status] || 'bg-gray-100 text-gray-800',
                                                        'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize my-auto'
                                                    )}
                                                >
                                                    {subscription.status}
                                                </span>
                                            </div>
                                            <div className="flex gap-x-2">
                                                <button className="text-red-600 hover:text-red-900 py-2" onClick={handleClickOpen}>
                                                    Cancel
                                                </button>
                                                <TableOptionsMenu 
                                                    extraBtnClasses={"h-full align-middle"}
                                                    items={[
                                                        {
                                                            label: 'Update Subscription',
                                                            icon: () => <PencilIcon className="h-5 w-5" />,
                                                            onClick: () => { setEditSubOpen(true); }
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </main>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={open}
                handleClose={handleClose}
                handleRemove={handleCancellation}
                text={'Are you sure you want to cancel this subscription?'}
                header={'Cancel Subscription'} />

            {/* Notification */}
            {notification.open &&
                <SnackBar 
                    open={notification.open}
                    handleClose={handleNotificationClose}
                    severity={notification.severity}
                    message={notification.message}
                    duration={6000} />
            }
        </>
    );
};

export default ShowSubscription;
