import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//components
import ScansDoneTable from '../../components/Tables/TableTypes/ScansDoneTable/ScansDoneTable';
import Header from './components/Header/Header';
//modals
import SearchFilterModal from './components/modals/SearchFilterModal';
//constants
import { CategoryFilter, MODAL_TYPES } from '../../constants';
//slices
import { fetchData, resetState, setFilters, setPage } from '../../store/slices/searchSlice';
import { closeModal } from '../../store/slices/scansDoneSlice';
//utils
import { createDictFromHashedUrl } from '../../utils/main_utils';


const ScansDone = () => {
    const dispatch = useDispatch();
    const hashDict = createDictFromHashedUrl(window.location.hash);
    // const [searchTerm, setSearchTerm] = useState(Object.keys(hashDict).length > 0 ? (hashDict?.subject || '') : '');
    const { page, pageSize, sortColumn, sortDirection, searchValue, filters, categoryFilter, totalItems } = useSelector(state => state.table);
    const { modals } = useSelector(state => state.scansDone);
    const location = useLocation();

    const fetchConfig = (filters={}, resetStats=false) => {
        const filtersObj = {
            ...filters,
            category: CategoryFilter.SCANS_DONE,
        };
        const usedSearchValue = Object.keys(hashDict).length > 0 ? decodeURIComponent(hashDict?.subject || '') : (resetStats ? "" : searchValue);
        const body = {
            lowerLimit: resetStats ? 0 : (page - 1) * pageSize,
            upperLimit: resetStats ? pageSize : page * pageSize,
            filters: filtersObj,
            searchValue: usedSearchValue,
            sortDirection: resetStats ? false : sortDirection,
            sortColumn: resetStats ? 0 : sortColumn,
        };

        return {body, filtersObj};
    };

    const performFetch = (filters={}, resetPage=false) => {
        const {body, filtersObj} = fetchConfig(filters);
        if (resetPage) {
            body.lowerLimit = 0;
            body.upperLimit = pageSize;
            dispatch(setPage(1));
        }
        dispatch(fetchData(body));
        dispatch(setFilters(filtersObj));
    };

    useEffect(() => {
        const {body, filtersObj} = fetchConfig({}, true);
        dispatch(resetState({filters: filtersObj, categoryFilter: CategoryFilter.SCANS_DONE}));
        dispatch(fetchData(body));
    }, [dispatch, location]);

    const handleCloseModal = (modalName) => {
        dispatch(closeModal(modalName));
    };


    return (
        <>
            {modals.searchFilterModal && 
                <SearchFilterModal
                    open={modals.searchFilterModal} 
                    handleClose={()=> handleCloseModal(MODAL_TYPES.SEARCH_FILTER_UPLOAD)} 
                    currentFilters={filters}
                    performFetch={(f) => performFetch(f, true)}
                />
            }
            <main className="flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-8">
                    <Header totalItems={totalItems} />
                    <div className="mt-2 flex flex-col">
                        <ScansDoneTable />
                    </div>
                </div>
            </main>
        </>
    )
};

export default ScansDone;