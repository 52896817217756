import React from 'react'

const FileStack = ({ fill = 'none', width = '16px', height = '16px', styleClass = "" }) => {
  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={fill}
      viewBox='0 0 16 16'
    >
        <path d="M13.9993 4.66732H11.9993C11.6457 4.66732 11.3066 4.52684 11.0565 4.27679C10.8065 4.02674 10.666 3.68761 10.666 3.33398V1.33398" stroke="#7F8594" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0007 4.00065V8.33398C14.0007 8.86732 13.534 9.33398 13.0007 9.33398H8.33398C7.80065 9.33398 7.33398 8.86732 7.33398 8.33398V2.33398C7.33398 1.80065 7.80065 1.33398 8.33398 1.33398H11.334L14.0007 4.00065Z" stroke="#7F8594" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.66602 5.33398V11.2007C4.66602 11.4007 4.79935 11.6007 4.93268 11.734C5.06602 11.8673 5.26602 12.0007 5.46602 12.0007H9.99935" stroke="#7F8594" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 8.00098V13.8676C2 14.0676 2.13333 14.2676 2.26667 14.401C2.4 14.5343 2.6 14.6676 2.8 14.6676H7.33333" stroke="#7F8594" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default FileStack