import React, {useState} from "react";
//components
import Btn from "../../buttons/standard/btn";
import Notification from "../../side_notification/side_notification";
import SimpleInput from "../../inputs/simple_input/input";
import { RadioInput } from "../../radioInput/radioInput";
//utils
import { isEmail, postReqOptBuilder } from "../../../utils/main_utils";
//styles
import "./add_new_employee.css";

export default function AddNewEmployee(props) {
    const [notifState, setNotifState] = useState(null);
    const [inputError, setInputError] = useState(null);
    const inputs = [["First Name", "text", "fName", 'First Name'], 
                    ["Last Name", "text", "lName", 'Last Name'],
                    ["Email", "email", "email", 'Email'], 
                    ["Role Type", "text", "role", 'Manager, Housekeeping, Front Desk...'],];
    const errorsList = {'fName': "Please enter employee first name", 
                        'lName': "Please enter employee last name",
                        'email': "Please enter employee email", 
                        'email2': "Invalid Email Address", 
                        'role': "Please enter employee role",};
                    
    const handleCreateEmployee = () => {
        setInputError(null);
        let _inputErrors = {};
        const reqBody = {'first_name': document.getElementById("fName").value, 'last_name': document.getElementById("lName").value, 
                        'email': document.getElementById("email").value, 'role': document.getElementById("role").value,
                        'user_type': document.querySelector('input[name="user-type"]:checked')?.value}
        for (let [k, v] of Object.entries({'fName': reqBody['first_name'], 'lName': reqBody['last_name'], 'email': reqBody['email'], 'role': reqBody['role']})) {
            if (!v || v.length <= 0) {
                _inputErrors[k] = errorsList[k];
            }
        }
        if (_inputErrors.length > 0) {
            setInputError(_inputErrors);
            return;
        } else if (!isEmail(reqBody['email'])) {
            setInputError({"email": errorsList['email2']});
            return;
        }
        fetch(props.para_be + '/settings/create-employee', postReqOptBuilder(reqBody))
        .then(response => response.json())
        .then(response => {
                console.log(response);
                if (response.status === 200) {
                    setNotifState({text: 'Created Successfully', type: 'success'});
                    props.closeFunc(true);
                } else {
                    setNotifState({text: response.msg, type: 'error'});
                }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: "An error has occurred, please try again later", type: 'error'});
        });
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    return (
        <section className="create-employee-container flexColumn">
            <div className="h2 blue-headline">Add new employee</div>
            <RadioInput
                values={["User","Admin"]}
                groupName="user-type"
                label="User Type"
            />
            {inputs.map(([label, inputType, id, placeholder], index) => {
                return (
                    <SimpleInput
                        label={label}
                        type={inputType}
                        id={id}
                        placeholder={placeholder}
                        inputError={inputError?.hasOwnProperty(id) ? inputError[id] : undefined}/>
                )
            })}
            <Btn
                type="primary"
                text="Create Employee"
                extraClasses="submit-employee"
                onclick={handleCreateEmployee}/>

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </section>
    )
}