import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//containers
import General from './tabs/general';
import UserSettings from './tabs/user_settings';
import Integrations from './tabs/integrations';
import Employees from './tabs/employees';
import Mailing from './tabs/mailing';
import Payments from './tabs/Payments/Payments';
//components
import MainLoader from '../../components/loaders/main_loader/main_loader';
import Btn from '../../components/buttons/standard/btn';
import Notification from '../../components/side_notification/side_notification';
import ConnectPms from '../../components/popups/connect_pms/connect_pms';
import MasterPopup from '../../components/popups/main_popup';
import AddNewEmployee from '../../components/popups/add_new_employee/add_new_employee';
import ChangePasswordPopup from '../../components/popups/change_password/change_password';
import ConfirmPopup from '../../components/popups/confirmation/confirmation_popup';
import UpdateEmployee from '../../components/popups/update_employee/update_employee';
import ChangeSmtpPasswordPopup from '../../components/popups/change_password/change_smtp_password';
import ThreeDotsLoader from '../../components/loaders/ThreeDotsLoader';
//utils
import { capitalizeString, isEmail, postReqOptBuilder } from '../../utils/main_utils';
import { connectToArthurPMS } from '../../utils/pms_connections/arthur_connection';
//styles
import './settings.css';
//assets
import { ReactComponent as BellIcon } from '../../assets/icons/notification.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/icons/employee.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/icons/integration.svg';
import { ReactComponent as MailingIcon } from '../../assets/icons/mailing.svg';
import { ReactComponent as PaymentIcon } from '../../assets/icons/payment.svg';
import { ReactComponent as UserSettingsIcon } from '../../assets/icons/use-setting.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/Home.svg';


const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/svg'];
const tabData = [
    {
        iconUrl: <HomeIcon height={20} width={20} />,
        label: 'General',
        minWidth: 90,
        isPublic: true,
    },
    {
        iconUrl: <MailingIcon height={20} width={20} />,
        label: 'Mailing',
        minWidth: 90,
        isPublic: false,
    },
    {
        iconUrl: <BellIcon height={20} width={20} />,
        label: 'Notifications',
        minWidth: 130,
        comingSoon: true,
        isPublic: true,
    },
    {
        iconUrl: <EmployeesIcon height={20} width={20} />,
        label: 'Employees',
        minWidth: 130,
        isPublic: false,
    },
    {
        iconUrl: <IntegrationIcon height={20} width={20} />,
        label: 'Integrations',
        minWidth: 130,
        isPublic: false,
    },
    {
        iconUrl: <PaymentIcon height={20} width={20} />,
        label: 'Subscription',
        minWidth: 110,
        comingSoon: true,
        isPublic: false,
    },
    {
        iconUrl: <UserSettingsIcon height={20} width={20} />,
        label: 'User Settings',
        minWidth: 140,
        isPublic: true,
    },
];
const tabsPath = [
    '/settings/general',
    '/settings/mailing',
    '/settings/notifications',
    '/settings/employees',
    '/settings/integrations',
    '/settings/payments',
    '/settings/user-settings'
];

export default function Settings(props) {
    const [isUserAdmin, setUserAdmin] = useState(false);

    const cred = useSelector(state => state.auth.cred);
    const userType = cred?.userType ?? null;
    // const userType = cred?.userType ?? 'User';
    const userTypeLoding = Boolean(!userType);
    
    const [isLoading, setLoading] = useState(true);
    const [tab, setTab] = useState(1);
    const [isLogo, setLogo] = useState(null);
    const [isBanner, setBanner] = useState(null);
    const [imprintTextArea, setImprintTextArea] = useState(null);
    const [preScanText, setPreScanText] = useState(null);
    const [postScanText, setPostScanText] = useState(null);
    const [companyName, setCompanyName] = useState('Company Name');
    const [fullName, setFullName] = useState(null);
    const [mobile, setMobile] = useState(null);
    const [email, setEmail] = useState(null);
    const [pmsMetaData, setPmsMetaData] = useState(null);
    const [smtpMetaData, setSmtpMetaData] = useState(null);
    const [smtpEditMode, setSmtpEditMode] = useState(false);
    const [smtpPassword, setSmtpPassword] = useState(null);
    const [smtpInputError, setSmtpInputError] = useState(null);
    const [employeesData, setEmployeesData] = useState(null);
    const [companyPrevData, setCompanyPrevData] = useState(null);
    const [userPrevData, setUserPrevData] = useState(null);
    const [autoEmails, setAutoEmails] = useState([]);

    const [notifState, setNotifState] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [popupState, setPopupState] = useState(null);
    const [activeDD, setActiveDD] = useState(null);
    const [userToDelete, setUserToDelete] = useState(null);
    const [saving, setSaving] = useState(false);
    const bannerImage = useRef(null);
    const logoImage = useRef(null);
    const isInitialMount1 = useRef(true);
    const isInitialMount2 = useRef(true);

    const history = useHistory();
    const location = useLocation();
    const currentPath = location.pathname;


    useEffect(() => {
        setLoading(true);
        const pathUsed = currentPath.endsWith('/') ? currentPath.slice(0, -1) : currentPath;
        const activeTabIdx = tabsPath.map((path) => pathUsed.startsWith(path)).indexOf(true);
        console.log("Active Tab Index:", activeTabIdx);
        if (activeTabIdx >= 0) {
            console.log("Setting Tab:", tabsPath.indexOf(pathUsed) + 1);
            setTab(activeTabIdx + 1);
        }
        fetch(props.para_be + '/settings/get-data', { credentials: 'include' })
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setCompanyName(response.result['company_data'][0]);
                setLogo(response.result['media'].logo);
                setBanner(response.result['media'].banner);
                setImprintTextArea(response.result['company_data'][3]);
                setPreScanText(response.result['company_data'][5]);
                setPostScanText(response.result['company_data'][6]);
                setCompanyPrevData(response.result['company_data']);
                setFullName(response.result['user_data'][0] + (response.result['user_data'][1].length > 0 ? ' ' : '') + response.result['user_data'][1]);
                setMobile(response.result['user_data'][2].phone);
                setEmail(response.result['user_data'][2].email);
                setUserPrevData(response.result['user_data']);
                setEmployeesData(response.result['employees']);
                let companyMeta = JSON.parse(response.result['company_data'][7]);
                setPmsMetaData(companyMeta.pms);
                setSmtpMetaData(companyMeta.smtp);
                if (companyMeta?.auto_mail_conf) {
                    let autoEmailConf = companyMeta.auto_mail_conf;
                    setAutoEmails(
                        autoEmailConf.map(item => {
                            return {
                                sendType: item.upon,
                                sendWhen: {amount: item.when?.amount, type: item.when?.type},
                                subject: item.subject,
                                body: item.body,
                                isCollapsed: true, // Initially, email box is collapsed,
                                variable: '',
                            };
                        })
                    );
                }
                setLoading(false);
            } else {
                setNotifState({ text: 'Load profile failed', type: 'error' });
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState({type: 'error', msg: 'An error has occurred, please try again later'});
        });

        //fires when PMS connection is being initialized
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get('connect_to_PMS')) {
            const pmsType = urlParams.get('connect_to_PMS');
            if (pmsType === 'arthur') {
                const pms_code = urlParams.get('code');
                const pms_state = urlParams.get('state');
                connectToArthurPMS(props.para_be, pms_code, pms_state)
                .then(response => {
                    if (response) notifState(response);
                });
            } else {
                //TODO - add other PMS handlers if applicable here.
            }
        }
    }, [forceRefresh]);

    const changeOccurred = (e = null) => {
        if (e === null && isInitialMount1.current) {
            isInitialMount1.current = false;
        } else if (e && isInitialMount2.current) {
            isInitialMount2.current = false;
        } else {
            let sSection = document.querySelector('.save-section');
            if (sSection && !sSection.classList.contains('change-occurred')) {
                sSection.classList.add('change-occurred');
                // window.onscroll(null);
            }
        }
    };

    useEffect(() => {
        changeOccurred();
    }, [isLogo, isBanner, imprintTextArea, preScanText, postScanText, fullName, mobile, email]);

    const handleViewProfile = () => {
        console.log('view profile');
    };

    const handleImageUploadClick = type => {
        if (type === 'logo') logoImage.current.click();
        else bannerImage.current.click();
    };

    const handleImageUpload = (e, type) => {
        const img = e.target.files[0];
        if (type === 'logo') setLogo(URL.createObjectURL(img));
        else setBanner(URL.createObjectURL(img));
    };

    const handleOpenPopup = (type, userId = null, userType = null, userRole = null, status = null) => {
        if (type === 'emp') setPopupState([<AddNewEmployee closeFunc={handleClosePopup} para_be={props.para_be} />, { closeFunc: handleClosePopup }]);
        else if (type === 'change') setPopupState([<ChangePasswordPopup closeFunc={handleClosePopup} para_be={props.para_be} />, { closeFunc: handleClosePopup }]);
        else if (type === 'pms') setPopupState([<ConnectPms closeFunc={handleClosePopup} para_be={props.para_be} />, { closeFunc: handleClosePopup }]);
        else if (type === 'smtp_pass_change') setPopupState([<ChangeSmtpPasswordPopup closeFunc={handleClosePopup} onSuccess={setSmtpPassword} para_be={props.para_be} />, { closeFunc: handleClosePopup }]);
        else if (type === 'edit_user') setPopupState([<UpdateEmployee 
                                                        userId={userId}
                                                        userType={userType}
                                                        userRole={userRole}
                                                        userStatus={status}
                                                        para_be={props.para_be}
                                                        closeFunc={handleClosePopup} />, { closeFunc: handleClosePopup }]);
        else {
            setUserToDelete([userId]);
            setPopupState([<ConfirmPopup
                            headlineText={'Please confirm that you want to delete this user.'}
                            mainText={"You won't be able to recover it."}
                            confirmText={'Delete'}
                            declineText={'Cancel'}
                            confirmChoice={handleConfirmDeleteUnit}
                            declineChoice={handleClosePopup}
                            closeFunc={handleClosePopup}/>, { closeFunc: handleClosePopup }]);
        }
    };

    const handleClosePopup = (refresh = false) => {
        setPopupState(null);
        if (refresh) setForceRefresh(!forceRefresh);
    };

    const handleSaveProfile = () => {
        const formData = new FormData();
        formData.append('tab_type', tabsNames[tab - 1]);
        if (tab === 1) {
            const logo = document.getElementById('logo-img').files[0];
            const banner = document.getElementById('banner-img').files[0];
            const preScan = document.getElementById('pre-scan').value;
            const postScan = document.getElementById('post-scan').value;
            const imprint = document.getElementById('impressum').value;
            if (logo == null && banner == null && imprint === companyPrevData[3] && 
                preScan === companyPrevData[5] && postScan === companyPrevData[6]) {
                setNotifState({ text: "Can't save unchanged", type: 'error' });
                return;
            }
            let files = [logo, banner];
            for (const [index, file] of files.entries()) {
                if (file !== null && file !== undefined) {
                    //check size
                    if (file.size > 5242880) {
                        setNotifState({text: 'File is too big, maximum 5MB is allowed', type: 'error'});
                        return;
                    }
                    //check types
                    if (!acceptedFileTypes.includes(file.type)) {
                        setNotifState({text: 'Only JPEG, PNG, SVG, WEBP files are accepted', type: 'error'});
                        return;
                    }
                    formData.append(index === 0 ? 'logo' : 'banner', file);
                    formData.append(index === 0 ? 'logo_type' : 'banner_type', file.type);
                }
            }
            if (imprint !== companyPrevData[3]) formData.append('impressum', imprint);
            if (preScan !== companyPrevData[5]) formData.append('pre_scan_text', preScan);
            if (postScan !== companyPrevData[6]) formData.append('post_scan_text', postScan);
        } else if (tab === 2) {
            console.log('Updating auto emails');
            console.log('autoEmails:', autoEmails);
            for (let i = 0; i < autoEmails.length; i++) {
                let ae = autoEmails[i];
                if (
                    !ae.sendType || ae.sendType.length === 0 ||
                    !ae.sendWhen.amount || ae.sendWhen.amount.length === 0 ||
                    !ae.sendWhen.type || ae.sendWhen.type.length === 0 ||
                    !ae.subject || ae.subject.length === 0 ||
                    !ae.body || ae.body.length === 0
                ) {
                    setNotifState({text: 'Please fill all required inputs in the auto-email-config', type: 'error'});
                    return;
                }
            }
            formData.append(
                'auto_email',
                JSON.stringify(
                    autoEmails ? autoEmails.map((item, item_idx) => {
                        return {
                            rule_id: `${new Date().getTime()}-${item_idx}`,
                            upon: item.sendType,
                            sendTo: item?.sendTo,
                            when: item.sendWhen,
                            subject: item.subject,
                            body: item.body,
                        };
                    }) : []
                )
            );
        } else if (tab === 7) {
            const name = document.getElementById('full-name').value;
            const mobileInput = document.getElementById('mobile').value;
            const emailInput = document.getElementById('email').value;
            if (name === userPrevData.full_name && emailInput === userPrevData.email && mobileInput === userPrevData.mobile) {
                setNotifState({ text: "Can't save unchanged", type: 'error' });
                return;
            }
            if (emailInput && !isEmail(emailInput)) {
                setNotifState({ text: 'Invalid Email Address', type: 'error' });
                return;
            }
            console.log(userPrevData);
            console.log(name);
            console.log(userPrevData.full_name);
            console.log(emailInput);
            console.log(userPrevData.email);
            console.log(mobileInput);
            console.log(userPrevData.mobile);
            if (name !== userPrevData.full_name) formData.append('full_name', name);
            if (emailInput !== userPrevData.email) formData.append('email', emailInput);
            if (mobileInput !== userPrevData.mobile) formData.append('mobile', mobileInput);
        }
        const requestOptions = {
            method: 'POST',
            body: formData,
            credentials: 'include',
        };

        setSaving(true);
        fetch(props.para_be + '/settings/save-data', requestOptions)
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setNotifState({ text: 'Saved successfully', type: 'success' });
                let sSection = document.querySelector('.save-section');
                if (sSection && sSection.classList.contains('change-occurred')) sSection.classList.remove('change-occurred');
            } else {
                setNotifState({ text: 'Save failed', type: 'error' });
            }
            setSaving(false);
        })
        .catch(error => {
            setSaving(false);
            setNotifState({ text: 'Save failed', type: 'error' });
        });
    };

    const handleConfirmDeleteUnit = () => {
        fetch(props.para_be + '/settings/change-employee-status', postReqOptBuilder({ user_to_delete: userToDelete }))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setNotifState({ text: response.msg, type: 'success' });
            } else {
                setNotifState({ text: response.msg, type: 'error' });
            }
        })
        .catch(error => {
            setNotifState({ text: 'Delete failed', type: 'error' });
        });
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    };

    const handleBack = () => {
        history.push(history.location.state && history.location.state.prevLocation ? history.location.state.prevLocation : '/management');
    };

    const handleDropDown = (event, employeeId) => {
        event.stopPropagation();
        if (activeDD === employeeId) closeDD();
        else {
            closeDD();
            setActiveDD(employeeId);
        }
    };

    const closeDD = () => {
        if (activeDD) setActiveDD(null);
    };

    const updateSmtpData = () => {
        if (smtpInputError) setSmtpInputError(null);

        const smtp_server = document.getElementById('smtp-server').value?.trim();
        const smtp_port = document.getElementById('smtp-port').value?.trim();
        const smtp_email = document.getElementById('smtp-email').value?.trim();

        let _inputErrors = {};
        for (let [k, v] of Object.entries({
            'smtp-server': smtp_server,
            'smtp-port': smtp_port,
            'smtp-email': smtp_email,
        })) {
            if (!v || v.length === 0) _inputErrors[k] = 'Please enter ' + capitalizeString(k.replace('-', ' '), true);
        }
        if (Object.keys(_inputErrors).length > 0) {
            setSmtpInputError(_inputErrors);
            return;
        }

        let reqData = {
            smtp_server: smtp_server,
            smtp_port: smtp_port,
            email: smtp_email,
        };
        if (smtpPassword) reqData['pass'] = smtpPassword;
        fetch(props.para_be + '/settings/save_smtp_data', postReqOptBuilder(reqData))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setNotifState({ text: 'SMTP Settings Updated', type: 'success' });
                closeSmtpEditMode();
            } else {
                setNotifState({ text: response.msg, type: 'error' });
            }
        })
        .catch(error => {
            setNotifState({ text: 'Failed to save SMTP settings', type: 'error' });
        });
    };

    const closeSmtpEditMode = () => {
        setSmtpPassword(null);
        setSmtpEditMode(false);
    };

    useEffect(() => {
        document.body.addEventListener('click', closeDD);
        return () => {
            document.body.removeEventListener('click', closeDD);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDD]);

    useEffect(() => {
        if (userType && 'admin' === userType.toLowerCase()) {
            setUserAdmin(true);
        }
    }, [userType]);

    //structure: [type, id, extraClass, text/placeholder, state, changeFunc]
    const companySettings = [
        ['text', 'pre-scan', null, 'Pre-scan text', preScanText],
        ['text', 'post-scan', null, 'Post-scan text', postScanText],
        ['textArea', 'impressum', null, 'Impressum', imprintTextArea],
    ];
    
    const tabsNames = ['company', 'mailing', 'notifications', 'employees', 'integrations', 'payments', 'user'];
    const skipSave = [4, 5, 6];

    return (
        <section className={'comp-settings-container d-flex-col ' + (isLoading ? 'pos-relative' : '')}>
            <Helmet>
                <title>{companyName + ' - Company Settings'}</title>
                <meta name="description" content="." />
                <meta property="og:title" content={companyName + ' - Company Settings'} />
                <meta property="og:description" content="." />
            </Helmet>

            <section className={`settings-nav-panel horizontal-scrollbar-1 ${isUserAdmin ? 'justify-between' : ''}`}>
                {userTypeLoding ? 
                    <div className='flex justify-center w-full'> 
                        <ThreeDotsLoader
                            height="40"
                            width="40"
                            color="#1890ff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass="" />
                    </div> :
                    tabData.map((data, index) => (data.isPublic || isUserAdmin) && (
                            <div
                                className={`settings-tab ${data?.comingSoon ? 'coming-soon' : ''} ${tab === index + 1 ? 'active' : ''}`}
                                // onClick={() => { if (!data?.comingSoon) setTab(index + 1); }}
                                onClick={() => {
                                    if (!data?.comingSoon) {
                                        setTab(index + 1);
                                        history.push(tabsPath[index]); 
                                    }
                                }}
                                key={index}
                            >
                                <div className="icon-label">
                                    {data.iconUrl}
                                    {data.label}
                                </div>
                                {data?.comingSoon && <div className="coming-soon-label">COMING SOON</div>}
                            </div>
                        )
                    )
                }
            </section>

            {isLoading ? <MainLoader /> : (
                <Switch>
                    {/* Mailing Tab */}
                    {isUserAdmin &&
                        <Route 
                            path={`/settings/mailing`} 
                            render={() => <Mailing
                                            smtpEditMode={smtpEditMode}
                                            smtpInputError={smtpInputError}
                                            smtpMetaData={smtpMetaData}
                                            setSmtpEditMode={setSmtpEditMode}
                                            closeSmtpEditMode={closeSmtpEditMode}
                                            updateSmtpData={updateSmtpData}
                                            handleOpenPopup={handleOpenPopup}
                                            //Auto-Mail
                                            emailData={autoEmails}
                                            setEmailData={setAutoEmails}
                                            setNotifState={setNotifState}/>} />
                    }
                    {/* Notifications Tab */}
                    <Route 
                        path={`/settings/notifications`} 
                        render={() => <></>} />
                    {/* Employees Tab */}
                    {isUserAdmin &&
                        <Route 
                            path={`/settings/employees`} 
                            render={() => <Employees 
                                            handleOpenPopup={handleOpenPopup}
                                            employeesData={employeesData}
                                            activeDD={activeDD}
                                            handleDropDown={handleDropDown}/>} />
                    }
                    {/* Integrations Tab */}
                    {isUserAdmin &&
                        <Route 
                            path={`/settings/integrations`} 
                            render={() => <Integrations 
                                            pmsMetaData={pmsMetaData}
                                            handleOpenPopup={handleOpenPopup}/>} />
                    }
                    {/* Payments Tab */}
                    {isUserAdmin &&
                        <Route 
                            path={`/settings/payments`} 
                            render={() => <Payments {...props} />} />
                    }
                    {/* User Settings Tab */}
                    <Route 
                        path={`/settings/user-settings`} 
                        render={() => <UserSettings 
                                        handleOpenPopup={handleOpenPopup}
                                        handleBack={handleBack}
                                        fullName={fullName}
                                        mobile={mobile}
                                        email={email}/>} />
                    {/* General Tab */}
                    <Route 
                        path={"/settings"}
                        render={() => <General
                                        handleOpenPopup={handleOpenPopup}
                                        handleBack={handleBack}
                                        handleViewProfile={handleViewProfile}
                                        isBanner={isBanner}
                                        isLogo={isLogo}
                                        logoImage={logoImage}
                                        bannerImage={bannerImage}
                                        handleImageUploadClick={handleImageUploadClick}
                                        handleImageUpload={handleImageUpload}
                                        companyName={companyName}
                                        companySettings={companySettings}
                                        pmsMetaData={pmsMetaData}
                                        changeOccurred={changeOccurred}
                                        setImprintTextArea={setImprintTextArea}/>} />
                </Switch>
            )}
            {!isLoading && !skipSave.includes(tab) ?
                <div className="save-section">
                    <Btn text={saving ? 'Saving . . .' : 'Save Settings'} type="primary" extraClasses="save-profile" onclick={handleSaveProfile} />
                </div> : ''
            }

            {popupState ? <MasterPopup extraClasses="custom-scrollbar" {...popupState[1]}>{popupState[0]}</MasterPopup> : ''}

            {notifState ? <Notification closeFunc={handleCloseNotif} text={notifState.text} type={notifState.type}/> : ''}
        </section>
    );
}
