import React, {useState, useEffect, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { UAParser } from 'ua-parser-js';

//styles
import "../management/management.css";
//components
import Btn from "../../components/buttons/standard/btn";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_state";
import Notification from "../../components/side_notification/side_notification";
import MasterPopup from "../../components/popups/main_popup";
import ConfirmPopup from "../../components/popups/confirmation/confirmation_popup";
//utils
import { dateTimeFormater, isToday } from "../../utils/date_utils.js";
import { postReqOptBuilder, createDictFromHashedUrl } from "../../utils/main_utils";
//assets
import searchUp from "../../assets/icons/search-up.svg";
import searchDown from "../../assets/icons/search-down.svg";
import searchDownSelect from "../../assets/icons/search-down-select.svg";
import searchUpSelect from "../../assets/icons/search-up-select.svg";
import dots from "../../assets/icons/dots.svg";
import { SimpleDropdownContainer, SimpleInputDropdown } from "../../components/dropdowns/simple_dropdown/simple_dropdown";
import ViewBaseline from "../../components/popups/view_baseline/view_baseline";
//constants
const itemsPerPage = 20;

export default function AdminScanDashboard (props) {
    // state based variables
    const [isLoader, setLoader] = useState(true);
    const [listScans, setListScans] = useState();
    const [currentSort, setCurrentSort] = useState({"column": null, "direction": null}); //sort direction(true ASC, false DESC)
    const [activeDD, setActiveDD] = useState(null);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    // reference based variables
    const unitToDelete = useRef();
    let updateStatusInterval = null;
    
    //load of initial data
    useEffect(() => {
        fetchScans();
        // setInterval(() => {
        //     fetchScans();
        // }, 120000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log("new value to listScans:", listScans);
        updateScansStatus();
    }, [listScans]);

    const fetchScans = () => {
        setLoader(true);
        fetch(props.para_be + "/scan/getActiveScans?token=paraspotAdmin22!")
        .then(response => response.json())
        .then(response => {
            console.log("getActiveScans result:", response);
            if (response.status === 200) {
                let tmpResult = response.result;
                for (let k of Object.keys(tmpResult)) {
                    const uaParsed = new UAParser(tmpResult[k].deviceDetails_ua);
                    tmpResult[k].deviceDetails_ua = uaParsed.getResult();
                }
                setListScans(tmpResult);
                if (isMainEmptyState) setMainEmptyState(false);
                if (!updateStatusInterval) clearInterval(updateStatusInterval);
                updateStatusInterval = setInterval(() => { updateScansStatus(response.result) }, 10000);
            } else {
                setMainEmptyState(true);
            }
            setLoader(false);
        })
        .catch(error => {
            setMainEmptyState(true);
            setNotifState({text: "Can't fetch data", type: "error"});
            setLoader(false);
        })
    };

    const updateScansStatus = (_listScans=null) => {
        if (_listScans == null) _listScans = listScans;
        console.log("Updating scans status");
        console.log(_listScans);
        for (let sid of Object.keys(_listScans || {})) {
            console.log("Sending API request getScanStatus for sid:", sid);
            fetch(props.para_be + "/scan/getScanStatus?token=paraspotAdmin22!&sid=" + sid)
            .then(response => response.json())
            .then(response => {
                console.log("getScanStatus?sid" + sid, response);
                if (response.status === 200) {
                    // <span className={"para-label ls-status para-l-" + rowStatus.toLowerCase()}>{rowStatus}</span>
                    document.querySelector(`tr[id="${sid}"] > td[data-th=Status] > div`).innerHTML = `<span class="para-label ls-status para-l-${response.result.type}">${response.result.status}</span>`
                }
            })
        }
    };

    const removeSession = (sess_id) => {
        fetch(props.para_be + "/scan/admin/removeSession", postReqOptBuilder({sid: sess_id, token: 'paraspotAdmin22!'}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                console.log("Successfully removed session:", sess_id);
                setNotifState({text: "Successfully removed session.", type: "success"});
            } else {
                console.log("Failed to remove session:", sess_id);
                console.log(response);
                setNotifState({text: "Failed to remove session.", type: "error"});
            }
        })
        .catch((err) => {
            console.log("Failed to remove session:", sess_id);
            console.log(err);
            setNotifState({text: "Failed to remove session.", type: "error"});
        });
    };

    const allowFrameMatch = (sess_id) => {
        fetch(props.para_be.replace("www.paraspot.de", "aiv2.paraspot.de").replace(".de/api", ".de") + "/internal/admin/allowFrameMatch", postReqOptBuilder({sid: sess_id}))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                console.log("Successfully allowed frame match in inspection scan for session:", sess_id);
                setNotifState({text: "Successfully allowed frame match in inspection scan.", type: "success"});
            } else {
                console.log("Failed to allow frame match in inspection scan for session:", sess_id);
                console.log(response);
                setNotifState({text: "Failed to allow frame match in inspection scan.", type: "error"});
            }
        })
        .catch((err) => {
            console.log("Failed to allow frame match in inspection scan for session:", sess_id);
            console.log(err);
            setNotifState({text: "Failed to allow frame match in inspection scan.", type: "error"});
        });
    };

    const handleCloseConfirmPopup = (event) => {
        if (event) event.stopPropagation();
        setPopupState(null);
    };

    useEffect(() => {
        if (activeDD) {
            document.body.addEventListener('click' , closeDD);
            return () => {document.body.removeEventListener('click' , closeDD)};
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDD]);

    const handleDropDown = (event, t) => {
        event.stopPropagation();
        if (activeDD === t) closeDD();
        else {
            if (activeDD) closeDD();
            setActiveDD(t);
        }
    };

    useEffect(()=> {
        closeDD();
    }, [popupState])

    const closeDD = () => {
        if (activeDD) setActiveDD(null);
    };

    const handleOpenBaselineVideo = (target_pid, target_subject) => {
        setPopupState([<ViewBaseline closeFunc={handleClosePopup} para_be={props.para_be} pid={target_pid} subject={target_subject}/>, {closeFunc: handleClosePopup, extraClasses: "has-bsp-container"}]);
    };

    const handleClosePopup = (refresh=false) => {
        setPopupState(null);
        if (refresh) fetchScans();
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    }


    return (
        <section className="mgmt-main">
            <Helmet>
                <title>Paraspot Admin | Scans Management</title>
                <meta name="description" content="."/>
                <meta property="og:title" content="Paraspot Admin | Scans Management"/>
                <meta property="og:description" content="."/>
            </Helmet>
            <div className="mgmt-header flexRow">
                {/* <div className="mgmt-search flexRow">
                    <input className="text-1" id="search" placeholder="Search" onKeyUp={() => handleSearch()}/>
                    <img className="unselectable" src={smallSearch} alt="small-search"/>
                </div> */}
                <div className="mgmt-header-btns flexRow">
                    {/* TODO: Update the onclick event */}
                    <Btn
                        text="Refresh"
                        type="primary"
                        onclick={() => fetchScans() }/>
                </div>
            </div>

            <div className="mgmt-table">
                <table cellSpacing="0" cellPadding="0">
                    <thead className="desktop-only">
                        <tr>
                            {Object.entries({'Client': {'sortable': false, 'className': 'th-client'}, 'Unit': {'sortable': false, 'className': 'th-unit'}, 'Type': {'sortable': false, 'className': 'th-type'}, 'Status': {'sortable': false, 'className': 'th-status'}, 'Action': {'sortable': false, 'className': 'th-action'}}).map(([k, v], index) => {
                                let isCurrentSort = currentSort.column === index+1 && v['sortable'];
                                return (
                                    <th className={v['className'] + (isCurrentSort ? " sort-active" : "")}>
                                        {/* <div {...(v['sortable'] ? {onClick: () => handleSortUnits(index+1)} : {})}> */}
                                        <div>
                                            <span>{k}</span>
                                            {v['sortable'] &&
                                                <div className="sort-icons flexColumn">
                                                    <img src={isCurrentSort && currentSort.direction === true ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                    <img src={isCurrentSort && currentSort.direction === false ? searchDownSelect : searchDown} alt="arrow-down"/>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                )})
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader ? <MainLoader/> :
                            isMainEmptyState ? <EmptyState text={"No active scans at the moment"}  size="lg"/> :
                            Object.keys(listScans)?.map(function (sid) {
                                // {endUserInfo: {pid: @str, cid: @str}, is_tenantScan: @bool, subject: @str, client_name: @str}
                                const item_id = sid;
                                return (
                                    <tr id={item_id} key={item_id}>
                                        <td data-th="Client">{listScans[sid].client_name}</td>
                                        <td data-th="Unit" title={listScans[sid].subject}>
                                            {listScans[sid].subject}
                                            <br/>
                                            pid: {listScans[sid].endUserInfo.pid}
                                            <br/>
                                            sid: {item_id}
                                            <br/>
                                            User Agent: {listScans[sid].deviceDetails_ua.ua}
                                            <br/>
                                            Device: {[
                                                listScans[sid].deviceDetails_ua.device?.vendor, 
                                                listScans[sid].deviceDetails_ua.device?.model, 
                                                listScans[sid].deviceDetails_ua.os?.name, 
                                                listScans[sid].deviceDetails_ua.os?.version].join(" ")}
                                            <br/>
                                            Browser: {[
                                                listScans[sid].deviceDetails_ua.browser?.name, 
                                                listScans[sid].deviceDetails_ua.browser?.version].join(" ")}
                                        </td>
                                        <td data-th="Type">
                                            {listScans[sid].is_tenantScan ? "Tenant" : "Baseline"}
                                            <br/>
                                            ({listScans[sid].endUserInfo.inspection_type})
                                        </td>
                                        {/* <span className={"para-label ls-status para-l-" + rowStatus.toLowerCase()}>{rowStatus}</span> */}
                                        <td data-th="Status">
                                            <div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="report-btns">
                                                <Link to={`/paraspot/admin/scan_viewer/${item_id}?token=paraspotAdmin22!`} className="simple-btn">View Scan</Link>
                                                <SimpleDropdownContainer
                                                    extraClasses="more-items"
                                                    showDropdown={activeDD === sid}
                                                    borderedItems={true}
                                                    items={{
                                                        'show_baseline': {'present': 'Show Baseline Scan', 'disabled': false, 'onclick': (k, e) => handleOpenBaselineVideo(listScans[sid].endUserInfo.pid, listScans[sid].subject)},
                                                        ...(listScans[sid].is_tenantScan ? {
                                                            'allow_frame_match': {'present': 'Allow Frame Match', 'disabled': false, 'onclick': (k, e) => allowFrameMatch(sid)},
                                                        } : {}),
                                                        'remove_session': {'present': 'Remove Session', 'disabled': false, 'onclick': (k, e) => removeSession(sid)},
                                                    }}>
                                                    <img src={dots} alt="more-btn" onClick={(e) => handleDropDown(e, sid)}/>
                                                </SimpleDropdownContainer>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {/* {isMainEmptyState ? "" :
                    <Pagination
                        currentPage = {currentPage}
                        maxPages = {Math.ceil(maxItems / itemsPerPage)}
                        handleChangePage = {(page) => handleChangePage(page)}
                        setNotifState = {setNotifState}
                        itemsPerPage = {itemsPerPage}
                    />
                } */}
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}       
        </section>
    )
}
