import { useState, useEffect } from 'react';
import axios from 'axios';
//constants
import { para_be } from '../../../../../../config'
import { dateTimeFormaterUTC } from '../../../../../../utils/date_utils';
import { DATE_FORMAT } from '../../../../../../constants';


const useFetchExtendedInfo = (pid, isOpen) => {
    const [extendedInfo, setExtendedInfo] = useState({});
    const [isExtendedInfoLoading, setIsExtendedInfoLoading] = useState(true);
    
    useEffect(() => {
        const fetchExtendedInfo = async () => {
            if (!isOpen || !pid) {
                return; // Early return if conditions are not met
            }
            setIsExtendedInfoLoading(true); // Start loading
            
            try {
                const response = await axios.get(`${para_be}/units/get_extended_info?pid=${pid}`, { withCredentials: true });
                console.log(response,pid)
                if (response.status === 200) {
                    setExtendedInfo(Object.entries(response.data.result).reduce((acc, [k, v]) => { 
                        return { 
                            ...acc,
                            [k]: (["move_in", "move_out"].includes(k) && (v || "") !== "") ? 
                                    dateTimeFormaterUTC(v, DATE_FORMAT) : 
                                    v
                        };
                    }, {}));
                } else {
                    console.log("Error:", response.data);
                }
            } catch (error) {
                console.error('Failed to fetch extended info:', error);
            } finally {
                setIsExtendedInfoLoading(false); 
            }
        };
        fetchExtendedInfo();
    }, [pid, isOpen]);
    
    return { extendedInfo, isExtendedInfoLoading };
}


export default useFetchExtendedInfo;
