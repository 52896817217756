import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import BaseModal from '../../../../../../components/Modal';
import Btn from "../../../../../../components/buttons/standard/btn";
//utils
import { postReqOptBuilder } from "../../../../../../utils/main_utils";
//slices
import { postLogEntry } from "../../../../../../store/slices/activitySlice";
//constants
import { para_be } from "../../../../../../config";
import { INSPECTION_TYPES } from "../../../../../../constants";
//assets
import wa_logo from "../../../../../../assets/icons/whatsapp-icon.png"


const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB chunks (adjust as needed)

const VideoUploaderModal = ({ open, handleClose, pid, setNotifState, changeBlockStatus, scanType=INSPECTION_TYPES.BASELINE }) => {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);

    const [isDragOver, setIsDragOver] = useState(false);
    const [uploadedFile, setUploadedFile] = useState("");
    const [chunck, setChunck] = useState(0); //progress bar data
    const [show, setShow] = useState(false); //show the progressbar
    const [loader, setLoader] = useState(false); //show the progressbar
    const [complete, setcomplete] = useState(false); // Indicates whether a process or task is complete
    
    const fetchPresignedUrls = (file) => {
        const data = {
            total_parts: Math.ceil(file.size / CHUNK_SIZE),
            id: pid,
            filename: file.name,
            filetype: file.type,
        };
    
        return fetch(`${para_be}/media/generate_presigned_url`, postReqOptBuilder(data))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                return {
                    upload_id: response.result.upload_id,
                    presigned_urls: response.result.presign_urls,
                };
            } else {
                console.log("Failed generating presigned url");
                setShow(false);
                return null;
            }
        })
        .catch((error) => {
            console.error("An error occurred:", error);
            setShow(false);
            return null;
        });
    };
    
    const uploadFile = (file, presignedUrls, uploadId) => {
        const parts = [];
        const totalChunks = presignedUrls.length;
        let uploadedChunks = 0;
    
        // Upload each chunk sequentially
        const chunkPromises = presignedUrls.map((presignedUrl, i) => {
            const blob = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
    
            // Upload a single chunk to the presigned URL
            return fetch(presignedUrl, {
                method: "PUT",
                headers: {"content-type": file.type},
                body: blob,
            })
            .then((response) => {
                if (response.ok) {
                    uploadedChunks++;
                    const progressPercentage = Math.floor(uploadedChunks / totalChunks * 100);
                    setChunck(progressPercentage);
            
                    const etag = response.headers.get("etag");
                    parts.push({
                        ETag: etag,
                        PartNumber: i + 1,
                    });
                }
            })
            .catch((error) => {
                // Handle error for this chunk upload here
                console.error("Chunk upload failed:", error);
            });
        });
    
        // Wait for all chunk uploads to complete
        Promise.all(chunkPromises)
        .then(() => {
            setLoader(true);
            // After all chunks are uploaded, send a request to finalize the upload
            fetch(`${para_be}/media/upload_video`, postReqOptBuilder({uploadId: uploadId, parts: parts, id: pid, filename: file.name}))
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    try {
                        fetch(`${para_be}/scan/scanStarted`, postReqOptBuilder(
                            pid.includes('_') ?
                                {pid: pid.split("_")[1], scanType: scanType === INSPECTION_TYPES.BASELINE ? INSPECTION_TYPES.MOVE_OUT : scanType} :
                                {pid: pid, scanType: INSPECTION_TYPES.BASELINE}
                        ))
                    } catch (error) {
                        console.error("An error occurred while sending scanStarted notification:", error);
                    }
                    if (!pid.includes('_')) {
                        try {
                            fetch(`${para_be}/scan/building_baseline`, postReqOptBuilder(
                                    {uid: '', cid: '', pid: pid}, 
                                    true, {Authorization: document.cookie.split("AuthToken=")[1].split(";")[0]}
                            ))
                            .then(response2 => response2.json())
                            .then(response2 => {
                                if (response2.status === 200) {
                                    handleClose()
                                    setNotifState({"type": "success", "text": "Updated unit status"});
                                } else {
                                    setNotifState({"type": "error", "text": "Failed to update unit status"});
                                }
                            })
                            .catch((x) => {
                                setNotifState({"type": "error", "text": "Failed to update unit status"});
                            });
                        } catch (error) {
                            console.error("An error occurred while updating baseline status:", error);
                        }
                        dispatch(postLogEntry( 
                            { 
                                activityID: pid, activityType: 'pid', ip: endUserData?.ip, 
                                action: {action: "upload-video-done", target: "baseline"} 
                            }
                        ));
                    } else {
                        dispatch(postLogEntry( 
                            { 
                                activityID: `${pid.split('_')[0]}--${scanType}`, activityType: 'pid--inspectionType', 
                                ip: endUserData?.ip, action: {action: "upload-video-done", target: "scan"} 
                            }
                        ));
                    }
                    
                    setLoader(false);
                    setcomplete(true);
                    changeBlockStatus(false);
                } else {
                    setLoader(false);
                    changeBlockStatus(false);
                    console.error("Failed to upload video");
                }
            })
            .catch((error) => {
                // Handle any errors that occurred during the final steps
                setLoader(false);
                console.error("An error occurred:", error);
                setShow(false);
                changeBlockStatus(false);
            });
        })
        .catch((error) => {
            // Handle any errors that occurred during the final steps
            setLoader(false);
            console.error("An error occurred:", error);
            setShow(false);
            changeBlockStatus(false);
        });
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const file = e.dataTransfer.files[0];
        setUploadedFile(file);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };
    
    const hanldeUploadVideo = () => {
        if (uploadedFile) {
            setShow(true);
            changeBlockStatus(true);
            if (pid.includes('_')) {
                dispatch(postLogEntry( 
                    { 
                        activityID: `${pid.split('_')[0]}--${scanType}`, activityType: 'pid--inspectionType', 
                        ip: endUserData?.ip, action: {action: "upload-video-start", target: "scan"} 
                    }
                ));
            } else {
                dispatch(postLogEntry( 
                    { 
                        activityID: pid, activityType: 'pid', 
                        ip: endUserData?.ip, action: {action: "upload-video-start", target: "baseline"} 
                    }
                ));
            }
        
            fetchPresignedUrls(uploadedFile)
            .then(({ upload_id, presigned_urls }) => {
                console.log({ upload_id, presigned_urls });
                if (upload_id && presigned_urls && presigned_urls.length) {
                    return uploadFile(uploadedFile, presigned_urls, upload_id);
                } else {
                    throw new Error("Presigned URLs not available");
                }
            })
            .catch((error) => {
                console.error("An error occurred:", error);
                setShow(false);
                changeBlockStatus(false);
            });
        }
    };
    
    const removeVideo = () => {
        setUploadedFile(null);
        setShow(false);
        changeBlockStatus(false);
        setChunck(0);
    };


    return (
        <>
            <BaseModal
                open={open}
                onClose={handleClose}
                minWidth={400}
                maxWidth={400}
            >
                <div className="flex flex-col items-center justify-center w-full mt-12 gap-5">
                    <div 
                        className={`w-[300px] border-2 border-dashed ${isDragOver ? "border-blue-500" : "border-gray-300"} flex items-center justify-center text-lg text-gray-800`} 
                        onDrop={handleDrop}
                    >
                        {uploadedFile ? 
                            <div className="flex items-center justify-center w-full h-full">
                                <video controls className="w-[300px] h-[300px]">
                                    <source src={URL.createObjectURL(uploadedFile)} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            :
                            <label className="text-center cursor-pointer">
                                <p className="m-0 p-2">
                                    {"Drag & drop a video file here or click to select a file"}
                                </p>
                                <input type="file" accept="video/*" onChange={handleFileChange} className="hidden"/>
                            </label>
                        }
                    </div>
                    {show &&
                        <>
                            {loader && 
                                <div className="text-center">
                                    <span>Processing</span>
                                    <br/>
                                    <span className="text-lg">It might take a few moments to start</span>
                                </div>
                            }
                            {complete &&
                                <h3 className="mb-0 text-2xl">
                                    Upload Complete
                                </h3>
                            }
                            <div className="w-full flex items-center justify-center">
                                <div className="border-2 border-gray-300 rounded-full w-5 h-5 animate-spin"></div>
                                <progress max="100" value={chunck} className="w-4/5 h-8 ml-4"></progress>
                                <p className="pl-4 text-xl">{chunck}%</p>
                            </div>
                        </>
                    }
                    {(uploadedFile && !show) &&
                        <div className="flex gap-2">
                            <Btn
                                text="Change Video"
                                type="secondary"
                                onclick={removeVideo}/>
                            <Btn
                                text="Upload Video"
                                type="primary"
                                onclick={hanldeUploadVideo}/>
                        </div>
                    }
                </div>
            </BaseModal>
            {open && 
                <button 
                    className="fixed bottom-2 right-2 z-1000 flex items-center justify-center bg-white shadow-md rounded-full p-0"
                    onClick={() => window.open("https://wa.me/message/NMWJBUHZPRIHA1")}
                >
                    <div className="mt-3 mr-5">Need Help?</div>
                    <img src={wa_logo} className="h-12" alt="WhatsApp Logo"/>
                </button>
            }
        </>
    )
}

export default VideoUploaderModal;