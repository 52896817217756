import React from 'react';


const ScanText = ({ fill = 'none', width = '16px', height = '16px', styleClass = '' }) => {
    return (
        <svg className={`transition duration-75 ${styleClass}`} width={width} height={height} fill={fill} viewBox="0 0 16 16">
            <path
                d="M1 3.66667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1H3.66667M10.3333 1H11.6667C12.0203 1 12.3594 1.14048 12.6095 1.39052C12.8595 1.64057 13 1.97971 13 2.33333V3.66667M13 10.3333V11.6667C13 12.0203 12.8595 12.3594 12.6095 12.6095C12.3594 12.8595 12.0203 13 11.6667 13H10.3333M3.66667 13H2.33333C1.97971 13 1.64057 12.8595 1.39052 12.6095C1.14048 12.3594 1 12.0203 1 11.6667V10.3333M3.66667 4.33333H9M3.66667 7H10.3333M3.66667 9.66667H7.66667"
                stroke="#7F8594"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ScanText;