import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//mui
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
//slices
import { openModal } from '../../../../store/slices/inspectionSchedulesSlice';
//constants
import { MODAL_TYPES } from '../../../../constants';
import { Chip } from '@mui/material';
//assets


const InspectionSchedulesHeader = ({journeyName, journeyId, status, totalItems}) => {
    const { data: clientLimits } = useSelector(state => state.clientLimits);
    const { isMobile } = useSelector(state => state.config);
    const history = useHistory();
    
    const dispatch = useDispatch();
    
    const handleOpenModal = (modalName) => {
        dispatch(openModal({modalName}));
    };

    const openCreateJourney = () => {
        history.push(`/journey/${journeyId}/schedules/create`);
    };

    
    return (
        <>
        <div className='flex flex-col gap-y-1'>
            <div className="flex mb-8">
                <Button
                    variant="contained"
                    startIcon={<ArrowBackIosIcon sx={{ width: 18, height: 18 }} />}  
                    sx={{
                        backgroundColor: '#fff', 
                        strokeWidth: '1px',
                        stroke: '#F2F2F3',
                        fontSize: '12px',
                        textTransform: 'none',
                        borderRadius: '8px',
                        padding: '6px 15px',
                        color: '#0C1222',
                        minWidth: 0,
                        '&:hover': {
                            backgroundColor: '#F7F7F7', 
                        },
                    }}
                    onClick={() => {
                        history.push('/journeys');
                    }}
                >
                    Back
                </Button>
            </div>
            <span className="text-xs font-bold" style={{color: "#4F46E5"}}>INSPECTION JOURNEY</span> 
            <div className='flex flexRow gap-x-2'>
                <span className="text-gray-700 text-lg font-medium">{journeyName}</span> 
                {status &&
                    <div style={{marginTop: '2px'}}>
                        <Chip 
                            label={status.charAt(0).toUpperCase() + status.slice(1)} 
                            variant="outlined"
                            size="small"
                            color={status === "active" ? "success" : "default"}
                        />
                    </div>
                }
            </div>
        </div>
        <div className="flex justify-between items-center flex-wrap gap-y-4 mb-6 mt-8"> 
            <div className="flex">
                <div className="flex flex-col">
                    <span className="text-gray-700 text-lg font-medium">Inspection Schedules</span> 
                    <span className="text-sm text-gray-500">{totalItems} Total Inspection Schedules</span>     
                </div>
            </div>
            
            <div className={"flex" + (isMobile ? " flex-row-reverse justify-end" : "")}>
                <Button
                    variant="contained"
                    startIcon={<AddIcon sx={{ width: 20, height: 20 }} />}  
                    onClick={()=>{openCreateJourney()}}
                    sx={{
                        backgroundColor: '#4F46E5', 
                        color: 'white',
                        fontSize: '12px',
                        textTransform: 'none',
                        borderRadius: '8px',
                        padding: '6px 12px',
                        '&:hover': { backgroundColor: '#4338CA' } 
                    }}
                >
                    Create New Schedule
                </Button>
            </div>
        </div>
        </>
    );
};

export default InspectionSchedulesHeader;