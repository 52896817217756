import React, {useState} from "react";
import { Helmet } from "react-helmet";
//containers
//components
import Btn from "../../components/buttons/standard/btn";
import SimpleInput from "../../components/inputs/simple_input/input"
import Notification from "../../components/side_notification/side_notification";
//assets
import { capitalizeString, isEmail, postReqOptBuilder } from "../../utils/main_utils";
//style
import "./create_company.css"


export default function CreateCompany (props) {
    const [notifState, setNotifState] = useState(null);
    const [inputError, setInputError] = useState(null);

    const handleCreateCompany = () => {
        console.log("Trying to create company");
        setInputError(null);
        const reqBody = {"admin_creation": 1, "company_name": document.getElementById("company-name").value, "email": document.getElementById("email").value};
        let _inputErrors = {};
        for (let key in reqBody) {
            if (!reqBody[key] || reqBody[key].length === 0) {
                _inputErrors[key] = "Please enter " + capitalizeString(key.replace("_", " "), true);
            } else if (key === "email" && !isEmail(reqBody[key])) _inputErrors[key] = "Email input must be in an Email format";
        }
        if (Object.keys(_inputErrors).length > 0) {
            console.log("Returning due to input errors");
            setInputError(_inputErrors);
            return;
        }
        fetch(props.para_be + '/auth/create_client', postReqOptBuilder(reqBody))
        .then(response => response.json())
        .then(response => {
                if (response.status === 200) {
                    setNotifState({text: "Client created successfully!", type: "success"});
                } else {
                    setNotifState({text: "Something went wrong while trying to create a new client", type: "error"});
                }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: "Failed to create client", type: "error"})
        });
    };

    return (
        <section className="create-company" style={{"marginTop":"100px"}}>
            <Helmet>
                <title>Paraspot Admin | Create Client</title>
                <meta name="description" content=""/>
                <meta property="og:title" content="Paraspot Admin | Create Client"/>
                <meta property="og:description" content=""/>
            </Helmet>
            <div className="h2 blue-headline">Create Client</div>
            <SimpleInput
                id="company-name"
                name='company-name'
                type="text"
                placeholder="Company Name"
                inputError={inputError?.hasOwnProperty("company_name") ? {text: inputError["company_name"], type: "error"} : undefined}
            />
            <SimpleInput
                id="email"
                name="email"
                type="email"
                placeholder="email"
                inputError={inputError?.hasOwnProperty("email") ? {text: inputError["email"], type: "error"} : undefined}
            />
            <Btn
                text="Create Company"
                type="sign"
                onclick={handleCreateCompany}
            />

            {notifState ?
                <Notification
                    closeFunc={() => setNotifState(null)}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
        </section>
    )
}