import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//components
import JourneysTable from '../../components/Tables/TableTypes/JourneysTable/JourneysTable';
import JourneysHeader from './components/JourneysHeader/JourneysHeader';
//modals
//constants
import { CategoryFilter, MODAL_TYPES } from '../../constants';
//slices
import { fetchData, resetState, setFilters, setPage } from '../../store/slices/searchSlice';
import { closeModal } from '../../store/slices/journeysSlice';
//utils
import { createDictFromHashedUrl } from '../../utils/main_utils';


const Journeys = () => {
    const dispatch = useDispatch();
    const hashDict = createDictFromHashedUrl(window.location.hash);
    // const [searchTerm, setSearchTerm] = useState(Object.keys(hashDict).length > 0 ? (hashDict?.subject || '') : '');
    const { page, pageSize, sortColumn, sortDirection, searchValue, filters, categoryFilter, totalItems } = useSelector(state => state.table);
    const { modals } = useSelector(state => state.journeys);
    const { data: clientLimits } = useSelector(state => state.clientLimits);
    const location = useLocation();

    const fetchConfig = (filters={}, resetStats=false) => {
        const filtersObj = {
            ...filters,
            category: CategoryFilter.INSPECTION_JOURNEYS,
        };
        const usedSearchValue = Object.keys(hashDict).length > 0 ? decodeURIComponent(hashDict?.subject || '') : (resetStats ? "" : searchValue);
        const body = {
            lowerLimit: resetStats ? 0 : (page - 1) * pageSize,
            upperLimit: resetStats ? pageSize : page * pageSize,
            filters: filtersObj,
            searchValue: usedSearchValue,
            sortDirection: resetStats ? false : sortDirection,
            sortColumn: resetStats ? 0 : sortColumn,
        };

        return {body, filtersObj};
    };

    const performFetch = (filters={}, resetPage=false) => {
        const {body, filtersObj} = fetchConfig(filters);
        if (resetPage) {
            body.lowerLimit = 0;
            body.upperLimit = pageSize;
            dispatch(setPage(1));
        }
        dispatch(fetchData(body));
        dispatch(setFilters(filtersObj));
    };

    useEffect(() => {
        const {body, filtersObj} = fetchConfig({}, true);
        dispatch(resetState({filters: filtersObj, categoryFilter: CategoryFilter.INSPECTION_JOURNEYS}));
        dispatch(fetchData(body));
    }, [dispatch, location]);

    const handleCloseModal = (modalName) => {
        dispatch(closeModal(modalName));
    };


    return (
        <>
            {/* {modals.createJourneyModal && 
                <AddNewUnitModal 
                    open={modals.createJourneyModal} 
                    handleClose={()=> handleCloseModal(MODAL_TYPES.CREATE_JOURNEY_MODAL)} 
                    performFetch={() => performFetch({}, true)}
                />
            } */}
            <main className="flex-1">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-8 mb-8">
                    <JourneysHeader totalItems={totalItems} />
                    <div className="mt-2 flex flex-col">
                        <JourneysTable />
                    </div>
                </div>
            </main>
        </>
    )
};

export default Journeys;