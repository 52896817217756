import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//slices
import { openModal } from '../../../../../../store/slices/searchSlice';
//assets
import DocsIcon from '../../../../icons/docsIcon';
import PlayIcon from '../../../../icons/playIcon';
import PlainIcon from '../../../../icons/plainIcon';
import EyeIcon from '../../../../icons/eyeIcon';
import UploadIcon from '../../../../icons/uploadIcon';
import BuildingIcon from '../../../../icons/buildingIcon';
//components
import { SimpleDropdownContainer } from '../../../../../dropdowns/simple_dropdown/simple_dropdown';
import TableOptionsMenu from '../../../../TableWidget/components/TableOptionsMenu';
//utils
import { addTextToClipboard, postReqOptBuilder } from '../../../../../../utils/main_utils';
//constants
import { para_be } from '../../../../../../config';
import { SeverityLevels, MODAL_TYPES } from '../../../../../../constants';


const OptionsMenu = React.memo(({ pid, subject, videoLink }) => {
    const dispatch = useDispatch();
    const menuItems = [
        {
            label: 'View baseline',
            icon: EyeIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.VIDEO_MODAL, subject, videoLink, pid })),
        },
        {
            label: 'Upload move-out scan',
            icon: UploadIcon,
            onClick: () => dispatch(openModal({ modalName: MODAL_TYPES.UPLOADER_MODAL, pid })),
        },
        {
            label: 'View unit',
            icon: BuildingIcon,
            onClick: () => {}, 
            disabled: true,
        },
    ];
    
    return (
        <TableOptionsMenu items={menuItems} />
    );
});

const ActionsPanelMiMo = ({ pid, subject, latest_report_id, changeSetNotifState, videoLink, scan_id }) => {
    const dispatch = useDispatch();
    const [showShareLinkDD, setShowShareLinkDD] = React.useState(false);
    const [shareLink, setShareLink] = React.useState(scan_id);
    const [loadingShareLink, setLoadingShareLink] = React.useState(false);
    

    const generateURL = (e) => {
        e.stopPropagation();
        setLoadingShareLink(true);
        
        fetch(para_be + '/units/generate_scan_link', postReqOptBuilder({"pid": pid}))
        .then(response => response.json())
        .then(response => {
            if(response.status === 200) {
                setShareLink(response.result);
                setLoadingShareLink(false);
            } else {
                changeSetNotifState({text: response.msg, type: SeverityLevels.ERROR});
                setLoadingShareLink(false);
            }
        })
        .catch(error => {
            changeSetNotifState({text: "Something went wrong", type: SeverityLevels.ERROR});
            console.log(error);
        });
        return false;
    };
    
    const handleCopy = (e, inspection_type=null) => {
        e.stopPropagation();
        
        // const textToCopy = `${window.location.origin}/checkout/${pid}`;
        const textToCopy = window.location.origin + "/inspection/" + (inspection_type === null ? 'checkout' : inspection_type) + "/" + shareLink;
        const copyStatus = addTextToClipboard(textToCopy);
        if (copyStatus) {
            changeSetNotifState({text: "Link copied successfully!", type: SeverityLevels.SUCCESS});
        } else {
            changeSetNotifState({text: "Failed to copy.", type: SeverityLevels.ERROR});
        }
        
        setShowShareLinkDD(false);
        setTimeout(() => changeSetNotifState(null), 3000);
    };

    return (
        <>
            {latest_report_id ? (
                    <>
                        <span
                            className={`cursor-pointer`}
                            onClick={(e) => {
                            e.stopPropagation();
                            console.log("Calling openVideoModal", videoLink ? { subject, videoLink } : { pid, subject });
                            dispatch(openModal({ modalName: 'videoModal', subject, videoLink }));
                            }}
                            title={"View Scan"}
                        >
                            <PlayIcon />
                        </span>
                        <Link to={`/report/${latest_report_id}`} className="cursor-pointer" title={"View Report"}>
                            <DocsIcon />
                        </Link>
                    </>
                ) : (
                    <>
                        <span className='cursor-default'>
                            <PlayIcon disabled />
                        </span>
                        <span className='cursor-default'>
                            <DocsIcon disabled />
                        </span>
                    </>
                )
            }
            <SimpleDropdownContainer
                extraClasses="relative flexRow"
                extraClassesIn="right-0 top-7"
                showDropdown={showShareLinkDD}
                borderedItems={true}
                items={
                    loadingShareLink ? {
                        'loading': {
                            'present': 'Loading...',
                            'onclick': (k, e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }
                    } : 
                    shareLink ? {
                        're_gen_link': {
                            'present': 'Re-Generate Link',
                            'onclick': (k, e) => {
                                e.preventDefault();  
                                generateURL(e);
                            }
                        },
                        'check_in': {
                            'present': 'Check-In',
                            'onclick': (k, e) => {
                                e.preventDefault();  
                                handleCopy(e, 'checkin');
                            }
                        },
                        'check_out': {
                            'present': 'Check-Out',
                            'onclick': (k, e) => {
                                e.preventDefault();  
                                handleCopy(e, 'checkout');
                            }
                        }
                    } : 
                    {
                        'gen_link': {
                            'present': 'Generate Link',
                            'onclick': (k, e) => {
                                e.preventDefault();  
                                generateURL(e);
                            }
                        }
                    }
                }
            >
                {/* <span className="cursor-pointer" onClick={(e) => handleCopy(e)}> */}
                <span className="cursor-pointer" onClick={(e) => { e.stopPropagation(); setShowShareLinkDD(!showShareLinkDD); }} title={"Share"}>
                    <PlainIcon />
                </span>
            </SimpleDropdownContainer>
            <OptionsMenu
                pid={pid}
                subject={subject}
                videoLink={videoLink}
            />
        </>
    )
}

export default ActionsPanelMiMo;