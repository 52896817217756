import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios/axios';
import { para_be } from '../../config';

const startLoadingV2 = (state, thunk) => {
    state[thunk].loading = true;
    state[thunk].error = null;
};

const loadingFailedV2 = (state, action, thunk) => {
    state[thunk].loading = false;
    state[thunk].error = action.payload;
};

const loadingSuccessV2 = (state, action, thunk, successCallback) => {
    state[thunk].loading = false;
    successCallback(state, action.payload);
};

// Async thunk for creating a customer
export const createCustomer = createAsyncThunk('customer/createCustomer', async (customerData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${para_be}/payments/create_new_customer`, customerData, { withCredentials: true, });
        if (response.data.status >= 400) {
            return rejectWithValue(response.data.msg);
        }
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Async thunk for fetching customer data
export const fetchCustomerData = createAsyncThunk('customer/fetchCustomerData', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${para_be}/payments/get_customer_data`, { withCredentials: true });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const setCustomer = createAsyncThunk('customer/setCustomer', async ({ email }, { rejectWithValue }) => {
    try {
        const requestBody = {email,};

        const { data } = await axios.post(`${para_be}/payments/update_customer_details`, requestBody, { withCredentials: true, });
        if (data.status >= 400) {
            const cleanedMessage = data.msg.replace(/^Request\s+\w+:\s*/, '');
            return rejectWithValue(cleanedMessage);
        }
        return requestBody;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

export const fetchCustomerInvoices = createAsyncThunk('customer/fetchCustomerInvoices', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${para_be}/payments/list_invoices`, { withCredentials: true });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Define the initial state of the customer slice
const initialState = {
    customerData: null,
    SubscriptionMetadata: null,
    customerInvoices: null,
    fetchCustomerData: {
        data: null,
        loading: false,
        error: null
    },
    fetchCustomerInvoices: {
        loading: false,
        error: null
    },
    createCustomer: {
        data: null,
        loading: false,
        error: null
    },
    updateCustomer: {
        data: null,
        loading: false,
        error: null
    },
};

// Create the customer slice
const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCustomerData.pending, (state, action) => startLoadingV2(state, "fetchCustomerData"))
        .addCase(
            fetchCustomerData.fulfilled, 
            (state, action) => loadingSuccessV2(
                state, action, "fetchCustomerData",
                (state, data) => {
                    state.fetchCustomerData.data = data;
                    if (data.status === 200) {
                        state.customerData = data.result.customer_data;
                        state.SubscriptionMetadata = data.result.subscription_metadata;
                    }
                }
            )
        )
        .addCase(fetchCustomerData.rejected, (state, action) => loadingFailedV2(state, action, "fetchCustomerData"))
        .addCase(createCustomer.pending, (state) => startLoadingV2(state, "createCustomer"))
        .addCase(
            createCustomer.fulfilled,
            (state, action) => loadingSuccessV2(
                state, action, "createCustomer", 
                (state, data) => (state.createCustomer.data = data)
            )
        )
        .addCase(createCustomer.rejected, (state, action) => loadingFailedV2(state, action, "createCustomer"))
        .addCase(setCustomer.pending, (state) => startLoadingV2(state, "updateCustomer"))
        .addCase(
            setCustomer.fulfilled,
            (state, action) => loadingSuccessV2(
                state, action, "updateCustomer",
                (state, data) => (state.updateCustomer.data = data)
            )
        )
        .addCase(setCustomer.rejected, (state, action) => loadingFailedV2(state, action, "updateCustomer"))
        .addCase(fetchCustomerInvoices.pending, (state, action) => startLoadingV2(state, "fetchCustomerInvoices"))
        .addCase(
            fetchCustomerInvoices.fulfilled, 
            (state, action) => loadingSuccessV2(
                state, action, "fetchCustomerInvoices",
                (state, data) => {
                    state.fetchCustomerInvoices.data = data;
                    if (data.status === 200) {
                        state.customerInvoices = data.invoices;
                    }
                }
            )
        )
        .addCase(fetchCustomerInvoices.rejected, (state, action) => loadingFailedV2(state, action, "fetchCustomerInvoices"))
    },
});

export const { clearError } = customerSlice.actions;
export default customerSlice.reducer;
