import React, {useState, useRef} from "react";
//components
import Btn from "../../buttons/standard/btn";
import SimpleInput from "../../inputs/simple_input/input";
import HideTextInput from "../../inputs/hide_text_input/input";
import MainLoader from "../../loaders/main_loader/main_loader";
import Notification from "../../side_notification/side_notification";
//utils
import { postReqOptBuilder, capitalizeString } from "../../../utils/main_utils";
//styles
import "../main_popups.css";
import "./change_password.css";
//assets
import SuccessLarge from "../../../assets/icons/success-large.svg";
import ErrorIcon from "../../../assets/icons/error-icon.svg";

export default function ChangePasswordPopup (props) {
    const changeRecover = window.location.pathname === "/settings" ? "change" : "recover";
    const [step, setStep] = useState(1); //1 - send email, 2 - enter token, 3 - change password, 4 - success message, 5 - error state, 6 - loader,
    const [userEmail, setUserEmail] = useState(document.getElementById("email")?.value);
    //const [emailToken, setEmailToken] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const [inputError, setInputError] = useState(null);
    const emailToken = useRef(null);

    const handleSendRecovery = () => {
        if (inputError) setInputError(null);
        let inputEmail;
        if (changeRecover === "recover") {
            inputEmail = document.getElementById("email")?.value;
            if (!inputEmail || inputEmail.length === 0) setInputError({"email": "Please enter your Email Address"});
        }
        const email = inputEmail ? inputEmail : userEmail;
        setUserEmail(email);
        setStep(6); // loader
        const body = {"change_recover": changeRecover};
        if (changeRecover === "recover") body.email = email;
        fetch(props.para_be + '/auth/change-password/send-recovery', postReqOptBuilder(body))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setStep(2);
            } else {
                setInputError({"email": response.msg});
                if (!response.result) {
                    setNotifState({text: response.msg, type: "error"});
                    setStep(5);
                } else setStep(1);
            }
        })
        .catch (error => {
            setNotifState({text: "The request wasn't completed due to a technical error", type: "error"});
            setStep(5);
        });
    };

    const handleVerifyToken = () => {
        if (inputError) setInputError(null);
        const token = document.getElementById("email-token").value;
        if (!token || token.length === 0) setInputError({'email-token': "Please enter Email Token"});

        emailToken.current = token;
        setStep(6); // loader
        fetch(props.para_be + '/auth/change-password/verify-token', postReqOptBuilder({"email_token": token, "change_recover": changeRecover, "user_email": userEmail}))
        .then(response => response.json())
        .then(response => {
            if(response.status === 200) {
                setStep(3);
            } else {
                if(response.status === 400 && response.result === "wrong token") {
                    setInputError({"email-token": "The token was not recognized"});
                    setStep(2); //wrong token input
                } else {
                    setNotifState({text: response.msg, type: "error"});
                    setStep(5); // something went wrong or to many tries
                }
            }
        })
        .catch (error => {
            setStep(5);
        });
    };

    const handleChangePassword = () => {
        if (inputError) setInputError(null);
        const oldPassword = document.getElementById("old-pass")?.value;
        const newPassword = document.getElementById("new-pass").value;
        const confirmNewPassword = document.getElementById("new-pass-confirm").value;

        let _inputErrors = {};
        for (let [k, v] of Object.entries({...(changeRecover === "change" ? {"old-password": oldPassword} : {}), "new-password": newPassword, "confirm-password": confirmNewPassword})) {
            if (!v || v.length === 0) _inputErrors[k] = "Please enter " + capitalizeString(k.replace("-", " "), true);
        }
        if (Object.keys(_inputErrors).length > 0) {
            setInputError(_inputErrors);
        } else if (newPassword !== confirmNewPassword) {
            setNotifState({text: "Passwords do not match", type: "error"});
        } else {
            fetch(props.para_be + '/auth/change-password/perform', postReqOptBuilder({...(changeRecover === "change" ? {"old-password": oldPassword} : {}), "new_password": newPassword, "user_email": userEmail, "change_recover": changeRecover, "email_token": emailToken.current}))
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    setNotifState({text: "Password Changed Successfully!", type: "success"});
                    setStep(4);
                } else {
                    setNotifState({text: response.msg, type: "error"});
                    if (response.status === 400 && response.result === "old pass") {
                        setStep(3); //wrong old password
                        setInputError({"old-password": "Invalid Password"});
                    } else {
                        setStep(5); // something went wrong or to many tries
                    }
                }
            })
            .catch (error => {
                setNotifState({text: "Failed to perform the password change", type: "error"});
                setStep(5);
            });
        }
    };

    const handleProcessStop = () => {
        setUserEmail(null);
        setStep(1);
        setInputError(null);
    };

    return (
       <section className="change-password-container">
           {/*TODO - When widget active, revise the cp-headline top margin
            <StepsWidget
                totalSteps={3}
                currentStep={step}
                colorScheme={"blue"}
           /> */}
           <section className="cp-main">
                <div className="h1 blue-headline">Change Password</div>
                {
                    step === 1 ? // Start change password
                        <div className="cp-step-item cp-step-1">
                            {changeRecover === "recover" ?
                                <SimpleInput
                                    label="Enter email for a recovery link"
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    error={inputError?.hasOwnProperty("email") ? inputError["email"] : undefined}
                                /> :
                                <div className="text-0">Once you click on the button below an email with a token will be sent to <u>{userEmail}</u>.</div>
                            }
                            <Btn text="Send me a recovery email" type="primary" onclick={handleSendRecovery}/>
                        </div> : 
                    step === 2 ? // verify token
                        <div className="cp-step-item">
                            <SimpleInput
                                label="An 8-digit token was sent to your email"
                                type="text"
                                id="email-token"
                                value={""}
                                placeholder="Please enter 8-digit number"
                                error={inputError?.hasOwnProperty("email-token") ? inputError["email-token"] : undefined}
                            />
                            <div className="text-3 step-2-label">Didn't receive the email? <a href="#" onClick={handleSendRecovery}>Resend</a></div>
                            <Btn text="Verify" type="primary" onclick={handleVerifyToken}/>
                        </div> : 
                    step === 3 ? // change password
                        <div className="cp-step-item">
                            <div className="cp-text">{changeRecover === "change" ? "Enter your old and new passwords" : "Enter your new password"}</div>
                            {changeRecover === "change" &&
                                <HideTextInput
                                    label="Old Password"
                                    id="old-pass"
                                    placeholder="Enter old password"
                                    inputError ={inputError?.hasOwnProperty("old-password") ? inputError["old-password"] : undefined}/>
                            }
                            <HideTextInput
                                label="New Password"
                                id="new-pass"
                                placeholder="Enter new password"
                                inputError ={inputError?.hasOwnProperty("new-password") ? inputError["new-password"] : undefined}/>
                            <HideTextInput
                                label="Confirm new Password"
                                id="new-pass-confirm"
                                placeholder="Confirm new password"
                                inputError ={inputError?.hasOwnProperty("confirm-password") ? inputError["confirm-password"] : undefined}/>
                            <Btn
                                text="Approve change"
                                type="primary"
                                onclick={handleChangePassword}/>
                        </div> :
                    step === 4 ? // success state
                        <div className="cp-step-item cp-step-4">
                            <img src={SuccessLarge} alt=""/>
                            <div className="text-0">Your password has been successfully changed!</div>
                            <Btn
                                text="Done"
                                type="primary"
                                onclick={()=> (window.location.reload())}/>
                        </div> :
                    step === 5 ? // error state
                        <div className="cp-step-item cp-step-5">
                            <img src={ErrorIcon} alt=""/>
                            <div className="text-0">Failed to change password.<br/>{inputError}</div>
                            <Btn
                                text="Restart"
                                type="primary"
                                onclick={handleProcessStop}/>
                        </div> : //loader state
                    <div className="pos-relative">
                        <MainLoader/>
                    </div>
                }
           </section>

           {notifState ?
                <Notification
                    closeFunc={() => setNotifState(null)}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
       </section>
    )
}