import React from "react";
// components
import Btn from "../../../../components/buttons/standard/btn";
// styles
import "./index.css"


export default function Integrations(props) {  
    const  { pmsMetaData, handleOpenPopup } = props;

    return (
        <section className="pms-section">
            <div className="pms-header">
                <div className="h3">PMS Connection</div>
            </div>
            {pmsMetaData ? (
                <div className="pms-conn-details flexRow">
                    {Object.keys(pmsMetaData).map((pms_client) => {
                        return pmsMetaData[pms_client].token?.access_token ?
                            <div className="pms-conn-client">
                                <div className="h3">{pms_client}</div>
                                <div className="pms-conn-item flexRow">
                                    <div className="pms-conn-key text-1-3">
                                        Connection Status:
                                    </div>
                                    <div className="pms-conn-val text-1">Connected</div>
                                </div>
                                <div className="pms-conn-item flexRow">
                                    <div className="pms-conn-key text-1-3">Token:</div>
                                    <div className="pms-conn-val text-1">
                                        {pmsMetaData[pms_client].token.access_token}
                                    </div>
                                </div>
                                {pms_client === "arthur" &&
                                    <div className="pms-conn-item flexRow">
                                        <div className="pms-conn-key text-1-3">Entities:</div>
                                        <div className="pms-conn-val text-1">
                                            {pmsMetaData[pms_client].connections
                                                .map((x) => x.extra_headers.entity_id)
                                                .join(", ")}
                                        </div>
                                    </div>
                                }
                                {/* <div className="pms-conn-item">
                                    <div className="pms-conn-key text-1-3">Expires in:</div>
                                    <div className="pms-conn-val text-1">12 days, 14:45:12</div>
                                </div> */}
                            </div>
                        : "";
                    })}
                </div>
            ) : (
                <Btn
                    text="Connect to your PMS"
                    type="primary"
                    extraClasses="connect-pms"
                    onclick={() => handleOpenPopup("pms")}
                />
            )}
        </section>
    );
};
