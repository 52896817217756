import React from 'react'


const UnitSettingIcon = ({ fill = 'none', innerFill = "#C2C4C8", width = '18px', height = '18px', styleClass = "" }) => {
    return (
        <svg
            className={`transition duration-75 ${styleClass}`}
            width={width}
            height={height}
            fill={fill}
            viewBox='0 0 18 18'
        >
        <path 
            d="M16.667 5.83203H9.16699M11.667 14.1654H4.16699" 
            stroke="#A8ADB8" 
            strokeWidth="1.2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
        />
        <path 
            d="M14.167 16.666C15.5477 16.666 16.667 15.5467 16.667 14.166C16.667 12.7853 15.5477 11.666 14.167 11.666C12.7863 11.666 11.667 12.7853 11.667 14.166C11.667 15.5467 12.7863 16.666 14.167 16.666Z" 
            stroke="#A8ADB8" 
            strokeWidth="1.2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
        />
        <path 
            d="M5.8335 8.33203C7.21421 8.33203 8.3335 7.21274 8.3335 5.83203C8.3335 4.45132 7.21421 3.33203 5.8335 3.33203C4.45278 3.33203 3.3335 4.45132 3.3335 5.83203C3.3335 7.21274 4.45278 8.33203 5.8335 8.33203Z" 
            stroke="#A8ADB8" 
            strokeWidth="1.2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
        />
        </svg>
    );
};

export default UnitSettingIcon;