import React from 'react'

const PlayIcon = ({ fill = 'none', width = '18px', height = '18px', styleClass = "", disabled = false }) => {
  // Define the disabled styles
  const disabledStroke = '#A0A0A0';  // Light gray color for disabled state
  const disabledFill = 'none';       // Default fill for disabled state (can be changed as needed)

  // Determine the final stroke and fill colors based on the disabled prop
  const finalStroke = disabled ? disabledStroke : '#0C1222';  // Default stroke color when not disabled
  const finalFill = disabled ? disabledFill : fill;

  return (
    <svg
      className={`transition duration-75 ${styleClass}`}
      width={width}
      height={height}
      fill={finalFill}
      viewBox='0 0 18 18'
    >
      <path 
        d="M15.75 9C15.75 9.88642 15.5754 10.7642 15.2362 11.5831C14.897 12.4021 14.3998 13.1462 13.773 13.773C13.1462 14.3998 12.4021 14.897 11.5831 15.2362C10.7642 15.5754 9.88642 15.75 9 15.75C8.11358 15.75 7.23583 15.5754 6.41689 15.2362C5.59794 14.897 4.85382 14.3998 4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 7.20979 2.96116 5.4929 4.22703 4.22703C5.4929 2.96116 7.20979 2.25 9 2.25C10.7902 2.25 12.5071 2.96116 13.773 4.22703C15.0388 5.4929 15.75 7.20979 15.75 9Z" 
        stroke={finalStroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9325 8.7547C11.9764 8.77903 12.013 8.81468 12.0385 8.85794C12.064 8.9012 12.0774 8.95049 12.0774 9.0007C12.0774 9.05091 12.064 9.1002 12.0385 9.14346C12.013 9.18671 11.9764 9.22236 11.9325 9.2467L7.73025 11.5814C7.68743 11.6052 7.63914 11.6174 7.59017 11.6168C7.5412 11.6161 7.49324 11.6027 7.45104 11.5779C7.40883 11.553 7.37385 11.5176 7.34955 11.4751C7.32526 11.4326 7.31248 11.3844 7.3125 11.3354V6.66595C7.3125 6.45145 7.54275 6.31645 7.73025 6.4207L11.9325 8.7547Z"
        stroke={finalStroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" 
      />
    </svg>
  );
};

export default PlayIcon