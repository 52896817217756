import React, {useState, useEffect, useRef} from "react";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";

//style
import "./baseline_scan.css";
//components
import {CAM_TYPE_BASELINE} from "../../components/scanner/camera/videoCamera";
import VideoCameraIOS from "../../components/scanner/camera/iosVideoCamera";
import SimpleLoader from "../../components/scanner/loader/simple-loader";
import Lobby from "../../components/scanner/foreplay/lobby";
import ScanStep from "../../components/scanner/foreplay/obi_one";
import CamSettingsLoader from "../../components/scanner/foreplay/load_cam_settings";
import Notification from "../../components/side_notification/side_notification";
import Btn from "../../components/buttons/standard/btn";
import ScanVideoStep from "../../components/scanner/foreplay/obi_one_vid";
//containers
//utils
import { isIOS, postReqOptBuilder } from "../../utils/main_utils";
import { blobToFile, fetchPresignedUrls, getFileExtFromBlob, uploadFile } from "../../utils/vid_upload_utils";
//assets
import step1x1 from "../../assets/scan_page/step1.jpg"
import step1x2 from "../../assets/scan_page/step1@2x.jpg"
import step1x3 from "../../assets/scan_page/step1@3x.jpg"
import MainLoader from "../../components/loaders/main_loader/main_loader";
import ScannerError from "../../components/errorMessages/scannerError/scannerError";
//slices
import { postLogEntry } from "../../store/slices/activitySlice";
//constants
const FPS = 20;
const CAMERA_CONSTRAINTS = {
    video: {
        facingMode: { exact: 'environment' },
        frameRate: { ideal: FPS },
        aspectRatio: 16/9,  // 1.777777778,
        zoom: 1,
        width: { ideal: 1920 },
        height: { ideal: 1080 },
    },
    audio: false
};
const STEP_LOBBY = 0;
const STEP_EXP = 1;
const STEP_EXP2 = 2;
const STEP_SCAN = 3;
const STEP_DONE = 4;
const STEP_RETRY_FILE_UPLOAD = 9;
const STEP_LOADING_ISSUE = 10;
const STEP_LOADING_ISSUE_RESOLVED = 11;
const STEP_UPLOAD_FAILED = 12;

export default function BaselineScanV2 (props) {
    const dispatch = useDispatch();
    const endUserData = useSelector(state => state.config.endUserData);
    
    const howToVid_NoBaseline = "https://paraspot-b2b-users.s3.eu-central-1.amazonaws.com/public/Baseline-HowToV2.mp4";
    const pid = props.match.params.pid;
    const uid = props.uid_cid['uid'];
    const cid = props.uid_cid['cid'];
    const token = props.token;
    const base_url = props.base_url;
    const debugMode = (new URLSearchParams(window.location.search)).get('debug') === '1';
    // state based variables
    const [loadingPage, setLoadingPage] = useState(true);
    
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [finalizingScan, setFinalizingScan] = useState(false);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    const [videoDeviceDets, setVideoDeviceDets] = useState(null);
    const [step, setStep] = useState(STEP_LOBBY);
    const [notifState, setNotifState] = useState(null);
    
    const [networkConnState, setNetworkConnState] = useState(navigator.onLine);
    // video upload related
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [recordingUploadRate, setRecordingUploadRate] = useState(0);
    const [uploadLoaderStatus, setUploadLoaderStatus] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [medRecOptionSelected, setMedRecOptions] = useState(null);
    const [debugStateStatus, setDebugStateStatus] = useState(null);
    // refs for variables outside of callback scope
    let stepRef = useRef(step);
    let scanSess = useRef(null);
    let lastResend = useRef(null);

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    const switchStep = (next_step) => {
        let permitSwitch = false;
        if (next_step === STEP_LOADING_ISSUE) {
            if (finalizingScan && step !== STEP_DONE) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_RETRY_FILE_UPLOAD) {
            if (step === STEP_LOADING_ISSUE || step === STEP_UPLOAD_FAILED) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_UPLOAD_FAILED) {
            if (step === STEP_LOADING_ISSUE || step === STEP_RETRY_FILE_UPLOAD) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_LOADING_ISSUE_RESOLVED) {
            if (step === STEP_LOADING_ISSUE) {
                permitSwitch = true;
            }
        } else {
            permitSwitch = true;
        }
        if (permitSwitch) {
            setStep(next_step);
        } else {
            console.log(`Switch step from ${step} to ${next_step} is not permitted`);
        }
    };

    const getFinalConstraints = () => {
        if (videoDeviceDets !== true && videoDeviceDets !== false) {
            return {
                video: {
                    ...CAMERA_CONSTRAINTS.video,
                    deviceId: videoDeviceDets.deviceId
                },
                audio: CAMERA_CONSTRAINTS.audio
            }
        } else return CAMERA_CONSTRAINTS
    }

    const showError = (errMsg=true) => {
        setShowErrorScreen(errMsg);
    };

    const uploadRecording = (sid) => {
        if (uploadStatus === 'done') {
            console.log("Upload already done. Skipping...");
            return;
        } else if (uploadStatus === 'ongoing') {
            console.log("Upload is ongoing. Skipping...");
            return;
        }
        const lastStatus = uploadStatus ? (uploadStatus + "") : null;
        setUploadStatus('ongoing');
        
        const fileToUpload = blobToFile(recordedBlob, sid);
        fetchPresignedUrls(props.para_be, fileToUpload, pid)
        .then(({ upload_id, presigned_urls }) => {
            uploadFile(
                props.para_be, fileToUpload, upload_id, presigned_urls, pid, 
                (uploadRate) => {
                    setLoadedPercentage(uploadRate);
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_LOADING_ISSUE_RESOLVED);
                }, 
                setUploadLoaderStatus, setNotifState, 
                () => {
                    setUploadStatus('done');
                    switchStep(STEP_DONE);
                }, 
                () => {
                    setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
                },
                () => {
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_RETRY_FILE_UPLOAD);
                }, setRecordingUploadRate
            );
        })
        .catch((error) => {
            console.error("An error occurred while uploading recorded scan:", error);
            setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
        });
    };

    const finalizeScan = (sid, intervalCount) => {
        setFinalizingScan(true);
        // console.log("At finalizeScan in baseline_scan.js");
        dispatch(postLogEntry( 
            { 
                activityID: `${pid}--${sid}`, activityType: 'pid--sessionID', 
                ip: endUserData?.ip, action: {action: "done", target: "baseline", extra: {frames: intervalCount}} 
            }
        ));
        lastResend.current = new Date();
    };

    const downloadScanFile = () => {
        try {
            if (!recordedBlob) throw new Error("No scan file to download");
            if (recordedBlob.size === 0) throw new Error("Empty scan file");
            if (!recordedBlob.type || recordedBlob.type === "") throw new Error("Blob type is empty or undefined.");
            
            const link = document.createElement('a');
            const objectURL = (window.webkitURL || URL).createObjectURL(recordedBlob);
            link.href = objectURL;
            link.download = `${scanSess.current}.${getFileExtFromBlob(recordedBlob)}`;
    
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setTimeout(() => {
                console.log("Revoking object url to free up memory");
                (window.webkitURL || URL).revokeObjectURL(objectURL);
            }, 5000);
        } catch (e) {
            console.log("Something went wrong while downloading the scan file:\n", e);
            setNotifState({type: "error", msg: "Failed to download the scan file"});
        }
    };

    useEffect(() => {
        fetch(props.para_be + '/scan/authenticateUnit', postReqOptBuilder({'pid': pid, 'cid': cid}))
        .then(response => response.json())
        .then(response => {
            console.log(response);
            if(response.status === 200) {
                console.log("Can proceed");
                dispatch(postLogEntry( 
                    { 
                        activityID: pid, activityType: 'pid', ip: endUserData?.ip, 
                        action: {action: "open", target: "baselineV2"} 
                    }
                ));
            } else {
                console.log("Can't proceed");
                console.log(response);
                setStep(-5);
            }
            setLoadingPage(false);
        })
        .catch ( error => { 
            console.log("An error occurred while authenticating the unit:", error);
            setShowErrorScreen("Failed to authenticate your request. Please try again later.");
        });

        window.onbeforeunload = function() {
            return "Data will be lost if you leave the page. Are you sure?";
        };
        window.addEventListener('online', () => { setNetworkConnState(true); })
        window.addEventListener('offline', () => { setNetworkConnState(false); })
    }, []);

    useEffect(() => {
        stepRef.current = step;
        if (step === STEP_SCAN) {
            const startWait = new Date();
            while (((new Date()) - startWait) < 5000) {
                if (scanSess.current !== null) {
                    dispatch(postLogEntry( 
                        { 
                            activityID: `${pid}--${scanSess.current}`, activityType: 'pid--sessionID', 
                            ip: endUserData?.ip, action: {action: "start", target: "baseline"} 
                        }
                    ));
                    break;
                }
            }
        } else if (step === STEP_DONE) {
            dispatch(postLogEntry( 
                { 
                    activityID: `${pid}--${scanSess.current}`, activityType: 'pid--sessionID', 
                    ip: endUserData?.ip, action: {action: "uploaded", target: "baseline"} 
                }
            ));
        }
    }, [step]);

    useEffect(() => {
        if (recordedBlob) {
            console.log("New scan file recorded. Uploading...");
            if (recordedBlob.size === 0) {
                console.log("[!!DEBUG/ERROR] Empty scan file");
            } else if (!recordedBlob.type || recordedBlob.type === "") {
                console.log("[!!DEBUG/ERROR] Blob type is empty or undefined.");
            } else {
                setTimeout(() => {
                    uploadRecording(scanSess.current);
                }, 700);
            }
        } else {
            console.log("No scan file recorded yet.");
        }
    }, [recordedBlob]);

    useEffect(() => {
        if (videoDeviceDets === false) {
            setShowErrorScreen("Cannot perform scan on this device. There is no back facing camera available.");
        }
    }, [videoDeviceDets]);

    useEffect(() => {
        if (uploadStatus === "fail") {
            setNotifState({"type": "error", "msg": "Trying to work through your network issues."});
            setTimeout(() => {
                uploadRecording(scanSess.current);
            }, 3000);
        }
    }, [uploadStatus]);

    return (
        <section className={`scan-main${step === STEP_DONE ? " results-mode" : ""}`}>
            <Helmet>
                <title>Create Your Baseline Scan | Paraspot</title>
                <meta name="description" content="Experience Paraspot's leading AI scan from your mobile device."/>
                <meta property="og:title" content="Create Your Baseline Scan | Paraspot"/>
                <meta property="og:description" content="Experience Paraspot's leading AI scan from your mobile device."/>
            </Helmet>

            {debugMode &&
                <div className="debug-layer">
                    <div className="sockets-view">
                        <p className="text-3">
                            Network Status: {networkConnState ? "Online" : "Offline"}
                            <br/>
                            Debug Status: {debugStateStatus ? debugStateStatus : "N/A"}
                            <br/>
                            Media Recorder Options: {medRecOptionSelected ? JSON.stringify(medRecOptionSelected) : "N/A"}
                        </p>
                    </div>
                </div>
            }

            {
                loadingPage ?
                    <MainLoader/> :
                showErrorScreen !== false ?
                    <ScannerError errMsg={showErrorScreen === true ? undefined : showErrorScreen}/> :
                step === -5 ?
                    <div>Error!<br/>Invalid unit ID</div> :
                step === STEP_LOBBY ?
                    <Lobby
                        includeClientLogo={false}
                        onNext={() => switchStep(STEP_EXP)}
                        btnText="Next"
                    /> :
                step === STEP_EXP && videoDeviceDets === null ?
                    <CamSettingsLoader onFinishedLoading={setVideoDeviceDets} /> :
                step === STEP_EXP ?
                    <ScanVideoStep
                        vid={howToVid_NoBaseline}
                        text={
                            <p>
                                <b>Before you start</b><br/>
                                1. Connect to a stable internet connection. (Preferably WiFi)<br/>
                                2. Hold your phone Vertically.<br/>
                                3. Turn on the lights in all rooms.<br/>
                                4. Start from the entrance.<br/>
                            </p>
                        }
                        btnText={"Next"}
                        onBtnClick={() => switchStep(STEP_EXP2)}
                        step={1}
                        totalSteps={4}
                    /> :
                step === STEP_EXP2 ?
                    <ScanStep
                        img={step1x1}
                        imgx2={step1x2}
                        imgx3={step1x3}
                        text={
                            <p>
                                <b>How To</b><br/>
                                1. Make sure that you completely capture the inventory.<br/>
                                2. Move simply for the convenience of the tenant.<br/>
                                3. Move steady at a normal pace.<br/>
                                4. Scan each room thoroughly.
                            </p>
                        }
                        btnText={"Let's Start"}
                        onSkip={null}
                        onBtnClick={() => switchStep(STEP_SCAN)}
                        step={2}
                        totalSteps={4}
                    /> :
                step === STEP_SCAN ?
                    <VideoCameraIOS
                        FPS={FPS}
                        cameraConstraints={getFinalConstraints()}
                        finalizationCallback={finalizeScan}
                        showError={showError}
                        endUserInfo={{'uid': uid, 'cid': cid, 'pid': pid, 'token': token}}
                        camType={CAM_TYPE_BASELINE}
                        showTimeStamp={true}
                        setRecordedBlob={setRecordedBlob}
                        para_be={props.para_be}
                        transmitSid={(sid) => { scanSess.current = sid; }}
                        setMedRecOptions={setMedRecOptions}
                    /> :
                (step === STEP_DONE && 
                    <SimpleLoader 
                        loaderSuccess={true} 
                        msg={
                            <p><b>Well Done!</b><br/>You cleared the scan</p>
                        } 
                        successCallback={ () => {window.location.href=base_url + "/management"} }
                        {...(recordedBlob ?
                            {
                                extraElementEnd: <div className="mt-2">
                                    <Btn 
                                        type="secondary"
                                        style={{
                                            marginTop: '20px', 
                                            marginBottom: '10px'
                                        }} 
                                        text="Download Scan"
                                        onclick={downloadScanFile}
                                    />
                                    {debugMode &&
                                        <>
                                            <Btn 
                                                type="secondary" 
                                                text="Upload Scan" 
                                                style={{marginTop: '10px', marginBottom: '10px'}} 
                                                onclick={() => uploadRecording(scanSess.current)} 
                                            />
                                            <div className="text-2">
                                                Upload Status: {uploadStatus}
                                            </div>
                                        </>
                                    }
                                </div>
                            } : {}
                        )}
                    />
                )
            }
            {step !== STEP_DONE && finalizingScan ? 
                <SimpleLoader 
                    loadedPercentage={
                        (step === STEP_UPLOAD_FAILED ? 
                            null :
                            loadedPercentage
                        )
                    } 
                    msg={
                        step === STEP_LOADING_ISSUE ?
                            <>
                                Something Went Wrong<br/>
                                <div className="text-0">Do not leave the screen.</div>
                                {(recordedBlob && recordingUploadRate) ?
                                    <div className="text-5">
                                        Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                        <br/>
                                        Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                        <br/>
                                        Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                    </div> : ""
                                }
                                <br/>
                                <div className="text-3">
                                    We noticed an issue with your network connection.<br/>
                                    Please hang tight while we try to resolve it.
                                </div>
                            </> :
                        (step === STEP_RETRY_FILE_UPLOAD ?
                            <>
                                Loading<br/>
                                <div className="text-0">Do not leave the screen</div>
                                {(recordedBlob && recordingUploadRate) ?
                                    <div className="text-5">
                                        Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                        <br/>
                                        Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                        <br/>
                                        Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                    </div> : ""
                                }
                                <br/>
                                <div className="text-3">
                                    The network issue persists.<br/>
                                    Please hold on for a bit longer as we are trying to establish connection.
                                </div>
                            </> :
                        (step === STEP_LOADING_ISSUE_RESOLVED ?
                            <>
                                Loading<br/>
                                <div className="text-0">Do not leave the screen</div>
                                {(recordedBlob && recordingUploadRate) ?
                                    <div className="text-5">
                                        Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                        <br/>
                                        Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                        <br/>
                                        Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                    </div> : ""
                                }
                                <br/>
                                <div className="text-3">Issue resolved! You should be on  your way in just a bit. Thank you for your patience!</div>
                            </> : 
                        (step === STEP_UPLOAD_FAILED ?
                            <>
                                Upload Failed<br/>
                                <div className="text-0">Network Connectivity Issue</div><br/>
                                <div className="text-3">
                                    Your network connection is unavailable or unstable.<br/>
                                    We appreciate your patience as we tried to mediate the issue on your device.<br/>
                                    Please download the scan, by clicking on the Download Scan button below, and upload it once you are connected to a stable network.<br/>
                                    You may also retry to upload the scan by clicking on the Retry button below.<br/><br/>
                                    Thank you for your cooperation!
                                </div>
                            </> :
                            <>
                                Loading<br/>
                                <div className="text-0">Do not leave the screen.</div>
                                {(recordedBlob && recordingUploadRate) ?
                                    <div className="text-5">
                                        Scan Size: {Math.round(recordedBlob.size / 1024 / 1024 * 100) / 100} MB
                                        <br/>
                                        Estimated Time Left: {Math.floor(((recordedBlob.size / 1024 / 1024) * (100 - loadedPercentage) / 100) / recordingUploadRate)} Seconds
                                        <br/>
                                        Upload Rate: {Math.round(recordingUploadRate * 100) / 100} MB/s
                                    </div> : ""
                                }
                                <br/>
                                <div className="text-3">If your network connection is slow or unstable, you may experience longer upload times. We appreciate your patience!</div>
                            </>
                        )))
                    }
                    {...(recordedBlob ?
                            {
                                extraElementEnd: <div className="mt-2">
                                    <Btn 
                                        type="secondary"
                                        style={{
                                            marginTop: '20px', 
                                            marginBottom: '10px'
                                        }} 
                                        text="Download Scan"
                                        onclick={downloadScanFile}
                                    />
                                    {step === STEP_UPLOAD_FAILED &&
                                        <Btn 
                                            type="secondary" 
                                            text="Retry Upload" 
                                            style={{marginTop: '10px', marginBottom: '10px'}} 
                                            onclick={() => {
                                                switchStep(STEP_RETRY_FILE_UPLOAD);
                                                uploadRecording(scanSess.current);
                                            }} 
                                        />
                                    }
                                    {debugMode &&
                                        <>
                                            <Btn 
                                                type="secondary" 
                                                text="Upload Scan" 
                                                style={{marginTop: '10px', marginBottom: '10px'}} 
                                                onclick={() => uploadRecording(scanSess.current)} 
                                            />
                                            <div className="text-2">
                                                Upload Status: {uploadStatus}
                                            </div>
                                        </>
                                    }
                                </div>
                            } : {}
                    )}
                /> : ""}
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.msg}
                    type={notifState.type}/> : ""
            }
        </section>
    );
}
