import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios/axios';  // adjust the import path as needed
import { para_be } from '../../config';

// Async thunk to fetch unit baseline
export const fetchUnitBaseline = createAsyncThunk(
  'baselineScan/fetchUnitBaseline',
  async (pid, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${para_be}/units/get_unit_baseline?pid=${pid}`, {
        withCredentials: true
      });
      if (response.status >= 400) {
        return rejectWithValue(response.data.msg || 'Error fetching baseline scan');
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Network error');
    }
  }
);

const initialState = {
  baselineLink: null,
  baselineTimestamp: null,
  isEmptyState: false,
  loading: false,
  error: null,
};

const baselineScanSlice = createSlice({
  name: 'baselineScan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnitBaseline.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnitBaseline.fulfilled, (state, action) => {
        state.loading = false;
        const { status, result } = action.payload;
        if (status === 200 && result && result.length > 0) {
          state.baselineLink = result;
          state.isEmptyState = false;
          let ts = result.split("/").slice(-1)[0].split(".mp4")[0];
          state.baselineTimestamp = `${ts.slice(0,2)}/${ts.slice(2,4)}/${ts.slice(4,8)} ${ts.slice(8,10)}:${ts.slice(10,12)}:${ts.slice(12,14)} UTC`;
        } else {
          state.isEmptyState = true;
        }
      })
      .addCase(fetchUnitBaseline.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default baselineScanSlice.reducer;